export enum RichMessageType {
  Bubble = 'text',
  Suggestions = 'chips',
  Loading = 'loading',
  FileUpload = 'fileupload',
  DatePicker = 'datepicker',
  ZonedDatePicker = 'zoneddatepicker',
  Survey = 'survey',
  RestartSession = 'restart_session',
  Form = 'form',
  CustomHandler = 'custom_handler',
  MultipleCheckbox = 'multiple-selection',
  Input = 'text-input',
  ChatbotErrorHandler = 'chatbot-error-handler',
}
