import { Action, createAction, props } from '@ngrx/store';

import { BusinessInformationAssortmentFlowType } from '../../core/models/business-information-assortment-flow-type.enum';
import { BusinessInformationAssortmentSubmitAnswerRequest } from '../../core/models/business-information-assortment-submit-answer-request.model';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { BusinessMoratoriums } from '../../home/models/business-moratoriums.model';
import { AddressChangeValidationResult } from '../models/address-change-validation-result.model';
import { AuthorizedUser } from '../models/authorized-user.model';
import { Business } from '../models/business.model';
import { BusinessGeneralInfo } from '../models/business-general-info.model';
import { BusinessPeopleInfo } from '../models/business-people-info.model';
import { BusinessPropertyInfo } from '../models/business-property-info.model';
import { CertificateActivity } from '../models/certificate-activity.model';
import { CertificateActivityType } from '../models/certificate-activity-type.enum';

const loadBusiness = createAction('[Business API] Load Business', props<{ successActions?: Action[] }>());

const setBusiness = createAction('[Business] Set business', props<{ businessDetails: Business }>());

const setBusinessId = createAction('[Business] Set business id', props<{ businessId: string }>());

const setIsPhoneVerified = createAction('[Business] Set is user phone is verified', props<{ isPhoneVerified: boolean }>());

const setIsEmailVerified = createAction('[Business] Set is user email is verified', props<{ isEmailVerified: boolean }>());

const loadMoratoriums = createAction('[Business API] Load moratoriums');

const setMoratoriums = createAction('[Business] Set moratoriums', props<{ moratoriums: BusinessMoratoriums }>());

const loadCertificatesActivities = createAction('[Business] Load certificates activities', props<{ activitiesNumber: number }>());

const setCertificatesActivities = createAction(
  '[Business] Set certificates activities',
  props<{ certificatesActivities: CertificateActivity[] }>(),
);

const createCertificateActivity = createAction(
  '[Business] Create certificate activity',
  props<{ certificateActivityType: CertificateActivityType; liveCertificateId: string }>(),
);

const addAuthorizedUser = createAction('[Business API] Add authorized user', props<{ authorizedUser: AuthorizedUser }>());

const loadGeneralInfo = createAction('[Business API] Load general info');

const setGeneralInfo = createAction('[Business] Set general info', props<{ generalInfo: BusinessGeneralInfo }>());

const loadPeopleInfo = createAction('[Business API] Load people info');

const setPeopleInfo = createAction('[Business] Set people info', props<{ peopleInfo: BusinessPeopleInfo }>());

const loadPropertyInfo = createAction('[Business API] Load property info');
const loadIsEmailVerified = createAction('[Business API] Load is email verified');
const loadIsPhoneVerified = createAction('[Business API] Load is phone verified');

const setPropertyInfo = createAction('[Business] Set property info', props<{ propertyInfo: BusinessPropertyInfo }>());

const setAddressChangeValidationResult = createAction(
  '[Business] Set address change validation result',
  props<{ addressChangeValidationResult: AddressChangeValidationResult; addressControlName: string }>(),
);

const clearAddressChangeValidationResult = createAction('[Business] Clear address change validation result');

const loadSegmentByCob = createAction('[Business] load cob segment from server', props<{ cobId: number }>());

const setCobSegment = createAction('[Business] Set cob segment', props<{ cobSegment: CobSegment }>());

const setLoadingCobSegment = createAction('[Business] Set loading cob segment', props<{ isLoadingCobSegment: boolean }>());

const submitBusinessInformationAssortmentAnswer = createAction(
  '[Business] Set Business Information Assortment Answer',
  props<{ data: BusinessInformationAssortmentSubmitAnswerRequest }>(),
);

const loadIsEligibleForBusinessInformationAssortment = createAction(
  '[Business] Load Is Eligible For Business Information Assortment',
  props<{ businessInformationAssortmentFlowType: BusinessInformationAssortmentFlowType }>(),
);

const setIsEligibleForBusinessInformationAssortment = createAction(
  '[Business] Set Is Eligible For Business Information Assortment',
  props<{ businessInformationAssortmentFlowType: BusinessInformationAssortmentFlowType; isEligible: boolean }>(),
);

export const businessActions = {
  loadIsEmailVerified,
  loadIsPhoneVerified,
  loadBusiness,
  setBusiness,
  setBusinessId,
  setIsPhoneVerified,
  setIsEmailVerified,
  loadMoratoriums,
  setMoratoriums,
  loadCertificatesActivities,
  setCertificatesActivities,
  createCertificateActivity,
  addAuthorizedUser,
  loadGeneralInfo,
  setGeneralInfo,
  loadPeopleInfo,
  setPeopleInfo,
  loadPropertyInfo,
  setPropertyInfo,
  setAddressChangeValidationResult,
  clearAddressChangeValidationResult,
  loadSegmentByCob,
  setCobSegment,
  setLoadingCobSegment,
  submitBusinessInformationAssortmentAnswer,
  loadIsEligibleForBusinessInformationAssortment,
  setIsEligibleForBusinessInformationAssortment,
};
