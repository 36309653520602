export const JsonParserUtil = {
  tryParse<T>(data: string | unknown): T {
    try {
      let result;
      result = typeof data === 'string' ? (JSON.parse(data) as T) : null;
      return result;
    } catch {
      return null;
    }
  },
};
