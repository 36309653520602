import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { diffInMilliseconds } from '@next-insurance/date';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, filter, repeatWhen, takeUntil, tap, timeout } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { ChatbotAgentType } from '../../core/models/chatbot-agent-type.enum';
import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { chatbotConfig } from '../configs/chatbot.config';
import { ChatSessionParams } from '../models/chat-session-params.model';
import { InitChatResponse } from '../models/init-chat-response.model';
import { ChatbotTrackingService } from './chatbot-tracking.service';

@Injectable()
export class ChatSessionService implements OnDestroy {
  readonly sessionTime = chatbotConfig.sessionTime;
  lastMessageTime: number;
  private chatParameters$ = new BehaviorSubject<Record<string, string>>(null);
  private destroyed$ = new Subject<void>();
  private conversationEnded: boolean;
  private stop$: Subject<void>;
  private start$: Subject<void>;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private chatbotTrackingService: ChatbotTrackingService,
    private trackingService: TrackingService,
  ) {}

  _isSessionTimeout$ = new BehaviorSubject<boolean>(false);

  /**
   * Emit `true` when the dialogflow session is timed out
   */
  get isSessionTimeout$(): Observable<boolean> {
    return this._isSessionTimeout$.asObservable().pipe(filter((bool) => !!bool));
  }

  _isSessionAlmostTimeout$ = new BehaviorSubject<boolean>(false);

  /**
   * Emit `true` when the dialogflow session is half way of going timed out
   */
  get isSessionAlmostTimeout$(): Observable<boolean> {
    return this._isSessionAlmostTimeout$.asObservable().pipe(filter((bool) => !!bool));
  }

  private _sessionsStream$ = new Subject<void>();

  get sessionsStream$(): Observable<void> {
    return this._sessionsStream$.asObservable();
  }

  private _encryptedBusinessId: string;

  get encryptedBusinessId(): string {
    return this._encryptedBusinessId;
  }

  private _sessionId: string;

  get sessionId(): string {
    return this._sessionId;
  }

  private _agentId: string;

  get agentId(): string {
    return this._agentId;
  }

  private _projectId: string;

  get projectId(): string {
    return this._projectId;
  }

  get chatParameters(): Record<string, string> {
    return this.chatParameters$.getValue();
  }

  set chatParameters(value: Record<string, string>) {
    this.chatParameters$.next(value);
  }

  isConversationEnded(): boolean {
    return this.conversationEnded;
  }

  setIsConversationEnded(isEnded: boolean): void {
    this.conversationEnded = isEnded;
  }

  resetTimer(): void {
    this.lastMessageTime = Date.now();
    this.stop$.next();
    this.start$.next();
  }

  stopTimer(): void {
    this.stop$.next();
  }

  isSessionTimeout(): boolean {
    return this._isSessionTimeout$.getValue();
  }

  startNewSession(agentType: ChatbotAgentType): void {
    this._sessionId = this.generateSessionId();
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'chatbot',
      name: 'chatbot-startNewSession',
      interactionData: {
        sessionId: this._sessionId,
      },
    });
    this.initProjectAndAgentId();
    this.resetSubscriptions();
    this.chatParameters = null;
    this.initSessionTimer();
    this.initChatbot(agentType)
      .pipe(
        tap(({ encryptedBusinessId }) => {
          this._encryptedBusinessId = encryptedBusinessId;
        }),
      )
      .subscribe({
        next: () => this._sessionsStream$.next(),
        error: (err) => this._sessionsStream$.error(err),
      });
  }

  getSessionParams(agentType?: ChatbotAgentType): ChatSessionParams {
    return {
      projectId: EnvConfig.isStaging() || EnvConfig.isDev() ? this.projectId : null,
      agentId: EnvConfig.isStaging() || EnvConfig.isDev() ? this.agentId : null,
      agentType,
    };
  }

  ngOnDestroy(): void {
    this.cleanSubscriptions();
  }

  hasSessionTimeoutPassed(): boolean {
    return diffInMilliseconds(Date.now(), this.lastMessageTime) > this.sessionTime;
  }

  onSessionTimeEnded(): void {
    this._isSessionTimeout$.next(true);
  }

  private initChatbot(agentType: ChatbotAgentType): Observable<InitChatResponse> {
    return this.httpClient
      .post<InitChatResponse>('/api/chatbot/init-chat', {
        sessionId: this.sessionId,
        sessionParams: this.getSessionParams(agentType),
      })
      .pipe(
        timeout(chatbotConfig.initChatbotRequestTimeout),
        tap(() => this.chatbotTrackingService.trackInitChatApi(this.sessionId, true)),
        catchError((err) => {
          this.chatbotTrackingService.trackInitChatApi(this.sessionId, false, err.message);
          return throwError(err);
        }),
      );
  }

  private initSessionTimer(): void {
    this.lastMessageTime = Date.now();
    timer(this.sessionTime)
      .pipe(
        takeUntil(this.destroyed$),
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.stop$),
        repeatWhen(() => this.start$),
      )
      .subscribe(() => this._isSessionTimeout$.next(true));
    timer(this.sessionTime / 2)
      .pipe(
        takeUntil(this.destroyed$),
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.stop$),
        repeatWhen(() => this.start$),
      )
      .subscribe(() => this._isSessionAlmostTimeout$.next(true));
  }

  private generateSessionId(): string {
    return Math.random().toString(36).slice(-15);
  }

  private cleanSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private resetSubscriptions(): void {
    this.cleanSubscriptions();
    this.destroyed$ = new Subject();
    this.stop$ = new Subject();
    this.start$ = new Subject();
    this._isSessionTimeout$.next(false);
    this._isSessionAlmostTimeout$.next(false);
    this.conversationEnded = false;
  }

  private initProjectAndAgentId(): void {
    /**
     * this code is for debugging purposes,in case that someone want to change projectId or agentId via query params.
     * if the projectId or AgentId is null, they will be populate from the common props file.
     * */
    this.route.queryParamMap.subscribe((result: any) => {
      if (result.params) {
        const lowerParams: Params = {};
        for (const key in result.params) {
          lowerParams[key.toLowerCase()] = result.params[key];
        }
        this._projectId = lowerParams.projectid;
        this._agentId = lowerParams.agentid;
      }
    });
  }
}
