import { PackageData } from '@next-insurance/core';
import { Action, createReducer, on } from '@ngrx/store';

import { FlexibleCoverageOptions } from '../../coverages/models/flexible-coverage-options.model';
import { CoverageExamples } from '../models/coverage-examples.model';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';
import { policiesActions } from './policies.actions';

export interface PoliciesState {
  policiesInfo: PoliciesInfoResponse;
  packageData: { [policyId: number]: PackageData };
  flexibleCoverageOptions: Record<number, FlexibleCoverageOptions>;
  coverageExamples: { [lob: string]: CoverageExamples };
  isLoading: boolean;
  isLoadingPackageData: boolean;
  isLoadingFlexibleCoverageOptions: boolean;
}

export const policiesInitialState: PoliciesState = {
  policiesInfo: {
    policies: [],
    hasPartnerPolicies: false,
  },
  packageData: {},
  coverageExamples: {},
  isLoading: false,
  isLoadingPackageData: false,
  isLoadingFlexibleCoverageOptions: false,
  flexibleCoverageOptions: null,
};

const reducer = createReducer(
  policiesInitialState,
  on(policiesActions.loadPolicies, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(policiesActions.setPolicies, (state, action) => ({
    ...state,
    policiesInfo: action.policiesInfo,
    isLoading: false,
  })),
  on(policiesActions.loadPackageData, (state) => ({
    ...state,
    isLoadingPackageData: true,
  })),
  on(policiesActions.setPackageData, (state, action) => ({
    ...state,
    packageData: action.policiesPackageData,
    isLoadingPackageData: false,
  })),
  on(policiesActions.setCoverageExamples, (state, action) => ({
    ...state,
    coverageExamples: action.coverageExamples,
  })),
  on(policiesActions.loadFlexibleCoverageOptions, (state) => ({
    ...state,
    isLoadingFlexibleCoverageOptions: true,
    flexibleCoverageOptions: null,
  })),
  on(policiesActions.setFlexibleCoverageOptions, (state, action) => ({
    ...state,
    flexibleCoverageOptions: action.flexibleCoverageOptions,
    isLoadingFlexibleCoverageOptions: false,
  })),
  on(policiesActions.loadFlexibleCoverageOptionsFailed, (state) => ({
    ...state,
    isLoadingFlexibleCoverageOptions: false,
  })),
);

export function policiesReducer(state: PoliciesState, action: Action): PoliciesState {
  return reducer(state, action);
}
