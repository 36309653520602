import { createAction, props } from '@ngrx/store';

import { HolidayTheme } from '../models/holiday-themes.model';
import { UserType } from '../models/user-type.enum';

const setLoading = createAction('[Core] Set loading', props<{ isLoading: boolean }>());

const clearState = createAction('[Core] Clear state');

const setFooterVisibility = createAction('[Core] Set footer visibility', props<{ isFooterVisible: boolean }>());

const setIsUserLoggedIn = createAction('[Core] Set is user logged in', props<{ isUserLoggedIn: boolean }>());

const setIsAfterPurchase = createAction('[Core] Set is after purchase', props<{ isAfterPurchase: boolean }>());

const setIsLandedWithOperation = createAction('[Core] Set is landed with operation', props<{ isLandedWithOperation: boolean }>());

const setUserType = createAction('[Core] Set user type', props<{ userType: UserType }>());

const setNavigationTabsVisibility = createAction('[Core] Set navigation tabs visibility', props<{ isNavigationTabsVisible: boolean }>());

const loadHolidayTheme = createAction('[Core API] Load holiday theme');

const setHolidayTheme = createAction('[Core] Set holiday theme', props<{ holidayTheme: HolidayTheme }>());

const setIsSubmittingUserInteraction = createAction(
  '[Core] Set is submitting user interaction',
  props<{ isSubmittingUserInteraction: boolean }>(),
);

export const coreActions = {
  setLoading,
  clearState,
  setFooterVisibility,
  setIsUserLoggedIn,
  setIsAfterPurchase,
  setUserType,
  setNavigationTabsVisibility,
  loadHolidayTheme,
  setHolidayTheme,
  setIsSubmittingUserInteraction,
  setIsLandedWithOperation,
};
