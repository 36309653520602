import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { businessChangeActions } from '../../business-change/store/business-change.actions';
import { FullstoryEvent } from '../../core/models/fullstory-event.enum';
import { DynamicDialogService } from '../../core/services/dynamic-dialog.service';
import { FullStoryService } from '../../core/services/fullstory.service';
import { ReinstateAgreementModalComponent } from '../../coverage-page/components/reinstate-agreement-modal/reinstate-agreement-modal.component';
import { coveragesActions } from '../../coverages/store/coverages.actions';
import { crossSellActions } from '../../cross-sell/store/cross-sell.actions';
import { PaymentService } from '../../payment/payment.service';
import { Policy } from '../../policies/models/policy.model';
import { policiesActions } from '../../policies/store/policies.actions';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { LobNameService } from '../../shared/services/lob-name.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { ReinstateInfoResponse } from '../models/reinstate-info-response.model';
import { ReinstateResponseStatus } from '../models/reinstate-response-status.enum';
import { ReinstateDataService } from '../services/reinstate.data.service';
import { ReinstateTrackingService } from '../services/reinstate-tracking.service';
import { reinstateActions } from './reinstate.actions';
import { reinstateSelectors } from './reinstate.selectors';

@Injectable()
export class ReinstateEffects {
  reinstateErrorMap: Record<string, string> = {
    [ReinstateResponseStatus.FailedToAddReinstatePolicyChange]: 'REINSTATE.NOT_TWICE_REINSTATE_IN_SAME_DAT_TOAST',
    [ReinstateResponseStatus.ParallelAttemptFailure]: 'REINSTATE.MULTIPLE_REINSTATE_REQ_IN_PARALLEL_TOAST',
    reinstateNotActive: 'REINSTATE.REINSTATE_NOT_ACTIVE_TOAST',
  };

  readonly reinstateFetchCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateFetchCharge),
      switchMap((action: { policy: Policy }) => {
        return this.reinstateDataService.getReinstateCharge(action.policy.bundleId).pipe(
          map((reinstateInfoResponse: ReinstateInfoResponse) =>
            reinstateActions.reinstateSetCharge(reinstateInfoResponse.oneTimeChargeAmount),
          ),
          catchErrorAndLog(() => of(navigationActions.toError())),
        );
      }),
    ),
  );

  readonly reinstateRequestSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateRequestSubmit),
      withLatestFrom(this.store.select(reinstateSelectors.reinstatePolicy)),
      switchMap(([, policy]: [Action, Policy]) =>
        this.reinstateDataService.submitReinstate(policy.bundleId).pipe(
          map((status: ReinstateResponseStatus) => {
            switch (status) {
              case ReinstateResponseStatus.Success:
                return reinstateActions.reinstateFlowSuccess();
              case ReinstateResponseStatus.PaymentFailed:
                this.paymentService.openRelevantModalOnPaymentFailure({
                  isSubmittingAction$: this.store.select(reinstateSelectors.isSubmitting),
                  onPaymentUpdateFinished: () => {
                    this.store.dispatch(reinstateActions.reinstateUpdateCardSubmit());
                  },
                });
                return reinstateActions.setSubmitting({ isSubmitting: false });
              default:
                return reinstateActions.reinstateFlowFailed({ errorStatus: status });
            }
          }),
          catchErrorAndLog(() => of(reinstateActions.reinstateFlowFailed({}))),
          finalize(() => {
            this.dynamicDialogService.close(ReinstateAgreementModalComponent);
          }),
        ),
      ),
    ),
  );

  readonly reinstateFlowSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateFlowSuccess),
      withLatestFrom(this.store.select(reinstateSelectors.reinstatePolicy)),
      switchMap(([, policy]: [Action, Policy]) => {
        this.reinstateTrackingService.trackReinstateResult(true);
        this.fullstoryService.fireEvent(FullstoryEvent.ReinstatePolicy, { isSuccess: true });
        return [
          toastActions.showToast({
            toastType: ToastType.Success,
            message: 'REINSTATE.REINSTATE_SUCCESS_TOAST',
            translateParams: {
              lobName: this.lobNameService.getLobName(policy.lob),
            },
          }),
          policiesActions.loadPolicies({}),
          crossSellActions.loadCrossSell(),
          crossSellActions.loadWCSuggestion(),
          businessChangeActions.loadPermittedChanges(),
          coveragesActions.loadPackagesIfPermitted({
            policyId: policy.policyId,
          }),
        ];
      }),
    ),
  );

  readonly reinstateFlowFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateFlowFailed),
      map((action: { errorStatus?: ReinstateResponseStatus; type: string }) => {
        this.reinstateTrackingService.trackReinstateResult(false);
        this.fullstoryService.fireEvent(FullstoryEvent.ReinstatePolicy, {
          isSuccess: false,
          errorStatus: action.errorStatus,
        });

        if (action.errorStatus) {
          return toastActions.showToast({
            toastType: ToastType.Error,
            message: this.reinstateErrorMap[action.errorStatus],
          });
        }
        return toastActions.showGeneralErrorToast();
      }),
    ),
  );

  readonly reinstateUpdateCardSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateUpdateCardSubmit),
      withLatestFrom(this.store.select(reinstateSelectors.reinstatePolicy)),
      switchMap(([, policy]: [Action, Policy]) => {
        return this.reinstateDataService.submitReinstate(policy.bundleId).pipe(
          switchMap((response: ReinstateResponseStatus) => {
            if (response === ReinstateResponseStatus.Success) {
              return [reinstateActions.reinstateFlowSuccess()];
            }
            return [navigationActions.toError()];
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        );
      }),
    ),
  );

  readonly reinstateFlowNotActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reinstateActions.reinstateFlowNotActive),
      switchMap(() => [
        toastActions.showToast({
          toastType: ToastType.Error,
          message: this.reinstateErrorMap.reinstateNotActive,
        }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private reinstateDataService: ReinstateDataService,
    private reinstateTrackingService: ReinstateTrackingService,
    private store: Store<AppState>,
    private dynamicDialogService: DynamicDialogService,
    private fullstoryService: FullStoryService,
    private lobNameService: LobNameService,
    private paymentService: PaymentService,
  ) {}
}
