import { Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AppState } from '../../../../../store';
import { DesignationType } from '../../../models/designation-type.enum';
import { CertificatesTrackingService } from '../../../services/certificates-tracking.service';
import { certificatesActions } from '../../../store/certificates.actions';
import { DeleteCertificateModalData } from './delete-certificate-modal-data.model';

@Component({
  selector: 'ni-delete-certificate-modal',
  templateUrl: './delete-certificate-modal.component.html',
  styleUrls: ['./delete-certificate-modal.component.scss'],
})
export class DeleteCertificateModalComponent implements OnInit {
  modalData: DeleteCertificateModalData;
  isSubmitting: boolean;
  ButtonType = ButtonType;
  overrideDesignationsApproved: boolean;
  displayThirtyDaysNoticeWarning = false;
  displayLossPayeeNoticeWarning = false;

  constructor(
    private store: Store<AppState>,
    private certificatesTrackingService: CertificatesTrackingService,
    private dialogConfig: DynamicDialogConfig<DeleteCertificateModalData>,
    private dialogRef: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.isSubmitting = false;
    this.modalData = this.dialogConfig.data;
    if (this.modalData.existingActiveDesignationTypes) {
      this.displayThirtyDaysNoticeWarning = this.modalData.existingActiveDesignationTypes.includes(DesignationType.ThirtyDaysCancellation);
      this.displayLossPayeeNoticeWarning = this.modalData.existingActiveDesignationTypes.includes(DesignationType.LossPayee);
      this.overrideDesignationsApproved = !this.displayLossPayeeNoticeWarning;
    }
  }

  checkDesignationsApproval(event: Event): void {
    this.overrideDesignationsApproved = (<HTMLInputElement>event.target).checked;
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.certificatesTrackingService.trackDeleteCertificateModalSubmit();
    this.store.dispatch(
      certificatesActions.deleteCertificate({
        liveCertificateId: this.modalData.liveCertificateId,
        overrideDesignationsApproved: this.overrideDesignationsApproved,
      }),
    );
  }

  onCancel(): void {
    this.certificatesTrackingService.trackDeleteCertificateModalCancel();
    this.dialogRef.close();
  }
}
