import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { ChatbotAgentType } from '../../../core/models/chatbot-agent-type.enum';
import { ChatbotService } from '../../services/chatbot.service';

@Component({
  selector: 'ni-chat-title',
  templateUrl: './chat-title.component.html',
  styleUrls: ['./chat-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTitleComponent {
  @Input() minimizable: boolean;
  @Output() closed = new EventEmitter();
  @Output() minimized = new EventEmitter();

  protected readonly ChatbotAgentType = ChatbotAgentType;
  agentType = inject(ChatbotService).getAgentType();

  closeChat(): void {
    this.closed.emit();
  }

  minimizeChat(): void {
    this.minimized.emit();
  }
}
