<form [formGroup]="holderNameForm" class="payment-form p-sm_md" novalidate>
  <div class="credit-card-form-wrapper" data-test="credit-card-wrapper">
    <div class="form-controls-row">
      <div class="form-control-row name-on-card">
        <label class="ni-input-label-rb">
          {{ 'PAYMENT.PAYMENT_FORM.PAYMENT_NAME' | translate }}
        </label>
        <input
          autocomplete="cc-name"
          class="ni-input-rb"
          data-test="name-on-card"
          formControlName="holderName"
          id="holderName"
          niTrim
          placeholder="{{ 'PAYMENT.PAYMENT_FORM.FIRST_LAST' | translate }}"
          required
          type="text"
        />
      </div>

      <div class="form-control-row">
        <label class="ni-input-label-rb">
          {{ 'PAYMENT.PAYMENT_FORM.CARD_NUMBER' | translate }}
        </label>
        <div [ngClass]="{ 'invalid-input': errors.number }" class="ni-input-rb" data-test="card-number" id="{{ cardNumberSelector }}">
          <!-- Braintree hosted field will be here -->
        </div>
        <div *ngIf="errors.number" class="form-error-message" data-test="error-card-number">
          {{ 'PAYMENT.PAYMENT_FORM.INVALID.CARD_NUMBER' | translate }}
        </div>
      </div>
      <div class="form-control same-line">
        <div class="form-control-row exp-date">
          <label class="ni-input-label-rb">
            {{ 'PAYMENT.PAYMENT_FORM.EXPIRATION_DATE' | translate }}
          </label>
          <div
            [ngClass]="{ 'invalid-input': errors.expirationDate }"
            class="ni-input-rb"
            data-test="card-expiration"
            id="{{ cardExpirationSelector }}"
          >
            <!-- Braintree hosted field will be here -->
          </div>
          <div *ngIf="errors.expirationDate" class="form-error-message" data-test="error-card-expiry">
            {{ 'PAYMENT.PAYMENT_FORM.INVALID.EXPIRATION_DATE' | translate }}
          </div>
        </div>
        <div class="form-control-row">
          <label class="ni-input-label-rb">
            <span>{{ 'PAYMENT.PAYMENT_FORM.SECURITY_CODE' | translate }}</span>
          </label>
          <div [ngClass]="{ 'invalid-input': errors.cvv }" class="ni-input-rb" data-test="cvc" id="{{ cardSecurityCodeSelector }}">
            <!-- Braintree hosted field will be here -->
          </div>
          <div *ngIf="errors.cvv" class="form-error-message" data-test="error-card-cvc">
            {{ 'PAYMENT.PAYMENT_FORM.INVALID.SECURITY_CODE' | translate }}
          </div>
        </div>
      </div>
      <div class="form-control-row credit-card-footer">
        <span class="secure-text p-xs_sm secure-icon">{{ 'PAYMENT.PAYMENT_FORM.SECURE_PAYMENT' | translate }}</span>
        <ni-credit-card-icons></ni-credit-card-icons>
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <ni-button-rb
      (click)="submit()"
      [disabled]="(!holderNameForm.valid || !isBraintreeFormValid) && isSubmitDisabled"
      [isLoading]="isSubmitting"
      class="submit-button"
      data-test="payment-update-submit"
      id="submit"
    >
      {{ 'GENERAL.CONFIRM' | translate }}
    </ni-button-rb>
  </div>
</form>
