import { inject, Pipe, PipeTransform } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { LobNameService } from '../services/lob-name.service';

@Pipe({
  name: 'lobName',
  standalone: true,
})
export class LobNamePipe implements PipeTransform {
  private lobNameService = inject(LobNameService);

  transform(lob: LOB, displayType?: 'initials' | 'short', cob?: number): string {
    return this.lobNameService.getLobName(lob, displayType, cob);
  }
}
