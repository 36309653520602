import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'ni-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClickOutsideDirective, NgIf, NgClass],
})
export class MenuComponent {
  @Input() icon = true;
  isMenuVisible: boolean;
  @Output() clicked = new EventEmitter();
  @Input() onHover?: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  openMenuOnHover(): void {
    if (this.onHover) {
      this.isMenuVisible = true;
      this.cdr.markForCheck();
    }
  }

  closeMenuOnHover(): void {
    if (this.onHover) {
      this.closeMenu();
    }
  }

  closeMenu(): void {
    this.isMenuVisible = false;
    this.cdr.markForCheck();
  }

  onClick(evt: MouseEvent): void {
    evt.stopPropagation();
    this.isMenuVisible = !this.isMenuVisible;
    this.cdr.markForCheck();
    this.clicked.emit();
  }
}
