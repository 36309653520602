import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { navigationActions } from '../../store/navigation.actions';
import { ClaimDetails } from '../models/claim-details.model';
import { ClaimsDataService } from '../services/claims.data.service';
import { claimsActions } from './claims.actions';

@Injectable()
export class ClaimsEffects {
  loadClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsActions.loadClaims),
      switchMap(() =>
        this.claimsDataService.getClaims().pipe(
          map((claims: ClaimDetails[]) => {
            return claimsActions.setClaims({ claims });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private claimsDataService: ClaimsDataService,
  ) {}
}
