import { LOB } from '@next-insurance/core';
import { CrossSellOrigin } from '../../core/models/cross-sell-origin.enum';

export enum ExternalCrossSellTrackingParams {
  NEXT_INSURANCE_USER = 'next_insurance_user',
}

export const externalCrossSellLinks: Partial<Record<LOB, string>> = {
  [LOB.CA]: `https://nextinsurance.tfaforms.net/4979973?`,
  [LOB.WC]: `https://nextinsurance.tfaforms.net/4979969?`,
  [LOB.PL]: `https://nextinsurance.tfaforms.net/4979986?`,
  [LOB.CP]: `https://nextinsurance.tfaforms.net/4979990?`,
  [LOB.IM]: `https://nextinsurance.tfaforms.net/4979986?`,
};

export const leadSourceParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2935',
  [LOB.WC]: 'tfa_2948',
  [LOB.PL]: 'tfa_2802',
  [LOB.CP]: 'tfa_2838',
  [LOB.IM]: 'tfa_2802',
};

export const affiliateIdParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2934',
  [LOB.WC]: 'tfa_2947',
  [LOB.PL]: 'tfa_2801',
  [LOB.CP]: 'tfa_2836',
  [LOB.IM]: 'tfa_2801',
};

export const trackingIdParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2930',
  [LOB.WC]: 'tfa_2794',
  [LOB.PL]: 'tfa_2794',
  [LOB.CP]: 'tfa_2822',
  [LOB.IM]: 'tfa_2794',
};

export const businessIdParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2924',
  [LOB.WC]: 'tfa_2789',
  [LOB.PL]: 'tfa_2789',
  [LOB.CP]: 'tfa_2823',
  [LOB.IM]: 'tfa_2789',
};

export const campaignParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2775',
  [LOB.WC]: 'tfa_2775',
  [LOB.PL]: 'tfa_2775',
  [LOB.CP]: 'tfa_2821',
  [LOB.IM]: 'tfa_2775',
};

export const channelParamByLob: Partial<Record<LOB, string>> = {
  [LOB.CA]: 'tfa_2774',
  [LOB.WC]: 'tfa_2774',
  [LOB.PL]: 'tfa_2774',
  [LOB.CP]: 'tfa_2820',
  [LOB.IM]: 'tfa_2774',
};

export const crossSellOriginParamByCrossSellOrigin: Partial<Record<CrossSellOrigin, string>> = {
  [CrossSellOrigin.CrossSell]: 'Direct_Portal',
  [CrossSellOrigin.CrossSellAffiliatePartner]: 'Direct_Portal_PS',
  [CrossSellOrigin.WcBanner]: 'Direct_Portal_Banner',
};
