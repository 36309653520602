import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CancelRewriteCompleteResponse } from '../models/cancel-rewrite-complete-response.model';
import { CancelRewritePaymentDetails } from '../models/cancel-rewrite-payment-details.model';

@Injectable({
  providedIn: 'root',
})
export class CancelRewriteDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getPaymentDetails(policyId: string): Observable<CancelRewritePaymentDetails> {
    return this.httpClient.get<CancelRewritePaymentDetails>('/api/cancel-rewrite/payment-details/v2', {
      params: {
        policyId,
      },
    });
  }

  /* istanbul ignore next */
  completeCancelRewrite(policyId: string, quoteRequestId: string): Observable<CancelRewriteCompleteResponse> {
    return this.httpClient.post<CancelRewriteCompleteResponse>('/api/cancel-rewrite/complete/v2', {
      policyId,
      quoteRequestId,
    });
  }
}
