import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[niAutoGrow]',
})
export class AutoGrowDirective {
  @Input() maxHeightBeforeScroll = 200;

  @HostListener('input')
  onInput(): void {
    this.adjust();
  }

  @HostListener('focus')
  onFocus(): void {
    this.adjust();
  }

  @HostListener('ngModelChange')
  onNgModelChange(): void {
    this.adjust();
  }

  @HostListener('change')
  onChange(): void {
    this.adjust();
  }

  @HostListener('blur')
  onBlur(): void {
    this.adjust();
  }

  constructor(private elementRef: ElementRef) {}

  adjust(): void {
    const el: HTMLTextAreaElement = this.elementRef.nativeElement;
    el.style.overflow = 'auto';
    el.style.height = 'auto';
    if (el.scrollHeight >= this.maxHeightBeforeScroll) {
      el.style.height = `${this.maxHeightBeforeScroll}px`;
    }
  }
}
