import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { convertEnumCaseToReadableString } from '../../../core/helpers/string-helper';
import { InteractionType } from '../../../core/models/interaction-type.enum';
import { TrackingService } from '../../../core/services/tracking.service';
import { AppState } from '../../../store';
import { CertificateShareOption } from '../models/certificate-share-option.enum';
import { CertificateType } from '../models/certificate-type.enum';
import { ThirdPartyRequestType } from '../models/third-party-request-type.enum';
import { certificatesSelectors } from '../store/certifiactes.selectors';

@Injectable({
  providedIn: 'root',
})
export class LiveCertificateTrackingService {
  readonly liveCertificatesPage = 'live-certificate-page';
  readonly thirdPartyRequestNotifications = 'request-notifications-live-certificate-page';
  readonly sendCertificateModal = 'send-certificate-modal';

  constructor(
    private trackingService: TrackingService,
    private store: Store<AppState>,
  ) {}

  /* istanbul ignore next */
  trackLiveCertificatePageLanding(certificateType: CertificateType, isAuthenticatedUser: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.liveCertificatesPage,
      name: 'live-certificate-page-landing',
      interactionData: {
        certificateType,
        isAuthenticatedUser,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackLobExpandCollapseClick(action: 'COLLAPSE' | 'EXPAND', lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'lob-expand-collapse-click',
      interactionData: {
        action,
        lob,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackAcordCertificateClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'acord-certificate-click',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackAcordCertificateOpened(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.liveCertificatesPage,
      name: 'acord-certificate-opened',
      interactionData: {
        isSuccess,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackShareOptionClick(action: CertificateShareOption): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'share-option-click',
      interactionData: {
        action,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `live certificate page - CLICK a share option - ${convertEnumCaseToReadableString(action)}`,
    });
  }

  /* istanbul ignore next */
  trackShareClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'share-click',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackSeeFullCoverageClick(policyId: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'see-full-coverage-click',
      interactionData: {
        policyId,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `live certificate page - CLICK see full coverage`,
    });
  }

  /* istanbul ignore next */
  trackErrorPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.liveCertificatesPage,
      name: 'error-page-landing',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyRequestClick(type: ThirdPartyRequestType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.liveCertificatesPage,
      name: 'third-party-request-click',
      interactionData: {
        type,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyNotificationsRequestLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.thirdPartyRequestNotifications,
      name: 'certificate-request-notifications-landing',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyNotificationsRequestSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.thirdPartyRequestNotifications,
      name: 'submit-button',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyRequestResult(isSuccess: boolean, type: ThirdPartyRequestType, requestId?: string, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.liveCertificatesPage,
      name: 'third-party-request-result',
      interactionData: {
        isSuccess,
        requestId,
        type,
        errorReason,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyNotificationsRequestToggleChangesList(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.thirdPartyRequestNotifications,
      name: 'toggle-changes-list',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackSendCertificateSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.sendCertificateModal,
      name: 'submit-button',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackSendCertificateClose(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.sendCertificateModal,
      name: 'close-button',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackSendCertificateResult(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.sendCertificateModal,
      name: 'send-certificate-result',
      interactionData: {
        isSuccess,
        errorReason,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  private get liveCertificateId(): string {
    let liveCertificateId: string;
    this.store.pipe(select(certificatesSelectors.getLiveCertificateId), first()).subscribe((id) => {
      liveCertificateId = id;
    });
    return liveCertificateId;
  }
}
