import { environment } from '../../../../../environments/environment';

export const errorsFilterConfig = {
  filters: {
    apiErrorFilter: {
      statusCodes: [0, 400, 401, 403, 500],
      apiUrls: [`${environment.url}/api/`, '/api/', 'api/'],
    },
    urlsFilter: {
      denyUrls: [] as string[],
    },
  },
};
