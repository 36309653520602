import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FormFieldTypes } from '../../enums/form-field-types.enum';
import { FormFieldRichMessageContent } from '../../models/form-field-rich-message-content.model';
import { phoneNumberMask } from '../../utils/masks';
import { emailAddressPattern, stateCodePattern, zipCodeNumberPattern } from '../../utils/patterns';

@Component({
  selector: 'ni-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormFieldComponent {
  @Input() dynamicFormField!: FormFieldRichMessageContent;
  @Input() form!: UntypedFormGroup;
  readonly FormFieldTypes = FormFieldTypes;
  readonly phoneNumberMask = phoneNumberMask;
  readonly emailAddressPattern = emailAddressPattern;
  readonly stateCodePattern = stateCodePattern;
  readonly zipCodeNumberPattern = zipCodeNumberPattern;
  readonly minPositiveNumber = 0;
  readonly maxNumber = 999999999999999; // 9 - 15 times
  readonly positiveIntegerConfig = {
    min: this.minPositiveNumber,
    max: this.maxNumber,
    pattern: '[0-9]*',
  };

  readonly positiveDoubleConfig = {
    min: this.minPositiveNumber,
    max: this.maxNumber,
    maxLength: 15,
  };

  readonly vinNumberErrorMessage = 'Vin number must be exactly 17 digits';
  readonly vinNumberErrors = {
    maxlength: this.vinNumberErrorMessage,
    minlength: this.vinNumberErrorMessage,
  };

  readonly yearErrorMessage = 'input must be a valid 4 digits year';
  readonly yearErrors = {
    min: this.yearErrorMessage,
    max: this.yearErrorMessage,
  };

  readonly maxYear = new Date().getFullYear() + 1;
  readonly minYear = 1850;

  readonly zipCodeErrors = {
    pattern: 'zip code must be exactly 5 digits',
  };

  readonly licenseStateErrorMessage = {
    pattern: 'Please enter a valid license state, example: TX',
  };
}
