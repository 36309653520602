import { createAction, props } from '@ngrx/store';

import { AbTest, AbTestsMap } from '../models/ab-test.model';
import { AbTestSection } from '../models/ab-test-section.enum';
import { LoadAbTestsRequest } from '../models/load-ab-tests-request.model';

export const abTestingActions = {
  loadSectionAbTests: createAction('[A/B Testing API] load A/B tests', props<LoadAbTestsRequest>()),
  setSectionAbTests: createAction('[A/B Testing] set A/B tests', props<{ abTestsMap: AbTestsMap; section: AbTestSection }>()),
  resetSectionAbTests: createAction('[A/B Testing] reset A/B tests', props<{ section: AbTestSection }>()),
  pairAbTest: createAction('[A/B Testing API] pair A/B test', props<{ abTest: AbTest; section: AbTestSection }>()),
  trackAbTest: createAction('[A/B Testing API] track A/B test', props<{ abTest: AbTest; section: AbTestSection }>()),
  onError: createAction('[A/B Testing] On error', props<{ section: AbTestSection }>()),
};
