import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { AppState } from '../../../store';

@Component({
  selector: 'ni-not-proprietary-policies-banner',
  templateUrl: './not-proprietary-policies-banner.component.html',
  styleUrls: ['./not-proprietary-policies-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotProprietaryPoliciesBannerComponent implements OnInit {
  hasPartnerPolicies$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.hasPartnerPolicies$ = this.store.select(policiesSelectors.hasPartnerPolicies);
  }
}
