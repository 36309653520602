<div class="ni-dialog-content center p-sm_md">
  <div class="ni-dialog-title center" data-test="remove-follower-modal">
    <img src="../../../../assets/rebrand/alert-icon.svg" alt="alert" />
    <div class="h6_h4-regular">{{ 'CERTIFICATES.FOLLOWERS.REMOVE_FOLLOWER.TITLE' | translate }}</div>
  </div>
  <div class="content">
    {{ 'CERTIFICATES.FOLLOWERS.REMOVE_FOLLOWER.MESSAGE' | translate }}
  </div>
</div>
<div class="ni-dialog-footer">
  <ni-button-rb
    (click)="onSubmit()"
    [isLoading]="isSubmitting"
    [disabled]="isSubmitting"
    [buttonType]="ButtonType.Destructive"
    data-test="submit-button"
  >
    {{ 'CERTIFICATES.FOLLOWERS.REMOVE_FOLLOWER.SUBMIT' | translate }}
  </ni-button-rb>
  <ni-button-rb (click)="onCancel()" [disabled]="isSubmitting" [buttonType]="ButtonType.Tertiary" data-test="cancel-button"
    >{{ 'CERTIFICATES.FOLLOWERS.REMOVE_FOLLOWER.CANCEL' | translate }}
  </ni-button-rb>
</div>
