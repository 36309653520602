import { Directive, ElementRef, Inject } from '@angular/core';
import { WINDOW } from '@next-insurance/ng-core';

@Directive({
  selector: 'input[niOnlyDigits]',
})
export class OnlyDigitsDirective {
  constructor(
    public el: ElementRef,
    @Inject(WINDOW) private window: Window,
  ) {
    this.el.nativeElement.addEventListener('keypress', (evt: KeyboardEvent): void => {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    });

    this.el.nativeElement.addEventListener('paste', (evt: ClipboardEvent): boolean => {
      let paste = (evt.clipboardData || this.window?.clipboardData)?.getData('text');

      // Filtering just digits.
      paste = [...paste].filter((char) => char.match(/\d/)).join('');

      const selection = this.window.getSelection();
      if (!selection.rangeCount) return;
      selection.deleteFromDocument();
      this.el.nativeElement.value = paste;
      this.el.nativeElement.dispatchEvent(new InputEvent('input', { data: paste }));
      evt.preventDefault();
    });
  }
}
