import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import { businessChangeActions } from '../../business-change/store/business-change.actions';
import { FullstoryEvent } from '../../core/models/fullstory-event.enum';
import { FullStoryService } from '../../core/services/fullstory.service';
import { crossSellActions } from '../../cross-sell/store/cross-sell.actions';
import { policiesActions } from '../../policies/store/policies.actions';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { navigationActions } from '../../store/navigation.actions';
import { CancelRewriteCompleteStatus, CancelRewriteCompleteStatusV2 } from '../models/cancel-rewrite-complete-status.enum';
import {
  CancelRewriteGetPaymentDetailsStatus,
  CancelRewriteGetPaymentDetailsStatusV2,
} from '../models/cancel-rewrite-payment-details-status.enum';
import { CancelRewriteDataService } from '../services/cancel-rewrite.data.service';
import { CancelRewriteTrackingService } from '../services/cancel-rewrite-tracking.service';
import { cancelRewriteActions } from './cancel-rewrite.actions';

@Injectable()
export class CancelRewriteEffects {
  cancelRewriteErrorMapOld: Record<string, string> = {
    [CancelRewriteGetPaymentDetailsStatus.SessionExpired]: 'CANCEL_REWRITE.EXPIRED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatus.PaymentFailed]: 'CANCEL_REWRITE.PAYMENT_FAILED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatus.CancelRewriteAlreadyCompleted]: 'CANCEL_REWRITE.ALREADY_COMPLETED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatus.ApplyInProgress]: 'CANCEL_REWRITE.INVALID_INPUT_ERROR_MESSAGE',
    [CancelRewriteCompleteStatus.TimeExpired]: 'CANCEL_REWRITE.EXPIRED_ERROR_MESSAGE',
    [CancelRewriteCompleteStatus.PolicyHasUpdatedRequest]: 'CANCEL_REWRITE.POLICY_HAS_UPDATED_REQUEST_ERROR_MESSAGE',
    [CancelRewriteCompleteStatus.InvalidInput]: 'CANCEL_REWRITE.INVALID_INPUT_ERROR_MESSAGE',
    [CancelRewriteCompleteStatus.FailedToCreatePoliciesOfferExpired]: 'CANCEL_REWRITE.FAILED_TO_CREATE_POLICIES_OFFER_EXPIRED',
  };

  cancelRewriteErrorMap: Record<string, string> = {
    [CancelRewriteGetPaymentDetailsStatusV2.SessionExpired]: 'CANCEL_REWRITE.EXPIRED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatusV2.PaymentFailed]: 'CANCEL_REWRITE.PAYMENT_FAILED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatusV2.CancelRewriteAlreadyCompleted]: 'CANCEL_REWRITE.ALREADY_COMPLETED_ERROR_MESSAGE',
    [CancelRewriteGetPaymentDetailsStatusV2.ApplyInProgress]: 'CANCEL_REWRITE.INVALID_INPUT_ERROR_MESSAGE',
    [CancelRewriteCompleteStatusV2.TimeExpired]: 'CANCEL_REWRITE.EXPIRED_ERROR_MESSAGE',
    [CancelRewriteCompleteStatusV2.PolicyHasUpdatedRequest]: 'CANCEL_REWRITE.POLICY_HAS_UPDATED_REQUEST_ERROR_MESSAGE',
    [CancelRewriteCompleteStatusV2.InvalidInput]: 'CANCEL_REWRITE.INVALID_INPUT_ERROR_MESSAGE',
    [CancelRewriteCompleteStatusV2.FailedToCreatePoliciesOfferExpired]: 'CANCEL_REWRITE.FAILED_TO_CREATE_POLICIES_OFFER_EXPIRED',
  };

  readonly completeCancelRewrite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelRewriteActions.completeCancelRewrite),
      switchMap((action: { policyId: string; quoteRequestId: string; type: string }) =>
        this.cancelRewriteDataService.completeCancelRewrite(action.policyId, action.quoteRequestId).pipe(
          map(() => {
            return cancelRewriteActions.cancelRewriteCompleted();
          }),
          catchErrorAndLog((error) => {
            const status = (error.error && error.error.niStatusCode) || CancelRewriteCompleteStatus.GeneralError;
            return of(cancelRewriteActions.cancelRewriteFailed({ status }));
          }),
        ),
      ),
    ),
  );

  readonly cancelRewriteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelRewriteActions.cancelRewriteCompleted),
      concatMap(() => {
        this.cancelRewriteTrackingService.trackCancelRewriteCompleted();
        this.fullstoryService.fireEvent(FullstoryEvent.CancelRewrite);
        return [
          businessChangeActions.loadPermittedChanges(),
          policiesActions.loadPolicies({}),
          crossSellActions.loadCrossSell(),
          navigationActions.toHomePage({}),
          toastActions.showToast({
            toastType: ToastType.Success,
            message: 'CANCEL_REWRITE.SUCCESS_MESSAGE',
          }),
        ];
      }),
    ),
  );

  readonly cancelRewriteFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelRewriteActions.cancelRewriteFailed),
      concatMap(
        (action: {
          status:
            | CancelRewriteCompleteStatus
            | CancelRewriteGetPaymentDetailsStatus
            | CancelRewriteCompleteStatusV2
            | CancelRewriteGetPaymentDetailsStatusV2;
          type: string;
        }) => {
          this.cancelRewriteTrackingService.trackCancelRewriteFailed();
          const errorMessage = this.cancelRewriteErrorMap[action.status] || 'CANCEL_REWRITE.GENERAL_ERROR';
          this.fullstoryService.fireEvent(FullstoryEvent.CancelRewrite, { errorMessage });
          return [
            navigationActions.toHomePage({}),
            errorMessage
              ? toastActions.showToast({
                  toastType: ToastType.Error,
                  message: errorMessage,
                  options: {
                    timeOut: 10000,
                  },
                })
              : toastActions.showGeneralErrorToast(),
          ];
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private fullstoryService: FullStoryService,
    private cancelRewriteDataService: CancelRewriteDataService,
    private cancelRewriteTrackingService: CancelRewriteTrackingService,
  ) {}
}
