import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { isUserType } from '../helpers/type.helper';
import { QueryParams } from '../models/query-params.enum';
import { coreActions } from '../store/core.actions';

export const userTypeGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean => {
  const store = inject(Store<AppState>);
  const userType = next.queryParams[QueryParams.UserType];
  if (isUserType(userType)) {
    store.dispatch(coreActions.setUserType({ userType }));
  }

  return true;
};
