<div class="expandable-item" [ngClass]="{ 'not-expanded': !expanded }">
  <div
    class="expand-title p-sm_lg"
    data-test="expand-title"
    [routerLink]="[tab.routerLink]"
    (click)="onTabClick(tab)"
    routerLinkActive="active"
    [ngClass]="{ active: routerLinkActive }"
  >
    <ng-container *ngIf="tab.tabs && (!tab.isTabsHidden$ || (tab.isTabsHidden$ | async) === false); else title">
      <span class="row" data-test="expand-row" (click)="toggle($event)">
        <ng-container [ngTemplateOutlet]="title"></ng-container>
        <ng-container>
          <fa-icon [hidden]="!expanded" class="chevron-up" [icon]="faChevronUp"></fa-icon>
          <fa-icon [hidden]="expanded" class="chevron-down" [icon]="faChevronDown"></fa-icon>
        </ng-container>
      </span>
    </ng-container>
  </div>
  <ng-container *ngIf="tab.tabs && (!tab.isTabsHidden$ || (tab.isTabsHidden$ | async) === false)">
    <div [@openClose]="expanded ? 'open' : 'closed'" [hidden]="!expanded" class="expanded-content" data-test="expand-content">
      <div class="sub-tab-container" *ngFor="let subTab of tab.tabs">
        <div
          *ngIf="!subTab.hidden$ || (subTab.hidden$ | async) === false"
          class="sub-tab p-xs_sm"
          routerLinkActive="active"
          [routerLink]="subTab.routerLink"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.data-sub-tab]="subTab.id"
          (click)="onTabClick(subTab)"
        >
          {{ subTab.displayName + '.LONG_NAME' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #title>
  <div class="tab" [attr.data-tab]="tab.id" [niHelpTip]="tab.helpTip">
    <img [alt]="tab.id" [src]="tab.icon" class="tab-icon" />
    {{ tab.displayName + '.SHORT_NAME' | translate }}
  </div>
</ng-template>
