import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats, transformDate } from '@next-insurance/date';

import { LocalDate } from '../../core/models/local-date.model';

@Pipe({
  name: 'localDate',
  standalone: true,
})
export class LocalDatePipe implements PipeTransform {
  transform(date: LocalDate, format: string = DateFormats.SlashesDate): string {
    if (!date) {
      return null;
    }
    return transformDate(date, format);
  }
}
