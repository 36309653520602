import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { delay, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { businessActions } from '../../../business/store/business.actions';
import { businessSelectors } from '../../../business/store/business.selectors';
import { BusinessInformationAssortmentFlowType } from '../../../core/models/business-information-assortment-flow-type.enum';
import { BusinessInformationAssortmentQuestionType } from '../../../core/models/business-information-assortment-questions-type.enum';
import { BusinessInformationAssortmentSubmitAnswerRequest } from '../../../core/models/business-information-assortment-submit-answer-request.model';
import { MobileAppEventId } from '../../../core/models/mobile-app-event.model';
import { BusinessInformationAssortmentDataService } from '../../../core/services/business-information-assortment.data.service';
import { DynamicDialogService } from '../../../core/services/dynamic-dialog.service';
import { MobileAppService } from '../../../core/services/mobile-app.service';
import { MobileAppEventsService } from '../../../core/services/mobile-app-events.service';
import { CreateFollowerResponse } from '../../../followers/models/follower.model';
import { LoadingMessageOverlayModalComponent } from '../../../shared/components/loading-message-overlay-modal/loading-message-overlay-modal.component';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { HidePhoneNumberPipe } from '../../../shared/pipes/hide-phone-number.pipe';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { navigationActions } from '../../../store/navigation.actions';
import { CreateCertificateFromHolderRequirements } from '../models/create-certificate-from-holder-requirements.model';
import { CreateCertificateRequest } from '../models/create-certificate-request.model';
import { CreateCertificateResponse } from '../models/create-certificate-response.model';
import { ThirdPartyNotificationsRequestErrorReason } from '../models/third-party-request-error-reason.enum';
import { certificatesSelectors } from '../store/certifiactes.selectors';
import { certificatesActions } from '../store/certificates.actions';
import { CertificatesDataService } from './certificates.data.service';
import { CertificatesTrackingService } from './certificates-tracking.service';

@Injectable({ providedIn: 'root' })
export class CreateCertificateService {
  constructor(
    private store: Store<AppState>,
    private certificatesDataService: CertificatesDataService,
    private dynamicDialogService: DynamicDialogService,
    private mobileAppEventsService: MobileAppEventsService,
    private certificatesTrackingService: CertificatesTrackingService,
    private hidePhoneNumberPipe: HidePhoneNumberPipe,
    private translateService: TranslateService,
    private businessInformationAssortmentDataService: BusinessInformationAssortmentDataService,
    private mobileAppService: MobileAppService,
  ) {}

  submitCreateCertificate(isAfterPaymentCardUpdate?: boolean): Observable<CreateCertificateResponse> {
    const request = this.getCreateCertificateRequest();
    const requestFromHolderRequest = this.getCreateCertificateFromHolderRequirements();
    const createCertificateResult$ = requestFromHolderRequest?.certificateHolderRequirementId
      ? this.certificatesDataService.createCustomCertificateFromHolderRequest(requestFromHolderRequest)
      : this.certificatesDataService.createCustomCertificate(request);

    return createCertificateResult$.pipe(
      delay(isAfterPaymentCardUpdate ? 0 : 5000),
      tap((createCertificateResponse: CreateCertificateResponse) => {
        this.createCertificateSuccess(createCertificateResponse, request, requestFromHolderRequest);
      }),
      catchErrorAndLog(() => {
        this.createCertificateFailed('CERTIFICATES.CREATE_CERTIFICATE_OLD.CREATE_CERTIFICATE_ERROR');
        return of(null);
      }),
      finalize(() => {
        if (!isAfterPaymentCardUpdate) {
          this.dynamicDialogService.close(LoadingMessageOverlayModalComponent);
        }
      }),
    );
  }

  createCertificateFailed(errorMessage: string): void {
    const createCertificateRequest = this.getCreateCertificateRequest();
    this.certificatesTrackingService.trackCustomCertificateCreated(
      false,
      createCertificateRequest?.certificateDesignations
        ? Object.keys(createCertificateRequest.certificateDesignations.designationTypeToLob)
        : null,
    );

    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({
        closeWebview: false,
        eventId: MobileAppEventId.TechnicalError,
        errorMessage,
      });
    } else {
      this.store.dispatch(
        toastActions.showToast({
          toastType: ToastType.Error,
          title: errorMessage,
          message: this.hidePhoneNumberPipe.transform('GENERAL.ERRORS.TOAST.CONTENT'),
        }),
      );
      this.store.dispatch(navigationActions.toCertificates());
    }
  }

  private createCertificateSuccess(
    createCertificateResponse: CreateCertificateResponse,
    createCertificateRequest: CreateCertificateRequest,
    requestFromHolderRequirements: CreateCertificateFromHolderRequirements,
  ): void {
    this.certificatesTrackingService.trackCustomCertificateCreated(
      true,
      createCertificateRequest.certificateDesignations
        ? Object.keys(createCertificateRequest.certificateDesignations.designationTypeToLob)
        : null,
      requestFromHolderRequirements ? requestFromHolderRequirements.certificateHolderRequirementId : null,
      !!createCertificateRequest.certificateCreationExternalMetadata?.externalCoiAnalyzerMetadata?.analysisId,
    );

    // On mobile app web view, we  need to wait for the business assortment answer and submit followers to be submitted
    // before we can send the certificate creation event, otherwise the answer/followers won't be saved.
    if (this.mobileAppService.isMobileAppWebview()) {
      forkJoin([
        this.submitBusinessAssortmentAnswer(createCertificateResponse.liveCertificateId),
        this.submitCertHolderAsFollower(createCertificateRequest),
      ])
        .pipe(
          map(() => {
            this.store.dispatch(certificatesActions.cleanCustomCertificateState());
            this.mobileAppEventsService.notifyMobile({
              closeWebview: true,
              eventId: MobileAppEventId.CertificateCreation,
              liveCertificateId: createCertificateResponse.liveCertificateId,
            });
          }),
        )
        .subscribe();
    } else {
      this.finalizeCreateCertificate(createCertificateResponse.liveCertificateId);
      this.submitBusinessAssortmentAnswer(createCertificateResponse.liveCertificateId).subscribe();
      this.submitCertHolderAsFollower(createCertificateRequest).subscribe();
    }
  }

  private buildInformationAssortmentData(answer: string, certificateId: string): BusinessInformationAssortmentSubmitAnswerRequest {
    const questionText = this.translateService.instant('CERTIFICATES.PRE_QUESTIONS_PAGE.QUESTION');
    return {
      metadata: {
        flowType: BusinessInformationAssortmentFlowType.CoiQuestions,
        flowProprietaryAttributes: {
          certificateId,
        },
      },
      informationAssortment: [
        {
          questionId: '1',
          assortmentQuestionType: BusinessInformationAssortmentQuestionType.OpenQuestion,
          questionText,
          possibleAnswersIfApplicable: [],
          assortmentAnswers: [{ answerId: null, answer }],
        },
      ],
    };
  }

  private submitBusinessAssortmentAnswer(liveCertificateId: string): Observable<boolean> {
    const answer = this.getPreQuestionsAnswer();

    if (!this.isEligibleForInformationAssortment()) {
      return of(false);
    }

    return this.businessInformationAssortmentDataService
      .submitBusinessInformationAssortment(this.buildInformationAssortmentData(answer, liveCertificateId))
      .pipe(
        map(() => {
          this.store.dispatch(
            businessActions.setIsEligibleForBusinessInformationAssortment({
              businessInformationAssortmentFlowType: BusinessInformationAssortmentFlowType.CoiQuestions,
              isEligible: false,
            }),
          );

          return true;
        }),
        catchErrorAndLog(() => {
          return of(false);
        }),
      );
  }

  private isEligibleForInformationAssortment(): boolean {
    let isEligible = false;
    this.store
      .select(businessSelectors.getBusinessInformationAssortmentEligibility(BusinessInformationAssortmentFlowType.CoiQuestions))
      .pipe(take(1))
      .subscribe((eligible) => {
        isEligible = eligible;
      });
    return isEligible;
  }

  private finalizeCreateCertificate(liveCertificateId: string): void {
    this.store.dispatch(navigationActions.toLiveCertificate({ liveCertificateId }));

    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Success,
        message: 'CERTIFICATES.CREATE_CERTIFICATE_OLD.CREATE_CERTIFICATE_SUCCESS',
      }),
    );

    this.store.dispatch(certificatesActions.cleanCustomCertificateState());
  }

  private submitCertHolderAsFollower(request: CreateCertificateRequest): Observable<boolean> {
    if (!request.followerRequest) {
      return of(true);
    }
    return this.certificatesDataService.createFollower(request.followerRequest).pipe(
      switchMap((followerResponse: CreateFollowerResponse) => {
        this.certificatesTrackingService.trackAddCertHolderAsFollower(true, followerResponse.thirdPartyId);
        return of(true);
      }),
      catchErrorAndLog((error: HttpErrorResponse) => {
        const errorReason = error.error?.niStatusCode;
        this.certificatesTrackingService.trackAddCertHolderAsFollower(false, null, errorReason);
        const errorMessageTranslation =
          errorReason === ThirdPartyNotificationsRequestErrorReason.ThirdPartyAlreadyFollowsThisBusiness
            ? 'THIRD_PARTY_ALREADY_FOLLOWS_THIS_BUSINESS'
            : 'FAIL_TO_ADD_CERT_HOLDER_AS_FOLLOWER';
        this.store.dispatch(
          toastActions.showToast({
            toastType: ToastType.Error,
            message: `CERTIFICATES.FOLLOWERS.ADD_FOLLOWER.ERRORS.${errorMessageTranslation}`,
          }),
        );
        return of(false);
      }),
    );
  }

  private getCreateCertificateRequest(): CreateCertificateRequest {
    let createCertificateRequest: CreateCertificateRequest;
    this.store
      .select(certificatesSelectors.createCertificateRequest)
      .pipe(take(1))
      .subscribe((request) => {
        createCertificateRequest = request;
      });
    return createCertificateRequest;
  }

  private getCreateCertificateFromHolderRequirements(): CreateCertificateFromHolderRequirements {
    let createCertificateFromHolderRequirements: CreateCertificateFromHolderRequirements;
    this.store
      .select(certificatesSelectors.createCertificateFromHolderRequirements)
      .pipe(take(1))
      .subscribe((req) => {
        createCertificateFromHolderRequirements = req;
      });
    return createCertificateFromHolderRequirements;
  }

  private getPreQuestionsAnswer(): string {
    let preQuestionsAnswer: string;
    this.store
      .select(certificatesSelectors.getPreQuestionsAnswer)
      .pipe(take(1))
      .subscribe((answer) => {
        preQuestionsAnswer = answer;
      });
    return preQuestionsAnswer;
  }
}
