import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NIError } from '@next-insurance/errors';
import { ChatService } from '@next-insurance/ni-chat';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
// eslint-disable-next-line import/extensions
import * as introJs from 'intro.js/intro.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, Observable, of } from 'rxjs';
import { catchError, delay, filter, first, map, retry, switchMap, tap } from 'rxjs/operators';

import { aiChatbotAbTestConfig } from '../../ab-testing/models/ab-test-config.model';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { FeatureFlags } from '../models/feature-flags.enum';
import { HelpTip, helpTipsConfig } from '../models/help-tip.enum';
import { coreSelectors } from '../store/core.selectors';
import { AbTestingService } from './ab-testing.service';
import { FeatureFlagsService } from './feature-flags.service';

interface Step {
  intro: string;
  element?: HTMLElement;
}

@Injectable({
  providedIn: 'root',
})
export class IntroJsService {
  readonly steps: Map<string, Step>;
  introJS = introJs();
  currentStep: HelpTip;
  isChatbotTip = false;

  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>,
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
    private abTestingService: AbTestingService,
    private chatService: ChatService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    this.steps = new Map<string, Step>();
    this.setOptions();
    this.setSteps();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.introJS.exit();
    });
  }

  addStepElementFromDirective(key: HelpTip, element: HTMLElement): void {
    this.steps.get(key).element = element;
  }

  setCurrentStep(
    stepName: HelpTip,
    retryCount: number,
    delayInMillis: number,
    timeUntilFallback: number,
    isChatbotTip: boolean,
  ): Observable<boolean> {
    this.currentStep = stepName;
    if (
      (this.featureFlagsService.isActive(FeatureFlags.AiChatbot) || this.abTestingService.canRunAbTest(aiChatbotAbTestConfig)) &&
      this.featureFlagsService.isActive(FeatureFlags.FallbackHelpTip)
    ) {
      return this.store.select(coreSelectors.isLoading).pipe(
        first((isLoading) => !isLoading),
        switchMap(() => {
          return this.startWhenVisibleV2(isChatbotTip, timeUntilFallback);
        }),
      );
    }
    return this.startWhenVisible(retryCount, delayInMillis);
  }

  private setOptions(): void {
    this.introJS.setOptions({
      hidePrev: true,
      showBullets: false,
      exitOnOverlayClick: true,
      exitOnEsc: true,
      nextToDone: true,
      doneLabel: 'Got it',
      scrollToElement: true,
      disableInteraction: true,
    });
  }

  private setSteps(): void {
    Object.keys(helpTipsConfig).forEach((helpTip: HelpTip) => {
      const { BODY } = this.translateService.instant(helpTipsConfig[helpTip]);
      this.steps.set(helpTip, { intro: BODY });
    });
  }

  private initializeStepsToBeEmpty(): void {
    this.introJS._introItems = null;
    this.introJS._options.steps = null;
  }

  private startSingleStep(): void {
    this.introJS.onexit(() => {
      this.initializeStepsToBeEmpty();
      if (this.isChatbotTip && !this.deviceDetectorService.isMobile()) {
        this.chatService.open();
        this.isChatbotTip = false;
      }
    });

    this.introJS.start();
  }

  private isVisible(elem: HTMLElement): boolean {
    return elem && elem.clientWidth !== 0 && elem.clientHeight !== 0;
  }

  private startWhenVisible(retryCount = 8, delayInMillis = 0): Observable<boolean> {
    return interval(500).pipe(
      map(() => {
        if (!this.isVisible(this.steps.get(this.currentStep).element)) {
          throw new NIError('Step element is still not visible', null, {
            step: this.currentStep,
          });
        }
        return true;
      }),
      first(),
      delay(delayInMillis),
      tap(() => {
        this.introJS.addStep(this.steps.get(this.currentStep));
        this.startSingleStep();
      }),
      retry(retryCount),
      catchErrorAndLog(() => {
        this.cantFindStep();
        return of(false);
      }),
    );
  }

  private startWhenVisibleV2(isChatbotTip: boolean, timeUntilFallback = 3500): Observable<boolean> {
    const intervalTime = 500;
    return interval(intervalTime).pipe(
      map((retryNumber: number) => {
        if (this.isVisible(this.steps.get(this.currentStep).element)) {
          return true;
        }
        if (timeUntilFallback > intervalTime * retryNumber) {
          return false;
        }

        throw new NIError('Element not visible');
      }),
      filter((visible: boolean) => !!visible),
      first(),
      delay(600),
      tap(() => {
        this.introJS.addStep(this.steps.get(this.currentStep));
        this.isChatbotTip = isChatbotTip;
        this.startSingleStep();
      }),
      // eslint-disable-next-line @next-insurance/catch-and-throw
      catchError(() => {
        return of(false);
      }),
    );
  }

  cantFindStep(): void {
    this.initializeStepsToBeEmpty();
    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Error,
        message: 'HELP.ERROR',
      }),
    );
  }
}
