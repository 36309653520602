import { createSelector, MemoizedSelector } from '@ngrx/store';
import { memoize } from 'lodash-es';

import { AppState } from '../../store';
import { AbTestsMap } from '../models/ab-test.model';
import { AbTestSection } from '../models/ab-test-section.enum';
import { AbTestingState } from './ab-testing.reducer';

const abTestingState = (state: AppState): AbTestingState => state.abTesting;

const getAbTestsMap: MemoizedSelector<AppState, AbTestsMap> = createSelector(abTestingState, (state: AbTestingState) => state.abTestsMap);

const isLoaded = memoize(
  (section: AbTestSection): MemoizedSelector<AppState, boolean> =>
    createSelector(abTestingState, (state: AbTestingState) => state.isLoaded[section]),
);

export const abTestingSelectors = {
  getAbTestsMap,
  isLoaded,
};
