export enum OldPolicyStatus {
  Paid = 'Paid',
  Active = 'Active',
  Bound = 'Bound',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Quote = 'Quote',
  Selected = 'Selected',
}

export enum PolicyStatus {
  Paid = 'PAID',
  Active = 'ACTIVE',
  Bound = 'BOUND',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
}
