import { LOB } from '@next-insurance/core';
import { createAction, props } from '@ngrx/store';

import { CrossSellResponse } from '../models/cross-sell-response.model';
import { CrossSellSuggestion } from '../models/cross-sell-suggestion.model';

const loadCrossSell = createAction('[Cross sell] Load cross sell');

const setCrossSell = createAction('[Cross sell] Set Cross Sell', props<{ crossSellResponse: CrossSellResponse }>());

const startCrossSell = createAction(
  '[Cross sell] get Retrieve Opportunity Token',
  props<{ lob: LOB; shouldOfferThroughSmartAgent?: boolean }>(),
);

const goToApplication = createAction(
  '[Cross sell] Go to Application',
  props<{ lob: LOB; rot: string; shouldOfferThroughSmartAgent?: boolean }>(),
);

const failedToCrossSell = createAction('[Cross sell] Failed To Cross Sell', props<{ status: string; error: any }>());

const modifyNotifyStatus = createAction('[Cross sell] Modify Notify Status', props<{ lob: LOB; status: boolean }>());

const setNotifyStatus = createAction('[Cross sell] Set Notify Status', props<{ lob: LOB; status: boolean }>());

const loadWCSuggestion = createAction('[Cross sell] Load WC Suggestion');

const setWCSuggestion = createAction('[Cross sell] Set WC Suggestion', props<{ wcSuggestion: CrossSellSuggestion }>());

export const crossSellActions = {
  loadCrossSell,
  setCrossSell,
  startCrossSell,
  goToApplication,
  failedToCrossSell,
  modifyNotifyStatus,
  setNotifyStatus,
  loadWCSuggestion,
  setWCSuggestion,
};
