import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FileSize } from '../models/file-size.model';
import { FileTypes } from '../models/file-types.model';

@Injectable()
export class FilesUploadService {
  /**
   * Supported files types.
   */
  extensions = ['doc', 'docx', 'heic', 'jpe', 'jpeg', 'jpg', 'pdf', 'png', 'txt', 'xsl', 'xslx', 'mp3', 'mp4', 'avi', 'mov', 'mp4'];

  /**
   * Supported mime types.
   */
  mimeTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'video/quicktime',
  ];

  validFileTypes: FileTypes = {
    extensions: this.extensions,
    mimeTypes: this.mimeTypes,
  };

  validFileSizes: FileSize = {
    size: 50 * 1024 ** 2,
    text: '50M',
    nameLength: 100,
  };

  constructor(private translate: TranslateService) {}

  /**
   * Verify that the provided file is valid for upload.
   */
  validateFile(file: File): string {
    if (!file) {
      return this.translate.instant('GENERAL.ERRORS.FORM.REQUIRED');
    }
    if (!this.hasValidLength(file)) {
      return this.translate.instant('CLAIMS.FILES_UPLOAD.FILE_NAME_ERROR', { nameLength: this.validFileSizes.nameLength });
    }
    if (!this.hasValidType(file)) {
      return this.translate.instant('CLAIMS.FILES_UPLOAD.FILE_TYPE_ERROR');
    }

    if (file.size === 0) {
      return this.translate.instant('CLAIMS.FILES_UPLOAD.FILE_CONTENT_ERROR');
    }
    if (!this.hasValidSize(file)) {
      return this.translate.instant('CLAIMS.FILES_UPLOAD.FILE_SIZE_ERROR', { fileSize: this.validFileSizes.text });
    }
    return null;
  }

  /**
   * Reads the entire contents of a provided file.
   */
  readFile(file: any): any {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          // eslint-disable-next-line no-param-reassign
          file.content = reader.result;
          resolve(file);
        },
        false,
      );
      reader.addEventListener(
        'error',
        (err) => {
          reject(err);
        },
        false,
      );
      reader.readAsDataURL(file);
    });
  }

  getFileAlreadyExistErrorMessage(): string {
    return this.translate.instant('CLAIMS.FILES_UPLOAD.FILE_ALREADY_EXIST_ERROR');
  }

  private hasValidType(file: File): boolean {
    const { name = '', type = '' } = file;
    const extension = name.split('.').pop();
    return this.validFileTypes.extensions.includes(extension) || type?.startsWith('video/') || this.validFileTypes.mimeTypes.includes(type);
  }

  /**
   * Verify that the provided file size is valid.
   */
  private hasValidSize(file: File): boolean {
    return file.size < this.validFileSizes.size;
  }

  /**
   * Verify that the provided file length is valid.
   */
  private hasValidLength(file: File): boolean {
    return file.name.length <= this.validFileSizes.nameLength;
  }
}
