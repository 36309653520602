import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NiButtonRbModule, NiCheckboxRbModule } from '@next-insurance/ni-material';
import { TranslateModule } from '@ngx-translate/core';

import { CrossSellModule } from '../cross-sell/cross-sell.module';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { FreezeDialogDirective } from '../shared/directives/freeze-dialog.directive';
import { KeyValueKeepOrderPipe } from '../shared/pipes/key-value-keep-order.pipe';
import { LobNamePipe } from '../shared/pipes/lob-name.pipe';
import { CoverageCheckupBusinessAnswersStep } from './components/coverage-checkup-business-answer-step/coverage-checkup-business-answers-step.component';
import { CoverageCheckupLobsStepComponent } from './components/coverage-checkup-lobs-step/coverage-checkup-lobs-step.component';
import { CoverageCheckupModalComponent } from './components/coverage-checkup-modal/coverage-checkup-modal.component';
import { CoverageCheckupRiskReviewStepComponent } from './components/coverage-checkup-risk-review-step/coverage-checkup-risk-review-step.component';
import { CoverageCheckupStepComponent } from './components/coverage-checkup-step/coverage-checkup-step.component';
import { CoverageCheckupTileComponent } from './components/coverage-checkup-tile/coverage-checkup-tile.component';
import { GaugeComponent } from './components/gauge/gauge.component';
import { OrderLobsCoverageDetailsPipe } from './pipes/order-lobs-coverage-details.pipe';

@NgModule({
  declarations: [
    CoverageCheckupModalComponent,
    CoverageCheckupStepComponent,
    CoverageCheckupRiskReviewStepComponent,
    CoverageCheckupTileComponent,
    GaugeComponent,
    OrderLobsCoverageDetailsPipe,
    CoverageCheckupBusinessAnswersStep,
    CoverageCheckupLobsStepComponent,
  ],
  imports: [
    CommonModule,
    CrossSellModule,
    LobNamePipe,
    KeyValueKeepOrderPipe,
    FreezeDialogDirective,
    LoaderComponent,
    NiCheckboxRbModule,
    NiButtonRbModule,
    TranslateModule,
  ],
  exports: [CoverageCheckupModalComponent, CoverageCheckupTileComponent],
})
export class CoverageCheckupModule {}
