export enum Operation {
  CancelPolicy = 'CANCEL_POLICY',
  ReinstatePolicy = 'REINSTATE_POLICY',
  ChangePackage = 'CHANGE_PACKAGE',
  HelpTipBank = 'HELP_TIP_BANK',
  CancelRenewalPolicy = 'CANCEL_RENEWAL_POLICY',
  PolicyDetails = 'POLICY_DETAILS',
  UndoCancellation = 'UNDO_CANCELLATION',
  CoverageCheckupModal = 'COVERAGE_CHECKUP_MODAL',
  CustomizeCoverage = 'CUSTOMIZE_COVERAGE',
  UpdatePaymentMethod = 'UPDATE_PAYMENT_METHOD',
}
