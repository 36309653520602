import { Action, createReducer, on } from '@ngrx/store';

import { DeveloperLoginPhase } from '../models/developer-login-phase.model';
import { developerLoginActions } from './developer-login.actions';

export interface DeveloperLoginState {
  isLoading: boolean;
  developerLoginPhase: DeveloperLoginPhase;
}

export const developerLoginInitialState: DeveloperLoginState = {
  isLoading: null,
  developerLoginPhase: null,
};

const reducer = createReducer(
  developerLoginInitialState,
  on(developerLoginActions.setLoading, (state, action) => ({
    ...state,
    isLoading: action.isLoading,
  })),
  on(developerLoginActions.developerLoginVerificationSuccess, (state) => ({
    ...state,
    isLoading: false,
    developerLoginPhase: DeveloperLoginPhase.RedirectToHomePage,
  })),
  on(developerLoginActions.submitDeveloperLoginBusinessRequest, (state) => ({
    ...state,
    emailAddress: null,
    isLoading: true,
    developerLoginPhase: DeveloperLoginPhase.BusinessRequestSubmit,
  })),
  on(developerLoginActions.developerLoginGetOtp, (state) => ({
    ...state,
    developerLoginPhase: DeveloperLoginPhase.BusinessAuthentication,
  })),
  on(developerLoginActions.developerLoginValidateOtp, (state) => ({
    ...state,
    developerLoginPhase: DeveloperLoginPhase.Verification,
  })),
  on(developerLoginActions.developerLoginFailed, (state) => ({
    ...state,
    isLoading: false,
    developerLoginPhase: DeveloperLoginPhase.Error,
  })),
);

export function developerLoginReducer(state: DeveloperLoginState, action: Action): DeveloperLoginState {
  return reducer(state, action);
}
