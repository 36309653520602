import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { HolidayTheme } from '../models/holiday-themes.model';
import { CommonConfigDataService } from '../services/common-config.data.service';
import { coreActions } from './core.actions';

@Injectable()
export class CoreEffects {
  loadHolidayTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coreActions.loadHolidayTheme),
      switchMap(() =>
        this.commonConfigDataService.getHolidayTheme().pipe(
          map((holidayTheme: HolidayTheme) => {
            return coreActions.setHolidayTheme({ holidayTheme });
          }),
          catchErrorAndLog(() => of(coreActions.setHolidayTheme({ holidayTheme: null }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private commonConfigDataService: CommonConfigDataService,
  ) {}
}
