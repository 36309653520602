<div class="date-picker-rich-message-container">
  <p-calendar
    [(ngModel)]="selectedDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    class="ni-date-picker chatbot-date-picker"
    inline="true"
    showTime="true"
    hourFormat="12"
    data-test="owl-date-time"
  >
    <p-footer class="p-footer">
      <p-dropdown
        class="p-dropdown"
        [class.opened]="timeZonesDropDownOpened"
        (onShow)="onTimezonesDropdownShow()"
        (onHide)="onTimezonesDropdownHide()"
        [autoDisplayFirst]="false"
        [options]="timezones"
        [(ngModel)]="selectedTimeZone"
        data-test="date-time-zone"
        placeholder="Select time zone"
        optionValue="value"
        optionLabel="name"
        appendTo="body"
      >
      </p-dropdown>
    </p-footer>
  </p-calendar>

  <ni-chat-button
    [dataTest]="'btn-confirm'"
    customClass="primary"
    [disabled]="!selectedDate || !selectedTimeZone"
    (clicked)="submitDateClicked()"
    >Confirm Date
  </ni-chat-button>
</div>
