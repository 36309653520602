import { Directive, ElementRef, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Directive({
  selector: 'form',
  standalone: true,
})
export class FormSubmitDirective implements OnInit {
  formSubmission$: Observable<any>;

  constructor(private host: ElementRef<any>) {}

  ngOnInit(): void {
    this.formSubmission$ = fromEvent(this.host.nativeElement, 'submit').pipe(shareReplay(1));
  }
}
