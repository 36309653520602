import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EMPTY } from 'rxjs';

import { catchErrorAndLog } from '../../../../../shared/utils/catch-error-and-log.utils';
import { chatbotEventsConfig } from '../../../../configs/chatbot-events.config';
import { ChatSessionService } from '../../../../services/chat-session.service';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { SurveyPointType } from '../../enums/survey-point-type.enum';
import { SurveyQuestionType } from '../../enums/survey-question-type.enum';
import { SaveSurveyRequest } from '../../models/save-survey-request.model';
import { SurveyPoint } from '../../models/survey-point.model';
import { RichMessagesService } from '../../services/rich-messages.service';
import { SurveyDataService } from '../../services/survey.data.service';

@Component({
  selector: 'ni-survey-rich-message',
  templateUrl: './survey-rich-message.component.html',
  styleUrls: ['./survey-rich-message.component.scss'],
  providers: [SurveyDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.Survey;
  readonly experienceScoreQuestion = 'How was this experience?';
  readonly scoreReasonQuestion = 'What is the primary reason for the score that you gave?';

  surveyForm: UntypedFormGroup;
  sessionId: string;
  isSurveyFinished = false;
  surveyPoints: SurveyPoint[] = [
    {
      pointType: SurveyPointType.ExtremelyUnsatisfied,
      score: 1,
      selected: false,
      label: 'Poor',
    },
    {
      pointType: SurveyPointType.Unsatisfied,
      score: 2,
      selected: false,
    },
    {
      pointType: SurveyPointType.Neutral,
      score: 3,
      selected: false,
    },
    {
      pointType: SurveyPointType.Happy,
      score: 4,
      selected: false,
    },
    {
      pointType: SurveyPointType.ExtremelyHappy,
      score: 5,
      selected: false,
      label: 'Excellent',
    },
  ];

  chatbotEvents = chatbotEventsConfig;

  constructor(
    private chatSessionService: ChatSessionService,
    private richMessagesService: RichMessagesService,
    private surveyDataService: SurveyDataService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  get hasSelectedPoint(): boolean {
    return this.surveyPoints.some((point: SurveyPoint) => point.selected);
  }

  ngOnInit(): void {
    this.sessionId = this.chatSessionService.chatParameters?.sessionId ?? '';
    this.initForm();
  }

  toggleSelectedIcon(point: SurveyPoint): void {
    // eslint-disable-next-line no-param-reassign
    point.selected = !point.selected;
    this.saveSurvey(SurveyQuestionType.ExperienceScore, this.experienceScoreQuestion, point.score.toString());
  }

  saveSurvey(questionType: SurveyQuestionType, question: string, response: string): void {
    const saveSurveyRequest: SaveSurveyRequest = {
      sessionId: this.sessionId,
      questionType,
      question,
      response,
    };
    this.surveyDataService
      .saveSurvey(saveSurveyRequest)
      .pipe(
        catchErrorAndLog(() => {
          return EMPTY;
        }),
      )
      .subscribe();
  }

  submit(): void {
    this.isSurveyFinished = true;
    const scoreReasonResponse = this.surveyForm.controls.scoreReasonResponse.value;
    this.saveSurvey(SurveyQuestionType.ScoreReason, this.scoreReasonQuestion, scoreReasonResponse);
    this.richMessagesService.sendMessage({
      actualMessage: this.chatbotEvents.success,
      displayMessage: scoreReasonResponse,
      keepAfterSend: true,
    });
  }

  private initForm(): void {
    this.surveyForm = this.formBuilder.group({
      scoreReasonResponse: [''],
    });
  }
}
