import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

import { SpinnerComponent } from '../components/spinner/spinner.component';

@Directive({
  selector: '[niSpinner]',
  standalone: true,
})
export class SpinnerDirective implements OnChanges {
  @Input() loading: boolean;
  @Input() spinnerSize = '20px';
  originalDisplay: string;

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {
      this.createSpinner();
    } else if (!changes.loading.firstChange) {
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    this.originalDisplay = getComputedStyle(this.elementRef.nativeElement).display;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent as any);
    this.elementRef.nativeElement.style.display = 'none';
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.setSpinnerSize(componentRef);
  }

  private destroySpinner(): void {
    this.elementRef.nativeElement.style.display = this.originalDisplay;
    this.viewContainerRef.clear();
  }

  private setSpinnerSize(componentRef: ComponentRef<SpinnerComponent>): void {
    const element = componentRef.location.nativeElement;
    element.style['font-size'] = this.spinnerSize;
  }
}
