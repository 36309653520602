@if (shouldShowMobileAppBanner) {
  <ni-mobile-app-banner data-test="mobile-app-banner"></ni-mobile-app-banner>
}
@if (!shouldHideHeader) {
  <ni-header [ngClass]="{ loading: isLoading }"></ni-header>
}
@if (!hideLoaderForMobileWebView) {
  <ni-loader [isFullScreen]="true" [isLoading]="isLoading"></ni-loader>
}
<div [ngClass]="{ 'no-scroll': isLoading }" class="router-container">
  <router-outlet></router-outlet>
</div>
@if (!isMobileAppWebview && (isFooterVisible$ | async)) {
  <ni-footer></ni-footer>
}
<div class="hidden" data-test="for-site24x7-alerts-do-not-remove">site24x7</div>
<p-toast position="bottom-center"></p-toast>

@defer {
  @if (isAiChatbotEnabled && (isUserLoggedIn$ | async)) {
    <ni-chat-button
      (click)="openChat()"
      [liveChatUnreadMessages]="zendeskService.unreadMessages$"
      [hidden]="(chatbotState$ | async) === ChatStates.Opened"
    >
      <img src="assets/chat.icon.svg" alt="chat" />
    </ni-chat-button>
    <ni-chat></ni-chat>
  }
  <ni-chatbot-widget [config]="chatWidgetConfig" [hidden]="isLoading"></ni-chatbot-widget>
}
