import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';

import { MenuComponent } from '../../../../shared/components/menu/menu.component';
import { NavigationSubTab, NavigationTab } from '../../../models/navigation-tab.enum';
import { Tab } from '../../../models/tab.model';
import { CoreTrackingService } from '../../../services/core-tracking.service';

@Component({
  selector: 'ni-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {
  @Input() tabs: Tab[];
  @ViewChildren('menu') menus: QueryList<MenuComponent>;

  constructor(private coreTrackingService: CoreTrackingService) {}

  onTabClick(event: MouseEvent, tabId: NavigationTab | NavigationSubTab): void {
    event.stopPropagation();
    this.coreTrackingService.trackNavigationThroughTabs(tabId);
  }

  closeAllMenus(): void {
    this.menus.forEach((menu) => menu.closeMenu());
  }
}
