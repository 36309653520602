import { Event, StackFrame } from '@sentry/types';

import { errorsFilterConfig as config } from '../errors-filter.config';

function hasDenyUrl(stackFrame: StackFrame, denyUrl: string): boolean {
  return !!stackFrame?.abs_path?.startsWith(denyUrl);
}

/**
 * Filter errors by their URL.
 * @param event Sentry event
 * @returns True for filtering the error event, otherwise false
 */
export function urlsFilter(event: Event): boolean {
  const stackFrames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!stackFrames || !stackFrames.length) {
    return false;
  }

  const { denyUrls } = config.filters.urlsFilter;

  return stackFrames.some((stackFrame) => denyUrls.some((denyUrl) => hasDenyUrl(stackFrame, denyUrl)));
}
