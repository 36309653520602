import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { navigationActions } from '../../store/navigation.actions';
import { CustomerActivity } from '../models/customer-activity.model';
import { WcAuditDataService } from '../services/wc-audit.data.service';
import { wcAuditActions } from './wc-audit.actions';

@Injectable()
export class WcAuditEffects {
  loadCustomerActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wcAuditActions.loadCustomerActivities),
      switchMap((action: { wcAuditId: number }) =>
        this.wcAuditDataService.getCustomerActivities(action.wcAuditId).pipe(
          map((customerActivities: CustomerActivity[]) => {
            return wcAuditActions.setCustomerActivities({ customerActivities });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  loadCustomerMentionedActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wcAuditActions.loadMentionedActivities),
      switchMap((action: { wcAuditId: number }) =>
        this.wcAuditDataService.getMentionedActivities(action.wcAuditId).pipe(
          map((mentionedActivities: CustomerActivity[]) => {
            return wcAuditActions.setMentionedActivities({ mentionedActivities });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private wcAuditDataService: WcAuditDataService,
  ) {}
}
