<div class="container" [ngClass]="{ 'form-disabled': isUploadingFinished }" [formGroup]="fileUploadForm">
  <div class="form-input-wrapper">
    <p-dropdown
      formControlName="fileType"
      data-test="select-file-type"
      placeholder="Description (required)"
      [options]="resourceTypes$ | async"
      optionValue="displayName"
      optionLabel="displayName"
    ></p-dropdown>
  </div>

  <div class="form-input-wrapper">
    <input #fileInput (change)="onSelectFileToUploadChanged($any($event.target).files)" hidden id="selectedFile" type="file" />
    <input
      formControlName="selectedFile"
      class="ni-input-rb file-upload-input file-upload-input-icon"
      data-test="selected-file"
      readonly
      type="text"
      [placeholder]="'Upload File (required)'"
      [translatedCustomErrors]="fileUploadForm.controls.selectedFile?.errors"
      (click)="fileInput.click()"
    />
    <div class="file-exist-error p-xs" *ngIf="this.fileUploadForm.controls.selectedFile.errors?.fileAlreadyExist">
      This file already exist
    </div>
  </div>

  <div class="form-input-wrapper" *ngIf="shouldDisplayAmount">
    <input
      type="number"
      onwheel="return false;"
      formControlName="invoiceAmount"
      class="ni-input-rb invoice-amount-input"
      data-test="invoice-amount"
      niTrim
      placeholder="Invoice amount"
    />
  </div>

  <div>
    <ni-chat-button
      customClass="secondary"
      [disabled]="isUploadingFinished || isProcessing"
      (clicked)="finishUpload(chatResponseStatuses.Cancel)"
      [dataTest]="'file-upload-cancel'"
    >
      Cancel
    </ni-chat-button>
    <ni-chat-button
      customClass="primary"
      [disabled]="isUploadingFinished"
      [loading]="isProcessing"
      (clicked)="submit()"
      [dataTest]="'file-upload-submit'"
    >
      Submit
    </ni-chat-button>
  </div>
</div>
