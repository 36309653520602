import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { loginActions } from '../../login/store/login.actions';
import { AppState } from '../../store';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  recaptchaObservable$: Subject<string>;
  private readonly recaptchaElementClassName = 'grecaptcha-badge';
  private readonly visibleClassName = 'visible';
  private readonly unhandledRejectionEvent = 'unhandledrejection';

  constructor(
    private recaptcha: ReCaptchaV3Service,
    private store: Store<AppState>,
  ) {}

  private get recaptchaElement(): Element {
    return document.getElementsByClassName(this.recaptchaElementClassName)[0];
  }

  executeRecaptcha(action: string): Observable<string> {
    this.recaptchaObservable$ = new Subject<string>();
    window.addEventListener(this.unhandledRejectionEvent, this.unhandledRejection.bind(this));

    this.recaptcha.execute(environment.recaptchaKey, action, (token) => {
      this.showRecaptcha();
      this.recaptchaObservable$.next(token);
      this.recaptchaObservable$.complete();
    });

    return this.recaptchaObservable$.asObservable().pipe(
      filter((token) => {
        // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
        window.removeEventListener(this.unhandledRejectionEvent, this.unhandledRejection.bind(this));
        if (!token) {
          this.store.dispatch(loginActions.authenticationFailed({ errorReason: AuthErrorStatuses.RecaptchaClientError }));
        }
        return !!token;
      }),
    );
  }

  showRecaptcha(): void {
    try {
      this.recaptchaElement.classList.add(this.visibleClassName);
    } catch {}
  }

  hideRecaptcha(): void {
    try {
      this.recaptchaElement.classList.remove(this.visibleClassName);
    } catch {}
  }

  private unhandledRejection(event: PromiseRejectionEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(loginActions.authenticationFailed({ errorReason: AuthErrorStatuses.RecaptchaClientError }));
  }
}
