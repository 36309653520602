@if (isReferralProgramVisible$ | async) {
  <div [ngClass]="placement">
    @if (icon) {
      <fa-icon [icon]="faGift" data-test="referral-icon" class="icon"></fa-icon>
    }
    <a (click)="openReferralProgram()" class="referral-link" data-test="referral-program-link">
      {{ 'HOME.REFERRAL_PROGRAM.BUTTON.SHORT_NAME' | translate }}</a
    >
  </div>
}
