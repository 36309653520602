import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateFormats, getTodayFormat } from '@next-insurance/date';
import { Observable } from 'rxjs';

import { PolicyStatus } from '../../policies/models/policy-status.enum';
import { BusinessCreationRequest, PolicyCreationRequest } from '../../shared/models/business-creation-request.model';
import { BusinessCreationResponse } from '../../shared/models/business-creation-response.model';
import { defaultBusinessCreationRequest } from '../../testing/mock-objects/mock-business-creation-request';

@Injectable({
  providedIn: 'root',
})
export class DeveloperLoginDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  createBusiness(request: Partial<BusinessCreationRequest>): Observable<BusinessCreationResponse> {
    const businessCreationRequest = {
      ...defaultBusinessCreationRequest,
      ...request,
      policies: request.policies
        ? request.policies.map((policy) => ({
            ...policy,
            expectedFinalStatus: policy.expectedFinalStatus || PolicyStatus.Active,
            cancellationDate: this.getCancellationDate(policy),
          }))
        : [],
    };
    return this.httpClient.post<BusinessCreationResponse>('/api/public/developer-login/create-business', businessCreationRequest, {
      headers: {
        'X-NI-IDP': 'false',
      },
    });
  }

  /* istanbul ignore next */
  getOtp(email: string): Observable<string> {
    return this.httpClient.post<string>('/api/public/developer-login/get-otp', { email });
  }

  getCancellationDate(policy: PolicyCreationRequest): string {
    const now = getTodayFormat(DateFormats.HyphensDate);
    return policy.expectedFinalStatus === PolicyStatus.Canceled && (policy.cancellationDate || now);
  }
}
