import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, merge, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { formErrorMessageConfig } from '../../../core/config/form-error-message.config';
import { FormErrorMessageComponent } from '../../components/form-error-message/form-error-message.component';
import { FormSubmitDirective } from './form-submit.directive';

@Directive({
  selector: '[formControlName]',
  standalone: true,
})
export class FormErrorMessageDirective implements OnInit, OnDestroy {
  componentRef: ComponentRef<FormErrorMessageComponent>;
  formSubmission$: Observable<Event>;
  @Input() customErrors: Record<string, string> = {};
  @Input() translatedCustomErrors: Record<string, string> = {};
  private formEventsSub: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private controlDir: NgControl,
    private translateService: TranslateService,
    @Optional() @Host() private form: FormSubmitDirective,
  ) {}

  get control(): AbstractControl {
    return this.controlDir.control;
  }

  @HostListener('blur')
  onBlur(): void {
    this.handleChange();
  }

  ngOnInit(): void {
    this.formSubmission$ = this.form ? this.form.formSubmission$.pipe(tap(this.handleSubmit.bind(this))) : EMPTY;

    this.formEventsSub = merge(this.control.valueChanges, this.control.statusChanges, this.formSubmission$).subscribe(() => {
      this.handleChange();
    });
  }

  ngOnDestroy(): void {
    this.formEventsSub.unsubscribe();
  }

  private handleSubmit(): void {
    this.control.parent.markAllAsTouched();
    this.changeDetectorRef.detectChanges();
  }

  private handleChange(): void {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      const translatedErrorMessage = this.getTranslatedMessage(controlErrors);
      this.setErrorMessage(translatedErrorMessage);
    } else if (this.componentRef) {
      this.setErrorMessage(null);
    }
  }

  private getTranslatedMessage(controlErrors: ValidationErrors): string {
    const [errorKey, errorValue] = Object.entries(controlErrors)[0];

    if (this.translatedCustomErrors?.[errorKey]) {
      return this.translatedCustomErrors[errorKey];
    }

    const { defaultErrors, translateKey } = formErrorMessageConfig;
    const defaultErrorTranslationKey = defaultErrors[errorKey] || defaultErrors.default;
    const errorTranslationPath = (this.customErrors && this.customErrors[errorKey]) || `${translateKey}.${defaultErrorTranslationKey}`;

    return errorTranslationPath && this.translateService.instant(errorTranslationPath, { ...errorValue });
  }

  private setErrorMessage(errorMessage: string): void {
    if (!this.componentRef) {
      const factory = this.resolver.resolveComponentFactory(FormErrorMessageComponent);
      this.componentRef = this.viewContainerRef.createComponent(factory);
    }
    this.componentRef.instance.errorMessage = errorMessage;
  }
}
