<div data-test="undo-cancellation-modal">
  <div class="ni-dialog-content">
    <div class="p-sm_md">{{ 'HOME.UNDO_CANCELLATION_MODAL.DESCRIPTION' | translate }}</div>
  </div>
  <div class="ni-dialog-footer flex-start with-border">
    <ni-button-rb
      (click)="undoCancellation()"
      [buttonType]="ButtonType.Default"
      [disabled]="undoCancellationInProgress"
      data-test="undo-cancellation-button"
    >
      {{ 'HOME.UNDO_CANCELLATION_MODAL.UNDO' | translate }}
    </ni-button-rb>
  </div>
</div>
