export enum FormFieldTypes {
  Number = 'number',
  WholeNumber = 'wholenumber',
  Currency = 'currency',
  TextBox = 'textbox',
  Dropdown = 'dropdown',
  Age = 'age',
  Email = 'email',
  Tel = 'tel',
  ZipCode = 'zipcode',
  State = 'state',
  PositiveInteger = 'positiveinteger',
  PositiveDouble = 'positivedouble',
  DateOfBirth = 'dob',
  VinNumber = 'vinNumber',
  Year = 'year',
}
