import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Browsers } from '../../models/browsers.enum';

@Component({
  selector: 'ni-unsupported-browsers-banner',
  templateUrl: './unsupported-browsers-banner.component.html',
  styleUrls: ['./unsupported-browsers-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedBrowsersBannerComponent implements OnInit {
  readonly minSupportedBrowsersVersion = {
    [Browsers.Edge]: '18',
    [Browsers.IE]: '11',
  };

  isUnsupportedBrowsersVersion = false;
  browserName = '';

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  ngOnInit(): void {
    this.isUnsupportedBrowsersVersion = this.isUnsupportedBrowser();
    this.browserName = this.deviceDetectorService.browser;
  }

  isUnsupportedBrowser(): boolean {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { browser, browser_version } = this.deviceDetectorService;
    let isUnsupportedBrowser = false;

    Object.entries(this.minSupportedBrowsersVersion).forEach(([browserType, browserVersions]) => {
      if (browserType === browser && Number(browserVersions) >= Number(browser_version)) {
        isUnsupportedBrowser = true;
      }
    });

    return isUnsupportedBrowser;
  }
}
