import { Injectable } from '@angular/core';

import { ChatSessionService } from '../../chatbot/services/chat-session.service';
import { HelpTipOption } from '../models/help-tip-models.model';
import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class HelpTipTrackingService {
  constructor(
    private trackingService: TrackingService,
    private chatSessionService: ChatSessionService,
  ) {}

  trackHelpTipSuccess(option: HelpTipOption, chatbotSessionId: string = null, owlChat = false): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'help-tip-service',
      name: 'help-tip-success',
      interactionData: {
        sessionId: this.chatSessionService.sessionId,
        tipName: option.id,
        isChatbotTip: !!chatbotSessionId,
        chatbotSessionId: chatbotSessionId || undefined,
        owlChat,
      },
      eventName: `Help Tip - ${InteractionType.View.toUpperCase()} succeeded`,
    });
  }

  trackHelpTipFailure(option: HelpTipOption, chatbotSessionId: string = null, owlChat = false): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'help-tip-service',
      name: 'help-tip-failure',
      interactionData: {
        sessionId: this.chatSessionService.sessionId,
        tipName: option.id,
        isChatbotTip: !!chatbotSessionId,
        chatbotSessionId: chatbotSessionId || undefined,
        owlChat,
      },
      eventName: `Help Tip - ${InteractionType.View.toUpperCase()} failed`,
    });
  }
}
