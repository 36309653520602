import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { concatMap, delay, delayWhen, filter, takeUntil, tap, timeout } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { FullstoryEvent } from '../../../core/models/fullstory-event.enum';
import { InteractionType } from '../../../core/models/interaction-type.enum';
import { FullStoryService } from '../../../core/services/fullstory.service';
import { TrackingService } from '../../../core/services/tracking.service';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { chatbotConfig } from '../../configs/chatbot.config';
import { chatbotEventsConfig } from '../../configs/chatbot-events.config';
import { ChatStates } from '../../enums/chat-states.enum';
import { DetectIntentResponse } from '../../models/detect-intent-response.model';
import { OutgoingMessage } from '../../models/outgoing-message.model';
import { ResponseMessage } from '../../models/response-message.model';
import { ResponseMessageType } from '../../models/response-message-type';
import { ChatMessagesService } from '../../services/chat-messages.service';
import { ChatSessionService } from '../../services/chat-session.service';
import { ChatbotDataService } from '../../services/chatbot.data.service';
import { ChatbotService } from '../../services/chatbot.service';
import { ChatbotTrackingService } from '../../services/chatbot-tracking.service';
import { RichMessageType } from '../rich-messages/enums/rich-message-type.enum';
import { ChatMessage } from '../rich-messages/models/chat-message.model';
import { RichMessagesService } from '../rich-messages/services/rich-messages.service';

/**
 * This chat app is an isolated chat bot app
 * Its interacting with a backend gateway service
 * which eventually interacts with google dialogflow
 * for bot interactions
 */
@Component({
  selector: 'ni-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent implements OnInit, OnDestroy, AfterViewInit {
  chatMessages$: Observable<ChatMessage[]>;
  destroyed$ = new Subject<void>();
  hideUserInput = true;
  shouldDisplayExitConfirmation = false;
  isPersistent = false;
  private sessionsStreamSub = Subscription.EMPTY;
  private chatStateSub = Subscription.EMPTY;
  private originalOverflowOfHostPage: string;

  constructor(
    private chatbotService: ChatbotService,
    private chatbotDataService: ChatbotDataService,
    private richMessagesService: RichMessagesService,
    private chatSessionsService: ChatSessionService,
    private chatMessagesService: ChatMessagesService,
    private fullstoryService: FullStoryService,
    private chatbotTrackingService: ChatbotTrackingService,
    private cookieService: CookieService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'chatbot',
      name: 'chatbot-on-init',
      interactionData: {
        sessionId: this.chatSessionsService.sessionId,
      },
    });
    this.chatMessages$ = this.chatMessagesService.chatMessages$;
    this.sessionsStreamSub = this.initSessionStream();
    this.chatSessionsService.startNewSession(this.chatbotService.getAgentType());
    this.isPersistent = this.chatbotService.getIsPersistent();
    this.fullstoryService.fireEvent(FullstoryEvent.InitChatBot, { sessionId: this.chatSessionsService.sessionId });
  }

  ngAfterViewInit(): void {
    this.originalOverflowOfHostPage = document.body.style.overflow;
    this.disableScrollingOnHostPage();
  }

  sendOutgoingMessage(outgoingMessage: OutgoingMessage): void {
    if (this.chatSessionsService.hasSessionTimeoutPassed()) {
      this.chatSessionsService.onSessionTimeEnded();
    } else {
      let sendAction;
      if (outgoingMessage.actualMessage) {
        sendAction = this.sendUserMessage(outgoingMessage);
      } else if (outgoingMessage.eventName) {
        sendAction = this.sendEvent(outgoingMessage);
      }

      if (this.chatSessionsService.isSessionTimeout()) {
        this.chatMessagesService.addSessionTimeoutMsg();
      } else if (sendAction) {
        sendAction
          .pipe(
            timeout(chatbotConfig.sendMessageOrEventRequestTimeout),
            catchErrorAndLog(() => {
              this.handleError();
              return EMPTY;
            }),
            concatMap((res: DetectIntentResponse) => this.handleChatbotIntentResponse(res)),
          )
          .subscribe();
      } else {
        this.handleError();
      }
    }
  }

  onClose(): void {
    this.chatbotService.closeChatbot();
    this.chatbotTrackingService.trackChatbotCloseClicked(this.chatSessionsService.sessionId);
  }

  ngOnDestroy(): void {
    this.chatMessagesService.resetChatMessages();
    this.resetSubscriptions();
    this.cleanSubscriptions();
    this.sessionsStreamSub.unsubscribe();
    this.enableScrollingOnHostPage();
    this.chatStateSub.unsubscribe();
  }

  verifyExit(): void {
    if (this.chatSessionsService.isConversationEnded()) {
      this.onClose();
    } else {
      this.shouldDisplayExitConfirmation = true;
    }
  }

  minimizeChatbot(): void {
    this.chatbotService.minimizeChatbot();
  }

  private initSessionStream(): Subscription {
    this.chatMessagesService.addBotLoadingMessage();
    return this.chatSessionsService.sessionsStream$
      .pipe(
        tap(() => {
          this.chatMessagesService.resetChatMessages();
          this.chatMessagesService.addBotLoadingMessage();
          this.resetSubscriptions();
        }),
        catchErrorAndLog(() => {
          this.handleError();
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.sessionTimeoutSubscribe();
        this.subscribeRichMessages();
        this.sendOutgoingMessage({
          eventName: chatbotEventsConfig.initConversation,
          additionalParams: this.chatbotService.chatbotInitConfig,
        });
      });
  }

  private sessionTimeoutSubscribe(): void {
    this.chatSessionsService.isSessionAlmostTimeout$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.chatMessagesService.addSessionTimeoutWarningMsg();
    });
    this.chatSessionsService.isSessionTimeout$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.chatMessagesService.addSessionTimeoutMsg();
    });
  }

  private handleError(): void {
    this.fullstoryService.fireEvent(FullstoryEvent.ChatBotFailure, { sessionId: this.chatSessionsService.sessionId });
    this.chatMessagesService.clearLoadingMessages();
    this.chatMessagesService.addErrorFallbackMessage();
    this.hideUserInput = true;
    this.chatSessionsService.setIsConversationEnded(true);
  }

  private handleChatbotIntentResponse(response: DetectIntentResponse): Observable<ResponseMessage> {
    return of(response).pipe(
      tap((res: DetectIntentResponse) => {
        this.chatSessionsService.chatParameters = res?.queryResult?.parameters;
        this.hideUserInput = this.chatMessagesService.shouldHideUserInput(res);
      }),
      concatMap((res) => res?.queryResult?.responseMessages),
      concatMap((responseMessage: ResponseMessage) => {
        return this.handleResponseMessage(responseMessage);
      }),
    );
  }

  private subscribeRichMessages(): void {
    this.richMessagesService.richMessages$
      .pipe(
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.destroyed$),
        filter((outgoingMessage) => !!outgoingMessage),
        delay(chatbotConfig.userInteractionFadeoutDelay),
        tap((outgoingMessage: OutgoingMessage) => {
          if (!outgoingMessage.keepAfterSend) {
            if (outgoingMessage.originalMessageId) {
              this.chatMessagesService.removeMessageById(outgoingMessage.originalMessageId);
            } else {
              this.chatMessagesService.removeLastMessage();
            }
          }
        }),
      )
      .subscribe((outgoingMessage: OutgoingMessage) => {
        this.sendOutgoingMessage(outgoingMessage);
      });
  }

  private sendEvent(outgoingMessage: OutgoingMessage): Observable<DetectIntentResponse> {
    if (outgoingMessage.displayMessage) {
      this.chatMessagesService.addUserMessage(outgoingMessage.displayMessage);
    }
    return this.chatbotDataService.sendEvent(outgoingMessage.eventName, outgoingMessage.additionalParams);
  }

  private sendUserMessage(outgoingMessage: OutgoingMessage): Observable<DetectIntentResponse> {
    return of(outgoingMessage).pipe(
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.destroyed$),
      filter((message) => !!message),
      tap((message: OutgoingMessage) => {
        this.chatMessagesService.clearLoadingMessages();
        if (message.displayMessage) {
          this.chatMessagesService.addUserMessage(message.displayMessage);
        }
        this.chatMessagesService.addBotLoadingMessage();
        this.hideUserInput = true;
      }),
      concatMap((message) => this.chatbotDataService.sendMessage(message.actualMessage, message.additionalParams)),
      delay(this.disableChatbotLoadingDelay() ? 0 : chatbotConfig.chatbotLoadingTypingDelay),
    );
  }

  private handleResponseMessage(resMessage: ResponseMessage): Observable<ResponseMessage> {
    return of(resMessage).pipe(
      filter((responseMessage: ResponseMessage) => !!responseMessage && !!Object.keys(responseMessage).length),
      tap((responseMessage: ResponseMessage) => {
        if (!this.chatMessagesService.isLastMessageLoadingMessage() && responseMessage.message === ResponseMessageType.Text) {
          this.chatMessagesService.addBotLoadingMessage();
        }
      }),
      delayWhen(() =>
        this.chatMessagesService.isLastMessageLoadingMessage() && !this.disableChatbotLoadingDelay()
          ? timer(chatbotConfig.betweenMessagesDelay)
          : timer(0),
      ),
      tap((responseMessage: ResponseMessage) => {
        if (responseMessage.payload?.richContent?.[0]?.some((richCont) => richCont?.type === RichMessageType.Survey)) {
          this.chatSessionsService.stopTimer();
          this.chatSessionsService.setIsConversationEnded(true);
        } else if (responseMessage.message === ResponseMessageType.EndInteraction) {
          this.chatSessionsService.stopTimer();
          this.chatSessionsService.setIsConversationEnded(true);
          return EMPTY;
        }
        this.chatMessagesService.clearLoadingMessages();
        this.chatSessionsService.resetTimer();
        this.chatMessagesService.addBotMessage(responseMessage);
        return null;
      }),
    );
  }

  private resetSubscriptions(): void {
    this.cleanSubscriptions();
    this.destroyed$ = new Subject();
    this.richMessagesService.cleanSubject();
  }

  private cleanSubscriptions(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private disableScrollingOnHostPage(): void {
    this.chatStateSub = this.chatbotService.chatbotState$.subscribe((state: ChatStates) => {
      if (state === ChatStates.Opened) {
        document.body.style.overflow = 'hidden';
      } else {
        this.enableScrollingOnHostPage();
      }
    });
  }

  private enableScrollingOnHostPage(): void {
    document.body.style.overflow = this.originalOverflowOfHostPage;
  }

  disableChatbotLoadingDelay(): boolean {
    const disableChatbotLoadingDelayCookie = this.cookieService.get(environment.disableChatbotLoadingDelay);
    return disableChatbotLoadingDelayCookie === 'true';
  }
}
