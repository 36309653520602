import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';

enum Placement {
  Page = 'contact-info-page',
  EditPage = 'contact-info-edit-page',
  EditEmailWarningModal = 'contact-info-edit-email-warning-modal',
}

@Injectable({
  providedIn: 'root',
})
export class ContactInfoTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackEditPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: Placement.EditPage,
      name: 'contact-info-edit',
    });
  }

  /* istanbul ignore next */
  trackEditSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.EditPage,
      name: 'contact-info-edit-submit',
    });
  }

  /* istanbul ignore next */
  trackEditResult(isSuccess: boolean, updatedFields?: string[]): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: Placement.EditPage,
      name: 'contact-info-edit-result',
      interactionData: {
        isSuccess,
        updatedFields,
      },
    });
  }

  /* istanbul ignore next */
  trackCancelEmailWarning(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.EditEmailWarningModal,
      name: 'contact-info-edit-email-cancel',
    });
  }
}
