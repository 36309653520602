import { FeatureFlagsMap } from '@next-insurance/core';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { FeatureFlagsState } from './feature-flags.reducer';

const featureFlagsState = (state: AppState): FeatureFlagsState => state.featureFlags;

const getFeatureFlagsMap: MemoizedSelector<AppState, FeatureFlagsMap> = createSelector(
  featureFlagsState,
  (state: FeatureFlagsState) => state.featureFlagsMap,
);

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(featureFlagsState, (state: FeatureFlagsState) => state.isLoading);

export const featureFlagsSelectors = {
  getFeatureFlagsMap,
  isLoading,
};
