import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class CancelRewriteTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackCancelRewriteAgreementView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'cancel-rewrite',
      name: 'cancel-rewrite-agreement-view',
    });
  }

  /* istanbul ignore next */
  trackCancelRewriteAgreementSubmit(interactionData: { policyId: string; quoteRequestId: string }): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'cancel-rewrite',
      name: 'cancel-rewrite-agreement-submit',
      interactionData,
    });
  }

  /* istanbul ignore next */
  trackCancelRewriteCompleted(): void {
    this.trackingService.track({
      interactionType: InteractionType.Change,
      placement: 'cancel-rewrite',
      name: 'cancel-rewrite-completed',
    });
  }

  /* istanbul ignore next */
  trackCancelRewriteFailed(): void {
    this.trackingService.track({
      interactionType: InteractionType.Error,
      placement: 'cancel-rewrite',
      name: 'cancel-rewrite-failed',
    });
  }
}
