import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { OnboardingService } from '../../../services/onboarding.service';
import { OnboardingTrackingService } from '../../../services/onboarding-tracking.service';
import { OnboardingStepComponent } from '../onboarding-step/onboarding-step.component';

@Component({
  selector: 'ni-onboarding-final-step',
  templateUrl: './onboarding-final-step.component.html',
  styleUrls: ['./onboarding-final-step.component.scss', '../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingFinalStepComponent extends OnboardingStepComponent implements OnInit, OnDestroy {
  isLoading = true;
  messages: string[];
  helpModalEnabledSub = Subscription.EMPTY;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private translateService: TranslateService,
    private onboardingTrackingService: OnboardingTrackingService,
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this.onboardingService.completeOnboarding().subscribe();
    this.onboardingTrackingService.trackOnboardingCompleted();
    this.messages = this.getMessages();
    setTimeout(() => {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }, 2000);
  }

  onSubmit(): void {
    this.moveToNextStep.emit();
  }

  ngOnDestroy(): void {
    this.helpModalEnabledSub.unsubscribe();
  }

  private getMessages(): string[] {
    return Object.values(this.translateService.instant('ONBOARDING.FINAL.MESSAGES'));
  }
}
