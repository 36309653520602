<div class="gauge" data-test="gauge-animation" [ngClass]="riskLevel || 'INITIAL'">
  <div class="circle-container">
    <div class="circle" data-test="circle"></div>
  </div>
  <div class="mask"></div>
  <div class="bg-arc"></div>
  <div class="gauge-arc-container">
    <div class="gauge-arc" data-test="gauge-arc"></div>
  </div>
  <div class="title">
    <div class="title-text">{{ 'COVERAGE_CHECKUP.RISK_LEVEL.LABEL' | translate }}</div>
    <div class="risk-level" data-test="risk-level">{{
      riskLevel ? ('COVERAGE_CHECKUP.RISK_LEVEL.' + riskLevel.toUpperCase() | translate) : ''
    }}</div>
  </div>
</div>
