import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DynamicDialogService } from '../../../core/services/dynamic-dialog.service';
import { InformativeModalComponent } from '../informative-modal/informative-modal.component';

@Component({
  selector: 'ni-terms-of-service-link',
  templateUrl: './terms-of-service-link.component.html',
  styleUrls: ['./terms-of-service-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class TermsOfServiceLinkComponent {
  @Input() label: string;
  @Input() message: string;
  @Input() modalName: string;

  constructor(private dynamicDialogService: DynamicDialogService) {}

  openTermsOfServiceModal(): void {
    this.dynamicDialogService.open(InformativeModalComponent, {
      showHeader: false,
      data: {
        identifier: this.modalName,
        titleTranslationKey: 'GENERAL.TERMS_OF_SERVICE',
        messageTranslationKey: this.message,
      },
    });
  }
}
