import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PackageType } from '@next-insurance/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackagesDetails, PackagesResponse } from './models/packages.model';

@Injectable({
  providedIn: 'root',
})
export class CoveragesDataService {
  constructor(private httpClient: HttpClient) {}

  getPackagesForPolicy(policyId: number): Observable<PackagesResponse> {
    return this.httpClient.get<PackagesResponse>(`/api/policies/packages/${policyId}`).pipe(
      map((packagesResponse: PackagesResponse) => {
        const packagesDetails = Object.keys(packagesResponse.packagesDetails).reduce(
          (newPackagesDetails: PackagesDetails, currPackageType: PackageType) => ({
            ...newPackagesDetails,
            [currPackageType]: { ...packagesResponse.packagesDetails[currPackageType], planName: currPackageType },
          }),
          {},
        );
        return {
          status: packagesResponse.status,
          packagesDetails,
        };
      }),
    );
  }
}
