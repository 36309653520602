import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { ExitConfirmationComponent } from './components/exit-confirmation/exit-confirmation.component';
import { EnumCaseToUIFormatDirective } from './directives/enum-case-to-ui-format.directive';
import { NiFocusDirective } from './directives/ni-focus.directive';
import { DataTestPipe } from './pipes/data-test.pipe';

@NgModule({
  declarations: [DataTestPipe, NiFocusDirective, EnumCaseToUIFormatDirective, ChatButtonComponent, ExitConfirmationComponent],
  exports: [DataTestPipe, NiFocusDirective, EnumCaseToUIFormatDirective, ChatButtonComponent, ExitConfirmationComponent],
  imports: [CommonModule],
})
export class ChatSharedModule {}
