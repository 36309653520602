import { inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LOB } from '@next-insurance/core';
import logger from '@next-insurance/logger';
import {
  AbstractAiChatbotService,
  AiChatbotInteractions,
  AiChatbotSessionStatus,
  AiChatbotTrackingParams,
  BaseMessageMetadata,
  ChatEscalationOriginEnum,
  ChatMessage,
  ChatSender,
  GetChatHistoryResponse,
  OptionsChatMessage,
  SystemChatMessage,
  TextChatMessage,
} from '@next-insurance/ni-chat';
import { UsStateCode } from '@next-insurance/utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, throwError, timer } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { businessSelectors } from '../../business/store/business.selectors';
import { PolicyStatus } from '../../policies/models/policy-status.enum';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { Language } from '../enums/language.enum';
import { ChatbotData } from '../models/chatbot-data.model';
import { InteractionType } from '../models/interaction-type.enum';
import { QueryParams } from '../models/query-params.enum';
import { LanguageService } from './language.service';
import { TrackingService } from './tracking.service';
import { ZendeskService } from './zendesk.service';

export interface MessageMetadata extends BaseMessageMetadata {
  businessInfo: {
    stateCode: UsStateCode;
    cobId: number;
    policies: {
      lob: LOB;
      status: PolicyStatus;
    }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class AiChatbotService extends AbstractAiChatbotService {
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private trackingService = inject(TrackingService);
  private store = inject(Store);

  private zendeskService = inject(ZendeskService);
  private activatedRoute = inject(ActivatedRoute);
  private languageService = inject(LanguageService);
  private chatbotData: ChatbotData;

  constructor(protected override injector: Injector) {
    super(injector);
  }

  protected handleError(error: Error): void {
    const chatErrorMessage: ChatMessage[] = [new SystemChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.ERROR_SYSTEM_MESSAGE'))];

    if (this.isBusinessHours()) {
      chatErrorMessage.push(
        new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.ERROR_IN_BUSINESS_HOURS'), ChatSender.System),
        ...this.getEscalationMessages(),
      );
    } else {
      chatErrorMessage.push(
        new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.ERROR_NOT_IN_BUSINESS_HOURS'), ChatSender.System),
        new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.ERROR_NOT_IN_BUSINESS_HOURS_2'), ChatSender.System),
      );
    }

    this.chatMessagesService.addMessages(chatErrorMessage);
    this.endSession(AiChatbotSessionStatus.Error);
    this.chatMessagesService.disableUserInput();
    this.chatMessagesService.hideLoadingIndicator();
    logger.error(`Portal chatbot error - ${error.message}`);
  }

  protected handleEscalation(chatEscalationOrigin: ChatEscalationOriginEnum): void {
    if (chatEscalationOrigin === ChatEscalationOriginEnum.Chat) {
      this.chatMessagesService.addMessages([
        new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.TRANSFER_TO_AGENT'), ChatSender.System),
      ]);
      this.chatMessagesService.showLoadingIndicator();
      timer(3000).subscribe(() => {
        this.openLiveChat();
      });
    } else {
      this.openLiveChat();
    }
  }

  protected sendUserInteractions(eventName: string, data?: any): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Event,
        placement: 'portal-chatbot',
        name: eventName,
        interactionData: {
          isBusinessHours: this.isBusinessHours(),
          currentUrl: this.getBaseUrl(),
          sessionId: this.session?.id,
          sessionStatus: this.session?.status,
          email: this.session?.emailAddress,
          ...data,
        },
      },
      true,
    );
  }

  protected isBusinessHours(): boolean {
    const businessHoursQueryParam = this.activatedRoute.snapshot.queryParams[QueryParams.ChatBusinessHours];
    if (businessHoursQueryParam) {
      if (businessHoursQueryParam === 'on') {
        return true;
      }

      if (businessHoursQueryParam === 'off') {
        return false;
      }
    }

    return this.zendeskService.isBusinessHours();
  }

  protected getGreetingMessages(): ChatMessage[] {
    const name = this.chatbotData.user.firstName;
    const cob = this.chatbotData.cob.cobName;
    const firstGreetingMessage = this.translateService.instant('NI_CHAT.MESSAGES.GREETING_WITH_NAME', { name, cob });
    return [
      new TextChatMessage(firstGreetingMessage, ChatSender.System),
      new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.GREETING_SECOND_MESSAGE'), ChatSender.System),
    ];
  }

  protected getEscalationMessages(): ChatMessage[] {
    const messages = this.isBusinessHours()
      ? [
          new OptionsChatMessage([
            {
              text: this.translateService.instant('NI_CHAT.MESSAGES.CHAT_WITH_LIVE_AGENT'),
              isEscalationOption: true,
            },
          ]),
        ]
      : [];

    if (messages.length) {
      this.sendUserInteractions(AiChatbotInteractions.ChatEscalationButtonView);
    }

    return messages;
  }

  protected getInactivityMessages(): ChatMessage[] {
    return [
      new SystemChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.INACTIVITY_SYSTEM_MESSAGE')),
      new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.INACTIVITY_TEXT_MESSAGE'), ChatSender.System),
    ];
  }

  protected getTerminationMessages(): ChatMessage[] {
    return [
      new SystemChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.ERROR_SYSTEM_MESSAGE')),
      new TextChatMessage(this.translateService.instant('NI_CHAT.MESSAGES.INACTIVITY_TEXT_MESSAGE'), ChatSender.System),
    ];
  }

  protected getMessageMetadata(): MessageMetadata {
    const { stateCode } = this.chatbotData.state;
    const { policies } = this.chatbotData;
    // eslint-disable-next-line unicorn/consistent-destructuring
    const { cobId } = this.chatbotData.cob;
    const trackingId = this.trackingService.getTrackingId();

    return {
      emailAddress: this.getUserEmail(),
      businessHours: this.isBusinessHours(),
      app: 'CUSTOMER_PORTAL',
      url: this.getBaseUrl(),
      trackingId,
      type: 'CUSTOMER_PORTAL_MESSAGE_METADATA',
      businessInfo: {
        stateCode,
        cobId,
        policies,
      },
    };
  }

  protected getUserEmail(): string {
    return this.chatbotData.user.emailAddress;
  }

  protected getSessionTrackingParams(): AiChatbotTrackingParams {
    return {
      trackingId: this.trackingService.getTrackingId(),
    };
  }

  protected loadData(): Observable<void> {
    this.zendeskService.loadSchedule();
    const emailAddress$ = this.store.select(businessSelectors.getEmailAddress);
    const fullName$ = this.store.select(businessSelectors.getFullUserName);
    const personalDetails$ = this.store.select(businessSelectors.getPersonalDetails);
    const getAddress$ = this.store.select(businessSelectors.getAddress);
    const cobID$ = this.store.select(policiesSelectors.getCOBId);
    const policies$ = this.store.select(policiesSelectors.getPolicies);
    const cobName$ = this.store.select(policiesSelectors.getCobName);

    return combineLatest([emailAddress$, fullName$, personalDetails$, getAddress$, cobID$, policies$, cobName$]).pipe(
      first(),
      map(([emailAddress, fullName, personalDetails, address, cobId, policies, cobName]) => {
        this.chatbotData = {
          user: {
            emailAddress,
            fullName,
            firstName: personalDetails.userFirstName,
            lastName: personalDetails.userLastName,
          },
          state: {
            stateCode: address.stateCode,
          },
          cob: {
            cobId,
            cobName,
          },
          policies: policies.map((policy: any) => ({
            lob: policy.lob,
            status: policy.policyStatus,
          })),
        };
      }),
    );
  }

  private openLiveChat(): void {
    this.getChatHistoryMessage()
      .pipe(
        catchError((err) => {
          // in case of error open the zd chat without history message
          this.chatService.close();
          this.zendeskService.openZendeskChat(' ', this.getLiveChatTags(), undefined, 400);
          return throwError(() => err);
        }),
      )
      .subscribe((message: string) => {
        this.chatService.close();
        this.zendeskService.openZendeskChat(message, this.getLiveChatTags(), undefined, 400);
        this.endSession(AiChatbotSessionStatus.Escalated);
      });
  }

  private getChatHistoryMessage(): Observable<string> {
    return this.aiChatbotDataService.getChatHistory(this.session).pipe(
      map((response: GetChatHistoryResponse) => {
        let chatHistoryMessage = 'Chat history:\n\n';
        for (const message of response.orderedUserAndBotMessages) {
          chatHistoryMessage = chatHistoryMessage.concat(`Visitor: ${message.userMessage}\n\n`);
          chatHistoryMessage = chatHistoryMessage.concat(`Assistant: ${message.botMessage}\n\n`);
        }
        return chatHistoryMessage;
      }),
    );
  }

  private getLiveChatTags(): string[] {
    return this.isSpanishLanguage()
      ? ['owl_live_agent_request', 'chat_cust_portal', 'spanish_owl_live_agent_request']
      : ['owl_live_agent_request', 'chat_cust_portal'];
  }

  private isSpanishLanguage(): boolean {
    return this.languageService.getLanguage() === Language.Spanish;
  }

  private getBaseUrl(): string {
    return this.router.url.split('?')[0];
  }
}
