export interface AbTestConfig {
  name: string;
  testVariant: string;
  controlVariant: string;
}

export const aiChatbotAbTestConfig: AbTestConfig = {
  name: 'ai-chatbot',
  controlVariant: 'ai-chatbot-control',
  testVariant: 'ai-chatbot-test',
};
