import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { PermittedChanges } from '../models/business-change.enum';
import { BusinessChangeData } from '../models/business-change.model';
import { BusinessChangeState } from './business-change.reducer';

const businessChangeState = (state: AppState): BusinessChangeState => state.businessChange;

const isLoadingPermittedChanges: MemoizedSelector<AppState, boolean> = createSelector(
  businessChangeState,
  (state: BusinessChangeState) => state.isLoadingPermittedChanges,
);

const getPermittedChanges: MemoizedSelector<AppState, PermittedChanges> = createSelector(
  businessChangeState,
  (state) => state?.permittedChanges,
);

const getBusinessChangeData: MemoizedSelector<AppState, BusinessChangeData> = createSelector(
  businessChangeState,
  (state: BusinessChangeState) => state && state.businessChangeData,
);

export const businessChangeSelectors = {
  isLoadingPermittedChanges,
  getPermittedChanges,
  getBusinessChangeData,
};
