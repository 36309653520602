import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';

@Injectable({
  providedIn: 'root',
})
export class VersionDataService {
  constructor(private httpClient: HttpClient) {}

  fetchAppVersion(): Observable<{ version: string }> {
    return this.httpClient.get<{ version: string }>('/api/public/version').pipe(catchErrorAndLog(() => of({ version: null })));
  }
}
