import { Injectable } from '@angular/core';
import { subtractFromLocalDate } from '@next-insurance/date';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';

import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { WcAudit } from '../models/wc-audit.model';
import { WcAuditCollectedData } from '../models/wc-audit-collected-data.model';
import { WcAuditSubmissionResponse } from '../models/wc-audit-summary.model';
import { wcAuditActions } from '../store/wc-audit.actions';
import { wcAuditSelectors } from '../store/wc-audit.selectors';
import { WcAuditDataService } from './wc-audit.data.service';
import { WcAuditTrackingService } from './wc-audit-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class WcAuditService {
  constructor(
    private wcAuditDataService: WcAuditDataService,
    private store: Store<AppState>,
    private wcAuditTrackingService: WcAuditTrackingService,
  ) {}

  loadWcAudits(): Observable<void> {
    this.store.dispatch(wcAuditActions.initWcAuditsLoading());

    return this.wcAuditDataService.getAudits().pipe(
      map((audits) => {
        const wcAudits = audits.map((wcAudit) => {
          return {
            ...wcAudit,
            lastActiveDateForPolicy: subtractFromLocalDate(wcAudit.policyEndDate, { days: 1 }),
          };
        });
        this.store.dispatch(wcAuditActions.setWcAudits({ wcAudits }));
      }),
    );
  }

  submitWcAuditCollectedData(): void {
    this.store.dispatch(wcAuditActions.setIsLoading({ isLoading: true }));
    this.store
      .pipe(
        select(wcAuditSelectors.getSelectedWcAudit),
        withLatestFrom(this.store.select(wcAuditSelectors.getSelectedWcAuditData)),
        first(),
        map(([wcAudit, wcAuditCollectedData]: [WcAudit, WcAuditCollectedData]) => {
          this.wcAuditDataService
            .submitWcAuditCollectedDataV2(wcAuditCollectedData, wcAudit.auditId)
            .pipe(
              map((wcAuditSubmissionResponse: WcAuditSubmissionResponse) => {
                this.wcAuditTrackingService.trackWcAuditCompletedSubmitResult(true);

                this.store.dispatch(wcAuditActions.submitWcAuditCollectedDataFinished(null));
                this.store.dispatch(wcAuditActions.setWcAuditSummary({ wcAuditSubmissionResponse }));
              }),
              catchErrorAndLog((error) => {
                const errorReason = error.error?.niStatusCode;
                this.store.dispatch(wcAuditActions.submitWcAuditCollectedDataFinished({ submissionStatus: errorReason }));
                this.wcAuditTrackingService.trackWcAuditCompletedSubmitResult(false, errorReason);
                if (errorReason !== 'AUDIT_OWNERS_EDIT_ERROR') {
                  this.store.dispatch(navigationActions.toError());
                }
                return of(error);
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }
}
