import { FeatureFlagsMap } from '@next-insurance/core';
import { createAction, props } from '@ngrx/store';

export const featureFlagsActions = {
  loadFeatureFlags: createAction(
    '[Feature Flags API] Load feature flags',
    props<{ turnOnFeatureFlags: string[]; turnOffFeatureFlags: string[] }>(),
  ),
  setFeatureFlags: createAction('[Feature Flags] Set feature flags', props<{ featureFlagsMap: FeatureFlagsMap }>()),
  turnOnFeatureFlag: createAction('[Feature Flags] Turn on feature flag', props<{ name: string }>()),
  turnOffFeatureFlag: createAction('[Feature Flags] Turn off feature flag', props<{ name: string }>()),
  onError: createAction('[Feature Flags] On error'),
};
