export enum NavigationTab {
  Certificate = 'CERTIFICATES',
  Coverage = 'COVERAGE',
  Account = 'ACCOUNT',
  Claims = 'CLAIMS',
  Logout = 'LOGOUT',
}

export enum NavigationSubTab {
  MyCertificates = 'MY_CERTIFICATES',
  Auto = 'AUTO',
  MyCoverage = 'MY_COVERAGE',
  Followers = 'FOLLOWERS',
  Business = 'BUSINESS',
  Billing = 'BILLING',
  DocumentCenter = 'DOCUMENT_CENTER',
}
