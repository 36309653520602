import { Injectable } from '@angular/core';

import { VerificationCodeDeliveryMethod } from '../../login/models/verification-code-delivery-method.enum';
import { EmailValidatorService } from './validators/email-validator.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static getIdentityType(identityValue: string): VerificationCodeDeliveryMethod {
    const isEmail = EmailValidatorService.isValid(identityValue);
    return isEmail ? VerificationCodeDeliveryMethod.Email : VerificationCodeDeliveryMethod.SMS;
  }
}
