import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DateFormats, transformDate } from '@next-insurance/date';

import { chatbotConfig } from '../../../../configs/chatbot.config';
import { OutgoingMessage } from '../../../../models/outgoing-message.model';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { RichMessagesService } from '../../services/rich-messages.service';

@Component({
  selector: 'ni-date-picker-rich-message',
  templateUrl: './date-picker-rich-message.component.html',
  styleUrls: ['./date-picker-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerRichMessageComponent {
  static readonly richComponentType = RichMessageType.DatePicker;
  private readonly currDate = new Date();
  maxDate = this.currDate;
  minDate = new Date(chatbotConfig.chatbotDatePickerMinDate);
  startAt = this.currDate;
  selectedDate: Date;

  constructor(private richMessagesService: RichMessagesService) {}

  submitDateClicked(): void {
    const outgoingMessage = this.generateOutgoingMessage(this.selectedDate);
    this.richMessagesService.sendMessage(outgoingMessage);
  }

  generateOutgoingMessage(date: Date): OutgoingMessage {
    const longDateTime = transformDate(date, DateFormats.SlashesDateTimeLong);
    return { displayMessage: longDateTime, actualMessage: longDateTime };
  }
}
