import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ni-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate(
          '0.1s ease-out',
          style({
            height: '*',
            opacity: 1,
          }),
        ),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate(
          '0.1s ease-in',
          style({
            height: 0,
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
  standalone: true,
  imports: [NgIf],
})
export class AlertBannerComponent {
  @Input() closable = true;
  @Output() closed = new EventEmitter();
  hidden: boolean;

  onClose(): void {
    this.hidden = true;
    this.closed.emit();
  }
}
