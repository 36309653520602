import { Action, createReducer, on } from '@ngrx/store';

import { PermittedChanges } from '../models/business-change.enum';
import { BusinessChangeData } from '../models/business-change.model';
import { businessChangeActions } from './business-change.actions';

export interface BusinessChangeState {
  permittedChanges: PermittedChanges;
  isLoadingPermittedChanges: boolean;
  businessChangeData: BusinessChangeData;
}

export const businessChangeInitialState: BusinessChangeState = {
  permittedChanges: null,
  isLoadingPermittedChanges: false,
  businessChangeData: null,
};

const reducer = createReducer(
  businessChangeInitialState,
  on(businessChangeActions.loadPermittedChanges, (state) => ({
    ...state,
    isLoadingPermittedChanges: true,
  })),
  on(businessChangeActions.setPermittedChanges, (state, action) => ({
    ...state,
    permittedChanges: action.permittedChanges || {},
    isLoadingPermittedChanges: false,
  })),
  on(businessChangeActions.setBusinessChangeData, (state, action) => ({
    ...state,
    businessChangeData: action.businessChangeData,
  })),
);

export function businessChangeReducer(state: BusinessChangeState, action: Action): BusinessChangeState {
  return reducer(state, action);
}
