<div class="tabs" data-test="navigation-tabs">
  <ng-container *ngFor="let tab of tabs; index as i">
    <div
      (click)="onTabClick($event, tab.id)"
      *ngIf="!tab.hidden$ || (tab.hidden$ | async) === false"
      [attr.data-tab]="tab.id"
      [niHelpTip]="tab.helpTip"
      [routerLink]="[tab.routerLink]"
      class="tab p-md"
      routerLinkActive="active"
    >
      {{ tab.displayName + '.SHORT_NAME' | translate }}
      <ni-menu
        #menu
        *ngIf="tab.tabs && (!tab.isTabsHidden$ || (tab.isTabsHidden$ | async) === false)"
        [icon]="false"
        [onHover]="true"
        class="menu"
        data-test="sub-tabs-menu"
      >
        <ng-container *ngFor="let subtab of tab.tabs">
          <ni-menu-option
            (click)="onTabClick($event, subtab.id)"
            (clicked)="closeAllMenus()"
            *ngIf="!subtab.hidden$ || (subtab.hidden$ | async) === false"
            [attr.data-sub-tab]="subtab.id"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="subtab.routerLink"
            class="menu-option"
            label="{{ subtab.displayName + '.LONG_NAME' | translate }}"
            routerLinkActive="blue"
          >
          </ni-menu-option>
        </ng-container>
      </ni-menu>
    </div>
  </ng-container>
</div>
