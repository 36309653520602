import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SendEventRequest, SendMessageRequest } from '../models/detect-intent-request.model';
import { DetectIntentResponse } from '../models/detect-intent-response.model';
import { ChatSessionService } from './chat-session.service';
import { ChatbotTrackingService } from './chatbot-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotDataService {
  constructor(
    private httpClient: HttpClient,
    private chatSessionService: ChatSessionService,
    private chatbotTrackingService: ChatbotTrackingService,
  ) {}

  sendMessage(userInput: string, additionalParams?: Record<string, any>): Observable<DetectIntentResponse> {
    const payload: SendMessageRequest = {
      sessionId: this.chatSessionService.sessionId,
      encryptedBusinessId: this.chatSessionService.encryptedBusinessId,
      sessionParams: this.chatSessionService.getSessionParams(),
      query: userInput,
      additionalParams,
    };
    return this.httpClient.post<DetectIntentResponse>('/api/chatbot/send-message', payload).pipe(
      tap(() => this.chatbotTrackingService.trackSendMessageApi(this.chatSessionService.sessionId, true)),
      catchError((err) => {
        this.chatbotTrackingService.trackSendMessageApi(this.chatSessionService.sessionId, false, err.error?.error?.cause?.message);
        return throwError(err);
      }),
    );
  }

  sendEvent(eventName: string, additionalParams?: Record<string, any>): Observable<DetectIntentResponse> {
    const payload: SendEventRequest = {
      sessionId: this.chatSessionService.sessionId,
      encryptedBusinessId: this.chatSessionService.encryptedBusinessId,
      sessionParams: this.chatSessionService.getSessionParams(),
      eventName,
      additionalParams,
    };
    return this.httpClient.post<DetectIntentResponse>('/api/chatbot/send-event', payload).pipe(
      tap(() => this.chatbotTrackingService.trackSendEventApi(this.chatSessionService.sessionId, true)),
      catchError((err) => {
        this.chatbotTrackingService.trackSendEventApi(this.chatSessionService.sessionId, false, err.error.error.cause.message);
        return throwError(err);
      }),
    );
  }
}
