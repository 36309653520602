import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { chatbotEventsConfig } from '../../../../configs/chatbot-events.config';
import { ChatbotParams } from '../../../../enums/chatbot-params.enum';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatRichMessage } from '../../models/chat-rich-message.model';
import { CheckboxOption } from '../../models/checkbox-option.model';
import { RichMessagesService } from '../../services/rich-messages.service';
import { MultipleCheckboxRichMessageContent } from './multiple-checkbox-rich-message.model';

@Component({
  selector: 'ni-multiple-checkbox-rich-message',
  templateUrl: './multiple-checkbox-rich-message.component.html',
  styleUrls: ['./multiple-checkbox-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleCheckboxRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.MultipleCheckbox;
  @Input() message: ChatRichMessage<MultipleCheckboxRichMessageContent>;
  isMoreOptionsClicked = false;
  options: CheckboxOption[];
  moreOptions: CheckboxOption[];
  isSubmitted = false;
  isSubmitButtonDisabled = true;
  selectedOptionsMessage: string;

  constructor(private richMessagesService: RichMessagesService) {}

  ngOnInit(): void {
    this.options = this.message.data.richContent.options;
    this.moreOptions = this.message.data.richContent.moreOptions;
  }

  submitOptions(): void {
    const checkedOptions = this.options.filter((option: CheckboxOption) => option.checked);
    const checkedMoreOptions = this.moreOptions.filter((option: CheckboxOption) => option.checked);
    const allChecked = [...checkedOptions, ...checkedMoreOptions];
    const allCheckedIds = allChecked.map((option: CheckboxOption) => option.id);
    const allCheckedValues = allChecked.map((option: CheckboxOption) => option.text);

    this.isSubmitted = true;
    const selectedOptionsMessage = `${allCheckedValues.join(',\n')}`;
    this.richMessagesService.sendMessage({
      eventName: chatbotEventsConfig.multipleSelectionSubmit,
      additionalParams: { [ChatbotParams.DamageTypes]: allCheckedIds },
      displayMessage: selectedOptionsMessage,
    });
  }

  optionSelected(): void {
    const isOneOptionSelected = this.options.some((option) => option.checked);
    const isOneMoreOptionSelected = this.moreOptions.some((option) => option.checked);
    this.isSubmitButtonDisabled = !isOneOptionSelected && !isOneMoreOptionSelected;
  }
}
