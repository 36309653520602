import { Injectable } from '@angular/core';

import { Language } from '../enums/language.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackHeaderLanguageSwitch(lang: Language): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'header',
      name: 'language-switch',
      interactionData: {
        lang,
      },
      eventName: 'header - CLICK language switch',
    });
  }

  /* istanbul ignore next */
  trackFooterLanguageSwitch(lang: Language): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'footer',
      name: 'language-switch',
      interactionData: {
        lang,
      },
      eventName: 'footer - CLICK language switch',
    });
  }

  /* istanbul ignore next */
  trackNavigationMenuLanguageSwitch(lang: Language): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'navigation-menu',
      name: 'language-switch',
      interactionData: {
        lang,
      },
      eventName: 'navigation menu - CLICK language switch',
    });
  }
}
