import { LOB, PackageData } from '@next-insurance/core';
import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { memoize } from 'lodash-es';

import { BusinessOwnershipStructure } from '../../business/models/business-ownership-structure.enum';
import { FlexibleCoverageOptions } from '../../coverages/models/flexible-coverage-options.model';
import { AppState } from '../../store';
import { PartnerType } from '../models/partner-type.enum';
import { PayAsYouGoFlowType } from '../models/pay-as-you-go-flow-type.enum';
import { Policy } from '../models/policy.model';
import { PolicyAssociatedType } from '../models/policy-associated-type.enum';
import { PolicyStatus } from '../models/policy-status.enum';
import { PoliciesState } from './policies.reducer';

const policiesState = (state: AppState): PoliciesState => state.policies;

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(policiesState, (state: PoliciesState) => state.isLoading);

const isLoadingPackageData: MemoizedSelector<AppState, boolean> = createSelector(
  policiesState,
  (state: PoliciesState) => state.isLoadingPackageData,
);

const getPolicies: MemoizedSelector<AppState, Policy[]> = createSelector(
  policiesState,
  (state: PoliciesState) => state.policiesInfo.policies,
);

const firstPolicy: MemoizedSelector<AppState, Policy> = createSelector(getPolicies, (policies: Policy[]) => policies[0]);

const firstNextPolicy: MemoizedSelector<AppState, Policy> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.find((policy) => policy.policyAssociatedType === PolicyAssociatedType.Next),
);

const firstOperativePolicy: MemoizedSelector<AppState, Policy> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.find((policy) => [PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus)),
);

const getPolicy = memoize(
  (lob: LOB): MemoizedSelector<AppState, Policy> =>
    createSelector(getPolicies, (policies: Policy[]) => policies.find((policy) => policy.lob === lob)),
);

const getPoliciesPackageData: MemoizedSelector<AppState, { [policyId: number]: PackageData }> = createSelector(
  policiesState,
  (state: PoliciesState) => state.packageData,
);

const getExtendedPolicies: MemoizedSelector<AppState, Policy[]> = createSelector(policiesState, (state: PoliciesState) =>
  state.policiesInfo.policies.map((policy) => ({
    ...policy,
    packageData: state.packageData[policy.policyId],
    coverageExamples: state.coverageExamples[policy.lob],
  })),
);

const allLobs: MemoizedSelector<AppState, LOB[]> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.map((policy) => policy.lob),
);

const allPolicyStatuses: MemoizedSelector<AppState, PolicyStatus[]> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.map((policy) => policy.policyStatus),
);

const hasSinglePolicy: MemoizedSelector<AppState, boolean> = createSelector(getPolicies, (policies: Policy[]) => policies.length === 1);

const hasPolicy: MemoizedSelectorWithProps<AppState, { lob: LOB }, boolean> = createSelector(
  getPolicies,
  (policies: Policy[], props: { lob: LOB }) => policies.some((policy) => policy.lob === props.lob),
);

const hasActiveOrBoundPolicy = memoize(
  (lob?: LOB): MemoizedSelector<AppState, boolean> =>
    createSelector(getPolicies, (policies: Policy[]) =>
      policies.some((policy) => (policy.lob === lob || !lob) && [PolicyStatus.Active, PolicyStatus.Bound].includes(policy.policyStatus)),
    ),
);

const getOperativePolicies: MemoizedSelector<AppState, Policy[]> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.filter((policy) => [PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus)),
);

const hasOperativePolicy: MemoizedSelectorWithProps<AppState, { lob?: LOB }, boolean> = createSelector(
  getOperativePolicies,
  (operativePolicies: Policy[], props: { lob: LOB }) =>
    operativePolicies.length && operativePolicies.some((policy) => policy.lob === props.lob || !props.lob),
);

const hasOperativeNextPolicy: MemoizedSelector<AppState, boolean> = createSelector(getOperativePolicies, (operativePolicies: Policy[]) =>
  operativePolicies.some((policy) => policy.policyAssociatedType === PolicyAssociatedType.Next),
);

const firstBoundOrActivePolicy: MemoizedSelector<AppState, Policy> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.find((policy) => policy.policyStatus === PolicyStatus.Active || policy.policyStatus === PolicyStatus.Bound),
);

const getBusinessOwnershipStructure: MemoizedSelector<AppState, BusinessOwnershipStructure> = createSelector(
  firstPolicy,
  (policy: Policy) => policy.businessOwnershipStructure,
);

const getCobName: MemoizedSelector<AppState, string> = createSelector(firstPolicy, (policy: Policy) => policy.cobDisplayName);

const isNextCarrier: MemoizedSelector<AppState, boolean> = createSelector(firstPolicy, (policy: Policy) =>
  [PartnerType.Next, PartnerType.NextCarrier, PartnerType.NationalSpecialty].includes(policy.carrierId),
);

const getPaidAndBoundPolicies: MemoizedSelector<AppState, Policy[]> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.filter((policy) => [PolicyStatus.Paid, PolicyStatus.Bound].includes(policy.policyStatus)),
);

const hasPayAsYouGoPolicy: MemoizedSelector<AppState, boolean> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.some((policy) => policy.policyAssociatedType === PolicyAssociatedType.PaygAP),
);

const hasNextPolicy: MemoizedSelector<AppState, boolean> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.some((policy) => policy.policyAssociatedType === PolicyAssociatedType.Next),
);

const isPayAsYouGoPolicy = memoize(
  (lob: LOB): MemoizedSelector<AppState, boolean> =>
    createSelector(
      getPolicies,
      (policies: Policy[]) => policies.find((policy) => policy.lob === lob)?.policyAssociatedType === PolicyAssociatedType.PaygAP,
    ),
);

const isHistoricalUser: MemoizedSelector<AppState, boolean> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.every((policy) => ![PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus)),
);

const hasPartnerPolicies: MemoizedSelector<AppState, boolean> = createSelector(
  policiesState,
  (state: PoliciesState) => state.policiesInfo.hasPartnerPolicies,
);

const firstPolicyEligibleToCancellation: MemoizedSelector<AppState, Policy> = createSelector(getExtendedPolicies, (policies: Policy[]) =>
  policies.find(
    (policy) =>
      policy.isCancellationEligible &&
      [PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus) &&
      !policy.scheduledCancellationDate,
  ),
);

const firstPolicyEligibleToRestart: MemoizedSelector<AppState, Policy> = createSelector(policiesState, (state: PoliciesState) =>
  state.policiesInfo.policies.find((policy) => policy.policyStatus === PolicyStatus.Canceled && !policy.isReinstateEligible),
);

const firstPolicyEligibleToReinstate: MemoizedSelector<AppState, Policy> = createSelector(policiesState, (state: PoliciesState) =>
  state.policiesInfo.policies.find((policy) => policy.isReinstateEligible),
);

const hasPolicyToReinstate: MemoizedSelector<AppState, boolean> = createSelector(policiesState, (state: PoliciesState) =>
  state.policiesInfo.policies.some((policy) => policy.isReinstateEligible),
);

const hasPolicyToRestart: MemoizedSelector<AppState, boolean> = createSelector(policiesState, (state: PoliciesState) =>
  state.policiesInfo.policies.some((policy) => policy.policyStatus === PolicyStatus.Canceled && !policy.isReinstateEligible),
);

const isAutoCardsAvailable: MemoizedSelector<AppState, boolean> = createSelector(
  getPolicy(LOB.CA),
  isHistoricalUser,
  (caPolicy: Policy, isHistorical: boolean) => {
    return caPolicy && (isHistorical || [PolicyStatus.Active, PolicyStatus.Bound].includes(caPolicy.policyStatus));
  },
);

const hasOnlyPaidPolicies: MemoizedSelector<AppState, boolean> = createSelector(allPolicyStatuses, (allStatuses: PolicyStatus[]) =>
  allStatuses.every((status) => status === PolicyStatus.Paid),
);

const getPolicyById = memoize(
  (policyId: number): MemoizedSelector<AppState, Policy> =>
    createSelector(getExtendedPolicies, (policies: Policy[]) => policies.find((policy) => policy.policyId === +policyId)),
);

const getPolicyByLob = memoize(
  (lob: LOB): MemoizedSelector<AppState, Policy> =>
    createSelector(getExtendedPolicies, (policies: Policy[]) => policies.find((policy) => policy.lob === lob)),
);

const getPayAsYouGoFlowType: MemoizedSelector<AppState, PayAsYouGoFlowType> = createSelector(
  getPolicies,
  (policies: Policy[]) => policies.find((policy) => policy.policyAssociatedType === PolicyAssociatedType.PaygAP)?.payAsYouGoFlowType,
);

const getIsLoadingFlexibleCoverageOptions: MemoizedSelector<AppState, boolean> = createSelector(
  policiesState,
  (state: PoliciesState) => state.isLoadingFlexibleCoverageOptions,
);

const getFlexibleCoverageOptionsByPolicyId = memoize(
  (policyId: number): MemoizedSelector<AppState, FlexibleCoverageOptions> =>
    createSelector(policiesState, (state: PoliciesState) => state.flexibleCoverageOptions && state.flexibleCoverageOptions[policyId]),
);

const getCOBId: MemoizedSelector<AppState, number> = createSelector(
  policiesState,
  (state: PoliciesState) => state.policiesInfo.policies[0]?.cobId,
);

const getPayAsYouGoPolicies: MemoizedSelector<AppState, Policy[]> = createSelector(getPolicies, (policies: Policy[]) =>
  policies.filter((policy) => policy.policyAssociatedType === PolicyAssociatedType.PaygAP),
);

export const policiesSelectors = {
  isLoading,
  isLoadingPackageData,
  getPolicies,
  getExtendedPolicies,
  firstPolicy,
  firstNextPolicy,
  firstOperativePolicy,
  getPolicy,
  hasSinglePolicy,
  hasPolicy,
  hasActiveOrBoundPolicy,
  allLobs,
  firstBoundOrActivePolicy,
  getBusinessOwnershipStructure,
  getCobName,
  isNextCarrier,
  getPoliciesPackageData,
  getPaidAndBoundPolicies,
  allPolicyStatuses,
  hasOperativePolicy,
  hasOperativeNextPolicy,
  hasPayAsYouGoPolicy,
  hasNextPolicy,
  isPayAsYouGoPolicy,
  isHistoricalUser,
  hasPartnerPolicies,
  firstPolicyEligibleToCancellation,
  hasPolicyToReinstate,
  hasPolicyToRestart,
  isAutoCardsAvailable,
  hasOnlyPaidPolicies,
  getPolicyById,
  getPayAsYouGoFlowType,
  firstPolicyEligibleToReinstate,
  firstPolicyEligibleToRestart,
  getPolicyByLob,
  getOperativePolicies,
  getIsLoadingFlexibleCoverageOptions,
  getFlexibleCoverageOptionsByPolicyId,
  getCOBId,
  getPayAsYouGoPolicies,
};
