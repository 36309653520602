/*
 * zip code number pattern
 */
export const zipCodeNumberPattern = /^\d{5}$/;
/*
 * email address extended validator
 */
export const emailAddressPattern =
  // eslint-disable-next-line unicorn/better-regex
  /^(([^<>()\?\[\]\\.,;:\s@"]+(\.[^<>()\?\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/*
 * state code pattern
 */
export const stateCodePattern =
  /^(A[AEKLPRSZ]|C[AOT]|D[CE]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHIN-PST]|N[C-EHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/;
