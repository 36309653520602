<div class="content-container">
  <div class="h4_h3-regular" data-test="header">{{ 'VERIFICATION.EMAIL.EDIT.HEADER' | translate }}</div>
  <form [formGroup]="editIdentityForm">
    <div class="ni-input-label-rb">{{ 'VERIFICATION.EMAIL.EDIT.EMAIL_ADDRESS' | translate }}</div>
    <input
      type="email"
      id="email"
      class="ni-input-rb"
      formControlName="emailAddress"
      data-test="identity-input"
      niTrim
      [placeholder]="identityValue$ | async"
    />
  </form>
  <div class="actions short-text">
    <ni-button-rb (click)="submit()" [isLoading]="isSubmitting" [disabled]="editIdentityForm.invalid" data-test="submit-button">
      {{ 'GENERAL.SUBMIT' | translate }}
    </ni-button-rb>
    <ni-button-rb (click)="cancel()" [disabled]="isSubmitting" [buttonType]="ButtonType.Secondary" data-test="cancel-button">
      {{ 'GENERAL.CANCEL' | translate }}
    </ni-button-rb>
  </div>

  <div class="footer p-xs_sm">
    <span> {{ 'VERIFICATION.HAVING_TROUBLE' | translate }} </span>
    <div class="chat-with-us-link" (click)="openZendeskChat()">{{ 'VERIFICATION.CHAT_WITH_US' | translate }}</div>
  </div>
</div>
