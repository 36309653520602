import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Language } from '../../../core/enums/language.enum';
import { LanguageService } from '../../../core/services/language.service';
import { NavigationService } from '../../../core/services/navigation.service';

@Component({
  selector: 'ni-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class LanguageSwitchComponent {
  @Input() isWhite = false;
  @Output() onLanguageChanged = new EventEmitter<Language>();
  constructor(
    private language: LanguageService,
    private navigationService: NavigationService,
  ) {}

  switchLanguage(): void {
    const currentLang: Language = this.language.getLanguage();
    const selectedLanguage: Language = currentLang === Language.English ? Language.Spanish : Language.English;
    this.language.setLanguage(selectedLanguage);
    this.onLanguageChanged.emit(selectedLanguage);
    this.refresh();
  }

  private refresh(): void {
    this.navigationService.reload();
  }
}
