import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserType } from '../../core/models/user-type.enum';
import { coreSelectors } from '../../core/store/core.selectors';
import { AppState } from '../../store';
import { CustomerActivity } from '../models/customer-activity.model';
import { WcAudit } from '../models/wc-audit.model';
import { WcAuditCollectedData } from '../models/wc-audit-collected-data.model';
import { WcAuditSaveDraftResponse } from '../models/wc-audit-save-draft-response.model';
import { WcAuditSubmissionResponse } from '../models/wc-audit-summary.model';

@Injectable({
  providedIn: 'root',
})
export class WcAuditDataService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
  ) {}

  /* istanbul ignore next */
  getCustomerActivities(wcAuditId: number): Observable<CustomerActivity[]> {
    return this.httpClient.get<CustomerActivity[]>('/api/wc-audit/customer-activities', {
      params: {
        wcAuditId: wcAuditId.toString(),
      },
    });
  }

  /* istanbul ignore next */
  getMentionedActivities(wcAuditId: number): Observable<CustomerActivity[]> {
    return this.httpClient.get<CustomerActivity[]>('/api/wc-audit/customer-mentioned-activities', {
      params: {
        wcAuditId: wcAuditId.toString(),
      },
    });
  }

  /* istanbul ignore next */
  getAudits(): Observable<WcAudit[]> {
    let userType: UserType;
    this.store
      .select(coreSelectors.getUserType)
      .pipe(first())
      .subscribe((type) => {
        userType = type;
      });
    const auditUserType = userType?.replace(/-/g, '_');
    return this.httpClient.get<WcAudit[]>('/api/wc-audit/audits', {
      params: {
        auditUserType,
      },
    });
  }

  /* istanbul ignore next */
  submitWcAuditCollectedDataV2(wcAuditCollectedData: WcAuditCollectedData, wcAuditId: number): Observable<WcAuditSubmissionResponse> {
    return this.httpClient.put<WcAuditSubmissionResponse>('/api/wc-audit/submit-collected-data-v2', {
      wcAuditId,
      wcAuditCollectedData,
    });
  }

  /* istanbul ignore next */
  saveWcAuditDraftData(wcAuditCollectedData: WcAuditCollectedData, wcAuditId: number): Observable<WcAuditSaveDraftResponse> {
    return this.httpClient.put<WcAuditSaveDraftResponse>('/api/wc-audit/save-draft-data', {
      wcAuditId,
      wcAuditCollectedData,
    });
  }
}
