<div [ngClass]="{ loading: isLoading }" [niFreezeWhile]="isSubmitting">
  <ni-loader *ngIf="isLoading" [isLoading]="true"></ni-loader>
  <form [formGroup]="reinstateForm" *ngIf="!isLoading">
    <div class="ni-dialog-content p-sm_md" data-test="reinstate-agreement-modal">
      <ng-container *ngIf="charge || charge === 0">
        <div>{{ 'REINSTATE.AGREEMENT.PLEASE_READ' | translate }}</div>
        <div class="lob">{{ modalData.policy.lob | lobName }}</div>
        <ul>
          <li>{{ 'REINSTATE.AGREEMENT.DETAILS.P1' | translate }}</li>
          <li [translateParams]="{ amount: charge | niCurrency }" translate="REINSTATE.AGREEMENT.DETAILS.P2"></li>
          <li>{{ 'REINSTATE.AGREEMENT.DETAILS.P3' | translate }}</li>
        </ul>
        <ni-checkbox-rb
          [label]="'REINSTATE.AGREEMENT.CHECKBOX' | translate"
          data-test="agreement-checkbox"
          formControlName="agreementCheckbox"
        >
        </ni-checkbox-rb>
      </ng-container>
    </div>
    <div class="ni-dialog-footer with-border flex-start">
      <ni-button-rb (click)="submit()" [disabled]="reinstateForm.invalid" [isLoading]="isSubmitting" data-test="agreement-button">
        {{ 'GENERAL.SUBMIT' | translate }}
      </ni-button-rb>
    </div>
    <ni-fraud-language-alert class="fraud-language-alert"></ni-fraud-language-alert>
  </form>
</div>
