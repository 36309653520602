import { Injectable } from '@angular/core';

import { HelpTip } from '../models/help-tip.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class HelpModalTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackHelpModalLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'help-modal',
      name: 'help-modal-view',
      eventName: 'help modal - VIEW',
    });
  }

  /* istanbul ignore next */
  trackHelpModalOptionClick(helpTip: HelpTip): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'help-modal',
      name: 'help-modal-option-click',
      interactionData: {
        optionType: helpTip,
      },
    });
  }
}
