import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FeatureFlags } from '../../core/models/feature-flags.enum';
import { FeatureFlagsService } from '../../core/services/feature-flags.service';
import { BusinessPropertiesType } from '../../coverage-checkup/models/business-properties-type.enum';
import { ExternalLobAnswer } from '../../coverage-checkup/models/external-lob-answer.model';
import { CrossSellTokenResponse } from '../../home/models/cross-sell-token-response.model';
import { CrossSellPolicy } from '../models/cross-sell-policy.model';
import { CrossSellResponse } from '../models/cross-sell-response.model';
import { CrossSellSuggestionResponse } from '../models/cross-sell-suggestion-response.model';
import { CrossSellTrackingService } from './cross-sell-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class CrossSellDataService {
  constructor(
    private httpClient: HttpClient,
    private crossSellTrackingService: CrossSellTrackingService,
    private featureService: FeatureFlagsService,
  ) {}

  /* istanbul ignore next */
  getRetrieveOpportunityToken(): Observable<CrossSellTokenResponse> {
    return this.httpClient.get<CrossSellTokenResponse>('/api/cross-sell/token');
  }

  /* istanbul ignore next */
  setNotifyStatus(lob: LOB, status: boolean): Observable<boolean> {
    return this.httpClient.put<boolean>('/api/cross-sell/notify', { lob, status });
  }

  /* istanbul ignore next */
  getSuggestion(lob: LOB): Observable<CrossSellSuggestionResponse> {
    return this.httpClient.get<CrossSellSuggestionResponse>(`/api/cross-sell/suggestions?lob=${lob}`);
  }

  /* istanbul ignore next */
  updateExternalCoveredLob(lob: LOB): Observable<void> {
    return this.httpClient.put<void>(`/api/cross-sell/business-lob-statement`, {
      lob,
      alreadyInuredDeclaration: true,
    });
  }

  /* istanbul ignore next */
  updateCoverageCheckupAnswers(
    businessPropertiesAnswers: BusinessPropertiesType[],
    externalLobsAnswers: ExternalLobAnswer[],
  ): Observable<void[]> {
    return this.httpClient.put<void[]>(`/api/cross-sell/coverage-checkup-answers`, {
      businessPropertiesAnswers,
      externalLobsAnswers,
    });
  }

  getCrossSell(): Observable<CrossSellResponse> {
    return this.httpClient.get<CrossSellResponse>('/api/cross-sell/opportunities').pipe(
      tap((crossSellResponse: CrossSellResponse) => {
        this.crossSellTrackingService.trackCrossSellResponse(crossSellResponse);
      }),
      map((crossSellResponse: CrossSellResponse) => {
        if (!this.featureService.isActive(FeatureFlags.BOP)) {
          // eslint-disable-next-line no-param-reassign
          crossSellResponse.crossSellPolicies = crossSellResponse.crossSellPolicies.filter(
            (crossSellPolicy: CrossSellPolicy) => crossSellPolicy.lob !== LOB.BP,
          );
        }

        if (!this.featureService.isActive(FeatureFlags.AutoPoliciesCrossSell)) {
          // eslint-disable-next-line no-param-reassign
          crossSellResponse.crossSellPolicies = crossSellResponse.crossSellPolicies.filter(
            (crossSellPolicy: CrossSellPolicy) => crossSellPolicy.lob !== LOB.AUTO,
          );
        }

        return crossSellResponse;
      }),
    );
  }
}
