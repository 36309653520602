import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'ni-refresh-modal',
  templateUrl: './refresh-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshModalComponent {
  constructor(private navigationService: NavigationService) {}

  refresh(): void {
    this.navigationService.navigateTo('/');
  }
}
