import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IsVerifiedResponse } from '../models/is-verified-response.model';
import { VerificationScope } from '../models/verification-scope.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class VerificationV2DataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  sendOtp(identityValue: string, scope: VerificationScope): Observable<void> {
    const identityType = AuthService.getIdentityType(identityValue);
    return this.httpClient.post<void>('/api/verification/send-otp/v2', {
      identityValue,
      identityType,
      scope, // TODO remove on access fallback cleanup
      identityInput: identityValue, // TODO remove on access fallback cleanup
    });
  }

  /* istanbul ignore next */
  validateOtp(identityValue: string, otp: string): Observable<void> {
    const identityType = AuthService.getIdentityType(identityValue);
    return this.httpClient.post<void>('/api/verification/validate-otp/v2', {
      identityValue,
      identityType,
      otp,
      verificationCode: otp, // TODO remove on access fallback cleanup
      identityInput: identityValue, // TODO remove on access fallback cleanup
    });
  }

  isVerified(identityValue: string): Observable<boolean> {
    const identityType = AuthService.getIdentityType(identityValue);
    return this.httpClient
      .get<IsVerifiedResponse>(
        `/api/verification/is-verified/v2?identityValue=${encodeURIComponent(identityValue)}&identityType=${identityType}`,
      )
      .pipe(map((response: IsVerifiedResponse) => response.isVerified));
  }
}
