import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'ni-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FormErrorMessageComponent {
  errorText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @Input() set errorMessage(value: string) {
    if (value !== this.errorText) {
      this.errorText = value;
      this.changeDetectorRef.detectChanges();
    }
  }
}
