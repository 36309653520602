import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { AuthErrorStatuses } from '../models/auth-error-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LoginTrackingService {
  loginFlowPlacement = 'login-flow';

  constructor(
    private trackingService: TrackingService,
    private cookieService: CookieService,
  ) {}

  /* istanbul ignore next */
  trackLoginPageView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.loginFlowPlacement,
      name: 'login-page',
      eventName: 'login flow - VIEW login page',
    });
  }

  /* istanbul ignore next */
  trackVerificationPageView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.loginFlowPlacement,
      name: 'verification-page',
      eventName: 'login flow - VIEW verification page',
    });
  }

  trackLoginSubmit(loginInput: string): void {
    const isSubmittingLoginWithPrefill = loginInput === this.cookieService.get(environment.portalLoginIdentityCookieName);
    if (isSubmittingLoginWithPrefill) {
      this.trackLoginSubmitWithPrefill(loginInput);
    } else {
      this.trackingService.track(
        {
          interactionType: InteractionType.Click,
          placement: this.loginFlowPlacement,
          name: 'login-submit',
          interactionData: {
            loginInput,
          },
          eventName: 'login flow - CLICK login submit',
        },
        true,
      );
    }
  }

  /* istanbul ignore next */
  trackLoginSubmitWithInvalidInput(loginInput: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: this.loginFlowPlacement,
      name: 'login-submit-with-invalid-value',
      interactionData: {
        loginInput,
      },
    });
  }

  /* istanbul ignore next */
  trackLoginResponse(errorReason?: AuthErrorStatuses): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Api,
        placement: this.loginFlowPlacement,
        name: 'login-response',
        interactionData: {
          result: errorReason || 'SUCCESS',
        },
        eventName: 'login flow - API login response',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackVerificationClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.loginFlowPlacement,
      name: 'verification-submit-button',
      eventName: 'login flow - CLICK verification submit button',
    });
  }

  /* istanbul ignore next */
  trackVerificationResponse(errorReason?: AuthErrorStatuses): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Api,
        placement: this.loginFlowPlacement,
        name: 'verification-submit',
        interactionData: {
          result: errorReason || 'SUCCESS',
        },
        eventName: 'login flow - API verification response',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackResendCodeClicked(loginInput: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.loginFlowPlacement,
      name: 'login-resend-code',
      interactionData: {
        loginInput,
      },
      eventName: 'login flow - CLICK resend code',
    });
  }

  /* istanbul ignore next */
  trackResendCodeResponse(errorReason?: AuthErrorStatuses): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.loginFlowPlacement,
      name: 'resend-code-response',
      interactionData: {
        result: errorReason || 'SUCCESS',
      },
      eventName: 'login flow - API resend code response',
    });
  }

  /* istanbul ignore next */
  trackGoogleLoginButtonClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.loginFlowPlacement,
        name: 'google-login-button-click',
        eventName: 'login flow - CLICK google login button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackPortalAgentsLinkClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.loginFlowPlacement,
        name: 'portal-agents-login-link-click',
        eventName: 'login flow - CLICK portal agents login link',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackGoogleLoginCallbackStart(): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: this.loginFlowPlacement,
      name: 'google-login-callback-start',
    });
  }

  /* istanbul ignore next */
  trackGoogleLoginCallbackComplete(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Api,
        placement: this.loginFlowPlacement,
        name: 'google-login-callback-complete',
        interactionData: {
          isSuccess,
          errorReason,
        },
        eventName: 'login flow - API google login callback',
      },
      true,
    );
  }

  /* istanbul ignore next */
  private trackLoginSubmitWithPrefill(loginInput: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.loginFlowPlacement,
        name: 'login-submit-with-prefill',
        interactionData: {
          loginInput,
        },
        eventName: 'login flow - CLICK login submit with prefill',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackOktaLoginCallbackStart(paramsString: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: this.loginFlowPlacement,
      name: 'okta-login-callback-start',
      interactionData: {
        paramsString,
      },
    });
  }
}
