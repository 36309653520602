import { LOB } from '@next-insurance/core';

export enum RichBusinessChangesType {
  BusinessNameAndStructure = 'DBA_AND_BOS',
  Address = 'ADDRESS_CHANGE',
  MailingAddress = 'MAILING_ADDRESS_CHANGE',
  WaiverOfSub = 'WAIVER_OF_SUB_CHANGE',
}

export enum RichPolicyChangesType {
  Payroll = 'PAYROLL_CHANGE',
  YearlyRevenue = 'YEARLY_REVENUE_CHANGE',
  Package = 'PACKAGE_CHANGE',
  Email = 'EMAIL_CHANGED',
  Vehicles = 'VEHICLES_CHANGE',
  ClassCode = 'CLASS_CODE_CHANGE',
  OwnersCoverage = 'OWNERS_COVERAGE_CHANGE',
}

export enum RichDirectPolicyChangeType {
  DirectYearlyRevenue = 'DIRECT_YEARLY_REVENUE_CHANGE',
  DirectCoverageLimitChange = 'COVERAGE_LIMIT_CHANGE',
}

export enum RichDirectBusinessChangeType {
  DirectAddress = 'DIRECT_ADDRESS_CHANGE',
  DirectMailingAddress = 'DIRECT_MAILING_ADDRESS_CHANGE',
  DirectAdditionalLocation = 'ADDITIONAL_LOCATIONS_CHANGE',
}

export type RichChangesType = RichBusinessChangesType | RichPolicyChangesType | RichDirectPolicyChangeType | RichDirectBusinessChangeType;

export enum InAppChangeType {
  ContactInfo = 'CONTACT_INFO',
  AuthorizedUsers = 'AUTHORIZED_USERS',
}

export enum TransactionType {
  Charge = 'CHARGE',
  Refund = 'REFUND',
  ChargeAndRefund = 'CHARGE_AND_REFUND',
  None = 'NONE',
}

export interface SubmitChangeResponse {
  status: ChangeResultStatus;
}

export enum ChangeResultStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Decline = 'DECLINE',
  PaymentIssue = 'CHANGE_PAYMENT_PLAN_FAILED',
  Error = 'INTERNAL_ERROR',
}

export enum StatusFromApplication {
  Success = 'Success',
  Error = 'Error',
}

export type PermittedChanges = {
  [policy in LOB]?: RichChangesType[];
};

export enum UpdatedFields {
  BusinessName = 'BUSINESS_NAME',
  BusinessStructure = 'BUSINESS_STRUCTURE',
  Address = 'ADDRESS',
  OwnershipStructure = 'OWNERSHIP_STRUCTURE',
  NumberOfEmployees = 'NUMBER_OF_EMPLOYEES',
  NumberOfOwners = 'NUMBER_OF_OWNERS',
  NumberOfCoveredOwners = 'NUMBER_OF_COVERED_OWNERS',
  Payroll = 'PAYROLL_CHANGE',
  EmployeeSubcontractorAnnualPayroll = 'EMPLOYEE_SUBCONTRACTOR_ANNUAL_PAYROLL',
  OfficePayroll = 'OFFICE_PAYROLL',
  OutsideSalesPayroll = 'OUTSIDE_SALES_PAYROLL',
  GLCoveragePlan = 'GL_COVERAGE_PLAN',
  WCCoveragePlan = 'WC_COVERAGE_PLAN',
  CACoveragePlan = 'CA_COVERAGE_PLAN',
  PLCoveragePlan = 'PL_COVERAGE_PLAN',
  IMCoveragePlan = 'IM_COVERAGE_PLAN',
  CPCoveragePlan = 'CP_COVERAGE_PLAN',
  Deductible = 'DEDUCTIBLE',
  Vehicles = 'VEHICLES',
  WaiverOfSub = 'BLANKET_WOS_ADDED',
  CoverageCustomizationChange = 'COVERAGE_CUSTOMIZATION_CHANGE',
}
