import { NgxZendeskWebwidgetConfig } from '@nitsanzo/ngx-zendesk-webwidget';
import { environment } from '../../../environments/environment';
import { ZendeskAction } from '../models/zendesk-action.enum';

export class ZendeskWidgetConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = environment.zendeskAccountUrl;
  lazyLoad = true;
  timeOut = 120000; // 2 min

  callback(zE: any): void {
    zE('webWidget', ZendeskAction.Hide);
  }
}
