import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';

export class NiTranslateLoader extends TranslateHttpLoader {
  constructor(httpClient: HttpClient) {
    super(httpClient, '/angular/assets/lang/', '.json');
  }

  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'en':
        return of(require('../../../assets/lang/en.json'));
      case 'es':
        return of(require('../../../assets/lang/es.json'));
    }
    return super.getTranslation(lang);
  }
}

// AoT requires an exported function for factories
export function NiHttpLoaderFactory(httpClient: HttpClient): NiTranslateLoader {
  return new NiTranslateLoader(httpClient);
}
