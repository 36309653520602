import { Injectable } from '@angular/core';
import { isDateInWorkingHours } from '@next-insurance/date';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChatWidgetConfig } from '../../chatbot/models/chat-button-config-model';
import { AppState } from '../../store';
import { zendeskConfig } from '../config/zendesk.config';
import { ChatbotAgentType } from '../models/chatbot-agent-type.enum';
import { coreSelectors } from '../store/core.selectors';
import { HelpTipService } from './help-tip.service';
import { MobileAppService } from './mobile-app.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotUtilsService {
  constructor(
    private helpTipService: HelpTipService,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private mobileAppService: MobileAppService,
  ) {}

  getChatWidgetConfig(agentType: ChatbotAgentType): Observable<ChatWidgetConfig> {
    return this.store.pipe(
      select(coreSelectors.isNavigationTabsVisible),
      map((showButton: boolean) => this.getServicingConfig(agentType, showButton)),
    );
  }

  isChatButtonEnabled(): boolean {
    return !this.mobileAppService.isMobileAppWebview();
  }

  private getServicingConfig(agentType: ChatbotAgentType, showButton: boolean): ChatWidgetConfig {
    if (agentType !== ChatbotAgentType.Servicing) {
      return null;
    }

    return {
      agentType,
      isPersistent: true,
      buttonConfig: showButton
        ? {
            visible: true,
            text: this.translateService.instant('CHAT.BUTTON_TEXT'),
            minimizedText: this.translateService.instant('CHAT.MINIMIZED_BUTTON_TEXT'),
          }
        : null,
      getParams: () => ({
        helpTipFilter: this.helpTipService.getVisibleHelpTips(true).join(','),
        zendeskChatEnabled: isDateInWorkingHours(zendeskConfig.workingHours),
        zdAgentStartTime: zendeskConfig.workingHours.startTime,
        zdAgentEndTime: zendeskConfig.workingHours.endTime,
        zdAgentTimeZone: zendeskConfig.workingHours.timezone,
      }),
    };
  }
}
