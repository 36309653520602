import { Action, createReducer, on } from '@ngrx/store';

import { PaymentDetailsPerPolicy } from '../models/payment-details-per-policy.model';
import { PaymentMethodDetails } from '../models/payment-method-details.model';
import { paymentActions } from './payment.actions';

export interface PaymentState {
  paymentMethodDetails: PaymentMethodDetails;
  paymentDetailsPerPolicy: PaymentDetailsPerPolicy;
  isLoading: boolean;
  isSubmitting: boolean;
  updatePaymentMethodFailed: boolean;
}

export const paymentInitialState: PaymentState = {
  paymentMethodDetails: null,
  paymentDetailsPerPolicy: null,
  isLoading: false,
  isSubmitting: false,
  updatePaymentMethodFailed: false,
};

const reducer = createReducer(
  paymentInitialState,
  on(paymentActions.loadCombinedPaymentDetails, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(paymentActions.setCombinedPaymentDetails, (state, { combinedPaymentDetails }) => ({
    ...state,
    paymentMethodDetails: combinedPaymentDetails?.paymentMethodDetails,
    paymentDetailsPerPolicy: combinedPaymentDetails?.paymentDetailsPerPolicy,
    isLoading: false,
  })),
  on(paymentActions.updatePaymentMethod, (state) => ({
    ...state,
    isSubmitting: true,
    updatePaymentMethodFailed: false,
  })),
  on(paymentActions.updatePaymentMethodSuccess, (state) => ({
    ...state,
    isSubmitting: false,
    updatePaymentMethodFailed: false,
  })),
  on(paymentActions.updatePaymentMethodError, (state) => ({
    ...state,
    isSubmitting: false,
    updatePaymentMethodFailed: true,
  })),
);

export function paymentReducer(state: PaymentState, action: Action): PaymentState {
  return reducer(state, action);
}
