import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ChatContinuitySessionService } from '@next-insurance/ni-chat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EMPTY, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { FeatureFlags } from '../../core/models/feature-flags.enum';
import { QueryParams } from '../../core/models/query-params.enum';
import { AuthService } from '../../core/services/auth.service';
import { FeatureFlagsService } from '../../core/services/feature-flags.service';
import { NavigationService } from '../../core/services/navigation.service';
import { NiValidatorsService } from '../../core/services/ni-validators.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
import { EmailValidatorService } from '../../core/services/validators/email-validator.service';
import { coreActions } from '../../core/store/core.actions';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { selectQueryParams } from '../../store/router.store';
import { AuthErrorStatuses } from '../models/auth-error-status.enum';
import { LoginResponse } from '../models/login-response.model';
import { SendOtpResponse } from '../models/send-otp-response.model';
import { VerificationCodeDeliveryMethod } from '../models/verification-code-delivery-method.enum';
import { LoginDataService } from '../services/login.data.service';
import { LoginTrackingService } from '../services/login-tracking.service';
import { LoginV2DataService } from '../services/login-v2.data.service';
import { loginActions } from './login.actions';
import { loginSelectors } from './login.selectors';

const RECAPTCHA_AUTHENTICATION_KEY = 'authentication';

@Injectable()
export class LoginEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.logout),
      switchMap(() => {
        this.storage.clear();
        this.chatContinuitySessionService.clearSessionIdFromStorage();
        const logout$ = this.featureFlagsService.isActive(FeatureFlags.LoginV2)
          ? this.loginDataServiceV2.logout()
          : this.loginDataService.logout();
        return logout$.pipe(
          switchMap(() => {
            return [
              navigationActions.toAuthentication({}),
              coreActions.setLoading({ isLoading: false }),
              loginActions.setLoading({ isLoading: false }),
            ];
          }),
        );
      }),
    ),
  );

  redirectToGoogleLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginActions.redirectToGoogleLogin),
        tap(() => {
          this.navigationService.navigateTo(`${location.origin}/api/public/authentication/redirect-to-google-login`);
        }),
      );
    },
    { dispatch: false },
  );

  authenticationSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.authenticationSubmit),
      switchMap((action: { loginInput: string }) => {
        return this.recaptchaService.executeRecaptcha(RECAPTCHA_AUTHENTICATION_KEY).pipe(
          switchMap((recaptchaToken: string) => {
            if (!NiValidatorsService.isPhoneValid(action.loginInput) && !EmailValidatorService.isValidOld(action.loginInput)) {
              this.loginTrackingService.trackLoginSubmitWithInvalidInput(action.loginInput);
              throw new Error();
            }
            if (this.featureFlagsService.isActive(FeatureFlags.LoginV2)) {
              return this.loginDataServiceV2.sendToken(action.loginInput, recaptchaToken);
            }
            return this.loginDataService.sendToken(action.loginInput, recaptchaToken);
          }),
          map((response: SendOtpResponse) => {
            this.recaptchaService.hideRecaptcha();
            return loginActions.authenticationSuccess({ response });
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode || AuthErrorStatuses.InternalFailure;
            return of(loginActions.authenticationFailed({ errorReason }));
          }),
        );
      }),
    );
  });

  authenticationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.authenticationSuccess),
      tap(() => this.loginTrackingService.trackLoginResponse()),
      map(() => navigationActions.toVerification()),
    ),
  );

  authenticationFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.authenticationFailed),
      tap(({ errorReason, isSSO }: { errorReason: AuthErrorStatuses; isSSO?: boolean }) => {
        if (!isSSO) {
          this.loginTrackingService.trackLoginResponse(errorReason);
        }
      }),
      switchMap((action: { errorReason: AuthErrorStatuses }) => {
        if (action.errorReason === AuthErrorStatuses.TooManyLoginAttempts) {
          return [navigationActions.toError()];
        }
        return EMPTY;
      }),
    ),
  );

  verificationSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.verificationSubmit),
      withLatestFrom(this.store.pipe(select(loginSelectors.getLoginInput))),
      switchMap(([action, identityValue]: [{ verificationToken: string }, string]) => {
        const validateToken$ = this.featureFlagsService.isActive(FeatureFlags.LoginV2)
          ? this.loginDataServiceV2.validateOtp(identityValue, action.verificationToken)
          : this.loginDataService.validateToken(identityValue, action.verificationToken);

        return validateToken$.pipe(
          map((response: LoginResponse) => {
            return loginActions.verificationSuccess({ response });
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode || AuthErrorStatuses.InternalFailure;
            return of(loginActions.verificationFailed({ errorReason }));
          }),
        );
      }),
    ),
  );

  verificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.verificationSuccess),
        withLatestFrom(this.store.select(selectQueryParams)),
        tap(([, params]: [Action, Params]) => {
          this.loginTrackingService.trackVerificationResponse();
          const returnUrl = params[QueryParams.ReturnUrl];
          if (returnUrl) {
            const queryParams: Params = {
              ...params,
              [QueryParams.ReturnUrl]: null,
            };
            this.router.navigate([`/${returnUrl}`], { queryParams });
          } else {
            this.store.dispatch(navigationActions.toHomePage({ replaceUrl: true }));
          }
        }),
      ),
    { dispatch: false },
  );

  verificationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.verificationFailed),
        tap((action: { errorReason: AuthErrorStatuses }) => {
          this.loginTrackingService.trackVerificationResponse(action.errorReason);
        }),
      ),
    { dispatch: false },
  );

  resendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.resendCode),
      switchMap((action: { loginInput: string }) => {
        return this.recaptchaService.executeRecaptcha(RECAPTCHA_AUTHENTICATION_KEY).pipe(
          switchMap((recaptchaToken: string) => {
            if (this.featureFlagsService.isActive(FeatureFlags.LoginV2)) {
              return this.loginDataServiceV2.sendToken(action.loginInput, recaptchaToken);
            }
            return this.loginDataService.sendToken(action.loginInput, recaptchaToken);
          }),
          map(() => {
            this.recaptchaService.hideRecaptcha();
            this.loginTrackingService.trackResendCodeResponse();
            return loginActions.resendCodeSuccess({ identityType: AuthService.getIdentityType(action.loginInput) });
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode || AuthErrorStatuses.InternalFailure;
            this.loginTrackingService.trackResendCodeResponse(errorReason);
            return of(loginActions.authenticationFailed({ errorReason }));
          }),
        );
      }),
    );
  });

  resendCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.resendCodeSuccess),
      map((action: { identityType: VerificationCodeDeliveryMethod }) => {
        return toastActions.showToast({
          toastType: ToastType.Success,
          message:
            action.identityType === VerificationCodeDeliveryMethod.Email
              ? `LOGIN.VERIFICATION.RESEND_CODE_SUCCESS.EMAIL`
              : `LOGIN.VERIFICATION.RESEND_CODE_SUCCESS.PHONE`,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private loginDataServiceV2: LoginV2DataService,
    private loginDataService: LoginDataService,
    private recaptchaService: RecaptchaService,
    private loginTrackingService: LoginTrackingService,
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
    private navigationService: NavigationService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private chatContinuitySessionService: ChatContinuitySessionService,
  ) {}
}
