import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { map, tap } from 'rxjs/operators';

import { HidePhoneNumberPipe } from '../../../pipes/hide-phone-number.pipe';
import { ToastType } from '../models/toast-type.enum';
import { toastActions } from './toast.actions';

@Injectable()
export class ToastEffects {
  showToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toastActions.showToast),
        tap((action) => {
          let { message, title } = action;
          const { isAlreadyTranslated, translateParams, toastType, options } = action;

          if (!isAlreadyTranslated) {
            message = message && this.translate.instant(message, translateParams);
            title = title && this.translate.instant(title, translateParams);
          }

          this.messageService.add({
            severity: toastType,
            id: toastType,
            summary: title,
            detail: message,
            closable: true,
            life: options?.timeOut || 6000,
          });
        }),
      ),
    { dispatch: false },
  );

  showGeneralErrorToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toastActions.showGeneralErrorToast),
      map(() => {
        return toastActions.showToast({
          toastType: ToastType.Error,
          message: this.hidePhoneNumberPipe.transform('GENERAL.ERRORS.TOAST.CONTENT'),
          title: 'GENERAL.ERRORS.TOAST.HEADER',
        });
      }),
    ),
  );

  showLoadingErrorToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toastActions.showLoadingErrorToast),
      map(() => {
        return toastActions.showToast({
          toastType: ToastType.Error,
          message: this.hidePhoneNumberPipe.transform('GENERAL.ERRORS.LOADING.CONTENT'),
          title: 'GENERAL.ERRORS.LOADING.HEADER',
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private translate: TranslateService,
    private hidePhoneNumberPipe: HidePhoneNumberPipe,
  ) {}
}
