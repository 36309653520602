import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NIUsStateHelper, UsStateCode, usStateCodeMap, UsStateName, usStateNameMap } from '@next-insurance/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of, timer } from 'rxjs';
import { combineLatestWith, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { CertificateAnalysisRecommendation } from '../../../core/models/certificate-analysis-recommendation.enum';
import { CertificateAnalysisRecommendationResponse } from '../../../core/models/certificate-analysis-recommendation-response.model';
import { CompleteThirdPartyRequestEvent, MobileAppEventId, TechnicalErrorEvent } from '../../../core/models/mobile-app-event.model';
import { DynamicDialogService } from '../../../core/services/dynamic-dialog.service';
import { MobileAppEventsService } from '../../../core/services/mobile-app-events.service';
import { coreActions } from '../../../core/store/core.actions';
import { CreateFollowerModalComponent } from '../../../followers/components/create-follower-modal/create-follower-modal.component';
import { RemoveFollowerModalComponent } from '../../../followers/components/remove-follower-modal/remove-follower-modal.component';
import { CreateFollowerRequest, CreateFollowerResponse, Follower } from '../../../followers/models/follower.model';
import { PaymentService } from '../../../payment/payment.service';
import { policiesActions } from '../../../policies/store/policies.actions';
import { LoadingMessageOverlayModalComponent } from '../../../shared/components/loading-message-overlay-modal/loading-message-overlay-modal.component';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { navigationActions } from '../../../store/navigation.actions';
import { DeleteCertificateModalComponent } from '../components/certificates-liability-section/delete-certificate-modal/delete-certificate-modal.component';
import { SendCertificateModalComponent } from '../components/send-certificate-modal/send-certificate-modal.component';
import { AutoInsuranceCard } from '../models/auto-insurance-card.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolder } from '../models/certificate-holder.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CertificateHolderRequirementsPartialInformationResponse } from '../models/certificate-holder-requirements-partial-information-response.model';
import { CertificateType } from '../models/certificate-type.enum';
import { CertificatesErrorReason } from '../models/certificates-error-reason.enum';
import { CreateCertificateRequest, CustomCertificateRequestChanges } from '../models/create-certificate-request.model';
import { SendCertificateRequest } from '../models/send-certificate-request.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { ThirdPartyNotificationsRequestErrorReason, ThirdPartyRequestErrorReason } from '../models/third-party-request-error-reason.enum';
import { ThirdPartyRequestResponse } from '../models/third-party-request-response.model';
import { ThirdPartyRequestAction, ThirdPartyRequestType } from '../models/third-party-request-type.enum';
import { CertificatesDataService } from '../services/certificates.data.service';
import { CertificatesTrackingService } from '../services/certificates-tracking.service';
import { CreateCertificateService } from '../services/create-certificate.service';
import { CustomCertificateDataService } from '../services/custom-certificate.data.service';
import { LiveCertificateDataService } from '../services/live-certificate.data.service';
import { LiveCertificateTrackingService } from '../services/live-certificate-tracking.service';
import { certificatesSelectors } from './certifiactes.selectors';
import { certificatesActions } from './certificates.actions';

@Injectable()
export class CertificatesEffects {
  loadProofCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadProofCertificate),
      switchMap(() => {
        return this.certificatesDataService.getCertificates(CertificateType.ProofCertificate).pipe(
          map((certificates: Certificate[]) => certificatesActions.setProofCertificate({ proofCertificate: certificates[0] })),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            if (errorReason === CertificatesErrorReason.CertificatesNotSupportedForBusiness) {
              return of(certificatesActions.setProofCertificate({ proofCertificate: null }));
            }
            return of(navigationActions.toError());
          }),
        );
      }),
    ),
  );

  loadCustomCertificates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadCustomCertificates),
      switchMap(() => {
        return this.certificatesDataService.getCertificates(CertificateType.CustomCertificate).pipe(
          map((customCertificates: Certificate[]) => certificatesActions.setCustomCertificates({ customCertificates })),
          catchErrorAndLog(() => of(navigationActions.toError())),
        );
      }),
    ),
  );

  deleteCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.deleteCertificate),
      switchMap((action) =>
        this.customCertificateDataService.deleteCertificate(action.liveCertificateId, action.overrideDesignationsApproved).pipe(
          switchMap(() => {
            this.certificatesTrackingService.trackCustomCertificateDeleted();
            return [
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.SUCCESS_MESSAGE',
              }),
              certificatesActions.loadCustomCertificates(),
              policiesActions.loadPolicies({}),
            ];
          }),
          catchErrorAndLog(() => {
            return of(toastActions.showGeneralErrorToast());
          }),
          finalize(() => {
            this.dynamicDialogService.close(DeleteCertificateModalComponent);
          }),
        ),
      ),
    ),
  );

  loadAutoInsuranceCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadAutoInsuranceCards),
      switchMap(() =>
        this.certificatesDataService.getAutoInsuranceCards().pipe(
          map((autoInsuranceCards: AutoInsuranceCard[]) => {
            return certificatesActions.setAutoInsuranceCards({ autoInsuranceCards });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  loadCertificateHolderRequirementsPartialInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadCertificateHolderRequirementsPartialInformation),
      switchMap(() =>
        this.certificatesDataService.getCertificateHolderRequirementsPartialInformation().pipe(
          map((certificateHolderRequirementsPartialInformationResponse: CertificateHolderRequirementsPartialInformationResponse) =>
            certificatesActions.setCertificateHolderRequirementsPartialInformation({
              certificateHolderRequirementsPartialInformation:
                certificateHolderRequirementsPartialInformationResponse.certificateHolderRequirementsPartialInformation,
            }),
          ),
          catchErrorAndLog(() => {
            this.createCertificateService.createCertificateFailed('CERTIFICATES.CREATE_CERTIFICATE_OLD.ALLOWED_OPERATIONS_ERROR');
            return [coreActions.setLoading({ isLoading: false })];
          }),
        ),
      ),
    ),
  );

  loadCertificateHolderRequirements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadCertificateHolderRequirements),
      switchMap((action) =>
        this.certificatesDataService.getCertificateHolderRequirements(action.certificateHolderRequirementId).pipe(
          combineLatestWith(timer(4000)),
          map((combinedValues) => combinedValues[0]),
          switchMap((certificateHolderRequirements: CertificateHolderRequirements) => {
            this.dynamicDialogService.close(LoadingMessageOverlayModalComponent);
            const request: CreateCertificateRequest = {
              certificateHolder: certificateHolderRequirements.certificateHolder,
              specialLanguages: [],
              descriptionOfOperations: '',
              isRequestFromHolderRequirements: true,
            };
            return [
              certificatesActions.loadCertificateHolderRequirementsSuccess({
                certificateHolderRequirements,
                request,
              }),
              navigationActions.toCustomCertificateReviewPage({}),
            ];
          }),
          catchErrorAndLog(() => {
            this.dynamicDialogService.close(LoadingMessageOverlayModalComponent);
            return [toastActions.showGeneralErrorToast()];
          }),
        ),
      ),
    ),
  );

  loadPublicCertificateAllowedOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadPublicCertificateAllowedOperations),
      withLatestFrom(this.store.select(certificatesSelectors.getLiveCertificateId)),
      switchMap(([, liveCertificateId]: [Action, string]) =>
        this.liveCertificateDataService.getPublicCertificateAllowedOperations(liveCertificateId).pipe(
          map((allowedOperations: CertificateAllowedOperations) =>
            certificatesActions.setPublicCertificateAllowedOperations({ allowedOperations }),
          ),
          catchErrorAndLog(() => {
            this.createCertificateService.createCertificateFailed('CERTIFICATES.CREATE_CERTIFICATE_OLD.ALLOWED_OPERATIONS_ERROR');
            return [coreActions.setLoading({ isLoading: false })];
          }),
        ),
      ),
    ),
  );

  loadCertificateAllowedModifiers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadCertificateAllowedModifiers),
      switchMap(() =>
        this.certificatesDataService.getCertificateAllowedModifiers().pipe(
          map((allowedModifiers: CertificateAllowedModifiers) => {
            return certificatesActions.setCertificateAllowedModifiers({ allowedModifiers });
          }),
          catchErrorAndLog(() => {
            this.createCertificateService.createCertificateFailed('CERTIFICATES.CREATE_CERTIFICATE_OLD.ALLOWED_OPERATIONS_ERROR');
            return [coreActions.setLoading({ isLoading: false })];
          }),
        ),
      ),
    ),
  );

  submitCustomCertificateForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitCustomCertificateForm),
      withLatestFrom(this.store.select(certificatesSelectors.isRequestChangesFlow)),
      switchMap(([, isRequestChangesFlow]: [Action, boolean]) => {
        return isRequestChangesFlow
          ? [navigationActions.toRequestChangesCustomizationsPage()]
          : [navigationActions.toCustomCertificateCustomizationsPage()];
      }),
    ),
  );

  submitWaiverChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitWaiverChange),
      map(() => {
        return navigationActions.toCustomCertificateReviewPage({});
      }),
    ),
  );

  goBackToCertificatesWithError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.goBackToCertificatesWithError),
      switchMap((action: { errorMessage?: string }) =>
        this.mobileAppEventsService.notifyMobileAndBreakFlowIfNeeded({
          eventId: MobileAppEventId.TechnicalError,
          ...action,
        } as TechnicalErrorEvent),
      ),
      switchMap(({ errorMessage }) => {
        return [
          errorMessage
            ? toastActions.showToast({ toastType: ToastType.Error, message: errorMessage })
            : toastActions.showGeneralErrorToast(),
          navigationActions.toCertificates(),
        ];
      }),
    ),
  );

  submitNotificationsRequestForCertHolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitNotificationsRequestForCertHolder),
      withLatestFrom(
        this.store.select(certificatesSelectors.getLiveCertificateId),
        this.store.select(certificatesSelectors.createCertificateRequest),
      ),
      switchMap(([, liveCertificateId, request]: [Action, string, CreateCertificateRequest]) => {
        const { thirdPartyName, emailAddress } = request as CustomCertificateRequestChanges;
        return this.liveCertificateDataService
          .requestNotifications(liveCertificateId, {
            thirdPartyName,
            emailAddress,
          })
          .pipe(
            switchMap((res: ThirdPartyRequestResponse) => {
              this.certificatesTrackingService.trackRequestNotificationsForCertHolderResult(true, res.requestId);
              return EMPTY;
            }),
            catchErrorAndLog((error: HttpErrorResponse) => {
              const errorReason = error.error?.niStatusCode;
              this.certificatesTrackingService.trackRequestNotificationsForCertHolderResult(false, null, errorReason);
              if (
                [
                  ThirdPartyNotificationsRequestErrorReason.ThirdPartyAlreadyFollowsThisBusiness,
                  ThirdPartyNotificationsRequestErrorReason.ThirdPartyHasPendingFollowRequest,
                ].includes(errorReason)
              ) {
                return of(
                  toastActions.showToast({
                    toastType: ToastType.Error,
                    message: `CERTIFICATES.THIRD_PARTY_REQUEST.REQUEST_NOTIFICATIONS.ERRORS.${errorReason}`,
                  }),
                );
              }
              return of(toastActions.showGeneralErrorToast());
            }),
          );
      }),
    ),
  );

  loadThirdPartyPendingRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadThirdPartyPendingRequests),
      switchMap(() =>
        this.certificatesDataService.getThirdPartyPendingRequests().pipe(
          map((thirdPartyPendingRequests: ThirdPartyPendingRequest[]) => {
            return certificatesActions.setThirdPartyPendingRequests({ thirdPartyPendingRequests });
          }),
          catchErrorAndLog(() => [
            certificatesActions.setThirdPartyPendingRequests({ thirdPartyPendingRequests: [] }),
            toastActions.showGeneralErrorToast(),
          ]),
        ),
      ),
    ),
  );

  approveThirdPartyRequestAfterPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.approveThirdPartyRequestAfterPayment),
      switchMap(({ requestId, requestType }) => {
        return this.certificatesDataService.approveThirdPartyRequest(requestId, requestType).pipe(
          map(() =>
            certificatesActions.submitThirdPartyRequestSuccess({
              requestType,
              requestId,
              requestAction: ThirdPartyRequestAction.Approve,
            }),
          ),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            return of(
              certificatesActions.submitThirdPartyRequestFailure({
                errorReason,
                requestId,
                requestType: requestType as ThirdPartyRequestType,
              }),
            );
          }),
        );
      }),
    ),
  );

  submitThirdPartyRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitThirdPartyRequestSuccess),
      switchMap((action: { requestType: ThirdPartyRequestType; requestAction: ThirdPartyRequestAction; requestId: string }) =>
        this.mobileAppEventsService.notifyMobileAndBreakFlowIfNeeded({
          eventId: MobileAppEventId.CompleteThirdPartyRequest,
          ...action,
          isSuccess: true,
        } as CompleteThirdPartyRequestEvent),
      ),
      switchMap(({ requestType, requestAction }) => {
        const messageTranslationKey =
          requestAction === ThirdPartyRequestAction.Approve ? 'APPROVE_SUCCESS_MESSAGE' : 'DENY_SUCCESS_MESSAGE';
        return [
          ...(requestType === ThirdPartyRequestType.Changes || requestAction === ThirdPartyRequestAction.Deny
            ? [navigationActions.toCertificates()]
            : [navigationActions.toFollowersPage({ replaceUrl: true })]),
          toastActions.showToast({
            toastType: ToastType.Success,
            message: `CERTIFICATES.PENDING_REQUESTS.${messageTranslationKey}`,
          }),
        ];
      }),
    ),
  );

  submitThirdPartyRequestFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitThirdPartyRequestFailure),
      switchMap(({ errorReason, requestId, requestType }) => {
        if (errorReason === ThirdPartyRequestErrorReason.ApplyChangePaymentFailed) {
          this.paymentService.openRelevantModalOnPaymentFailure({
            isSubmittingAction$: this.store.select(certificatesSelectors.isApprovingThirdPartyRequestAfterPayment),
            onPaymentUpdateFinished: () => {
              this.store.dispatch(certificatesActions.approveThirdPartyRequestAfterPayment({ requestId, requestType }));
            },
          });
          return EMPTY;
        }
        return [toastActions.showGeneralErrorToast()];
      }),
    ),
  );

  loadFollowers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.loadFollowers),
      switchMap(() =>
        this.certificatesDataService.getFollowers().pipe(
          map((followers: Follower[]) => {
            return certificatesActions.setFollowers({ followers });
          }),
          catchErrorAndLog(() => [certificatesActions.setFollowers({ followers: [] }), toastActions.showGeneralErrorToast()]),
        ),
      ),
    ),
  );

  createFollower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.createFollower),
      switchMap((action: { request: CreateFollowerRequest }) =>
        this.certificatesDataService.createFollower(action.request).pipe(
          switchMap((followerResponse: CreateFollowerResponse) => {
            const follower = { ...followerResponse, ...action.request };
            this.dynamicDialogService.close(CreateFollowerModalComponent);
            this.certificatesTrackingService.trackAddFollowerModalResult(true, follower);
            return [
              certificatesActions.createFollowerSuccess({ follower }),
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'CERTIFICATES.FOLLOWERS.ADD_FOLLOWER.SUCCESS_MESSAGE',
                translateParams: { name: action.request.name },
              }),
            ];
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            this.certificatesTrackingService.trackAddFollowerModalResult(false, null, errorReason);
            this.dynamicDialogService.close(CreateFollowerModalComponent);
            return [
              errorReason === ThirdPartyNotificationsRequestErrorReason.ThirdPartyAlreadyFollowsThisBusiness
                ? toastActions.showToast({
                    toastType: ToastType.Error,
                    message: 'CERTIFICATES.FOLLOWERS.ADD_FOLLOWER.ERRORS.THIRD_PARTY_ALREADY_FOLLOWS_THIS_BUSINESS',
                  })
                : toastActions.showGeneralErrorToast(),
            ];
          }),
        ),
      ),
    ),
  );

  removeFollower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.removeFollower),
      switchMap((action: { follower: Follower }) =>
        this.certificatesDataService.removeFollower(action.follower.thirdPartyId).pipe(
          switchMap(() => {
            this.dynamicDialogService.close(RemoveFollowerModalComponent);
            this.certificatesTrackingService.trackRemoveFollowerModalResult(true, action.follower);
            return [
              certificatesActions.removeFollowerSuccess({ follower: action.follower }),
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'CERTIFICATES.FOLLOWERS.REMOVE_FOLLOWER.SUCCESS_MESSAGE',
                translateParams: { name: action.follower.name },
              }),
            ];
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            this.certificatesTrackingService.trackRemoveFollowerModalResult(false, action.follower, errorReason);
            this.dynamicDialogService.close(RemoveFollowerModalComponent);
            return [toastActions.showGeneralErrorToast()];
          }),
        ),
      ),
    ),
  );

  sendCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.sendCertificate),
      switchMap((action: { request: SendCertificateRequest; liveCertificateId: string }) =>
        this.certificatesDataService.sendCertificate(action.liveCertificateId, action.request).pipe(
          switchMap(() => {
            this.dynamicDialogService.close(SendCertificateModalComponent);
            this.liveCertificateTrackingService.trackSendCertificateResult(true);
            return [
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.SUCCESS_MESSAGE',
              }),
            ];
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            this.liveCertificateTrackingService.trackSendCertificateResult(false, errorReason);
            this.dynamicDialogService.close(SendCertificateModalComponent);
            return [
              toastActions.showToast({
                toastType: ToastType.Error,
                message: 'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.FAILURE_MESSAGE',
              }),
            ];
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private certificatesDataService: CertificatesDataService,
    private customCertificateDataService: CustomCertificateDataService,
    private liveCertificateDataService: LiveCertificateDataService,
    private dynamicDialogService: DynamicDialogService,
    private certificatesTrackingService: CertificatesTrackingService,
    private liveCertificateTrackingService: LiveCertificateTrackingService,
    private mobileAppEventsService: MobileAppEventsService,
    private createCertificateService: CreateCertificateService,
    private paymentService: PaymentService,
  ) {}

  private buildCoiAnalyzerCertificateRequest = (certificateHolder: CertificateHolder, analysisId: string): CreateCertificateRequest => {
    return {
      descriptionOfOperations: '',
      specialLanguages: [],
      certificateHolder: {
        ...certificateHolder,
        // Name cannot be null or undefined, but it can be an empty string
        name: certificateHolder.name || '',
        address: {
          ...certificateHolder.address,
          stateCode: this.extractStateCode(certificateHolder?.address?.stateCode as UsStateName),
        },
      },
      isRequestFromHolderRequirements: false,
      certificateCreationExternalMetadata: { externalCoiAnalyzerMetadata: { analysisId } },
    };
  };

  submitCoiAnalyzerResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesActions.submitCoiAnalyzerResults),
      map((action: { result: CertificateAnalysisRecommendationResponse; analysisId: string }) => {
        const request = this.buildCoiAnalyzerCertificateRequest(action.result.certificateHolderInfo, action.analysisId);
        this.store.dispatch(certificatesActions.setCertificateRequest({ request }));
        switch (action.result.analysisRecommendation) {
          case CertificateAnalysisRecommendation.FinalReview:
            return navigationActions.toCustomCertificateReviewPage({ fromSampleCOI: true });
          default:
            return navigationActions.toCreateCustomCertificate();
        }
      }),
    ),
  );

  // TODO: should be removed after ML fix the model https://nextinsurance.atlassian.net/browse/NI-75464
  private extractStateCode = (state: UsStateName | UsStateCode): UsStateCode => {
    if (Object.keys(usStateCodeMap).includes(state.toUpperCase())) {
      return state.toUpperCase() as UsStateCode;
    }

    if (Object.keys(usStateNameMap).some((stateName) => state.toLocaleLowerCase() === stateName.toLowerCase())) {
      let stateName = state as UsStateName;
      return NIUsStateHelper.mapToUsStateCode(this.capitalizeWords(stateName));
    }

    return undefined;
  };

  private capitalizeWords(input: string): UsStateName {
    const words = input.toLowerCase().split(' ');
    const capitalizedWords = [];

    for (const word of words) {
      const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      capitalizedWords.push(capitalizedWord);
    }

    return capitalizedWords.join(' ') as UsStateName;
  }
}
