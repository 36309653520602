import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LOB } from '@next-insurance/core';
import { NIError } from '@next-insurance/errors';
import logger from '@next-insurance/logger';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { aiChatbotAbTestConfig } from '../../ab-testing/models/ab-test-config.model';
import { Certificate } from '../../certificates/shared/models/certificate.model';
import { CertificateType } from '../../certificates/shared/models/certificate-type.enum';
import { CertificatesDataService } from '../../certificates/shared/services/certificates.data.service';
import { Policy } from '../../policies/models/policy.model';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { FeatureFlags } from '../models/feature-flags.enum';
import { HelpTip } from '../models/help-tip.enum';
import { HelpTipOption, HelpTipOptionsConfig } from '../models/help-tip-models.model';
import { AbTestingService } from './ab-testing.service';
import { FeatureFlagsService } from './feature-flags.service';
import { HelpTipTrackingService } from './help-tip-tracking.service';
import { IntroJsService } from './intro-js.service';

interface HelpTipOptionInternal extends HelpTipOption {
  isHidden?: () => boolean;
}

type HelpTipOptionsConfigInternal = {
  [id in HelpTip]?: HelpTipOptionInternal;
};

@Injectable({
  providedIn: 'root',
})
export class HelpTipService {
  private static navigationMenuSelector = '[data-test=navigation-menu-icon]';
  navigationEnd$: Subscription;
  private basicOptionsConfig: HelpTipOptionsConfigInternal;
  private webhookOptionsConfig: HelpTipOptionsConfigInternal; // used by DialogFlow chatbot

  constructor(
    private store: Store<AppState>,
    private introJsService: IntroJsService,
    private certificatesDataService: CertificatesDataService,
    private router: Router,
    private helpTipTrackingService: HelpTipTrackingService,
    private featureFlagsService: FeatureFlagsService,
    private abTestingService: AbTestingService,
  ) {
    this.initOptionsConfig();
    this.initWebhookHelpTipsConfig();
  }

  private static clickEvent(): MouseEvent {
    return new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
  }

  private static clickElement(elementSelector: string): void {
    const element: HTMLElement = document.querySelector(elementSelector) as HTMLElement;
    if (element) {
      element.dispatchEvent(HelpTipService.clickEvent());
    }
  }

  getOptionsConfigState(includeWebhookTips = false): HelpTipOptionsConfig {
    const result: HelpTipOptionsConfig = {};
    let helpTipKeys = Object.keys(this.basicOptionsConfig);

    if (includeWebhookTips) {
      helpTipKeys = helpTipKeys.concat(Object.keys(this.webhookOptionsConfig));
    }

    helpTipKeys.forEach((key: HelpTip) => {
      const configElement: HelpTipOptionInternal = this.basicOptionsConfig[key] || this.webhookOptionsConfig[key];
      result[key] = {
        ...configElement,
        visible: !configElement.isHidden || !configElement.isHidden(),
      };
    });

    return result;
  }

  helpTipClicked(selectedOption: HelpTip, chatbotSessionId: string = null, isChatbotTip = false): void {
    const option: HelpTipOption = this.basicOptionsConfig[selectedOption] || this.webhookOptionsConfig[selectedOption];
    window.scrollTo(0, 0);
    if (option.action) {
      option.action();
    }
    this.introJsService
      .setCurrentStep(option.id, option.retryCount, option.delayInMillis, option.timeUntilFallback, isChatbotTip)
      .subscribe((succeeded: boolean) => {
        if (succeeded) {
          this.helpTipTrackingService.trackHelpTipSuccess(option, chatbotSessionId, isChatbotTip);
        } else if (this.shouldTriggerFallbackTip() && option.fallback) {
          this.helpTipClicked(option.fallback);
          logger.warn(`help tip ${selectedOption} failed, falling back to ${option.fallback}`);
        } else {
          this.handleStepFailure(selectedOption, option, chatbotSessionId, isChatbotTip);
        }
      });
  }

  private shouldTriggerFallbackTip(): boolean {
    return (
      (this.featureFlagsService.isActive(FeatureFlags.AiChatbot) || this.abTestingService.canRunAbTest(aiChatbotAbTestConfig)) &&
      this.featureFlagsService.isActive(FeatureFlags.FallbackHelpTip)
    );
  }

  private handleStepFailure(selectedOption: HelpTip, option: HelpTipOption, chatbotSessionId: string, isChatbotTip: boolean): void {
    if (this.shouldTriggerFallbackTip()) {
      this.introJsService.cantFindStep();
      throw new NIError('Step element is still not visible', null, {
        step: selectedOption,
      });
    }
    this.helpTipTrackingService.trackHelpTipFailure(option, chatbotSessionId, isChatbotTip);
  }

  getVisibleHelpTips(includeWebhookTips = false): HelpTip[] {
    return Object.values(this.getOptionsConfigState(includeWebhookTips))
      .filter((tipItem) => tipItem.visible)
      .map((tip) => tip.id);
  }

  private openCoveragePagePolicyCardMenu(lob: LOB): void {
    const action = (): void =>
      HelpTipService.clickElement(`[data-test=policy-details-card][data-lob=${lob}] [data-test=policy-menu] [data-test=menu-icon]`);
    this.navigateToCoveragePage(action);
  }

  private navigateToLiveCertificate(): void {
    this.certificatesDataService.getCertificates(CertificateType.ProofCertificate).subscribe((certificates: Certificate[]) => {
      const { liveCertificateId } = certificates[0];
      this.store.dispatch(navigationActions.toLiveCertificate({ liveCertificateId }));
    });
  }

  private checkIsHidden(selector: MemoizedSelector<AppState, any>, transformFn?: (value: any) => boolean): boolean {
    let hidden = false;

    this.store.pipe(select(selector), first()).subscribe((result: boolean) => {
      hidden = transformFn ? transformFn(result) : result;
    });

    return hidden;
  }

  private navigateToCoveragePage(action: () => void): void {
    if (this.router.url.endsWith('coverage')) {
      action();
    } else {
      this.store.dispatch(navigationActions.toCoveragePage());
      this.onNavigationEndAction('coverage', action);
    }
  }

  private onNavigationEndAction(url: string, action: () => void): void {
    this.navigationEnd$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: Event | RouterEvent) => {
        if ((event as RouterEvent).url.includes(url)) {
          this.unsubscribeNavigationEndAction();
          setTimeout(() => action(), 1500);
        }
      });
  }

  private unsubscribeNavigationEndAction(): void {
    this.navigationEnd$.unsubscribe();
  }

  private initOptionsConfig(): void {
    this.basicOptionsConfig = {
      [HelpTip.ManageCertificates]: {
        id: HelpTip.ManageCertificates,
        fallback: HelpTip.CertificatesGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toCertificates());
        },
      },
      [HelpTip.AddCertificateHolder]: {
        id: HelpTip.AddCertificateHolder,
        fallback: HelpTip.CertificatesGeneral,
        isHidden: () =>
          this.checkIsHidden(policiesSelectors.hasActiveOrBoundPolicy(), (hasActiveOrBoundPolicy: boolean) => !hasActiveOrBoundPolicy),
        action: () => {
          this.store.dispatch(navigationActions.toCertificates());
        },
      },
      [HelpTip.CertificateDetails]: {
        id: HelpTip.CertificateDetails,
        delayInMillis: 500,
        fallback: HelpTip.CertificatesGeneral,
        isHidden: () =>
          this.checkIsHidden(policiesSelectors.hasActiveOrBoundPolicy(), (hasActiveOrBoundPolicy: boolean) => !hasActiveOrBoundPolicy),
        action: () => {
          this.store.dispatch(navigationActions.toCertificates());
        },
      },
      [HelpTip.ShareCertificate]: {
        id: HelpTip.ShareCertificate,
        fallback: HelpTip.CertificatesGeneral,
        isHidden: () =>
          this.checkIsHidden(policiesSelectors.hasActiveOrBoundPolicy(), (hasActiveOrBoundPolicy: boolean) => !hasActiveOrBoundPolicy),
        action: () => {
          this.navigateToLiveCertificate();
        },
      },
      [HelpTip.DownloadCertificate]: {
        id: HelpTip.DownloadCertificate,
        fallback: HelpTip.CertificatesGeneral,
        action: () => {
          if (this.getIsHistoricalUser()) {
            this.store.dispatch(navigationActions.toCertificates());
          } else {
            this.navigateToLiveCertificate();
          }
        },
      },
      [HelpTip.UpComingPayments]: {
        id: HelpTip.UpComingPayments,
        delayInMillis: 1000,
        isHidden: () => this.getIsHistoricalUser(),
        action: () => {
          this.store.dispatch(navigationActions.toHomePage({}));
        },
      },
      [HelpTip.UpdateBusiness]: {
        id: HelpTip.UpdateBusiness,
        displayName: `HELP.ACCOUNT_MANAGEMENT.UPDATE_BUSINESS.${
          this.getIsHistoricalUser() ? 'HISTORICAL_USER_TITLE' : 'ACTIVE_USER_TITLE'
        }`,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.DownloadReceipt]: {
        id: HelpTip.DownloadReceipt,
        fallback: HelpTip.AccountGeneral,
        isHidden: () => this.checkIsHidden(policiesSelectors.hasOnlyPaidPolicies),
        action: () => {
          this.store.dispatch(navigationActions.toBilling());
        },
      },
      [HelpTip.FileClaim]: {
        id: HelpTip.FileClaim,
        fallback: HelpTip.ClaimsGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toClaimsPage());
        },
      },
      [HelpTip.CancelPolicy]: {
        id: HelpTip.CancelPolicy,
        retryCount: 20,
        delayInMillis: 500,
        fallback: HelpTip.CoverageGeneral,
        timeUntilFallback: 6000,
        isHidden: () => this.checkIsHidden(policiesSelectors.firstPolicyEligibleToCancellation, (canCancel: boolean) => !canCancel),
        action: () => {
          this.store
            .select(policiesSelectors.firstPolicyEligibleToCancellation)
            .pipe(first())
            .subscribe((policy: Policy) => {
              this.openCoveragePagePolicyCardMenu(policy.lob);
            });
        },
      },
      [HelpTip.ReinstatePolicy]: {
        id: HelpTip.ReinstatePolicy,
        retryCount: 20,
        delayInMillis: 500,
        timeUntilFallback: 5000,
        fallback: HelpTip.CoverageGeneral,
        isHidden: () =>
          this.checkIsHidden(policiesSelectors.hasPolicyToReinstate, (hasPolicyToReinstate: boolean) => !hasPolicyToReinstate),
        action: () => {
          this.store
            .select(policiesSelectors.firstPolicyEligibleToReinstate)
            .pipe(first())
            .subscribe((policy: Policy) => {
              this.openCoveragePagePolicyCardMenu(policy.lob);
            });
        },
      },
      [HelpTip.RestartPolicy]: {
        id: HelpTip.RestartPolicy,
        retryCount: 20,
        delayInMillis: 500,
        fallback: HelpTip.CoverageGeneral,
        timeUntilFallback: 5000,
        isHidden: () => this.checkIsHidden(policiesSelectors.hasPolicyToRestart, (hasPolicyToRestart: boolean) => !hasPolicyToRestart),
        action: () => {
          this.store
            .select(policiesSelectors.firstPolicyEligibleToRestart)
            .pipe(first())
            .subscribe((policy: Policy) => {
              this.openCoveragePagePolicyCardMenu(policy.lob);
            });
        },
      },
      [HelpTip.DownloadPolicyDocs]: {
        id: HelpTip.DownloadPolicyDocs,
        delayInMillis: 500,
        retryCount: 10,
        fallback: HelpTip.CoverageGeneral,
        timeUntilFallback: 5000,
        action: () => {
          this.store
            .select(policiesSelectors.getExtendedPolicies)
            .pipe(first())
            .subscribe((policies: Policy[]) => {
              this.openCoveragePagePolicyCardMenu(policies[0].lob);
            });
        },
      },
      [HelpTip.UpdatePaymentCard]: {
        id: HelpTip.UpdatePaymentCard,
        fallback: HelpTip.AccountGeneral,
        isHidden: () => this.checkIsHidden(policiesSelectors.hasPayAsYouGoPolicy),
        delayInMillis: 500,
        action: () => {
          this.store.dispatch(navigationActions.toBilling());
        },
      },
      [HelpTip.SeeWhatIsCovered]: {
        id: HelpTip.SeeWhatIsCovered,
        isHidden: () => this.getIsHistoricalUser(),
        fallback: HelpTip.CoverageGeneral,
        delayInMillis: 1500,
        action: () => {
          this.store.dispatch(navigationActions.toCoveragePage());
        },
      },
      [HelpTip.AddCoverageFollower]: {
        id: HelpTip.AddCoverageFollower,
        delayInMillis: 500,
        fallback: HelpTip.CoverageGeneral,
        isHidden: () => this.getIsHistoricalUser(),
        action: () => {
          this.store.dispatch(navigationActions.toFollowersPage({}));
        },
      },
      [HelpTip.AddAuthorizedAdmin]: {
        id: HelpTip.AddAuthorizedAdmin,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.UpdateContactInfo]: {
        id: HelpTip.UpdateContactInfo,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.UpdateBusinessAddress]: {
        id: HelpTip.UpdateBusinessAddress,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.UpdateBusinessDetails]: {
        id: HelpTip.UpdateBusinessDetails,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.CertificatesGeneral]: {
        id: HelpTip.CertificatesGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toCertificates());
        },
      },
      [HelpTip.CoverageGeneral]: {
        id: HelpTip.CoverageGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toCoveragePage());
        },
      },
      [HelpTip.AccountGeneral]: {
        id: HelpTip.AccountGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.ClaimsGeneral]: {
        id: HelpTip.ClaimsGeneral,
        action: () => {
          HelpTipService.clickElement(HelpTipService.navigationMenuSelector);
          this.store.dispatch(navigationActions.toClaimsPage());
        },
      },
    };
  }

  private initWebhookHelpTipsConfig(): void {
    this.webhookOptionsConfig = {
      [HelpTip.ContactClaimAdvocate]: {
        id: HelpTip.ContactClaimAdvocate,
        delayInMillis: 500,
        fallback: HelpTip.ClaimsGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toClaimsPage());
        },
      },
      [HelpTip.CheckClaimStatus]: {
        id: HelpTip.CheckClaimStatus,
        delayInMillis: 500,
        fallback: HelpTip.ClaimsGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toClaimsPage());
        },
      },
      [HelpTip.CustomizeDescriptionOfOperations]: {
        id: HelpTip.CustomizeDescriptionOfOperations,
        delayInMillis: 1000,
        fallback: HelpTip.CertificatesGeneral,
        isHidden: () => true, // Todo: remove once NI-68024 is resolved
        action: () => {
          this.store.dispatch(navigationActions.toCreateCustomCertificate());
        },
      },
      [HelpTip.AddOrRemoveDrivers]: {
        id: HelpTip.AddOrRemoveDrivers,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.AddOrRemoveVehicles]: {
        id: HelpTip.AddOrRemoveVehicles,
        delayInMillis: 500,
        fallback: HelpTip.AccountGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toBusinessPage());
        },
      },
      [HelpTip.ChangeCoverageLimits]: {
        id: HelpTip.ChangeCoverageLimits,
        delayInMillis: 1000,
        action: () => {
          this.store.dispatch(navigationActions.toHomePage({}));
        },
      },
      [HelpTip.DownloadLossRunReport]: {
        id: HelpTip.DownloadLossRunReport,
        delayInMillis: 500,
        fallback: HelpTip.ClaimsGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toClaimsPage());
        },
      },
      [HelpTip.AddPolicy]: {
        id: HelpTip.AddPolicy,
        delayInMillis: 500,
        action: () => {
          this.store.dispatch(navigationActions.toHomePage({}));
        },
      },
      [HelpTip.CrossSellNotification]: {
        id: HelpTip.CrossSellNotification,
        delayInMillis: 1500,
        fallback: HelpTip.ClaimsGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toHomePage({}));
        },
      },
      [HelpTip.AssignLossPayee]: {
        id: HelpTip.AssignLossPayee,
        delayInMillis: 1000,
        fallback: HelpTip.CertificatesGeneral,
        action: () => {
          this.store.dispatch(navigationActions.toCreateCustomCertificate());
        },
      },
    };
  }

  private getIsHistoricalUser(): boolean {
    return this.checkIsHidden(policiesSelectors.isHistoricalUser, (isHistoricalUser: boolean) => isHistoricalUser);
  }
}
