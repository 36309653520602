import { PortalRoutes } from '../models/portal-routes.enum';
import { navigationActions } from '../../store/navigation.actions';

export const navigationConfig = {
  mapPortalRoutToNavigationAction: {
    [PortalRoutes.Home]: navigationActions.toHomePage,
    [PortalRoutes.Authentication]: navigationActions.toAuthentication,
    [PortalRoutes.Verification]: navigationActions.toVerification,
    [PortalRoutes.Error]: navigationActions.toError,
    [PortalRoutes.Billing]: navigationActions.toBilling,
    [PortalRoutes.Back]: navigationActions.back,
    [PortalRoutes.Certificates]: navigationActions.toCertificates,
    [PortalRoutes.CertificatesAutoTab]: navigationActions.toCertificatesAutoTab,
    [PortalRoutes.ThirdPartyChangesRequestReview]: navigationActions.toThirdPartyChangesRequestReviewPage,
    [PortalRoutes.ThirdPartyNotificationsRequestReview]: navigationActions.toThirdPartyNotificationsRequestReviewPage,
    [PortalRoutes.Followers]: navigationActions.toFollowersPage,
    [PortalRoutes.LiveCertificate]: navigationActions.toLiveCertificate,
    [PortalRoutes.LiveCertificateRequestChanges]: navigationActions.toLiveCertificateRequestChanges,
    [PortalRoutes.LiveCertificateRequestNotifications]: navigationActions.toLiveCertificateRequestNotifications,
    [PortalRoutes.LiveCertificateError]: navigationActions.toLiveCertificateErrorPage,
    [PortalRoutes.CreateCustomCertificate]: navigationActions.toCreateCustomCertificate,
    [PortalRoutes.CustomCertificateReview]: navigationActions.toCustomCertificateReviewPage,
    [PortalRoutes.CustomCertificateCustomizations]: navigationActions.toCustomCertificateCustomizationsPage,
    [PortalRoutes.RequestChangesReview]: navigationActions.toRequestChangesReviewPage,
    [PortalRoutes.ClaimsChatbot]: navigationActions.toClaimsChatbot,
    [PortalRoutes.Claims]: navigationActions.toClaimsPage,
    [PortalRoutes.EditContactInfo]: navigationActions.toEditContactInfo,
    [PortalRoutes.WcAudit]: navigationActions.toWcAudit,
    [PortalRoutes.Business]: navigationActions.toBusinessPage,
    [PortalRoutes.AddAuthorizedUser]: navigationActions.toAddAuthorizedUserPage,
    [PortalRoutes.Coverage]: navigationActions.toCoveragePage,
    [PortalRoutes.PolicyCoverage]: navigationActions.toPolicyCoverage,
    [PortalRoutes.EmptyPage]: navigationActions.toEmptyPage,
  },
};
