import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { HelpTip, helpTipsConfig } from '../../models/help-tip.enum';
import { HelpTipOptionsConfig, HelpTipSection } from '../../models/help-tip-models.model';
import { HelpModalTrackingService } from '../../services/help-modal-tracking.service';
import { HelpTipService } from '../../services/help-tip.service';

@Component({
  selector: 'ni-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpModalComponent implements OnInit {
  sections: HelpTipSection[];
  helpTipsConfig: Record<HelpTip, string> = helpTipsConfig;
  optionsConfig: HelpTipOptionsConfig;
  HelpTip: HelpTip;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private helpTipService: HelpTipService,
    private helpModalTrackingService: HelpModalTrackingService,
  ) {}

  ngOnInit(): void {
    this.initOptions();
    this.helpModalTrackingService.trackHelpModalLanding();
  }

  onOptionClicked(option: HelpTip, event: MouseEvent): void {
    event.stopPropagation();
    this.dynamicDialogRef.close();
    this.helpTipService.helpTipClicked(option);
    this.helpModalTrackingService.trackHelpModalOptionClick(option);
  }

  private initOptions(): void {
    this.optionsConfig = this.helpTipService.getOptionsConfigState();
    this.sections = [
      {
        title: 'HELP.CERTIFICATES.TITLE',
        options: [
          HelpTip.ManageCertificates,
          HelpTip.AddCertificateHolder,
          HelpTip.CertificateDetails,
          HelpTip.ShareCertificate,
          HelpTip.DownloadCertificate,
        ],
      },
      {
        title: 'HELP.ACCOUNT_MANAGEMENT.TITLE',
        options: [HelpTip.UpComingPayments, HelpTip.UpdateBusiness, HelpTip.DownloadReceipt],
      },
      {
        title: 'HELP.POLICY_ACTIONS.TITLE',
        options: [HelpTip.FileClaim, HelpTip.CancelPolicy, HelpTip.ReinstatePolicy, HelpTip.RestartPolicy, HelpTip.DownloadPolicyDocs],
      },
    ];
  }
}
