import { FeatureFlagsMap } from '@next-insurance/core';
import { Action, createReducer, on } from '@ngrx/store';

import { featureFlagsActions } from './feature-flags.actions';

export interface FeatureFlagsState {
  featureFlagsMap: FeatureFlagsMap;
  isLoading: boolean;
}

export const featureFlagsInitialState: FeatureFlagsState = {
  featureFlagsMap: {},
  isLoading: false,
};

const reducer = createReducer(
  featureFlagsInitialState,
  on(featureFlagsActions.loadFeatureFlags, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(featureFlagsActions.setFeatureFlags, (state, action) => ({
    ...state,
    featureFlagsMap: action.featureFlagsMap,
    isLoading: false,
  })),
  on(featureFlagsActions.turnOnFeatureFlag, (state, action) => ({
    ...state,
    featureFlagsMap: {
      ...state.featureFlagsMap,
      [action.name]: {
        ...state.featureFlagsMap[action.name],
        enabled: true,
      },
    },
  })),
  on(featureFlagsActions.turnOffFeatureFlag, (state, action) => ({
    ...state,
    featureFlagsMap: {
      ...state.featureFlagsMap,
      [action.name]: {
        ...state.featureFlagsMap[action.name],
        enabled: false,
      },
    },
  })),
  on(featureFlagsActions.onError, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export function featureFlagsReducer(state: FeatureFlagsState, action: Action): FeatureFlagsState {
  return reducer(state, action);
}
