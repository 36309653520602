<div class="date-picker-rich-message-container">
  <p-calendar
    [(ngModel)]="selectedDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    class="ni-date-picker chatbot-date-picker"
    inline="true"
    showTime="true"
    hourFormat="12"
    data-test="owl-date-time"
  ></p-calendar>
  <ni-chat-button [dataTest]="'btn-confirm'" customClass="primary" [disabled]="!selectedDate" (clicked)="submitDateClicked()"
    >Confirm Date
  </ni-chat-button>
</div>
