import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NavigationService } from '../../../../../core/services/navigation.service';
import { JsonParserUtil } from '../../../../utils/json-parser.util';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatRichMessage } from '../../models/chat-rich-message.model';
import { SuggestionOption } from '../../models/suggestion-option.model';
import { RichMessagesService } from '../../services/rich-messages.service';
import { RichMessagesUtilsService } from '../../services/rich-messages-utils.service';
import { SuggestionsRichMessageContent } from './suggestions-rich-message-content.model';

@Component({
  selector: 'ni-suggestions-rich-message',
  templateUrl: './suggestions-rich-message.component.html',
  styleUrls: ['./suggestions-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionsRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.Suggestions;
  @Input() message: ChatRichMessage<SuggestionsRichMessageContent>;
  options: SuggestionOption[];
  moreOptions: SuggestionOption[];
  isMoreOptionsClicked = false;

  constructor(
    private richMessagesService: RichMessagesService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.options = this.flatOptionsList(this.message.data.richContent.options);
    this.moreOptions = this.flatOptionsList(this.message.data.richContent.moreOptions);
    this.filterOptions();
  }

  isOptionVisible(option: SuggestionOption): boolean {
    if (option.isVisible === null || option.isVisible === undefined) {
      // true by default
      return true;
    }

    const maxLengthExpression = 100;
    if (typeof option.isVisible !== 'string' || option.isVisible.length > maxLengthExpression) {
      return false;
    }

    let res = false;
    try {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      res = new Function(`"use strict";return ( ${option.isVisible} )`)();
    } catch {}

    return res;
  }

  optionClicked(option: SuggestionOption): void {
    if (option.url) {
      this.navigationService.navigateTo(option.url, true);
    }
    this.richMessagesService.sendMessage({
      actualMessage: option.id,
      displayMessage: option.text,
      originalMessageId: this.message.id,
    });
  }

  private getIdsToShow(): string[] {
    return RichMessagesUtilsService.convertSessionParamToList(this.message.data.richContent?.filter);
  }

  private flatOptionsList(options: SuggestionOption[]): SuggestionOption[] {
    let flatOptionsList: SuggestionOption[];
    if (options) {
      flatOptionsList = [];
      options?.forEach((option) => {
        const parsedOptions = JsonParserUtil.tryParse<SuggestionOption[]>(option) ?? [option];
        flatOptionsList = [...flatOptionsList, ...parsedOptions];
      });
    } else {
      flatOptionsList = null;
    }
    return flatOptionsList;
  }

  private filterOptions(): void {
    if (this.message.data.richContent.filter) {
      const idsToShow = this.getIdsToShow();
      if (idsToShow?.length) {
        this.options = this.options.filter((option) => idsToShow.includes(option.id) && this.isOptionVisible(option));
        if (this.moreOptions) {
          this.moreOptions = this.moreOptions.filter((option) => idsToShow.includes(option.id) && this.isOptionVisible(option));
        }
      }
    }
  }
}
