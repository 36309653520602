<div [attr.data-lob]="policy.lob" class="container" data-test="cross-sell-policy">
  <div class="content">
    <ng-container
      [ngTemplateOutletContext]="{ $implicit: policy }"
      [ngTemplateOutlet]="
        shouldSuggestLOB &&
        (isAvailableThroughNext ||
          shouldOfferedThrough === crossSellOfferThrough.Partner ||
          shouldOfferedThrough === crossSellOfferThrough.SmartAgent)
          ? availableOrOfferThroughPartnerOrSmartAgent
          : notAvailable
      "
    ></ng-container>
  </div>
</div>

<ng-template #notAvailable>
  <div class="cross-sell-info">
    <div class="lob-name-and-status-container">
      <div class="policy-type h6_h4-regular" data-test="policy-type">{{ policy.lob | lobName }}</div>
      <div *ngIf="!isRequiredToBePurchasedFromState" class="status p-sm_md status-gray" data-test="coming-soon">
        {{ 'CROSS_SELL.TILE.COMING_SOON' | translate }}
      </div>
    </div>
    <div class="p-sm_md">
      <span data-test="description">{{ policy | crossSellPolicyDescription: isRequiredToBePurchasedFromState | translate }}</span>
      <span class="link" data-test="learn-more-link" (click)="clickLearnMore()"> {{ 'CROSS_SELL.LEARN_MORE' | translate }}</span>
    </div>
  </div>
  <div *ngIf="!isRequiredToBePurchasedFromState" class="cross-sell-actions">
    <div class="policy-details p-sm_md">
      <div class="notify-me-container">
        <span class="notify-me-label p-sm">{{ 'CROSS_SELL.TILE.NOTIFY_ME' | translate }}</span>
        <ni-switch-rb
          (switch)="clickNotify()"
          [attr.data-checked]="policy.notifyBusinessOnLob"
          [checked]="policy.notifyBusinessOnLob"
          data-test="notify-button"
        ></ni-switch-rb>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #availableOrOfferThroughPartnerOrSmartAgent>
  <div class="cross-sell-info">
    <div class="lob-name-and-status-container">
      <div class="policy-type h6_h4-regular" data-test="policy-type">{{ policy.lob | lobName }}</div>
      <div class="status p-sm_md status-gray" data-test="sub-title">
        <span *ngIf="shouldOfferedThrough === crossSellOfferThrough.Next">{{
          'CROSS_SELL.TILE.PRICE.' + policy.status | translate: { price: policy.monthlyMinimumPremium | number: '1.2-2' }
        }}</span>
        <span *ngIf="shouldOfferedThrough !== crossSellOfferThrough.Next">{{ 'CROSS_SELL.TILE.OFFERED_THROUGH_PARTNER' | translate }}</span>
      </div>
    </div>
    <div class="p-sm_md">
      <span data-test="description">{{ policy | crossSellPolicyDescription: isRequiredToBePurchasedFromState | translate }}</span>
      <span class="link" data-test="learn-more-link" (click)="clickLearnMore()"> {{ 'CROSS_SELL.LEARN_MORE' | translate }}</span>
    </div>
  </div>
  <div class="cross-sell-actions">
    <div class="policy-details p-sm_md">
      <div class="add-button-container">
        <ni-button-rb (click)="startCrossSell()" [buttonType]="ButtonType.Secondary" data-test="add-now-button">
          + {{ 'CROSS_SELL.TILE.ADD' | translate }}
        </ni-button-rb>
      </div>
    </div>
  </div>
</ng-template>
