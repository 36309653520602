import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';

import { ResponseMessage } from '../../../models/response-message.model';
import { ResponseMessageType } from '../../../models/response-message-type';
import { GenericMessageContent } from '../components/generic-rich-message/generic-message-content.model';
import { ChatSenderType } from '../enums/chat-sender-type.enum';
import { RichMessageType } from '../enums/rich-message-type.enum';
import { ChatMessage } from '../models/chat-message.model';
import { richMessageComponents } from '../rich-messages-list';

@Injectable()
export class RichMessagesFactoryService {
  private componentsTypeMap: Partial<Record<RichMessageType, unknown>> = {};

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.initComponentsTypeMap();
  }

  private initComponentsTypeMap(): void {
    richMessageComponents.forEach((component) => {
      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      const richMessageType = Object.values(RichMessageType).find((type) => type === (factory.componentType as any).richComponentType);
      if (richMessageType) {
        this.componentsTypeMap[richMessageType] = component;
      }
    });
  }

  createRichMessageComponent(richMessageType: RichMessageType, message: ChatMessage, container: ViewContainerRef): ComponentRef<unknown> {
    let component = null;
    const richMessageClassType = this.getRichMessageClassType(richMessageType);
    if (richMessageClassType) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(richMessageClassType);
      component = container.createComponent(componentFactory);
      (component as ComponentRef<any>).instance.message = message;
    }
    return component;
  }

  createChatMessage(sender: ChatSenderType, messageType: RichMessageType, content: string = null): ChatMessage {
    const chatMessage: ChatMessage<GenericMessageContent> = {
      id: Math.random().toString(36).slice(-5),
      data: {},
      sender,
      type: messageType,
    };

    if (messageType === RichMessageType.Bubble) {
      chatMessage.data.text = content;
    } else {
      chatMessage.data.richContent = content;
    }

    return chatMessage;
  }

  createBotMessage(responseMessage: ResponseMessage): ChatMessage {
    let botMessage: ChatMessage;
    if (responseMessage.message === ResponseMessageType.Payload) {
      const { payload } = responseMessage;
      const richContents = payload.richContent[0];
      for (const richContent of richContents) {
        if (this.isRichMessageTypeExists(richContent.type)) {
          botMessage = this.createChatMessage(ChatSenderType.Bot, richContent.type, richContent);
        }
      }
    } else if (responseMessage.message === ResponseMessageType.Text) {
      botMessage = this.createChatMessage(ChatSenderType.Bot, RichMessageType.Bubble, responseMessage.text.text);
    }

    return botMessage;
  }

  isRichMessageTypeExists(type: RichMessageType): boolean {
    return !!this.getRichMessageClassType(type);
  }

  private getRichMessageClassType(type: RichMessageType): Type<unknown> {
    return this.componentsTypeMap[type] as Type<unknown>;
  }
}
