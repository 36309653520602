import { Pipe, PipeTransform } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { CrossSellPolicy } from '../models/cross-sell-policy.model';

@Pipe({
  name: 'crossSellPolicyDescription',
})
export class CrossSellPolicyDescriptionPipe implements PipeTransform {
  transform(policy: CrossSellPolicy, isPolicyRequiredToBePurchasedFromState: boolean): string {
    if (!policy) {
      return null;
    }
    if (isPolicyRequiredToBePurchasedFromState && policy.lob !== LOB.WC) {
      throw new Error('No description exists for non-WC policies that are required to be purchased from state.');
    }

    return isPolicyRequiredToBePurchasedFromState
      ? `CROSS_SELL.DESCRIPTION.MONOPOLISTIC.${policy.lob}`
      : `CROSS_SELL.DESCRIPTION.${policy.lob}`;
  }
}
