import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChatbotAgentType } from '../../core/models/chatbot-agent-type.enum';
import { ChatStates } from '../enums/chat-states.enum';
import { ChatSessionService } from './chat-session.service';
import { ChatbotTrackingService } from './chatbot-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private chatbotStatesSubject$: BehaviorSubject<ChatStates> = new BehaviorSubject<ChatStates>(ChatStates.Closed);
  private agentType: ChatbotAgentType;
  private isPersistent: boolean;

  constructor(
    private chatSessionService: ChatSessionService,
    private chatbotTrackingService: ChatbotTrackingService,
  ) {}

  private _chatbotInitConfig: Record<string, any>;

  get chatbotInitConfig(): Record<string, any> {
    return this._chatbotInitConfig;
  }

  get chatbotState$(): Observable<ChatStates> {
    return this.chatbotStatesSubject$.asObservable();
  }

  getAgentType(): ChatbotAgentType {
    return this.agentType;
  }

  getIsPersistent(): boolean {
    return this.isPersistent;
  }

  getSessionId(): string {
    return this.chatSessionService.sessionId;
  }

  openChatbot(type: ChatbotAgentType, persistent: boolean, initConfig: Record<string, any> = {}): void {
    if (this.getAgentType() && type !== this.getAgentType()) {
      // If the agent type is changed we would like to reopen the chatbot
      this.chatbotStatesSubject$.next(ChatStates.Closed);
    }

    if (!persistent || this.chatbotStatesSubject$.getValue() === ChatStates.Closed) {
      this.agentType = type;
      this._chatbotInitConfig = initConfig;
      this.isPersistent = persistent;
    }

    this.chatbotStatesSubject$.next(ChatStates.Opened);
  }

  closeChatbot(): void {
    this.chatbotStatesSubject$.next(ChatStates.Closed);
  }

  minimizeChatbot(): void {
    this.chatbotStatesSubject$.next(ChatStates.Minimized);
    this.chatbotTrackingService.trackChatbotMinimizedClicked(this.chatSessionService.sessionId);
  }
}
