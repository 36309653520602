import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { LoadingMessageOverlayModalData } from './loading-message-overlay-modal-data.model';

@Component({
  selector: 'ni-loading-message-overlay-modal',
  templateUrl: './loading-message-overlay-modal.component.html',
  styleUrls: ['./loading-message-overlay-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LoadingMessageOverlayModalComponent implements OnInit {
  modalData: LoadingMessageOverlayModalData;

  constructor(private dynamicDialogConfig: DynamicDialogConfig<LoadingMessageOverlayModalData>) {}

  ngOnInit(): void {
    this.modalData = this.dynamicDialogConfig.data;
  }
}
