import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { PaymentMethodType } from '../../payment/models/payment-method-details.model';

@Injectable({
  providedIn: 'root',
})
export class BillingTrackingService {
  billingPagePlacement = 'billing-page';
  billingPageFailedPaymentNoticePlacement = 'billing-page-failed-payment-notice';
  billingNotificationModalPlacement = 'billing-notification-modal';

  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackEditPaymentMethodIconClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.billingPagePlacement,
      name: 'edit-payment-method-icon',
    });
  }

  /* istanbul ignore next */
  trackBillingPageEditPaymentMethodButtonClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.billingPageFailedPaymentNoticePlacement,
        name: 'edit-payment-method-button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackBillingPageOpenZendeskChatClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.billingPageFailedPaymentNoticePlacement,
        name: 'open-zendesk-chat-button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackBillingNotificationModalEditPaymentMethodButtonClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.billingNotificationModalPlacement,
        name: 'edit-payment-method-button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackBillingNotificationModalOpenZendeskChatClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.billingNotificationModalPlacement,
        name: 'open-zendesk-chat-button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackViewBillingNotificationModal(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: this.billingNotificationModalPlacement,
        name: 'modal-opened',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCloseBillingNotificationModal(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.billingNotificationModalPlacement,
        name: 'modal-closed',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackDownloadReceiptClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.billingPagePlacement,
      name: 'download-receipt-button',
    });
  }

  /* istanbul ignore next */
  trackDownloadReceiptResult(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.billingPagePlacement,
      name: 'download-receipt-result',
      interactionData: {
        isSuccess,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackDownloadReceiptForLobClicked(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.billingPagePlacement,
      name: 'download-receipt-for-lob-button',
      interactionData: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  trackDownloadReceiptForLobResult(lob: LOB, isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.billingPagePlacement,
      name: 'download-receipt-for-lob-result',
      interactionData: {
        lob,
        isSuccess,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackSeePaymentsClicked(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.billingPagePlacement,
      name: 'see-all-payments-button',
      interactionData: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  trackCurrentPaymentMethod(paymentMethodType: PaymentMethodType): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.billingPagePlacement,
      name: 'current-payment-method',
      interactionData: {
        paymentMethodType,
      },
    });
  }
}
