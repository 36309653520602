import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OnboardingStatusResponse } from '../models/onboarding-status-response.model';
import { OnboardingStep } from '../models/onboarding-step.enum';

@Injectable({
  providedIn: 'root',
})
export class OnboardingDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getOnboardingStatus(): Observable<OnboardingStatusResponse> {
    return this.httpClient.get<OnboardingStatusResponse>('/api/onboarding/status');
  }

  /* istanbul ignore next */
  completeOnboarding(): Observable<void> {
    return this.httpClient.post<void>('/api/onboarding/complete', {});
  }

  /* istanbul ignore next */
  skipStep(stepName: OnboardingStep): Observable<void> {
    return this.httpClient.post<void>('/api/onboarding/skip-step', { stepName });
  }
}
