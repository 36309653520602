import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReinstateInfoResponse } from '../models/reinstate-info-response.model';
import { ReinstateResponseStatus } from '../models/reinstate-response-status.enum';

@Injectable({
  providedIn: 'root',
})
export class ReinstateDataService {
  private readonly base: string;

  constructor(private httpClient: HttpClient) {
    this.base = '/api/reinstate';
  }

  /* istanbul ignore next */
  getReinstateCharge(bundleId: number): Observable<ReinstateInfoResponse> {
    return this.httpClient.get<ReinstateInfoResponse>(`${this.base}/agreement`, {
      params: {
        bundleId: bundleId.toString(),
      },
    });
  }

  /* istanbul ignore next */
  submitReinstate(bundleId: number): Observable<ReinstateResponseStatus> {
    return this.httpClient.post<ReinstateResponseStatus>(`${this.base}/submit`, { bundleId });
  }
}
