import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChatSenderType } from '../components/rich-messages/enums/chat-sender-type.enum';
import { RichMessageType } from '../components/rich-messages/enums/rich-message-type.enum';
import { ChatMessage } from '../components/rich-messages/models/chat-message.model';
import { RichMessagesFactoryService } from '../components/rich-messages/services/rich-messages-factory.service';
import { chatbotConfig } from '../configs/chatbot.config';
import { DetectIntentResponse } from '../models/detect-intent-response.model';
import { ResponseMessage } from '../models/response-message.model';
import { ResponseMessageType } from '../models/response-message-type';

@Injectable()
export class ChatMessagesService {
  private chatConfig = chatbotConfig;

  constructor(private richMessagesFactoryService: RichMessagesFactoryService) {}

  private _chatMessages$ = new BehaviorSubject<ChatMessage[]>([]);

  get chatMessages$(): Observable<ChatMessage[]> {
    return this._chatMessages$.asObservable();
  }

  resetChatMessages(): void {
    this._chatMessages$.next([]);
  }

  addSessionTimeoutMsg(): void {
    this.addBotMessage({
      message: ResponseMessageType.Text,
      text: { text: this.chatConfig.sessionTimeoutMsg },
    });
    this.addRestartSessionMessage();
  }

  addSessionTimeoutWarningMsg(): void {
    this.addBotMessage({
      message: ResponseMessageType.Text,
      text: { text: this.chatConfig.sessionTimeoutWarningMsg },
    });
  }

  addUserMessage(message: string): void {
    const userMessage = this.richMessagesFactoryService.createChatMessage(ChatSenderType.User, RichMessageType.Bubble, message);
    this._chatMessages$.next([...this._chatMessages$.getValue(), userMessage]);
  }

  addBotMessage(responseMessage: ResponseMessage): void {
    const botMessage = this.richMessagesFactoryService.createBotMessage(responseMessage);
    this._chatMessages$.next([...this._chatMessages$.getValue(), botMessage]);
  }

  addBotLoadingMessage(): void {
    const botLoadingMessage = this.richMessagesFactoryService.createChatMessage(ChatSenderType.Bot, RichMessageType.Loading);
    this._chatMessages$.next([...this._chatMessages$.getValue(), botLoadingMessage]);
  }

  clearLoadingMessages(): void {
    const chatMessagesSnapshot = this._chatMessages$.getValue();
    const chatMessagesWithoutLoading = chatMessagesSnapshot.filter(
      (chatMessage: ChatMessage) => chatMessage.type !== RichMessageType.Loading,
    );

    this._chatMessages$.next(chatMessagesWithoutLoading);
  }

  removeLastMessage(): void {
    const chatMessagesSnapshot = this._chatMessages$.getValue();
    this._chatMessages$.next(chatMessagesSnapshot.slice(0, -1));
  }

  removeMessageById(messageIdToRemove: string): void {
    const chatMessagesSnapshot = this._chatMessages$.getValue();
    this._chatMessages$.next(chatMessagesSnapshot.filter((message: ChatMessage) => message.id !== messageIdToRemove));
  }

  isLastMessageLoadingMessage(): boolean {
    const chatMessagesSnapshot = this._chatMessages$.getValue();
    return chatMessagesSnapshot.length && chatMessagesSnapshot[chatMessagesSnapshot.length - 1].type === RichMessageType.Loading;
  }

  addErrorFallbackMessage(): void {
    const messages = [];
    const botMessage = this.richMessagesFactoryService.createChatMessage(
      ChatSenderType.Bot,
      RichMessageType.Bubble,
      this.chatConfig.errorFallbackMessage,
    );
    messages.push(botMessage);

    const chatbotErrorHandlerMessage = this.richMessagesFactoryService.createBotMessage({
      message: ResponseMessageType.Payload,
      payload: {
        richContent: [[{ type: RichMessageType.ChatbotErrorHandler }]],
      },
    });
    messages.push(chatbotErrorHandlerMessage);

    this._chatMessages$.next([...this._chatMessages$.getValue(), ...messages]);
  }

  addRestartSessionMessage(): void {
    this.addBotMessage({
      message: ResponseMessageType.Payload,
      payload: {
        richContent: [[{ type: RichMessageType.RestartSession }]],
      },
    });
  }

  shouldHideUserInput(detectIntentRes: DetectIntentResponse): boolean {
    if (detectIntentRes.queryResult.currentPage?.name?.includes(this.chatConfig.endSessionPageName)) {
      return true;
    }

    for (const responseMessage of detectIntentRes.queryResult.responseMessages) {
      if (responseMessage.message === ResponseMessageType.Payload) {
        const { payload } = responseMessage;
        if (!payload.richContent) continue;
        const richContents = payload.richContent[0];
        for (const richContent of richContents) {
          if (this.richMessagesFactoryService.isRichMessageTypeExists(richContent.type)) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
