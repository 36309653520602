import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';

import { FullstoryEvent } from '../../../core/models/fullstory-event.enum';
import { FullStoryService } from '../../../core/services/fullstory.service';
import { CrossSellTrackingService } from '../../../cross-sell/services/cross-sell-tracking.service';
import { CoverageCheckupStep } from '../../enums/coverage-checkup-step.enum';
import { CoverageCheckupService } from '../../services/coverage-checkup.service';
import { CoverageCheckupBusinessAnswersStep } from '../coverage-checkup-business-answer-step/coverage-checkup-business-answers-step.component';
import { CoverageCheckupLobsStepComponent } from '../coverage-checkup-lobs-step/coverage-checkup-lobs-step.component';
import { CoverageCheckupRiskReviewStepComponent } from '../coverage-checkup-risk-review-step/coverage-checkup-risk-review-step.component';
import { CoverageCheckupStepComponent } from '../coverage-checkup-step/coverage-checkup-step.component';

type CoverageCheckupStepsConfig = Record<CoverageCheckupStep, Type<CoverageCheckupStepComponent>>;

@Component({
  selector: 'ni-coverage-checkup-modal',
  templateUrl: './coverage-checkup-modal.component.html',
  styleUrls: ['./coverage-checkup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageCheckupModalComponent implements OnInit, AfterViewInit {
  @ViewChild('stepContainer', { read: ViewContainerRef }) stepContainer: ViewContainerRef;
  private coverageCheckupStepsConfig: CoverageCheckupStepsConfig;
  private coverageCheckupStepsOrder: CoverageCheckupStep[];
  activeStepIndex: number;

  constructor(
    private crossSellTrackingService: CrossSellTrackingService,
    private dynamicDialogRef: DynamicDialogRef,
    private fullStoryService: FullStoryService,
    protected coverageCheckupService: CoverageCheckupService,
  ) {}

  ngOnInit(): void {
    this.listenToCloseEvent();
    this.coverageCheckupService.resetSavedAnswers();
    this.fullStoryService.fireEvent(FullstoryEvent.CoverageCheckupModalOpened);
  }

  ngAfterViewInit(): void {
    this.activeStepIndex = 0;
    this.setCoverageCheckupStepsConfig();
    this.createStepComponent();
  }

  private setCoverageCheckupStepsConfig(): void {
    this.coverageCheckupStepsConfig = {
      [CoverageCheckupStep.RiskReview]: CoverageCheckupRiskReviewStepComponent,
      [CoverageCheckupStep.BusinessChangeQuestion]: CoverageCheckupBusinessAnswersStep,
      [CoverageCheckupStep.LobsSelection]: CoverageCheckupLobsStepComponent,
    };

    this.coverageCheckupStepsOrder = Object.keys(this.coverageCheckupStepsConfig) as CoverageCheckupStep[];
  }

  private createStepComponent(): void {
    this.stepContainer.clear();
    const activeStep: CoverageCheckupStep = this.coverageCheckupStepsOrder[this.activeStepIndex];
    const componentToRender = this.coverageCheckupStepsConfig[activeStep];
    const componentRef = this.stepContainer.createComponent<CoverageCheckupStepComponent>(componentToRender);
    this.registerToStepChanges(componentRef);
    componentRef.changeDetectorRef.detectChanges();
  }

  private registerToStepChanges(componentRef: ComponentRef<CoverageCheckupStepComponent>): void {
    componentRef.instance.moveToNextStep.pipe(first()).subscribe(() => this.moveToNextStep());
    componentRef.instance.moveToPreviousStep.pipe(first()).subscribe(() => this.moveToPreviousStep());
  }

  moveToNextStep(): void {
    const numOfSteps = Object.keys(this.coverageCheckupStepsConfig).length;
    this.activeStepIndex = ++this.activeStepIndex % numOfSteps;
    this.createStepComponent();
  }

  moveToPreviousStep(): void {
    this.activeStepIndex -= 1;

    if (this.activeStepIndex < 0) {
      this.activeStepIndex = 0;
      throw new Error('Cannot go to step with a negative index');
    }

    this.createStepComponent();
  }

  private listenToCloseEvent(): void {
    this.dynamicDialogRef.onClose.pipe(first()).subscribe(() => {
      this.crossSellTrackingService.trackCloseCoverageCheckupModal();
    });
  }
}
