import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import logger from '@next-insurance/logger';
import { UsStateCode } from '@next-insurance/utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CrossSellOfferThrough } from '../../core/enums/cross-sell-offer-through.enum';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { CrossSellOrigin } from '../../core/models/cross-sell-origin.enum';
import { UserType } from '../../core/models/user-type.enum';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { NavigationService } from '../../core/services/navigation.service';
import { AppState } from '../../store';
import { CrossSellPolicy } from '../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../models/cross-sell-policy-status.enum';
import { CrossSellSuggestion } from '../models/cross-sell-suggestion.model';
import { CrossSellSuggestionResponse } from '../models/cross-sell-suggestion-response.model';
import { crossSellActions } from '../store/cross-sell.actions';
import { CrossSellDataService } from './cross-sell.data.service';
import { ExternalCrossSellService } from './external-cross-sell.service';

@Injectable({
  providedIn: 'root',
})
export class CrossSellService {
  readonly monopolisticStates: UsStateCode[] = ['OH', 'ND', 'WA', 'WY'];
  readonly distributorCobId = 910073;

  constructor(
    private store: Store<AppState>,
    private navigationService: NavigationService,
    private crossSellDataService: CrossSellDataService,
    private externalCrossSellService: ExternalCrossSellService,
    private mobileAppService: MobileAppService,
  ) {}

  startCrossSell(shouldOfferThrough: CrossSellOfferThrough, lob: LOB, crossSellOrigin: CrossSellOrigin = CrossSellOrigin.CrossSell): void {
    const externalCrossSellLink = this.externalCrossSellService.getLink(lob, crossSellOrigin);
    if (shouldOfferThrough === CrossSellOfferThrough.Partner && externalCrossSellLink) {
      if (crossSellOrigin === CrossSellOrigin.WcBanner && this.mobileAppService.isMobileAppWebview()) {
        this.navigationService.navigateTo(externalCrossSellLink, true, true);
      } else {
        this.navigationService.navigateTo(externalCrossSellLink, true);
      }
    } else {
      this.store.dispatch(
        crossSellActions.startCrossSell({
          lob,
          shouldOfferThroughSmartAgent: shouldOfferThrough === CrossSellOfferThrough.SmartAgent,
        }),
      );
    }
  }

  isPolicyRequiredToBePurchasedFromState(policy: CrossSellPolicy, stateCode: string): boolean {
    return policy.lob === LOB.WC && this.monopolisticStates.includes(stateCode as UsStateCode);
  }

  getCrossSellApplicationUrl(rot: string, coverages: LOB, userType: UserType, shouldOfferThroughSmartAgent = false): string {
    const applicationMode = 'portal_cross_sell';
    let applicationUrl = `${environment.trackingUrl}/links?serial=99013&rot=${rot}&application_mode=${applicationMode}&user_type=${userType}`;
    if (coverages) {
      applicationUrl += `&coverages=["${coverages}"]`;
    }
    if (shouldOfferThroughSmartAgent) {
      applicationUrl += `&direct_smart_agent_cross_sell=true`;
    }
    return applicationUrl;
  }

  filterOutNotEligibleCrossSellPoliciesByCobSegment(policies: CrossSellPolicy[], cobSegment: CobSegment, cobId: number): CrossSellPolicy[] {
    if (!cobSegment) {
      logger.error(`filterOutNotEligibleCrossSellPoliciesByCobSegment() - cobSegment is missing`);
    }

    return policies.filter((policy) => {
      if (policy.lob === LOB.IM) {
        return this.isEligibleForIM(cobSegment, cobId);
      }
      if (policy.lob === LOB.PL) {
        return this.isEligibleForPL(cobSegment);
      }
      return true;
    });
  }

  getSuggestion(lob: LOB): Observable<CrossSellSuggestion> {
    return this.crossSellDataService.getSuggestion(lob).pipe(
      map((suggestions: CrossSellSuggestionResponse) => {
        let offerThrough = CrossSellOfferThrough.Partner;
        if (suggestions.shouldSellViaNext) {
          offerThrough = CrossSellOfferThrough.Next;
        }

        if (suggestions.crossSellLob.status === CrossSellPolicyStatus.ExternalLobAvailable) {
          offerThrough = CrossSellOfferThrough.SmartAgent;
        }

        return {
          lob: suggestions.lob,
          shouldSuggest: suggestions.shouldSuggest,
          shouldOfferThrough: offerThrough,
        };
      }),
    );
  }

  private isEligibleForIM(cobSegment: CobSegment, cobId: number): boolean {
    return cobSegment === CobSegment.Construction || cobSegment === CobSegment.Cleaning || cobId === this.distributorCobId;
  }

  private isEligibleForPL(cobSegment: CobSegment): boolean {
    const ineligibleSegments: CobSegment[] = [CobSegment.Consulting, CobSegment.Construction, CobSegment.Cleaning];

    return !ineligibleSegments.includes(cobSegment);
  }
}
