import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LOB } from '@next-insurance/core';
import { concat, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CrossSellDataService } from '../../cross-sell/services/cross-sell.data.service';
import { CrossSellService } from '../../cross-sell/services/cross-sell.service';
import { CrossSellOfferThrough } from '../enums/cross-sell-offer-through.enum';
import { CrossSellOrigin } from '../models/cross-sell-origin.enum';

@Injectable({ providedIn: 'root' })
export class WcComplianceBannerService {
  private readonly userClickedCloseWCComplianceBannerStorageKey = 'userClickedCloseWCComplianceBanner';
  private readonly bannerClosingCounterStorageKey = 'numOfTimesWCComplianceBannerClosed';
  private readonly excludedUrls: RegExp[] = [];

  constructor(
    private router: Router,
    private crossSellService: CrossSellService,
    private crossSellDataService: CrossSellDataService,
  ) {}

  startCrossSell(shouldOfferThrough: CrossSellOfferThrough): void {
    this.crossSellService.startCrossSell(shouldOfferThrough, LOB.WC, CrossSellOrigin.WcBanner);
  }

  isBannerEnabled(businessId: string): Observable<boolean> {
    if (!this.isBannerEnabledForTheSession(businessId)) {
      return of(false);
    }

    return this.shouldBeVisibleInCurrURL().pipe(
      map((shouldBeVisibleInUrl) => {
        if (!shouldBeVisibleInUrl) {
          return false;
        }

        return this.isBannerEnabledForTheSession(businessId);
      }),
    );
  }

  private isBannerEnabledForTheSession(businessId: string): boolean {
    const bannerClosingCounter = this.getBannerClosingCounter(businessId);

    if (bannerClosingCounter >= 3) {
      return false;
    }

    return !this.getBannerClosedClicked(businessId);
  }

  private shouldBeVisibleInCurrURL(): Observable<boolean> {
    const shouldShowInURL = !this.isExcludedUrl(this.router.url);
    return concat(
      of(shouldShowInURL),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => !this.isExcludedUrl(event.url)),
      ),
    );
  }

  private isExcludedUrl(url: string): boolean {
    return this.excludedUrls.some((excludedUrlPattern) => url.match(excludedUrlPattern));
  }

  disableBannerForTheSession(businessId: string): void {
    let bannerClosingCounter = this.getBannerClosingCounter(businessId);

    this.setBannerClosingCounter(businessId, bannerClosingCounter + 1);
    this.setBannerClosedClick(businessId);
  }

  markIsAlreadyInsuredDeclaration(): Observable<void> {
    return this.crossSellDataService.updateExternalCoveredLob(LOB.WC);
  }

  getBannerClosingCounter(businessId: string): number {
    return +localStorage.getItem(`${this.bannerClosingCounterStorageKey}-${businessId}`) || 0;
  }

  setBannerClosingCounter(businessId: string, counter: number): void {
    localStorage.setItem(`${this.bannerClosingCounterStorageKey}-${businessId}`, `${counter}`);
  }

  getBannerClosedClicked(businessId: string): boolean {
    return !!sessionStorage.getItem(`${this.userClickedCloseWCComplianceBannerStorageKey}-${businessId}`);
  }

  setBannerClosedClick(businessId: string): void {
    sessionStorage.setItem(`${this.userClickedCloseWCComplianceBannerStorageKey}-${businessId}`, 'true');
  }
}
