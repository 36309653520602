<div class="container" data-test="phone-verification-modal">
  <div class="img-container">
    <img data-test="step-icon" src="/assets/rebrand/calling-phone.svg" alt="calling-phone-img" />
  </div>

  <div class="content-container">
    <div class="header h4_h3-regular" data-test="header" [ngClass]="{ 'is-step-completed': isVerificationCompleted }">
      {{ headers[currentStatus] | translate }}
    </div>
    <ng-container *ngIf="!isVerificationCompleted">
      <div class="identification h6_h4-regular" data-test="phone-verification-address">
        {{ currIdentityInput$ | async | phone }}
      </div>
      <form [formGroup]="verificationForm">
        <div class="ni-input-label-rb">{{ 'VERIFICATION.VERIFICATION_CODE' | translate }}</div>
        <div
          class="verification-code-input-container ni-input-rb"
          data-test="verification-code-input-container"
          [style.--digits-num]="verificationCodeLength"
          [ngClass]="{ animation: showAnimation, invalid: verificationForm.controls.code.invalid }"
        >
          <input
            class="verification-code-input novalidate"
            data-test="verification-code-input"
            type="tel"
            [maxlength]="verificationCodeLength"
            formControlName="code"
            niTrim
            spellcheck="false"
            (input)="onInputChange()"
            [customErrors]="verificationCodeErrors"
            (paste)="onPaste($event)"
            [readOnly]="isVerificationCompleted"
          />
        </div>
      </form>
      <div class="actions">
        <ni-button-rb
          class="action-button"
          [buttonType]="ButtonType.Tertiary"
          [disabled]="isSubmitting"
          (click)="onCloseModal()"
          data-test="cancel-button"
        >
          {{ 'GENERAL.CLOSE' | translate }}
        </ni-button-rb>
        <ni-button-rb
          class="action-button"
          [buttonType]="ButtonType.Tertiary"
          [disabled]="isSubmitting"
          (click)="resendCode()"
          data-test="resend-code-button"
        >
          {{ 'VERIFICATION.RESEND_CODE' | translate }}
        </ni-button-rb>
      </div>

      <div class="footer p-xs_sm">
        <ng-container *ngIf="isMobileAppView; else chatWithUs">
          <span> {{ 'VERIFICATION.FOOTER' | translate }} </span>
          <a href="tel: {{ 'VERIFICATION.FOOTER_PHONE' | translate }}">{{ 'VERIFICATION.FOOTER_PHONE' | translate }}</a>
        </ng-container>
        <ng-template #chatWithUs>
          <span> {{ 'VERIFICATION.HAVING_TROUBLE' | translate }} </span>
          <div class="chat-with-us-link" data-test="open-zendesk-chat" (click)="openZendeskChat()">{{
            'VERIFICATION.CHAT_WITH_US' | translate
          }}</div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
