import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatMessage } from '../../models/chat-message.model';
import { BubbleMessageContent } from './bubble-message-content.model';

@Component({
  selector: 'ni-bubble-rich-message',
  templateUrl: './bubble-message.component.html',
  styleUrls: ['./bubble-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleMessageComponent {
  static readonly richComponentType = RichMessageType.Bubble;
  @Input() message: ChatMessage<BubbleMessageContent>;
}
