export const zendeskConfig = {
  chatEnabledPaths: ['/home'],
  zendeskChatEnabled: true,
  workingHours: {
    timezone: 'America/Los_Angeles',
    startTime: 6,
    endTime: 15,
    dayOfWeekMask: '12345',
  },
  secondsUntilHandoffError: 3,
};
