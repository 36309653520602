<div class="suggestions-container">
  <ni-chat-button
    *ngFor="let option of options"
    [dataTest]="'option-' + option.id | dataTest"
    customClass="secondary"
    (clicked)="optionClicked(option)"
  >
    {{ option.text }}
  </ni-chat-button>
  <div *ngIf="moreOptions">
    <ng-container *ngIf="!isMoreOptionsClicked; else moreOptionsExtended">
      <ni-chat-button [dataTest]="'more-options'" customClass="secondary" (clicked)="isMoreOptionsClicked = true">
        More options...
      </ni-chat-button>
    </ng-container>
  </div>
</div>

<ng-template #moreOptionsExtended>
  <ni-chat-button
    *ngFor="let option of moreOptions"
    [dataTest]="'option-' + option.id | dataTest"
    customClass="secondary"
    (clicked)="optionClicked(option)"
  >
    {{ option.text }}
  </ni-chat-button>
</ng-template>
