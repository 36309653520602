import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { businessActions } from '../../business/store/business.actions';
import { businessSelectors } from '../../business/store/business.selectors';
import { businessChangeActions } from '../../business-change/store/business-change.actions';
import { paymentActions } from '../../payment/store/payment.actions';
import { policiesActions } from '../../policies/store/policies.actions';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { coreActions } from '../store/core.actions';
import { coreSelectors } from '../store/core.selectors';

const waitForBusiness = (store: Store<AppState>): Observable<boolean> => {
  return store.pipe(
    select(businessSelectors.isLoading),
    filter((isLoading: boolean) => !isLoading),
    first(),
  );
};

const waitForPolicies = (store: Store<AppState>): Observable<boolean> => {
  return store.pipe(
    select(policiesSelectors.isLoading),
    filter((isLoading: boolean) => !isLoading),
    first(),
  );
};

const getIsUserLoggedIn = (store: Store<AppState>): boolean => {
  let isUserLoggedIn = false;
  store
    .select(coreSelectors.isUserLoggedIn)
    .pipe(
      first(),
      map((isLoggedIn: boolean) => {
        isUserLoggedIn = isLoggedIn;
      }),
    )
    .subscribe();
  return isUserLoggedIn;
};

export const businessGuard: CanActivateFn = (): Observable<boolean> => {
  const store = inject(Store<AppState>);
  if (getIsUserLoggedIn(store)) {
    store.dispatch(coreActions.setLoading({ isLoading: true }));
    store.dispatch(policiesActions.loadPolicies({ preventMobileBusinessChangeEvent: true }));
    store.dispatch(businessActions.loadBusiness({}));
    store.dispatch(businessChangeActions.loadPermittedChanges());
    store.dispatch(paymentActions.loadCombinedPaymentDetails());

    return forkJoin([waitForBusiness(store), waitForPolicies(store)]).pipe(map(() => true));
  }

  return of(true);
};
