export enum FullstoryEvent {
  Error = 'ERROR',
  CancelRewrite = 'CANCEL_REWRITE',
  CancelPolicy = 'CANCEL_POLICY',
  NonRenewPolicy = 'NON_RENEW_POLICY',
  ChangeBusinessOrPolicy = 'CHANGE_BUSINESS_OR_POLICY',
  ReinstatePolicy = 'REINSTATE_POLICY',
  InitChatBot = 'INIT_CHAT',
  ChatBotFailure = 'CHATBOT_FAILURE',
  LoginAttemptByPhone = 'LOGIN_ATTEMPT_BY_PHONE',
  GetWCQuoteFromBanner = 'GET_WC_QUOTE_FROM_BANNER',
  CoverageCheckupModalOpened = 'COVERAGE_CHECKUP_MODAL_OPENED',
  CoverageCheckupUpdateBusinessInfoClicked = 'COVERAGE_CHECKUP_UPDATE_BUSINESS_INFO_CLICKED',
}
