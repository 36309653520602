import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotTrackingService {
  placement = 'chatbot';

  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackChatbotMinimizedClicked(sessionId: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'minimize-button',
      interactionData: {
        sessionId,
      },
      eventName: `chatbot - ${InteractionType.Click.toUpperCase()} minimize`,
    });
  }

  /* istanbul ignore next */
  trackChatbotCloseClicked(sessionId: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'close-button',
      interactionData: {
        sessionId,
      },
      eventName: `chatbot - ${InteractionType.Click.toUpperCase()} close`,
    });
  }

  /* istanbul ignore next */
  trackSendEventApi(sessionId: string, success: boolean, errorMessage?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: success ? 'send-event-success' : 'send-event-failure',
      interactionData: {
        sessionId,
        errorMessage,
      },
      eventName: `chatbot - ${InteractionType.Api.toUpperCase()} send event ${success ? 'succeeded' : 'failed'}`,
    });
  }

  /* istanbul ignore next */
  trackSendMessageApi(sessionId: string, success: boolean, errorMessage?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: success ? 'send-message-success' : 'send-message-failure',
      interactionData: {
        sessionId,
        errorMessage,
      },
      eventName: `chatbot - ${InteractionType.Api.toUpperCase()} send message ${success ? 'succeeded' : 'failed'}`,
    });
  }

  /* istanbul ignore next */
  trackInitChatApi(sessionId: string, success: boolean, errorMessage?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: success ? 'init-chat-success' : 'init-chat-failure',
      interactionData: {
        sessionId,
        errorMessage,
      },
      eventName: `chatbot - ${InteractionType.Api.toUpperCase()} init chat ${success ? 'succeeded' : 'failed'}`,
    });
  }

  /* istanbul ignore next */
  trackLiveChatHandoff(sessionId: string, messageParams: any): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'live-chat-handoff',
      interactionData: {
        sessionId,
        messageParams,
      },
      eventName: `chatbot - ${InteractionType.View.toUpperCase()} live chat handoff`,
    });
  }

  /* istanbul ignore next */
  trackLiveChatOnChatbotError(sessionId: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'live-chat-on-chatbot-error',
      interactionData: {
        sessionId,
      },
      eventName: `chatbot - ${InteractionType.View.toUpperCase()} live chat on chatbot error`,
    });
  }
}
