<ni-alert-banner *ngIf="!hidden" (closed)="closeBanner()" data-test="wc-compliance-banner">
  <div banner-body class="banner-body" data-test="wc-compliance-banner-body">
    <strong>{{ 'CROSS_SELL.BANNER.TITLE' | translate: { stateName } }}</strong>
    {{ 'CROSS_SELL.BANNER.SUB_TITLE' | translate }}
  </div>
  <div banner-actions class="banner-actions">
    <ni-button-rb [buttonType]="ButtonType.Tertiary" data-test="wc-compliance-banner-get-quote-button" (click)="getQuote()">
      {{ 'CROSS_SELL.BANNER.ACTIONS.GET_QUOTE' | translate }}
    </ni-button-rb>
    <ni-button-rb
      [buttonType]="ButtonType.Tertiary"
      data-test="wc-compliance-banner-already-insured-declaration-button"
      (click)="alreadyInsuredDeclarationClicked()"
    >
      {{ 'CROSS_SELL.BANNER.ACTIONS.ALREADY_INSURED' | translate }}
    </ni-button-rb>
  </div>
</ni-alert-banner>
