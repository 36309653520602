import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { ChatMessagesComponent } from './components/chat-messages/chat-messages.component';
import { ChatTextInputComponent } from './components/chat-text-input/chat-text-input.component';
import { ChatTitleComponent } from './components/chat-title/chat-title.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { ChatbotWidgetComponent } from './components/chatbot-widget/chatbot-widget.component';
import { RichMessagesModule } from './components/rich-messages/rich-messages.module';
import { RichMessagesService } from './components/rich-messages/services/rich-messages.service';
import { RichMessagesFactoryService } from './components/rich-messages/services/rich-messages-factory.service';
import { AutoGrowDirective } from './directives/auto-grow.directive';
import { ContentEditableDirective } from './directives/content-editable.directive';
import { ChatMessagesService } from './services/chat-messages.service';
import { ChatSessionService } from './services/chat-session.service';
import { ChatbotService } from './services/chatbot.service';
import { ChatSharedModule } from './shared/chat-shared.module';

@NgModule({
  declarations: [
    ChatbotComponent,
    ChatTitleComponent,
    ChatMessagesComponent,
    ChatTextInputComponent,
    ChatbotWidgetComponent,
    AutoGrowDirective,
    ContentEditableDirective,
  ],
  imports: [RichMessagesModule, CommonModule, FormsModule, FontAwesomeModule, ChatSharedModule, TranslateModule],
  exports: [ChatbotComponent, ChatbotWidgetComponent],
  providers: [ChatbotService, ChatSessionService, RichMessagesService, RichMessagesFactoryService, ChatMessagesService],
})
export class ChatbotModule {}
