<div class="container" [attr.data-step]="OnboardingStep.AmazonSellerInformation">
  <div class="img-container">
    <img data-test="step-icon" src="/assets/rebrand/affiliate-amazon-information.svg" alt="phone-holding-proof-img" />
  </div>
  <div class="content-container">
    <div class="text-container">
      <div class="h4_h3-regular header" data-test="header">{{ 'ONBOARDING.AMAZON_SELLER_INFORMATION.HEADER' | translate }}!</div>
      <div class="h6_h4-regular message" data-test="message">{{ 'ONBOARDING.AMAZON_SELLER_INFORMATION.MESSAGE' | translate }}.</div>
    </div>
    <div class="actions">
      <ni-button-rb (click)="navigateToLiveCertificate()" [isLoading]="isLoading" data-test="view-certificate-button">
        {{ 'ONBOARDING.AMAZON_SELLER_INFORMATION.VIEW_CERTIFICATE' | translate }}
      </ni-button-rb>
      <ni-button-rb class="skip-button" data-test="skip-step-button" [buttonType]="ButtonType.Text" (click)="onSkip()">
        {{ 'ONBOARDING.SKIP' | translate }}
      </ni-button-rb>
    </div>
  </div>
</div>
