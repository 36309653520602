import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';

@Injectable({ providedIn: 'root' })
export class PhoneVerificationModalTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackVerificationValidateCodeResult(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'phone-verification-modal',
      name: 'phone-verification-modal-validate-code-result',
      interactionData: {
        isSuccess,
        errorReason,
      },
      eventName: 'phone verification modal - API validate code result',
    });
  }

  /* istanbul ignore next */
  trackVerificationSendCodeResult(isSuccess: boolean, isResend: boolean, errorReason?: string): void {
    const action = isResend ? 'resend' : 'send';

    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'phone-verification-modal',
      name: `phone-verification-modal-${action}-code-result`,
      interactionData: {
        isSuccess,
        errorReason,
      },
      eventName: `phone verification modal - API ${action} code result`,
    });
  }

  /* istanbul ignore next */
  trackVerificationLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'phone-verification-modal',
      name: 'phone-verification-modal-landing',
      eventName: 'phone verification modal - VIEW modal opened',
    });
  }

  /* istanbul ignore next */
  trackVerificationModalClosed(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'phone-verification-modal',
      name: 'phone-verification-modal-closed',
      eventName: 'phone verification modal - VIEW modal closed',
    });
  }

  /* istanbul ignore next */
  trackVerificationResendCodeClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'phone-verification-modal',
      name: 'phone-verification-modal-resend-code-clicked',
      eventName: 'phone verification modal - CLICK resend code clicked',
    });
  }
}
