import * as Sentry from '@sentry/angular-ivy';
import { createConsoleTracker, createSentryTracker, Options } from '@next-insurance/logger';
import { EnvConfig } from '../../../environments/env.config';

function getSentryOptions(): Options {
  return {
    trackerName: 'Sentry',
    errorTracker: createSentryTracker(Sentry.captureException),
  };
}

function getDevOptions(): Options {
  return {
    trackerName: 'Console',
    errorTracker: createConsoleTracker(),
  };
}

const loggerOptions = EnvConfig.isProduction() ? getSentryOptions() : getDevOptions();

export { loggerOptions };
