import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { CrossSellPolicy } from '../../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../../models/cross-sell-policy-status.enum';

@Component({
  selector: 'ni-cross-sell-policies-title',
  templateUrl: './cross-sell-policies-title.component.html',
  styleUrls: ['./cross-sell-policies-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossSellPoliciesTitleComponent implements OnInit, OnChanges {
  @Input() policies: CrossSellPolicy[];
  @Input() isHistoricalUser: boolean;
  @Input() customTitle: string;
  hasTwoOrMoreAvailablePolicies: boolean;

  ngOnInit(): void {
    this.hasTwoOrMoreAvailablePolicies = this.getHasTwoOrMoreAvailablePolicies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.policies) {
      this.hasTwoOrMoreAvailablePolicies = this.getHasTwoOrMoreAvailablePolicies();
    }
  }

  private getHasTwoOrMoreAvailablePolicies(): boolean {
    return this.policies.filter((policy) => policy.status !== CrossSellPolicyStatus.CurrentlyNotAvailable).length >= 2;
  }
}
