import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { diffInHours } from '@next-insurance/date';
import { UsStateCode } from '@next-insurance/utils';

import { usStatesArray } from '../models/states';
import { EmailValidatorService } from './validators/email-validator.service';
import { NotContainsValidatorService } from './validators/not-contains-validator.service';

const EntityNameRegex = /^[\w !"#$&'()+,./:?@‘’“\-]+$/;

@Injectable({
  providedIn: 'root',
})
export class NiValidatorsService {
  static entityNameRegex = EntityNameRegex;
  static phoneRegex = /^[\s()-]*[2-9](\d[\s()-]*){9}$/;

  static phonePattern: ValidatorFn = Validators.pattern('^[2-9][0-9]{9}$');
  static zipPattern: ValidatorFn = Validators.pattern('[0-9]{5}');
  static cityPattern: ValidatorFn = Validators.pattern(/^[\s',.A-Za-z\-]+$/);
  static personalNamePattern: ValidatorFn = Validators.pattern(/^[\d ',.A-Za-z-]+$/);
  static nameWithoutNumbersPattern: ValidatorFn = Validators.pattern("[a-zA-Z\\s-'.,]*");
  static maxLengthShortInput: ValidatorFn = Validators.maxLength(100);
  static maxLengthMediumInput: ValidatorFn = Validators.maxLength(400);
  static maxLengthLongInput: ValidatorFn = Validators.maxLength(1000);
  static entityNamePattern: ValidatorFn = Validators.pattern(EntityNameRegex);

  static email(control: AbstractControl): ValidationErrors {
    return EmailValidatorService.isValid(control.value) ? null : { email: control.value };
  }

  static notContains(array: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return NotContainsValidatorService.isValid(array, control.value) ? null : { contains: control.value };
    };
  }

  static isPhoneValid(value: string): boolean {
    return NiValidatorsService.phoneRegex.test(value);
  }

  static phoneOrEmail(control: AbstractControl): ValidationErrors {
    return EmailValidatorService.isValidOld(control.value) || NiValidatorsService.isPhoneValid(control.value)
      ? null
      : { notValid: control.value };
  }

  static minDate(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      return diffInHours(control.value, date) >= 0 ? null : { minDate: date.toString() };
    };
  }

  static maxDate(date: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      return diffInHours(date, control.value) >= 0 ? null : { maxDate: date.toString() };
    };
  }

  static isValidUsStateCode(excludedStates?: UsStateCode[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (excludedStates?.includes(control.value)) {
        return { unsupportedState: true };
      }
      if (!usStatesArray.some((state) => state.id === control.value)) {
        return { invalidStateCode: true };
      }
      return null;
    };
  }
}
