import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[niWideDesktop]',
  standalone: true,
})
export class NiWideDesktopDirective implements OnInit {
  @Input() niWideDesktop: boolean;
  mediaQuery: MediaQueryList;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.mediaQuery = window.matchMedia(this.niWideDesktop ? '(min-width: 1024px)' : '(max-width: 1023px)');
    this.listenToViewportChangeEvent();
    this.checkMediaQuery();
  }

  private checkMediaQuery(): void {
    this.mediaQuery.matches ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
    this.changeDetectorRef.markForCheck();
  }

  private listenToViewportChangeEvent(): void {
    const listener = this.checkMediaQuery.bind(this);
    if (this.mediaQuery.addEventListener) {
      this.mediaQuery.addEventListener('change', listener);
    } else if (this.mediaQuery.addListener) {
      // Safari support
      this.mediaQuery.addListener(listener);
    }
  }
}
