<div class="ni-dialog-content p-sm_md" data-test="create-follower-modal">
  <div>
    <form [formGroup]="followerForm">
      <div class="form-control">
        <label for="name" class="ni-input-label-rb">{{ 'GENERAL.CONTACT_NAME' | translate }}</label>
        <input class="ni-input-rb" formControlName="name" id="name" type="text" data-test="name" niTrim />
      </div>
      <div>
        <label for="emailAddress" class="ni-input-label-rb">{{ 'GENERAL.EMAIL' | translate }}</label>
        <input class="ni-input-rb" formControlName="emailAddress" id="emailAddress" type="text" data-test="email-address" niTrim />
      </div>
    </form>
  </div>
</div>
<div class="ni-dialog-footer with-border space-between">
  <ni-button-rb (click)="onSubmit()" [disabled]="followerForm.invalid" [isLoading]="isSubmitting" data-test="submit-button">
    {{ 'GENERAL.SUBMIT' | translate }}
  </ni-button-rb>
  <ni-terms-of-service-link
    modalName="createFollowerTermsOfServiceModal"
    label="CERTIFICATES.FOLLOWERS.ADD_FOLLOWER.TERMS_OF_SERVICE.LABEL"
    message="CERTIFICATES.FOLLOWERS.ADD_FOLLOWER.TERMS_OF_SERVICE.MESSAGE"
  ></ni-terms-of-service-link>
</div>
