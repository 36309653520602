import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

import { CoreTrackingService } from '../../../core/services/core-tracking.service';
import { ReferralProgramService } from '../../../core/services/referral-program.service';

@Component({
  selector: 'ni-referral-program',
  templateUrl: './referral-program-button.component.html',
  styleUrls: ['./referral-program-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslateModule, FaIconComponent, NgClass, NgIf],
})
export class ReferralProgramButtonComponent {
  private referralProgramService = inject(ReferralProgramService);
  private coreTrackingService = inject(CoreTrackingService);

  @Input() icon: boolean;
  @Input({ required: true }) placement: 'header' | 'footer';

  isReferralProgramVisible$ = this.referralProgramService.isReferralProgramVisible();

  protected readonly faGift = faGift;

  openReferralProgram(): void {
    this.coreTrackingService.trackReferralProgramClick(this.placement);
    this.referralProgramService.onClickReferralProgram();
  }
}
