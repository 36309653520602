<div class="survey-container" [formGroup]="surveyForm">
  <div class="survey-first-step">
    <div class="chat-message chat-message-bot" data-test="experience-score-question">{{ experienceScoreQuestion }}</div>
    <ul class="points-wrapper" data-test="experience-scores-wrapper" [ngClass]="{ 'point-selected': hasSelectedPoint }">
      <li *ngFor="let point of surveyPoints">
        <button
          class="point-item {{ point.pointType | lowercase }}"
          (click)="toggleSelectedIcon(point)"
          [attr.data-test]="'point-item-' + point.pointType | dataTest"
          [hidden]="hasSelectedPoint && !point.selected"
          [ngClass]="{ selected: point.selected }"
        ></button>
        <div class="point-label" *ngIf="point.label" [hidden]="hasSelectedPoint && !point.selected">
          <span>{{ point.label }}</span>
        </div>
      </li>
    </ul>
  </div>
  <div class="chat-message chat-message-bot" data-test="score-reason-question" *ngIf="hasSelectedPoint">{{ scoreReasonQuestion }}</div>
  <div class="survey-second-step" *ngIf="hasSelectedPoint && !isSurveyFinished">
    <textarea
      formControlName="scoreReasonResponse"
      data-test="survey-input"
      class="survey-content"
      placeholder="Type here..."
      niFocus
      rows="4"
    >
    </textarea>
    <ni-chat-button customClass="primary" (clicked)="submit()" dataTest="survey-submit"> Submit </ni-chat-button>
  </div>
</div>
