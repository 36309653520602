import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CertificatesTrackingService } from '../../../certificates/shared/services/certificates-tracking.service';
import { certificatesActions } from '../../../certificates/shared/store/certificates.actions';
import { AppState } from '../../../store';
import { Follower } from '../../models/follower.model';

@Component({
  selector: 'ni-remove-follower-modal',
  templateUrl: './remove-follower-modal.component.html',
  styleUrls: ['./remove-follower-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveFollowerModalComponent implements OnInit {
  isSubmitting: boolean;
  follower: Follower;
  ButtonType = ButtonType;

  constructor(
    private store: Store<AppState>,
    private certificatesTrackingService: CertificatesTrackingService,
    private dynamicDialogConfig: DynamicDialogConfig<{ follower: Follower }>,
    private dynamicDialogRef: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.isSubmitting = false;
    this.follower = this.dynamicDialogConfig.data.follower;
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.certificatesTrackingService.trackRemoveFollowerModalSubmit(this.follower);
    this.store.dispatch(certificatesActions.removeFollower({ follower: this.follower }));
  }

  onCancel(): void {
    this.certificatesTrackingService.trackRemoveFollowerModalCancel(this.follower);
    this.dynamicDialogRef.close();
  }
}
