import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

import { businessSelectors } from '../../business/store/business.selectors';
import { FeatureFlags } from '../models/feature-flags.enum';
import { LmsSerials } from '../models/lms-serials.enum';
import { UserType } from '../models/user-type.enum';
import { coreSelectors } from '../store/core.selectors';
import { FeatureFlagsService } from './feature-flags.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramService {
  store = inject(Store);
  referralProgramFF = inject(FeatureFlagsService).isActive(FeatureFlags.ReferralProgram);
  businessId$ = this.store.select(businessSelectors.getBusinessId);
  navigationService = inject(NavigationService);

  isReferralProgramVisible(): Observable<boolean> {
    return this.store.pipe(
      select(coreSelectors.getUserType),
      first(),
      map((userType: UserType) => {
        return this.referralProgramFF && userType === UserType.Customer;
      }),
    );
  }

  onClickReferralProgram(): void {
    this.businessId$.pipe(take(1)).subscribe((businessId: string) => {
      this.navigationService.navigateByLMS(LmsSerials.PublicSiteReferralProgram, true, { businessid: businessId });
    });
  }
}
