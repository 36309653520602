import { Inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { select, Store } from '@ngrx/store';
import URLSearchParams from '@ungap/url-search-params';
import { Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { AbTest, AbTestsMap } from '../../ab-testing/models/ab-test.model';
import { AbTestConfig } from '../../ab-testing/models/ab-test-config.model';
import { AbTestMultiVariantsConfig } from '../../ab-testing/models/ab-test-multi-variants-config.model';
import { AbTestSection } from '../../ab-testing/models/ab-test-section.enum';
import { abTestingActions } from '../../ab-testing/store/ab-testing.actions';
import { abTestingSelectors } from '../../ab-testing/store/ab-testing.selectors';
import { AppState } from '../../store';
import { InteractionType } from '../models/interaction-type.enum';
import { QueryParams } from '../models/query-params.enum';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { AbTestingDataService } from './ab-testing.data.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class AbTestingService {
  constructor(
    private abTestingDataService: AbTestingDataService,
    private store: Store<AppState>,
    private trackingService: TrackingService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}

  private get abTestsMap(): AbTestsMap {
    let abTests: AbTestsMap;

    this.store.pipe(select(abTestingSelectors.getAbTestsMap), first()).subscribe((abTestsMap: AbTestsMap) => {
      abTests = abTestsMap;
    });

    return abTests;
  }

  overrideAbTestsByQueryParams(): void {
    const urlParams = this.getUrlParams();
    const turnOnAbTests = urlParams.get(QueryParams.TurnOnAbTests);
    const turnOnAbTestVariants = urlParams.get(QueryParams.TurnOnAbTestVariants);
    const turnOffAbTests = urlParams.get(QueryParams.TurnOffAbTests);
    this.sessionStorage.setItem(QueryParams.TurnOnAbTests, turnOnAbTests);
    this.sessionStorage.setItem(QueryParams.TurnOnAbTestVariants, turnOnAbTestVariants);
    this.sessionStorage.setItem(QueryParams.TurnOffAbTests, turnOffAbTests);
  }

  loadSectionAbTests(section: AbTestSection): Observable<boolean> {
    const urlParams = this.getUrlParams();
    const forceUserGroupId = urlParams.get(QueryParams.SetUserGroupId);

    this.store.dispatch(
      abTestingActions.loadSectionAbTests({
        forceUserGroupId,
        section,
      }),
    );
    return this.waitForAbTests(section);
  }

  waitForAbTests(section: AbTestSection): Observable<boolean> {
    return this.store.pipe(
      select(abTestingSelectors.isLoaded(section)),
      filter((isLoaded: boolean) => isLoaded),
      first(),
    );
  }

  pairAbTest(abTest: AbTest, section: AbTestSection): Observable<boolean> {
    if (!abTest) {
      return of(false);
    }

    return this.abTestingDataService.pairAbTest(section);
  }

  trackAbTest(abTest: AbTest, section: AbTestSection): Observable<boolean> {
    if (!abTest) {
      return of(false);
    }

    this.trackUserInteraction(abTest);
    return this.abTestingDataService.trackAbTest(abTest.ab_test_variant_id, section);
  }

  canRunAbTest(testConfig: AbTestConfig): boolean {
    const { name, testVariant } = testConfig;
    if (!name || !testVariant) {
      return false;
    }

    const turnOnAbTests = this.sessionStorage.getItem(QueryParams.TurnOnAbTests);
    if (turnOnAbTests?.includes(name)) {
      return true;
    }

    const turnOffAbTests = this.sessionStorage.getItem(QueryParams.TurnOffAbTests);
    if (turnOffAbTests?.includes(name)) {
      return false;
    }

    const { abTestsMap } = this;
    const activeVariant = abTestsMap[name] && abTestsMap[name].ab_test_variant_name === testVariant;
    return !!activeVariant;
  }

  getActiveVariant(testConfig: AbTestMultiVariantsConfig): string {
    const { abTestsMap } = this;
    const { name, variants, defaultVariantName } = testConfig;

    if (!name) {
      logger.warn(`The test name is missing in the ab test configuration`);
      return defaultVariantName;
    }

    if (!variants?.length) {
      logger.warn(`Variants are missing in the configuration for '${name}' ab test`);
      return defaultVariantName;
    }

    const turnOnAbTestVariant = this.getActiveVariantByQueryParam(variants, QueryParams.TurnOnAbTestVariants);
    if (turnOnAbTestVariant?.length) {
      return turnOnAbTestVariant;
    }

    const abtestActiveVariantName = abTestsMap?.[name]?.ab_test_variant_name;

    if (!variants.includes(abtestActiveVariantName)) {
      return defaultVariantName;
    }

    return abtestActiveVariantName;
  }

  private getUrlParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }

  private getActiveVariantByQueryParam(testVariants: string[], queryParam: QueryParams): string {
    const testVariantsLowerCased = testVariants.map((variant: string) => variant.toLowerCase());
    const queryParamsVariants: string[] = this.sessionStorage.getItem(queryParam)?.split(',');
    return queryParamsVariants?.find((variant: string) => testVariantsLowerCased?.includes(variant.toLowerCase()));
  }

  private trackUserInteraction(abTest: AbTest): void {
    this.trackingService.track({
      interactionType: InteractionType.AbTest,
      placement: 'ab_test_init',
      name: 'ab_test_init',
      interactionData: { abTest },
      eventName: 'Adding user to ab test group',
    });
  }
}
