import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { AppState } from '../../store';
import { coreActions } from '../store/core.actions';
import { coreSelectors } from '../store/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class HolidayThemeService {
  constructor(private store: Store<AppState>) {}

  loadHolidayTheme(): Observable<boolean> {
    this.store.dispatch(coreActions.loadHolidayTheme());

    return this.store.pipe(
      select(coreSelectors.isLoadingHolidayTheme),
      filter((isLoading: boolean) => !isLoading),
      first(),
    );
  }
}
