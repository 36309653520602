import { createAction, props } from '@ngrx/store';

import { CertificateAnalysisRecommendationResponse } from '../../../core/models/certificate-analysis-recommendation-response.model';
import { CreateFollowerRequest, Follower } from '../../../followers/models/follower.model';
import { AutoInsuranceCard } from '../models/auto-insurance-card.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolderRequirementPartialInformation } from '../models/certificate-holder-requirement-partial-information.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CreateCertificateRequest } from '../models/create-certificate-request.model';
import { CustomCertificateFlowType } from '../models/custom-certificate-flow-type.enum';
import { SendCertificateRequest } from '../models/send-certificate-request.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { ThirdPartyRequestAction, ThirdPartyRequestType } from '../models/third-party-request-type.enum';

export const certificatesActions = {
  loadProofCertificate: createAction('[Certificates] Load proof certificate'),
  setProofCertificate: createAction('[Certificates] Set proof certificate', props<{ proofCertificate: Certificate }>()),
  loadCustomCertificates: createAction('[Certificates] Load custom certificates'),
  setCustomCertificates: createAction(
    '[Certificates] Set custom certificates',
    props<{
      customCertificates: Certificate[];
    }>(),
  ),
  deleteCertificate: createAction(
    '[Certificates] Delete a certificate',
    props<{ liveCertificateId: string; overrideDesignationsApproved?: boolean }>(),
  ),
  loadAutoInsuranceCards: createAction('[Certificates API] Load auto insurance cards'),
  setAutoInsuranceCards: createAction(
    '[Certificates] Set auto insurance cards',
    props<{
      autoInsuranceCards: AutoInsuranceCard[];
    }>(),
  ),
  loadCertificateHolderRequirementsPartialInformation: createAction('[Certificates API] Load certificate holder requirements partial info'),
  setCertificateHolderRequirementsPartialInformation: createAction(
    '[Certificates] Set listed certificate holder requirements',
    props<{ certificateHolderRequirementsPartialInformation: CertificateHolderRequirementPartialInformation[] }>(),
  ),
  loadCertificateHolderRequirements: createAction(
    '[Certificates API] Load listed certificate holder requirements',
    props<{ certificateHolderRequirementId: string }>(),
  ),
  loadCertificateHolderRequirementsSuccess: createAction(
    '[Certificates] load listed certificate holder requirements succeeded',
    props<{
      certificateHolderRequirements: CertificateHolderRequirements;
      request: CreateCertificateRequest;
    }>(),
  ),
  loadPublicCertificateAllowedOperations: createAction('[Certificates API] Load public certificate allowed operations'),
  setPublicCertificateAllowedOperations: createAction(
    '[Certificates] Set public certificate allowed operations',
    props<{ allowedOperations: CertificateAllowedOperations }>(),
  ),
  loadCertificateAllowedModifiers: createAction('[Certificates API] Load custom certificate allowed modifiers'),
  setCertificateAllowedModifiers: createAction(
    '[Certificates] Set custom certificate allowed modifiers',
    props<{ allowedModifiers: CertificateAllowedModifiers }>(),
  ),
  submitCustomCertificateForm: createAction(
    '[Certificates] Submit custom certificate flow',
    props<{ request: CreateCertificateRequest }>(),
  ),
  setPreQuestionsAnswer: createAction('[Certificates] Set pre questions answer', props<{ answer: string }>()),
  submitNotificationsRequestForCertHolder: createAction('[Certificates API] Submit notifications request for cert holder'),
  cleanCustomCertificateState: createAction('[Certificates] Clean custom certificate state'),
  submitWaiverChange: createAction('[Certificates] Submit waiver change'),
  goBackToCertificatesWithError: createAction(
    '[Certificates] Go back to certificates with error',
    props<{
      errorMessage?: string;
    }>(),
  ),
  setCustomCertificateFlowType: createAction(
    '[Certificates] Set Custom Certificate Flow Type',
    props<{
      flowType: CustomCertificateFlowType;
    }>(),
  ),
  loadThirdPartyPendingRequests: createAction('[Certificates API] Load third party pending requests'),
  setThirdPartyPendingRequests: createAction(
    '[Certificates] Set third party pending requests',
    props<{ thirdPartyPendingRequests: ThirdPartyPendingRequest[] }>(),
  ),
  submitThirdPartyRequestSuccess: createAction(
    '[Certificates] Submit third party request success',
    props<{ requestType: ThirdPartyRequestType; requestId: string; requestAction: ThirdPartyRequestAction }>(),
  ),
  submitThirdPartyRequestFailure: createAction(
    '[Certificates] Submit third party request failure',
    props<{ errorReason: string; requestId: string; requestType: ThirdPartyRequestType }>(),
  ),
  approveThirdPartyRequestAfterPayment: createAction(
    '[Certificates] Approve third party request after payment',
    props<{ requestId: string; requestType: ThirdPartyRequestType }>(),
  ),
  loadFollowers: createAction('[Certificates API] Load followers'),
  setFollowers: createAction('[Certificates] Set followers', props<{ followers: Follower[] }>()),
  createFollower: createAction('[Certificates API] Create a follower', props<{ request: CreateFollowerRequest }>()),
  createFollowerSuccess: createAction('[Certificates] Create follower success', props<{ follower: Follower }>()),
  removeFollower: createAction('[Certificates API] Remove a follower', props<{ follower: Follower }>()),
  removeFollowerSuccess: createAction('[Certificates] Remove follower success', props<{ follower: Follower }>()),
  sendCertificate: createAction(
    '[Certificates API] Send certificate',
    props<{ request: SendCertificateRequest; liveCertificateId: string }>(),
  ),
  submitCoiAnalyzerResults: createAction(
    '[Certificates] Submit COI analyzer results',
    props<{ result: CertificateAnalysisRecommendationResponse; analysisId: string }>(),
  ),
  setCertificateRequest: createAction(
    '[Certificates] Set certificate request',
    props<{
      request: CreateCertificateRequest;
    }>(),
  ),
};
