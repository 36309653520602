import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[niContentEditable]',
})
export class ContentEditableDirective {
  @HostBinding('style.white-space') styleWhiteSpace = 'pre-line';

  constructor(private elementRef: ElementRef) {}

  @HostListener('drop')
  onDrop(): void {
    this.noHTML();
  }

  @HostListener('paste')
  onPaste(): void {
    this.noHTML();
  }

  @HostListener('ngModelChange')
  onNgModelChange(): void {
    this.noHTML();
  }

  @HostListener('copy')
  onCopy(): void {
    this.noHTML();
  }

  @HostListener('blur')
  onBlur(): void {
    this.noHTML();
  }

  @HostListener('focus')
  onFocus(): void {
    this.noHTML();
  }

  noHTML(): void {
    const el: HTMLTextAreaElement = this.elementRef.nativeElement;
    setTimeout(function () {
      el.innerHTML = el.textContent;
    }, 0);
  }
}
