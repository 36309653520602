import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { NIError } from '@next-insurance/errors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { businessSelectors } from '../../business/store/business.selectors';
import { CobSegment } from '../../core/models/cob-segment.enum';
import { MobileAppEventId, TechnicalErrorEvent } from '../../core/models/mobile-app-event.model';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { NavigationService } from '../../core/services/navigation.service';
import { coreActions } from '../../core/store/core.actions';
import { coreSelectors } from '../../core/store/core.selectors';
import { CoverageRiskLevel } from '../../coverage-checkup/enums/coverage-risk-level.enum';
import { CrossSellTokenResponse } from '../../home/models/cross-sell-token-response.model';
import { CrossSellTokenStatus } from '../../home/models/cross-sell-token-status.enum';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { CrossSellResponse } from '../models/cross-sell-response.model';
import { CrossSellDataService } from '../services/cross-sell.data.service';
import { CrossSellService } from '../services/cross-sell.service';
import { CrossSellTrackingService } from '../services/cross-sell-tracking.service';
import { crossSellActions } from './cross-sell.actions';

@Injectable()
export class CrossSellEffects {
  loadCrossSell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(crossSellActions.loadCrossSell),
      switchMap(() =>
        combineLatest([this.crossSellDataService.getCrossSell(), this.store.select(businessSelectors.isLoadingCobSegment)]).pipe(
          tap(([crossSellResponse]: [CrossSellResponse, boolean]) => {
            if (!this.validateCrossSellResponse(crossSellResponse)) {
              throw new NIError('Invalid cross sell response', new Error(), { crossSellResponse });
            }
          }),
          switchMap(([crossSellResponse, isLoadingCobSegment]) =>
            of([crossSellResponse, isLoadingCobSegment]).pipe(
              filter(() => !isLoadingCobSegment),
              switchMap(() =>
                combineLatest([this.store.select(businessSelectors.getCobSegment), this.store.select(policiesSelectors.getCOBId)]).pipe(
                  map(([cobSegment, cobId]: [CobSegment, number]) => {
                    const eligiblePolicies = this.crossSellService.filterOutNotEligibleCrossSellPoliciesByCobSegment(
                      crossSellResponse.crossSellPolicies,
                      cobSegment,
                      cobId,
                    );

                    return crossSellActions.setCrossSell({
                      crossSellResponse: {
                        ...crossSellResponse,
                        crossSellPolicies: eligiblePolicies,
                      },
                    });
                  }),
                ),
              ),
            ),
          ),
          catchErrorAndLog((error) => {
            this.trackingService.trackCrossSellLoadPoliciesError('ERROR', error);
            return of(
              crossSellActions.setCrossSell({
                crossSellResponse: { crossSellPolicies: [], eligibleForCoverageCheckup: false },
              }),
            );
          }),
        ),
      ),
    ),
  );

  startCrossSell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(crossSellActions.startCrossSell),
      switchMap((action) => {
        this.store.dispatch(coreActions.setLoading({ isLoading: true }));
        return this.crossSellDataService.getRetrieveOpportunityToken().pipe(
          map((res: CrossSellTokenResponse) => {
            if (res.status === CrossSellTokenStatus.Success) {
              return crossSellActions.goToApplication({
                lob: action.lob,
                rot: res.crossSellToken,
                shouldOfferThroughSmartAgent: action?.shouldOfferThroughSmartAgent,
              });
            }
            return crossSellActions.failedToCrossSell({ status: res.status, error: 'failed to get ROT' });
          }),
          catchErrorAndLog((errorMessage: any) => {
            return of(crossSellActions.failedToCrossSell({ status: 'ERROR', error: errorMessage }));
          }),
        );
      }),
    ),
  );

  goToApplication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(crossSellActions.goToApplication),
        withLatestFrom(this.store.select(coreSelectors.getUserType)),
        tap(([{ rot, lob, shouldOfferThroughSmartAgent }, userType]) => {
          const crossSellUrl = this.crossSellService.getCrossSellApplicationUrl(rot, lob, userType, shouldOfferThroughSmartAgent);
          this.navigationService.navigateTo(crossSellUrl);
        }),
      ),
    { dispatch: false },
  );

  failedToCrossSell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(crossSellActions.failedToCrossSell),
      tap(({ status, error }) => this.trackingService.trackCrossSellRedirectError(status, error)),
      switchMap((action) =>
        this.mobileAppEventsService.notifyMobileAndBreakFlowIfNeeded({
          eventId: MobileAppEventId.TechnicalError,
          ...action,
        } as TechnicalErrorEvent),
      ),
      switchMap(() => {
        return [
          coreActions.setLoading({ isLoading: false }),
          toastActions.showToast({
            toastType: ToastType.Error,
            message: 'CROSS_SELL.REDIRECT_TO_APPLICATION.ERROR.TEXT',
            title: 'CROSS_SELL.REDIRECT_TO_APPLICATION.ERROR.TITLE',
          }),
        ];
      }),
    ),
  );

  modifyNotifyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(crossSellActions.modifyNotifyStatus),
      switchMap(({ lob, status }) =>
        this.crossSellDataService.setNotifyStatus(lob, status).pipe(
          map(() =>
            crossSellActions.setNotifyStatus({
              lob,
              status,
            }),
          ),
          catchErrorAndLog(() => of(toastActions.showGeneralErrorToast())),
        ),
      ),
    ),
  );

  setNotifyStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(crossSellActions.setNotifyStatus),
        tap(({ lob, status }) => this.trackingService.trackClickNotify(lob, !status)),
      ),
    { dispatch: false },
  );

  loadWCSuggestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(crossSellActions.loadWCSuggestion),
      switchMap(() =>
        this.crossSellService.getSuggestion(LOB.WC).pipe(
          tap(() => {
            this.crossSellTrackingService.trackWCComplianceBannerGetSuggestion(true);
          }),
          map((wcSuggestion) => crossSellActions.setWCSuggestion({ wcSuggestion })),
          catchErrorAndLog((error: HttpErrorResponse) => {
            this.crossSellTrackingService.trackWCComplianceBannerGetSuggestion(false, error?.error?.niStatusCode);
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private crossSellDataService: CrossSellDataService,
    private crossSellService: CrossSellService,
    private crossSellTrackingService: CrossSellTrackingService,
    private trackingService: CrossSellTrackingService,
    private navigationService: NavigationService,
    private mobileAppEventsService: MobileAppEventsService,
  ) {}

  private validateCrossSellResponse(crossSellResponse: CrossSellResponse): boolean {
    if (crossSellResponse.eligibleForCoverageCheckup) {
      return [CoverageRiskLevel.Low, CoverageRiskLevel.Moderate, CoverageRiskLevel.High].includes(
        crossSellResponse.coverageCheckupDetails?.coverageRiskLevel,
      );
    }

    return true;
  }
}
