import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { chatbotEventsConfig } from '../../../../configs/chatbot-events.config';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatRichMessage } from '../../models/chat-rich-message.model';
import { RichMessagesService } from '../../services/rich-messages.service';
import { FormRichMessageContent } from './models/form-rich-message-content.model';
import { DynamicFormService } from './services/dynamic-form.service';

@Component({
  selector: 'ni-form-rich-message',
  templateUrl: './form-rich-message.component.html',
  styleUrls: ['./form-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DynamicFormService],
})
export class FormRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.Form;
  @Input() message!: ChatRichMessage<FormRichMessageContent>;
  form!: UntypedFormGroup;
  isAddingMode = true;
  isCollection: boolean;
  isSubmitted = false;
  formMessages: string[] = [];
  private actualFormCollectionResult: Record<string, string>[] = [];
  private labelsMap: Record<string, string> = {};
  private paramName: string;

  constructor(
    private dynamicFormService: DynamicFormService,
    private richMessagesService: RichMessagesService,
  ) {}

  ngOnInit(): void {
    this.form = this.dynamicFormService.toFormGroup(this.message.data.richContent.fields);
    this.isCollection = this.getIsCollection(this.message.data.richContent?.isCollection);
    this.paramName = this.message.data.richContent.paramName;
    this.initLabelsMap();
  }

  private getIsCollection(isCollectionValue: string | boolean): boolean {
    let isCollection = false;
    if (typeof isCollectionValue === 'string') {
      isCollection = isCollectionValue === 'true';
    } else if (typeof isCollectionValue === 'boolean') {
      isCollection = isCollectionValue;
    }
    return isCollection;
  }

  onSave(): void {
    this.addToCollection();
    this.displayMessage(this.parseAsPrettyMessage(this.form.value));
    this.isAddingMode = false;
    this.form.reset();
  }

  onSubmit(): void {
    let actualFormResult;

    if (this.isCollection) {
      actualFormResult = this.actualFormCollectionResult;
    } else {
      this.displayMessage(this.parseAsPrettyMessage(this.form.value));
      actualFormResult = this.getActual();
    }
    this.isSubmitted = true;
    this.richMessagesService.sendMessage({
      eventName: chatbotEventsConfig.submitForm,
      additionalParams: { [this.paramName]: actualFormResult },
      keepAfterSend: true,
    });
  }

  private getActual(): Record<string, string> {
    return { ...this.form.getRawValue(), entityType: this.message.data.richContent.entityType };
  }

  private parseAsPrettyMessage(payload: Record<string, string>, title = this.message.data.richContent.title): string {
    return `${title}\n${Object.entries(payload)
      .filter(([, value]) => !!value)
      .map(([key, value]) => `${this.labelsMap[key]}: ${value}`)
      .join('\n')}`;
  }

  private addToCollection(): void {
    const actual = this.getActual();

    this.actualFormCollectionResult.push(actual);
  }

  private displayMessage(message: string): void {
    this.formMessages = [...this.formMessages, message];
  }

  private initLabelsMap(): void {
    this.message.data.richContent.fields.forEach((field) => {
      this.labelsMap[field.key] = field.label;
    });
  }

  onCancel(): void {
    if (this.isCollection && this.actualFormCollectionResult.length) {
      this.isAddingMode = false;
      return;
    }

    this.richMessagesService.sendMessage({ eventName: chatbotEventsConfig.cancelForm });
  }
}
