import { LOB, PackageType } from '@next-insurance/core';
import { DateFormats, subtractFromDate, transformDate } from '@next-insurance/date';
import { NIUsStateHelper } from '@next-insurance/utils';

import { BusinessOwnershipStructure } from '../../business/models/business-ownership-structure.enum';
import { PaymentProvider } from '../../payment/enums/payment-provider.enum';
import { PaymentMethodType } from '../../payment/models/payment-method-details.model';
import { PaymentFrequency } from '../../policies/models/payment-frequency.enum';
import { PolicyStatus } from '../../policies/models/policy-status.enum';
import { BusinessCreationRequest } from '../../shared/models/business-creation-request.model';
import { PreparedBusinessCreationRequest } from '../../shared/models/prepared-business-creation-request.model';

export const defaultBusinessCreationRequest: BusinessCreationRequest = {
  cobId: 1000,
  stateCode: NIUsStateHelper.mapToUsStateCode('Texas'),
  policies: [],
};

export const defaultBusinessCreationRequestWithGL: BusinessCreationRequest = {
  cobId: 1000,
  stateCode: NIUsStateHelper.mapToUsStateCode('Texas'),
  policies: [{ lob: LOB.GL }],
};

export const preparedBusinessCreationRequests: PreparedBusinessCreationRequest[] = [
  {
    title: 'Multiple LOBs',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      cobId: 100001,
      policies: [{ lob: LOB.GL }, { lob: LOB.WC }, { lob: LOB.IM }, { lob: LOB.CA }],
    },
  },
  {
    title: 'Renewal Policy',
    businessCreationRequest: {
      cobId: 1000,
      stateCode: 'KY',
      policies: [
        {
          expectedFinalStatus: PolicyStatus.Active,
          lob: LOB.GL,
          policyStartDate: '2022-07-13',
          paymentFrequency: PaymentFrequency.SinglePayment,
          hasRenewalPolicy: true,
        },
      ],
      businessOwnershipStructure: BusinessOwnershipStructure.Corporation,
    },
  },

  {
    title: 'WC Audit Policy',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      policies: [
        {
          lob: LOB.WC,
          expectedFinalStatus: PolicyStatus.Expired,
          policyStartDate: '2020-03-01',
          paymentFrequency: PaymentFrequency.Monthly,
          packageTypeWithTria: PackageType.Basic,
        },
      ],
    },
  },
  {
    title: 'Historical User',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      policies: [
        {
          lob: LOB.GL,
          expectedFinalStatus: PolicyStatus.Canceled,
          policyStartDate: transformDate(subtractFromDate(new Date(), { days: 1 }), DateFormats.HyphensDate),
        },
      ],
    },
  },
  {
    title: 'Next + Paygo Policies',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      policies: [
        {
          lob: LOB.GL,
        },
        {
          lob: LOB.WC,
          paymentFrequency: PaymentFrequency.Weekly,
          isExternalPeriodic: true,
        },
      ],
    },
  },
  {
    title: 'Business with braintree as payment provider',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      policies: [
        {
          lob: LOB.GL,
          paymentProvider: PaymentProvider.Braintree,
        },
      ],
    },
  },
  {
    title: 'User who paid with ACH',
    businessCreationRequest: {
      ...defaultBusinessCreationRequest,
      paymentMethodType: PaymentMethodType.BankAccount,
      policies: [
        {
          lob: LOB.GL,
        },
      ],
    },
  },
];
