import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';

import { RichDirectPolicyChangeType, RichPolicyChangesType } from '../../business-change/models/business-change.enum';
import { PermittedChangesService } from '../../business-change/services/permitted-changes.service';
import { Policy } from '../../policies/models/policy.model';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';

@Injectable({
  providedIn: 'root',
})
export class CoverageService {
  constructor(
    private store: Store<AppState>,
    private permittedChangesService: PermittedChangesService,
  ) {}

  isPermittedForPackageChange(policyId: number): Observable<boolean> {
    return this.permittedChangesService.waitForPermittedChanges().pipe(
      withLatestFrom(this.store.select(policiesSelectors.getPolicyById(policyId))),
      first(),
      map(([, policy]: [boolean, Policy]) => {
        return this.permittedChangesService.isPermitted(RichPolicyChangesType.Package, policy.lob);
      }),
    );
  }

  isFlexibleCoveragePermitted(policyId: number): Observable<boolean> {
    return this.permittedChangesService.waitForPermittedChanges().pipe(
      withLatestFrom(this.store.select(policiesSelectors.getPolicyById(policyId))),
      first(),
      map(([, policy]: [boolean, Policy]) => {
        return this.permittedChangesService.isPermitted(RichDirectPolicyChangeType.DirectCoverageLimitChange, policy.lob);
      }),
    );
  }
}
