import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { OnboardingStep } from '../../../models/onboarding-step.enum';
import { StepStatus } from '../../../models/step-status.enum';

@Component({
  selector: 'ni-onboarding-step',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class OnboardingStepComponent {
  @Output() moveToNextStep = new EventEmitter<void>();
  @Output() abortOnboarding = new EventEmitter<void>();
  readonly DELAY_BETWEEN_STEPS = 2000;
  isStepCompleted: boolean;
  stepSettings: { [stepStatus in StepStatus]: any };
  currentStepStatus = StepStatus.Initial;
  OnboardingStep = OnboardingStep;

  protected constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  completeStep(): void {
    this.isStepCompleted = true;
    this.currentStepStatus = StepStatus.Completed;
    this.changeDetectorRef.markForCheck();
    this.waitAndMoveToNextStep();
  }

  private waitAndMoveToNextStep(): void {
    setTimeout(() => {
      this.moveToNextStep.emit();
    }, this.DELAY_BETWEEN_STEPS);
  }
}
