import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ChatbotCustomMessageType } from '../../../../../core/models/chatbot-custom-message-type.enum';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { AbstractChatbotCustomHandlerService } from '../../services/abstract-chatbot-custom-handler-service';
import { CustomHandlerRichMessageContent } from '../custom-handler-rich-message/custom-handler-rich-message-content.model';

@Component({
  selector: 'ni-chatbot-error-handler',
  templateUrl: './chatbot-error-handler.component.html',
  styleUrls: ['./chatbot-error-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatbotErrorHandlerComponent {
  static readonly richComponentType = RichMessageType.ChatbotErrorHandler;
  readonly errorHanldlerButtonLabel = 'Chat with live agent';

  constructor(private chatbotErrorHandlerService: AbstractChatbotCustomHandlerService) {}

  handleError(): void {
    const message: CustomHandlerRichMessageContent = {
      type: ChatbotCustomMessageType.ChatbotError,
      params: null,
      close: true,
      minimize: false,
    };

    this.chatbotErrorHandlerService.processMessage(message);
  }
}
