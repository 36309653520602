import { PackageType } from '@next-insurance/core';
import { createAction, props } from '@ngrx/store';

import { PackagesDetails } from '../models/packages.model';

const loadPackagesForPolicy = createAction('[Coverages] Load packages for policy', props<{ policyId: number }>());

const setPackagesForPolicy = createAction('[Coverages] Set packages for policy', props<{ packagesDetails: PackagesDetails }>());

const clearPackagesForPolicy = createAction('[Coverages] Clear packages for policy');

const loadPackagesForPolicyFailed = createAction('[Coverages] Load packages for policy failed');

const setCurrentSelectedPackage = createAction('[Coverages] Set current package', props<{ selectedPackageType: PackageType }>());

const setSelectedPackageAsCurrent = createAction('[Coverages] Set selected package as current');

const loadPackagesIfPermitted = createAction('[Coverages] Load packages if permitted', props<{ policyId: number }>());

const loadPackagesIfPermittedFinished = createAction('[Coverages] Load packages if permitted finished');

export const coveragesActions = {
  loadPackagesForPolicy,
  setPackagesForPolicy,
  loadPackagesForPolicyFailed,
  setCurrentSelectedPackage,
  setSelectedPackageAsCurrent,
  loadPackagesIfPermitted,
  loadPackagesIfPermittedFinished,
  clearPackagesForPolicy,
};
