import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocLinkResponse } from '../../../core/models/doc-link-response.model';
import { CreateFollowerRequest, CreateFollowerResponse, Follower } from '../../../followers/models/follower.model';
import { AutoInsuranceCard } from '../models/auto-insurance-card.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CertificateHolderRequirementsPartialInformationResponse } from '../models/certificate-holder-requirements-partial-information-response.model';
import { CertificateLanguageResponse } from '../models/certificate-language-response.model';
import { CertificateType } from '../models/certificate-type.enum';
import { CreateCertificateFromHolderRequirements } from '../models/create-certificate-from-holder-requirements.model';
import { CertificateLanguageRequest, CreateCertificateRequest } from '../models/create-certificate-request.model';
import { CreateCertificateResponse } from '../models/create-certificate-response.model';
import { SendCertificateRequest } from '../models/send-certificate-request.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { ThirdPartyRequest } from '../models/third-party-request.modal';
import { ThirdPartyRequestType } from '../models/third-party-request-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CertificatesDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getCertificates(type: CertificateType): Observable<Certificate[]> {
    return this.httpClient.get<Certificate[]>('/api/certificates', {
      params: {
        type,
      },
    });
  }

  /* istanbul ignore next */
  getAutoInsuranceCards(): Observable<AutoInsuranceCard[]> {
    return this.httpClient.get<AutoInsuranceCard[]>('/api/certificates/auto-insurance-cards');
  }

  /* istanbul ignore next */
  getAutoInsuranceCardDocLink(autoInsuranceCardId: string): Observable<DocLinkResponse> {
    return this.httpClient.get<DocLinkResponse>('/api/certificates/auto-insurance-cards/pdf', {
      params: {
        autoInsuranceCardId,
      },
    });
  }

  /* istanbul ignore next */
  getCertificateAllowedModifiers(): Observable<CertificateAllowedModifiers> {
    return this.httpClient.get<CertificateAllowedModifiers>('/api/certificates/custom-certificate/allowed-modifiers');
  }

  /* istanbul ignore next */
  getCertificateHolderRequirementsPartialInformation(): Observable<CertificateHolderRequirementsPartialInformationResponse> {
    return this.httpClient.get<CertificateHolderRequirementsPartialInformationResponse>(
      '/api/certificates/custom-certificate/certificate-holder-requirements-partial-information',
    );
  }

  /* istanbul ignore next */
  getCertificateHolderRequirements(certificateHolderRequirementId: string): Observable<CertificateHolderRequirements> {
    return this.httpClient.get<CertificateHolderRequirements>('/api/certificates/custom-certificate/certificate-holder-requirements', {
      params: {
        certificateHolderRequirementId,
      },
    });
  }

  /* istanbul ignore next */
  createCustomCertificate(createCertificateRequest: CreateCertificateRequest): Observable<CreateCertificateResponse> {
    return this.httpClient.post<CreateCertificateResponse>('/api/certificates/custom-certificate/create', {
      ...createCertificateRequest,
    });
  }

  /* istanbul ignore next */
  createCustomCertificateFromHolderRequest(
    createCertificateFromHolderRequirements: CreateCertificateFromHolderRequirements,
  ): Observable<CreateCertificateResponse> {
    return this.httpClient.post<CreateCertificateResponse>(
      '/api/certificates/custom-certificate/create-from-holder-requirements',
      createCertificateFromHolderRequirements,
    );
  }

  /* istanbul ignore next */
  getThirdPartyPendingRequests(): Observable<ThirdPartyPendingRequest[]> {
    return this.httpClient.get<ThirdPartyPendingRequest[]>('/api/certificates/third-party-requests/pending');
  }

  /* istanbul ignore next */
  getThirdPartyRequest(requestId: string, requestType: ThirdPartyRequestType): Observable<ThirdPartyRequest> {
    return this.httpClient.get<ThirdPartyRequest>(`/api/certificates/third-party-requests?requestType=${requestType}`, {
      params: {
        requestId,
      },
    });
  }

  /* istanbul ignore next */
  approveThirdPartyRequest(requestId: string, requestType: ThirdPartyRequestType): Observable<void> {
    return this.httpClient.post<void>(`/api/certificates/third-party-requests/approve?requestType=${requestType}`, {
      requestId,
    });
  }

  /* istanbul ignore next */
  denyThirdPartyRequest(requestId: string, requestType: ThirdPartyRequestType): Observable<void> {
    return this.httpClient.post<void>(`/api/certificates/third-party-requests/deny?requestType=${requestType}`, {
      requestId,
    });
  }

  /* istanbul ignore next */
  getFollowers(): Observable<Follower[]> {
    return this.httpClient.get<Follower[]>('/api/certificates/followers');
  }

  /* istanbul ignore next */
  createFollower(request: CreateFollowerRequest): Observable<CreateFollowerResponse> {
    return this.httpClient.post<CreateFollowerResponse>('/api/certificates/followers', {
      ...request,
    });
  }

  /* istanbul ignore next */
  removeFollower(thirdPartyId: string): Observable<void> {
    return this.httpClient.delete<void>('/api/certificates/followers', {
      params: {
        thirdPartyId,
      },
    });
  }

  /* istanbul ignore next */
  unsubscribeFollower(liveCertificateId: string, thirdPartyId: string): Observable<void> {
    return this.httpClient.post<void>('/api/public/certificates/followers/unsubscribe', {
      liveCertificateId,
      thirdPartyId,
    });
  }

  /* istanbul ignore next */
  sendCertificate(liveCertificateId: string, sendCertificateRequest: SendCertificateRequest): Observable<void> {
    return this.httpClient.post<void>('/api/certificates/send-certificate', {
      liveCertificateId,
      sendCertificateRequest,
    });
  }

  /* istanbul ignore next */
  previewCertificate(createCertificateRequest: CreateCertificateRequest): Observable<DocLinkResponse> {
    return this.httpClient.post<DocLinkResponse>('/api/certificates/certificate-preview', {
      ...createCertificateRequest,
    });
  }

  /* istanbul ignore next */
  previewCertificateFromHolderRequest(
    createCertificateFromHolderRequirements: CreateCertificateFromHolderRequirements,
  ): Observable<DocLinkResponse> {
    return this.httpClient.post<DocLinkResponse>(
      '/api/certificates/certificate-preview/certificates-via-holder-requirements',
      createCertificateFromHolderRequirements,
    );
  }

  /* istanbul ignore next */
  getCertificateLanguage(certificateLanguageRequest: CertificateLanguageRequest): Observable<CertificateLanguageResponse> {
    return this.httpClient.post<CertificateLanguageResponse>('/api/certificates/certificate-language', {
      ...certificateLanguageRequest,
    });
  }
}
