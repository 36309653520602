import { Pipe, PipeTransform } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { CrossSellPolicy } from '../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../models/cross-sell-policy-status.enum';

@Pipe({
  name: 'crossSellPoliciesOrder',
})
export class CrossSellPoliciesOrder implements PipeTransform {
  mapStatusToOrder: Partial<Record<CrossSellPolicyStatus, number>> = {
    [CrossSellPolicyStatus.Quote]: 0,
    [CrossSellPolicyStatus.Available]: 1,
    [CrossSellPolicyStatus.CurrentlyNotAvailable]: 2,
  };

  mapLobToOrder: { [key in Exclude<LOB, LOB.AUTO>]: number } = {
    [LOB.BP]: 0,
    [LOB.GL]: 1,
    [LOB.PL]: 2,
    [LOB.WC]: 3,
    [LOB.CP]: 4,
    [LOB.CA]: 5,
    [LOB.IM]: 6,
  };

  transform(policies: CrossSellPolicy[]): CrossSellPolicy[] {
    if (!Array.isArray(policies)) {
      return null;
    }
    return policies
      .slice()
      .sort(
        (p1, p2) =>
          this.mapStatusToOrder[p1.status] - this.mapStatusToOrder[p2.status] ||
          this.mapLobToOrder[p1.lob as Exclude<LOB, LOB.AUTO>] - this.mapLobToOrder[p2.lob as Exclude<LOB, LOB.AUTO>],
      );
  }
}
