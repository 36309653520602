import { Injectable } from '@angular/core';
import { UsStateCode, UsStateName } from '@next-insurance/utils';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { InitiateAnalysisRecommendationProcessResponse } from '../../../core/models/analysis-recommendation-creation-response.model';
import { CertificateAnalysisRecommendationResponse } from '../../../core/models/certificate-analysis-recommendation-response.model';
import { COIUploadUrlResponse } from '../../../core/models/coi-upload-url-response.model';
import { InteractionType } from '../../../core/models/interaction-type.enum';
import { TrackingService } from '../../../core/services/tracking.service';
import { Follower } from '../../../followers/models/follower.model';
import { AppState } from '../../../store';
import { CertificateFollowerDialogData } from '../components/certificates-liability-section/create-custom-certificate/certificate-customizations-page/certificate-follower-dialog/certificate-follower-dialog-data.model';
import { CertificateJobLocationDialogData } from '../components/certificates-liability-section/create-custom-certificate/certificate-customizations-page/certificate-job-location/certificate-job-location-dialog-data.model';
import { CertificateHolder } from '../models/certificate-holder.model';
import { CertificateHolderRequirementPartialInformationSuggestion } from '../models/certificate-holder-requirement-partial-information.model';
import { CreateCertificateRequest } from '../models/create-certificate-request.model';
import { DesignationType } from '../models/designation-type.enum';
import { FollowerFormData } from '../models/designations-form-data.model';
import { SpecialLanguage } from '../models/special-language.enum';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { ThirdPartyRequestType } from '../models/third-party-request-type.enum';
import { certificatesSelectors } from '../store/certifiactes.selectors';

@Injectable({
  providedIn: 'root',
})
export class CertificatesTrackingService {
  readonly certificatesPage = 'certificates-page';
  readonly createCertificatePage = 'create-certificate-page';
  readonly createCertificateAdditionalDesignationsPage = 'create-certificate-additional-designations-page';
  readonly reviewCertificatePage = 'review-certificate-page';
  readonly requestChangesForm = 'request-changes-live-certificate-page';
  readonly requestChangesReview = 'request-changes-review-live-certificate-page';
  readonly reviewThirdPartyPendingRequest = 'review-third-party-pending-request';
  readonly followersPage = 'certificates-followers-page';
  readonly customizationPage = 'certificates-customization-page';
  readonly preQuestionsPage = 'certificates-pre-question-page';

  constructor(
    private trackingService: TrackingService,
    private store: Store<AppState>,
  ) {}

  /* istanbul ignore next */
  trackCertificatesTabLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.certificatesPage,
      name: 'certificates-tab-landing',
    });
  }

  /* istanbul ignore next */
  trackCreateCustomCertificateClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'create-custom-certificate-click',
    });
  }

  /* istanbul ignore next */
  trackProofCertificateClick(liveCertificateId: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'proof-certificate-click',
      interactionData: {
        liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackCustomCertificateClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'custom-certificate-click',
    });
  }

  /* istanbul ignore next */
  trackCustomCertificateMenuClick(itemClicked: 'DELETE_CERTIFICATE'): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'custom-certificate-menu-click',
      interactionData: {
        itemClicked,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteCertificateModalSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'delete-certificate-modal-submit',
    });
  }

  /* istanbul ignore next */
  trackCustomCertificateDeleted(): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.certificatesPage,
      name: 'custom-certificate-deleted',
    });
  }

  /* istanbul ignore next */
  trackDeleteCertificateModalCancel(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'delete-certificate-modal-cancel',
    });
  }

  /* istanbul ignore next */
  trackAutoInsuranceCardClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'auto-insurance-card-click',
    });
  }

  /* istanbul ignore next */
  trackAutoTabLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.certificatesPage,
      name: 'auto-tab-landing',
    });
  }

  /* istanbul ignore next */
  trackCustomizationCheckboxClick(item: SpecialLanguage | DesignationType, checked: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'customization-item-checkbox',
      interactionData: {
        item,
        checked,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} checkbox`,
    });
  }

  /* istanbul ignore next */
  trackCustomizationEditClick(item: SpecialLanguage | DesignationType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'customization-item-edit',
      interactionData: {
        item,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} edit`,
    });
  }

  /* istanbul ignore next */
  trackJobLocationDialogView(dialogData: CertificateJobLocationDialogData): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.customizationPage,
      name: 'job-location-dialog-view',
      interactionData: {
        dialogData,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.View.toUpperCase()} job location dialog`,
    });
  }

  /* istanbul ignore next */
  trackCustomizationNextButtonClicked(result: CreateCertificateRequest): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'customization-page-next-clicked',
      interactionData: {
        result,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} next`,
    });
  }

  /* istanbul ignore next */
  trackJobLocationDialogSubmit(result: { stateCode: UsStateCode; stateName: UsStateName }): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'job-location-dialog-submit',
      interactionData: {
        result,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} job location dialog submit`,
    });
  }

  /* istanbul ignore next */
  trackJobCompletedDialogView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.customizationPage,
      name: 'job-completed-dialog-view',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.View.toUpperCase()} job completed dialog`,
    });
  }

  /* istanbul ignore next */
  trackJobCompletedDialogSubmit(result: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'job-completed-dialog-submit',
      interactionData: {
        result,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} job completed dialog submit`,
    });
  }

  /* istanbul ignore next */
  trackFollowerDialogView(dialogData: CertificateFollowerDialogData): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.customizationPage,
      name: 'follower-dialog-view',
      interactionData: {
        dialogData,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.View.toUpperCase()} follower dialog`,
    });
  }

  /* istanbul ignore next */
  trackFollowerDialogSubmit(result: FollowerFormData): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'follower-dialog-submit',
      interactionData: {
        result,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} follower dialog submit`,
    });
  }

  /* istanbul ignore next */
  trackTooltipClicked(item: DesignationType | SpecialLanguage): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.customizationPage,
      name: 'create-certificate-help-tip-clicked',
      interactionData: {
        item,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `certificates customization page - ${InteractionType.Click.toUpperCase()} help tip`,
    });
  }

  /* istanbul ignore next */
  trackAutoInsuranceCardOpened(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.certificatesPage,
      name: 'auto-insurance-card-opened',
      interactionData: {
        isSuccess,
      },
    });
  }

  /* istanbul ignore next */
  trackCreateCertificateLanding(): void {
    if (this.isRequestChangesFlow()) {
      this.trackingService.track({
        interactionType: InteractionType.View,
        placement: this.requestChangesForm,
        name: 'certificate-request-changes-landing',
        interactionData: {
          liveCertificateId: this.liveCertificateId,
        },
      });
    } else {
      this.trackingService.track({
        interactionType: InteractionType.View,
        placement: this.createCertificatePage,
        name: 'create-custom-certificate-landing',
      });
    }
  }

  /* istanbul ignore next */
  trackCertificateRequestChangesEmailTooltipClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.requestChangesForm,
      name: 'email-address-tooltip',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackCreateCertificateNextButtonClick(holderData: CertificateHolder): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.getCertificateFormPlacement(),
      name: 'next-button',
      interactionData: {
        holderData,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackReviewCertificateLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.getReviewPagePlacement(),
      name: 'review-certificate-landing',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackAdditionalDesignationsBackButtonClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificateAdditionalDesignationsPage,
      name: 'back-button',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `create certificate - designations page - ${InteractionType.Click.toUpperCase()} back button`,
    });
  }

  /* istanbul ignore next */
  trackJobHasCompletedAnswer(hasJobCompleted: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.getReviewPagePlacement(),
      name: 'job-has-completed-question',
      interactionData: {
        hasJobCompleted,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackReviewCertificateSubmit(): void {
    if (this.isRequestChangesFlow()) {
      this.trackingService.track({
        interactionType: InteractionType.Click,
        placement: this.requestChangesReview,
        name: 'request-changes-review-submit-button',
        interactionData: {
          liveCertificateId: this.liveCertificateId,
        },
      });
    } else {
      this.trackingService.track({
        interactionType: InteractionType.Click,
        placement: this.reviewCertificatePage,
        name: 'create-certificate-button',
      });
    }
  }

  /* istanbul ignore next */
  trackEditChangesButtonClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.getReviewPagePlacement(),
      name: 'edit-changes-button',
      interactionData: {
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `${
        this.isRequestChangesFlow() ? 'request changes' : 'create certificate'
      } - review page - ${InteractionType.Click.toUpperCase()} edit button`,
    });
  }

  /* istanbul ignore next */
  trackCreateCertificateOverlayModalView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.reviewCertificatePage,
      name: 'create-certificate-overlay-modal-view',
    });
  }

  /* istanbul ignore next */
  trackJobHasCompletedModalView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.getReviewPagePlacement(),
      name: 'job-has-completed-modal-view',
    });
  }

  /* istanbul ignore next */
  trackSubmitJobHasCompletedModal(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.getReviewPagePlacement(),
      name: 'job-has-completed-modal-submit-button',
    });
  }

  /* istanbul ignore next */
  trackCustomCertificateCreated(
    isSuccess: boolean,
    designations: string[],
    certificateHolderRequirementId?: string,
    isViaCoiAnalyzer?: boolean,
  ): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.certificatesPage,
      name: 'custom-certificate-created',
      interactionData: {
        isSuccess,
        designations,
        certificateHolderRequirementId,
        isViaCoiAnalyzer,
      },
      eventName: `create certificate - API create custom certificate`,
    });
  }

  /* istanbul ignore next */
  trackRequestChangesResultModalView(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.requestChangesReview,
      name: 'request-changes-result-modal-view',
      interactionData: {
        isSuccess,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackPendingRequestsView(thirdPartyRequests: ThirdPartyPendingRequest[]): void {
    const { changes, notifications } = thirdPartyRequests.reduce(
      (requestsMap, currRequest) => {
        currRequest.type === ThirdPartyRequestType.Changes
          ? requestsMap.changes.push(currRequest.requestId)
          : requestsMap.notifications.push(currRequest.requestId);
        return requestsMap;
      },
      { changes: [], notifications: [] },
    );

    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.certificatesPage,
      name: 'view-pending-requests',
      interactionData: {
        changes,
        notifications,
      },
    });
  }

  /* istanbul ignore next */
  trackPendingRequestClick(requestId: string, type: ThirdPartyRequestType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'click-pending-request',
      interactionData: {
        requestId,
        type,
      },
    });
  }

  /* istanbul ignore next */
  trackReviewThirdPartyPendingRequestLanding(requestId: string, type: ThirdPartyRequestType): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'page-landing',
      interactionData: {
        requestId,
        type,
      },
    });
  }

  /* istanbul ignore next */
  trackApproveThirdPartyRequest(requestId: string, type: ThirdPartyRequestType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'approve-button',
      interactionData: {
        requestId,
        type,
      },
    });
  }

  /* istanbul ignore next */
  trackDenyThirdPartyRequest(requestId: string, type: ThirdPartyRequestType): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'deny-button',
      interactionData: {
        requestId,
        type,
      },
    });
  }

  /* istanbul ignore next */
  trackApproveThirdPartyRequestResult(requestId: string, type: ThirdPartyRequestType, isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'approve-result',
      interactionData: {
        requestId,
        type,
        isSuccess,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackDenyThirdPartyRequestResult(requestId: string, type: ThirdPartyRequestType, isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'deny-result',
      interactionData: {
        requestId,
        type,
        isSuccess,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackThirdPartyRequestToggleChangesList(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.reviewThirdPartyPendingRequest,
      name: 'toggle-changes-list',
    });
  }

  /* istanbul ignore next */
  trackFollowersIconClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'followers-icon',
    });
  }

  /* istanbul ignore next */
  trackFollowersPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.followersPage,
      name: 'page-landing',
    });
  }

  /* istanbul ignore next */
  trackAddFollowerClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.followersPage,
      name: 'add-follower-button',
    });
  }

  /* istanbul ignore next */
  trackAddFollowerModalSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.followersPage,
      name: 'add-follower-modal-submit-button',
    });
  }

  /* istanbul ignore next */
  trackAddFollowerModalResult(isSuccess: boolean, follower: Follower, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.followersPage,
      name: 'add-follower-modal-result',
      interactionData: {
        isSuccess,
        thirdPartyId: follower?.thirdPartyId,
        requestId: follower?.requestId,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackRemoveFollowerClick({ thirdPartyId, requestId }: Follower): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.followersPage,
      name: 'remove-follower-button',
      interactionData: {
        thirdPartyId,
        requestId,
      },
    });
  }

  /* istanbul ignore next */
  trackRemoveFollowerModalSubmit({ thirdPartyId, requestId }: Follower): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.followersPage,
      name: 'remove-follower-modal-submit-button',
      interactionData: {
        thirdPartyId,
        requestId,
      },
    });
  }

  /* istanbul ignore next */
  trackRemoveFollowerModalCancel({ thirdPartyId, requestId }: Follower): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.followersPage,
      name: 'remove-follower-modal-cancel-button',
      interactionData: {
        thirdPartyId,
        requestId,
      },
    });
  }

  /* istanbul ignore next */
  trackRemoveFollowerModalResult(isSuccess: boolean, { thirdPartyId, requestId }: Follower, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.followersPage,
      name: 'remove-follower-modal-result',
      interactionData: {
        isSuccess,
        thirdPartyId,
        requestId,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackAddCertHolderAsFollower(isSuccess: boolean, thirdPartyId?: string, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.createCertificatePage,
      name: 'add-cert-holder-as-follower',
      interactionData: {
        isSuccess,
        thirdPartyId,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackRequestNotificationsForCertHolderResult(isSuccess: boolean, requestId?: string, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.requestChangesForm,
      name: 'request-notifications-for-cert-holder',
      interactionData: {
        isSuccess,
        requestId,
        errorReason,
        liveCertificateId: this.liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  trackDismissHolderNameSuggestions(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificatePage,
      name: 'dismiss-holder-name-suggestions',
      eventName: `create certificate - ${InteractionType.Click.toUpperCase()} dismiss holder name suggestions`,
    });
  }

  /* istanbul ignore next */
  trackSubmitHolderRequirementsFormFilling(holderRequirements: CertificateHolderRequirementPartialInformationSuggestion): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificatePage,
      name: 'submit-form-filling-with-certificate-holder-requirements',
      interactionData: {
        suggestion: holderRequirements,
      },
      eventName: `create certificate - ${InteractionType.Click.toUpperCase()} submit form filling with certificate holder requirements`,
    });
  }

  /* istanbul ignore next */
  trackDismissHolderRequirementsFormFilling(holderRequirements: CertificateHolderRequirementPartialInformationSuggestion): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificatePage,
      name: 'dismiss-form-filling-with-holder-requirements',
      interactionData: {
        suggestion: holderRequirements,
      },
      eventName: `create certificate - ${InteractionType.Click.toUpperCase()} dismiss form filling with holder requirements`,
    });
  }

  /* istanbul ignore next */
  trackSelectCertificateHolderRequirementSuggestion(holder: CertificateHolderRequirementPartialInformationSuggestion): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificatePage,
      name: 'select-certificate-holder-requirement-suggestion',
      interactionData: {
        suggestion: holder,
      },
      eventName: `create certificate - ${InteractionType.Click.toUpperCase()} holder name with requirements suggestion`,
    });
  }

  /* istanbul ignore next */
  trackSelectHolderNameGoogleSuggestion(holder: CertificateHolder | CertificateHolderRequirementPartialInformationSuggestion): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.createCertificatePage,
      name: 'select-holder-name-google-suggestion',
      interactionData: {
        suggestion: holder,
      },
      eventName: `create certificate - ${InteractionType.Click.toUpperCase()} holder name google suggestion`,
    });
  }

  /* istanbul ignore next */
  trackCertificateFormHelpModalView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'certificate-form-help-modal',
      name: 'modal-view',
      eventName: `create certificate - ${InteractionType.View.toUpperCase()} certificate form help modal`,
    });
  }

  /* istanbul ignore next */
  trackUploadSampleCertificateLoadingModalView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'upload-sample-certificate-loading-modal',
      name: 'modal-view',
      eventName: `upload sample certificate loading modal - ${InteractionType.View.toUpperCase()}`,
    });
  }

  /* istanbul ignore next */
  trackUploadSampleCertificateErrorModalView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'upload-sample-certificate-error-modal',
      name: 'modal-view',
      eventName: `upload sample certificate error modal - ${InteractionType.View.toUpperCase()}`,
    });
  }

  /* istanbul ignore next */
  trackSampleCertificateFileUploadClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'create-custom-certificate-options-page',
      name: 'sample-certificate-file-upload-button',
    });
  }

  /* istanbul ignore next */
  trackSampleCertificateFileSelected(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'create-custom-certificate-options-page',
      name: 'select-sample-certificate-file',
    });
  }

  /* istanbul ignore next */
  trackErrorModalCreateCertificateManuallyClicked(): void {
    this.trackCreateCertificateManuallyClicked('upload-sample-certificate-error-modal');
  }

  /* istanbul ignore next */
  trackLoadingModalCreateCertificateManuallyClicked(): void {
    this.trackCreateCertificateManuallyClicked('upload-sample-certificate-loading-page');
  }

  /* istanbul ignore next */
  trackOptionsModalCreateCertificateManuallyClicked(): void {
    this.trackCreateCertificateManuallyClicked('create-custom-certificate-options-page');
  }

  /* istanbul ignore next */
  private trackCreateCertificateManuallyClicked(placement: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement,
      name: 'create-certificate-manually-button',
      eventName: `${placement.replace(/-/g, ' ')} - ${InteractionType.Click.toUpperCase()} create certificate manually`,
    });
  }

  /* istanbul ignore next */
  trackUploadSampleCertificateErrorModalCancelClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'upload-sample-certificate-error-modal',
      name: 'cancel-button',
      eventName: `upload sample certificate error modal - ${InteractionType.Click.toUpperCase()} cancel button`,
    });
  }

  /* istanbul ignore next */
  trackTotalCoiAnalysisFlowTimeStarted(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'create-custom-certificate-options-page',
      name: 'total-coi-analysis-flow-time-started',
    });
  }

  /* istanbul ignore next */
  trackTotalCoiAnalysisFlowTimeEnded(isSuccess: boolean, duration: number): void {
    this.trackingService.track({
      interactionType: InteractionType.View,

      placement: 'create-custom-certificate-options-page',
      name: 'total-coi-analysis-flow-time-ended',
      interactionData: {
        isSuccess,
        duration,
      },
    });
  }

  private isRequestChangesFlow(): boolean {
    let isRequestChanges = null;
    this.store.pipe(select(certificatesSelectors.isRequestChangesFlow), first()).subscribe((requestChangesFlow: boolean) => {
      isRequestChanges = requestChangesFlow;
    });
    return isRequestChanges;
  }

  private getCertificateFormPlacement(): string {
    return this.isRequestChangesFlow() ? this.requestChangesForm : this.createCertificatePage;
  }

  private getReviewPagePlacement(): string {
    return this.isRequestChangesFlow() ? this.requestChangesReview : this.reviewCertificatePage;
  }

  /* istanbul ignore next */
  trackGenerateSampleCoiUploadURLResult(result: COIUploadUrlResponse, duration: number, isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'create-custom-certificate-options-page',
      name: 'generate-sample-coi-upload-url',
      interactionData: {
        result,
        duration,
        isSuccess,
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackUploadFileWithPresignedUrlResult(result: any, duration: number, isSuccess: boolean, httpStatusCode?: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'create-custom-certificate-options-page',
      name: 'upload-file-to-s3-with-presigned-url',
      interactionData: {
        result,
        duration,
        isSuccess,
        httpStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackSampleCoiUploadFileNotSupported(errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Error,
      placement: 'create-custom-certificate-options-page',
      name: 'sample-coi-upload-file-not-supported',
      interactionData: {
        errorReason,
      },
    });
  }

  /* istanbul ignore next */
  trackInitiateAnalysisRecommendationProcessResult(
    result: InitiateAnalysisRecommendationProcessResponse,
    duration: number,
    isSuccess: boolean,
    niStatusCode?: string,
  ): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'create-custom-certificate-options-page',
      name: 'initiate-analysis-recommendation-process',
      interactionData: {
        result,
        duration,
        isSuccess,
        niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackGetAnalysisRecommendationResult(
    result: CertificateAnalysisRecommendationResponse,
    duration: number,
    isSuccess: boolean,
    niStatusCode?: string,
  ): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'create-custom-certificate-options-page',
      name: 'get-analysis-recommendation',
      interactionData: {
        result,
        duration,
        isSuccess,
        niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackPreviewCustomCertificateDocView(isSuccess: boolean, certificateHolderRequirementId?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.reviewCertificatePage,
      name: 'preview-custom-certificate-doc-view',
      interactionData: {
        isSuccess,
        certificateHolderRequirementId,
      },
      eventName: `review certificate page - ${InteractionType.View.toUpperCase()} preview custom certificate doc view`,
    });
  }

  /* istanbul ignore next */
  trackPreviewCustomCertificateDocClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.reviewCertificatePage,
      name: 'preview-custom-certificate-doc-click',
      eventName: `review certificate page - ${InteractionType.Click.toUpperCase()} preview custom certificate doc click`,
    });
  }

  /* istanbul ignore next */
  trackCertificateLanguageClick(isRequestChangesFlow: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.reviewCertificatePage,
      name: 'certificate-language-click',
      interactionData: {
        isRequestChangesFlow,
        liveCertificateId: this.liveCertificateId,
      },
      eventName: `review certificate page - ${InteractionType.Click.toUpperCase()} certificate language`,
    });
  }

  /* istanbul ignore next */
  trackCertificateLanguageDialogView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.reviewCertificatePage,
      name: 'certificate-language-dialog-view',
      eventName: `review certificate page - ${InteractionType.View.toUpperCase()} certificate language dialog view`,
    });
  }

  /* istanbul ignore next */
  trackCertificateLanguageDialogApi(isSuccess: boolean, isRequestChangesFlow: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.reviewCertificatePage,
      name: 'certificate-language-dialog-api',
      interactionData: {
        isSuccess,
        isRequestChangesFlow,
        errorReason,
      },
      eventName: `review certificate page - ${InteractionType.Api.toUpperCase()} certificate language api`,
    });
  }

  /* istanbul ignore next */
  trackPreQuestionSaveAnswerClick(answer: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.preQuestionsPage,
      name: 'pre-question-save-answer-click',
      interactionData: {
        answer,
      },
      eventName: `pre question page - ${InteractionType.Click.toUpperCase()} save answer click`,
    });
  }

  private get liveCertificateId(): string {
    let liveCertificateId: string;
    this.store.pipe(select(certificatesSelectors.getLiveCertificateId), first()).subscribe((id) => {
      liveCertificateId = id;
    });
    return liveCertificateId;
  }

  trackHistoricalUserDocCenterClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.certificatesPage,
      name: 'historical-user-doc-center-click',
      eventName: `certificates page - ${InteractionType.Click.toUpperCase()} doc center link for historical user`,
    });
  }
}
