export enum FeatureFlags {
  // Permanent flags
  UrgentUserMessage = 'urgent_user_message',
  Onboarding = 'onboarding',
  SpecialOccasionWelcomeBanner = 'special_occasion_welcome_banner',
  LoginV2 = 'login_v2',
  HelpCenter = 'help_center',
  CHBGooglePlacesApi = 'chb_google_places_api',

  // Temporary flags
  MultiLocationsV2 = 'multi_locations_v2',
  BOP = 'bop_new_lob',
  AutoPoliciesCrossSell = 'auto_policies_cross_sell',
  SetupIntent = 'setup_intent',
  ReferralProgram = 'referral_program',
  AiChatbot = 'ai_chatbot',
  MultiLocationsNewFlowPhase1 = 'multi_locations_new_flow_phase1',
  FallbackHelpTip = 'fallback_help_tip',
  UpdateOwnersCoverageAlert = 'update_owners_coverage_alert',
}
