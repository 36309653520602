<ni-ach-failed-payment-notice *ngIf="achFailureCategory" [achFailureCategory]="achFailureCategory" data-test="ach-failed-payment-notice">
</ni-ach-failed-payment-notice>
<div class="payment-method-details-container p-sm_md">
  <ng-container
    [ngTemplateOutlet]="paymentMethodDetails.type === PaymentMethodType.CreditCard ? creditCardDetails : achDetails"
  ></ng-container>
</div>

<ng-template #creditCardDetails>
  <div class="payment-method-details">
    <img
      class="payment-method-icon"
      src="assets/rebrand/credit-card-icons/{{
        $any(mapCardTypeToIconSvg)[$any(paymentMethodDetails).creditCardDetails.cardType.toLowerCase()] || 'credit-card.svg'
      }}"
      alt="Credit card"
      data-test="card-icon"
    />
    <span *ngIf="$any(paymentMethodDetails).creditCardDetails.funding != NA" data-test="card-funding">{{
      $any(paymentMethodDetails).creditCardDetails.funding | titlecase
    }}</span>
    <span class="ending-digits-prefix">• • • •</span>
    <div class="payment-method-ending-digits" data-test="card-ending-digits">{{
      $any(paymentMethodDetails).creditCardDetails.cardEndingDigits
    }}</div>
    <img src="assets/rebrand/checkmark_circle-green.svg" class="check-icon" data-test="check-icon" alt="Check icon" />
  </div>
</ng-template>

<ng-template #achDetails>
  <div class="payment-method-details" [ngClass]="{ 'has-failure': !!achFailureCategory }">
    <img class="payment-method-icon" src="assets/rebrand/bank-account.svg" alt="Bank account" data-test="bank-icon" />
    <div class="bank-details">
      <div class="bank-name" data-test="bank-account-name">{{ $any(paymentMethodDetails).achDetails.bankAccount.bankName }}</div>
      <div>
        <span data-test="bank-account-type">{{ $any(paymentMethodDetails).achDetails.bankAccount.accountType | titlecase }}</span>
        <span class="ending-digits-prefix">• • • •</span>
        <span class="payment-method-ending-digits" data-test="bank-account-ending-digits">{{
          $any(paymentMethodDetails).achDetails.bankAccount.accountEndingDigits
        }}</span>
      </div>
    </div>
    <img *ngIf="achFailureCategory" src="/assets/rebrand/alert-red.svg" class="alert-icon" data-test="alert-icon" alt="Alert icon" />
    <img
      *ngIf="!achFailureCategory"
      src="assets/rebrand/checkmark_circle-green.svg"
      class="check-icon"
      data-test="check-icon"
      alt="Check icon"
    />
  </div>
  <ni-button-rb
    *ngIf="achFailureCategory === AchFailureCategory.BankAccountNotFound"
    (click)="onUpdatePaymentMethodClicked()"
    class="payment-failure-cta"
    data-test="update-payment-method-button"
    >{{ 'BILLING.UPDATE_PAYMENT_METHOD' | translate }}</ni-button-rb
  >
  <ni-button-rb
    *ngIf="achFailureCategory && achFailureCategory !== AchFailureCategory.BankAccountNotFound"
    (click)="openZendeskChat()"
    class="payment-failure-cta"
    data-test="chat-now-button"
    >{{ 'BILLING.CHAT_NOW' | translate }}</ni-button-rb
  >
</ng-template>
