import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ni-coverage-checkup-step',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageCheckupStepComponent {
  @Output() moveToNextStep = new EventEmitter<void>();
  @Output() moveToPreviousStep = new EventEmitter<void>();
}
