import { Inject, Injectable } from '@angular/core';
import { NiSurvicateService, SurveyTraits, SurvicateEventType, WINDOW, WindowSurvicate } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { Business } from '../../business/models/business.model';
import { businessSelectors } from '../../business/store/business.selectors';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { InteractionType } from '../models/interaction-type.enum';
import { UserInteractionsDataService } from './user-interactions.data.service';

@Injectable({
  providedIn: 'root',
})
export class SurvicateService {
  constructor(
    private store: Store<AppState>,
    private niSurvicate: NiSurvicateService,
    @Inject(WINDOW) private window: WindowSurvicate,
    private userInteractionsService: UserInteractionsDataService,
  ) {}

  init(trackingId: string): void {
    this.updateTraits({ trackingId });
    this.listenToSurveyDisplayedEvent();
  }

  updateTraitsByBusiness(business: Business): void {
    this.store
      .select(businessSelectors.getBusinessId)
      .pipe(first())
      .subscribe((businessId: string) => {
        this.updateTraits({
          email: business.emailAddress,
          businessId,
        });
      });
  }

  updateTraitsByPolicies(): void {
    this.store
      .select(policiesSelectors.isHistoricalUser)
      .pipe(first())
      .subscribe((isHistoricalUser: boolean) => {
        this.updateTraits({ isHistoricalUser: String(isHistoricalUser) });
      });
  }

  listenToSurveyDisplayedEvent(): void {
    this.niSurvicate.addEventListener(SurvicateEventType.SurveyDisplayed, (surveyId: string) => {
      const surveyData = this.window._sva.getSurvey(surveyId);
      this.userInteractionsService.sendInteraction({
        interactionType: InteractionType.Debug,
        placement: 'survicate',
        name: 'survey-displayed',
        interactionData: { ...surveyData },
      });
    });
  }

  private async updateTraits(traits: SurveyTraits): Promise<void> {
    try {
      await this.niSurvicate.setVisitorTraits(traits);
    } catch {}
  }
}
