import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RichMessagesUtilsService {
  static readonly SESSION_PARAM_SYMBOL = '$';
  static convertSessionParamToList(sessionParam: string): string[] {
    let stringArray = sessionParam?.replace(/ /g, '')?.split(',') || [];

    if (stringArray?.length) {
      if (!stringArray[0]?.length) {
        stringArray = [];
        // in case the given name did not correspond to a field in $session.params it will remain like that in the string,
        // so we remove it.
      } else if (stringArray[0].includes(RichMessagesUtilsService.SESSION_PARAM_SYMBOL)) {
        stringArray = [];
      }
    }

    return stringArray;
  }
}
