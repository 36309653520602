import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { select, Store } from '@ngrx/store';
import { finalize, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { businessSelectors } from '../../business/store/business.selectors';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { TAB_ID } from '../config/global-session-storage-items.config';
import { InteractionType } from '../models/interaction-type.enum';
import { eventNamePattern, UserInteractionParams } from '../models/user-interaction-params.model';
import { UserType } from '../models/user-type.enum';
import { coreActions } from '../store/core.actions';
import { coreSelectors } from '../store/core.selectors';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { GtmService } from './gtm.service';
import { MobileAppService } from './mobile-app.service';

@Injectable({
  providedIn: 'root',
})
export class UserInteractionsDataService {
  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private mobileAppService: MobileAppService,
    private gtmService: GtmService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}

  sendInteraction(data: UserInteractionParams, sendToGTM?: boolean): void {
    const interactionParams = { ...data, interactionTimestamp: Date.now() };
    this.store.dispatch(coreActions.setIsSubmittingUserInteraction({ isSubmittingUserInteraction: true }));
    if (this.shouldBuildEventName(interactionParams)) {
      interactionParams.eventName = this.buildEventName(interactionParams);
    }
    this.validateEventName(interactionParams.eventName);

    this.store
      .pipe(
        select(coreSelectors.getUserType),
        withLatestFrom(this.store.select(businessSelectors.getBusinessId), this.store.select(policiesSelectors.isHistoricalUser)),
        first(),
        map(([userType, businessId, isHistoricalUser]: [UserType, string, boolean]) => {
          interactionParams.interactionData = {
            data: interactionParams.interactionData,
            userType,
            isMobileWebview: this.mobileAppService.isMobileAppWebview(),
            tabId: this.sessionStorage.getItem(TAB_ID),
            ...(businessId && { businessId }),
            ...(businessId && { isHistoricalUser }),
            appName: 'Portal',
          };
          return interactionParams;
        }),
        tap((userInteractionsParams) => {
          if (EnvConfig.isDev()) {
            console.log(userInteractionsParams);
          }

          if (sendToGTM) {
            this.gtmService.sendUserInteractionAsGTMEvent(interactionParams);
          }
        }),
        switchMap((userInteractionParams) => this.httpClient.post('/api/public/tracking/create-user-interaction', userInteractionParams)),
        finalize(() => {
          this.store.dispatch(coreActions.setIsSubmittingUserInteraction({ isSubmittingUserInteraction: false }));
        }),
      )
      .subscribe();
  }

  private shouldBuildEventName(interactionParams: UserInteractionParams): boolean {
    return !(
      interactionParams.eventName ||
      interactionParams.excludeEventName ||
      interactionParams.interactionType === InteractionType.Debug
    );
  }

  private buildEventName(data: UserInteractionParams): string {
    const placement = this.removeHyphens(data.placement);
    const name = this.removeHyphens(data.name);
    const type = data.interactionType.toUpperCase();
    return `${placement} - ${type} ${name}`;
  }

  private removeHyphens(str: string): string {
    return str.replace(/-/g, ' ');
  }

  private validateEventName(eventName: string): void {
    if (eventName && !eventNamePattern.test(eventName)) {
      logger.error(`User interactions | wrong event name convention - ${eventName}`);
    }
  }
}
