import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { WINDOW } from '@next-insurance/ng-core';

import { LoginTrackingService } from '../../login/services/login-tracking.service';

export const oktaLoginCallbackGuard: CanActivateFn = (route) => {
  const loginTrackingService = inject(LoginTrackingService);
  const params = new URLSearchParams(route.queryParams);
  loginTrackingService.trackOktaLoginCallbackStart(params.toString());
  const { location } = inject(WINDOW);
  location.href = `${location.origin}/api/public/authentication/okta-login-callback?${params.toString()}`;
  return false;
};
