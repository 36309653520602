<div [loading]="isLoading$ | async" niSpinner>
  <div *ngIf="policies.length" [niHelpTip]="HelpTip.AddPolicy" class="container" data-test="cross-sell-policies-container">
    <ni-cross-sell-policies-title
      [isHistoricalUser]="isHistoricalUser"
      [policies]="policies"
      *ngIf="showTitle"
    ></ni-cross-sell-policies-title>
    <div data-test="cross-sell-policies" class="cross-sell-policies">
      <ni-cross-sell-policy
        *ngFor="let policy of policies | crossSellPoliciesOrder"
        [niHelpTip]="HelpTip.CrossSellNotification"
        [policy]="policy"
        class="cross-sell-policy"
        data-test="cross-sell-policy"
      >
      </ni-cross-sell-policy>
    </div>
  </div>
</div>
