import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ReinstateTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackOpenReinstateModal(lob: LOB): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'reinstate-agreement',
      name: 'reinstate-open-modal',
      interactionData: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  trackCloseReinstateModal(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'reinstate-agreement',
      name: 'reinstate-close-modal',
    });
  }

  /* istanbul ignore next */
  trackSubmitReinstate(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'reinstate-agreement',
      name: 'reinstate-agreement-submit',
    });
  }

  /* istanbul ignore next */
  trackReinstateResult(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'reinstate-agreement',
      name: 'reinstate-result',
      interactionData: {
        isSuccess,
      },
    });
  }
}
