import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';

import { Tab } from '../../../models/tab.model';

@Component({
  selector: 'ni-expandable-menu-item',
  templateUrl: './expandable-menu-item.component.html',
  styleUrls: ['./expandable-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          visibility: 'visible',
        }),
      ),
      state(
        'closed',
        style({
          visibility: 'hidden',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class ExpandableMenuItemComponent {
  @Input() tab: Tab;
  @Input() expanded = false;
  @Output() toggled = new EventEmitter<MouseEvent>();
  @Output() onClick = new EventEmitter<Tab>();
  @Output() closeOpenedMenu = new EventEmitter<void>();
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  routerLinkActive: boolean;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.tab.tabs) {
      this.routerLinkActive = this.isActiveRouterLink();
      this.expanded = this.routerLinkActive;
    }
  }

  toggle(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.expanded) {
      this.closeOpenedMenu.emit();
    }
    this.expanded = !this.expanded;
    this.toggled.emit(event);
  }

  onTabClick(tab: Tab): void {
    this.onClick.emit(tab);
  }

  closeExpand(): void {
    this.expanded = false;
    this.cdr.detectChanges();
  }

  isActiveRouterLink(): boolean {
    return !!this.tab.tabs.some((subTab: Tab) => this.router.url === subTab.routerLink);
  }
}
