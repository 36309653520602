import { ThirdPartyRequestAction, ThirdPartyRequestType } from '../../certificates/shared/models/third-party-request-type.enum';
import { BusinessStatus } from './business-status.enum';

export enum MobileAppEventId {
  CertificateCreation = 'CERTIFICATE_CREATION',
  CertificatesNavigation = 'CERTIFICATES_NAVIGATION',
  CompleteThirdPartyRequest = 'COMPLETE_THIRD_PARTY_REQUEST',
  BusinessDetailsChanged = 'BUSINESS_DETAILS_CHANGED',
  TechnicalError = 'TECHNICAL_ERROR',
  Back = 'BACK',
  HideWebViewLoader = 'HIDE_WEBVIEW_LOADER',
  SendOnNextLetterhead = 'SEND_ON_NEXT_LETTERHEAD',
  Logout = 'LOGOUT',
  RefreshAuthTokens = 'REFRESH_AUTH_TOKENS',
  LastFailedPaymentHandledEvent = 'LAST_FAILED_PAYMENT_HANDLED',
  BusinessStatusUpdate = 'BUSINESS_STATUS_UPDATE',
  OpenExternalBrowser = 'OPEN_OS_BROWSER',
  OpenPdfViewer = 'OPEN_PDF_VIEWER',
  SendEmailViaMailbox = 'SEND_EMAIL_VIA_MAILBOX',
}

export type OpenExternalBrowserEvent = {
  eventId: MobileAppEventId.OpenExternalBrowser;
  url: string;
};

export type OpenPdfViewerEvent = {
  eventId: MobileAppEventId.OpenPdfViewer;
  url: string;
  fileName: string;
};

export type SendEmailViaMailboxEvent = {
  eventId: MobileAppEventId.SendEmailViaMailbox;
  url: string;
};

export type CertificateCreationEvent = {
  eventId: MobileAppEventId.CertificateCreation;
  liveCertificateId: string;
};

export type RefreshAuthTokensEvent = {
  eventId: MobileAppEventId.RefreshAuthTokens;
};

export type TechnicalErrorEvent = {
  eventId: MobileAppEventId.TechnicalError;
  errorMessage?: string;
};

export type CompleteThirdPartyRequestEvent = {
  eventId: MobileAppEventId.CompleteThirdPartyRequest;
  isSuccess: boolean;
  requestId: string;
  requestType: ThirdPartyRequestType;
  requestAction: ThirdPartyRequestAction;
};

export type CertificateNavigationEvent = {
  eventId: MobileAppEventId.CertificatesNavigation;
};

export type BusinessDetailsChangedEvent = {
  eventId: MobileAppEventId.BusinessDetailsChanged;
};

export type LastFailedPaymentHandledEvent = {
  eventId: MobileAppEventId.LastFailedPaymentHandledEvent;
};

export type BusinessStatusUpdateEvent = {
  eventId: MobileAppEventId.BusinessStatusUpdate;
  businessStatus: BusinessStatus;
};

export type BackEvent = {
  eventId: MobileAppEventId.Back;
};

export type HideWebViewLoader = {
  eventId: MobileAppEventId.HideWebViewLoader;
};

export type MobileAppLogoutEvent = {
  eventId: MobileAppEventId.Logout;
};

export type SendCertificateOnNextLetterheadEvent = {
  eventId: MobileAppEventId.SendOnNextLetterhead;
  liveCertificateId: string;
  businessId: string;
};

export type MobileAppEvent =
  | TechnicalErrorEvent
  | CertificateCreationEvent
  | HideWebViewLoader
  | CompleteThirdPartyRequestEvent
  | CertificateNavigationEvent
  | BusinessDetailsChangedEvent
  | BusinessStatusUpdateEvent
  | BackEvent
  | MobileAppLogoutEvent
  | SendCertificateOnNextLetterheadEvent
  | RefreshAuthTokensEvent
  | LastFailedPaymentHandledEvent
  | OpenExternalBrowserEvent
  | OpenPdfViewerEvent
  | SendEmailViaMailboxEvent;

export type MobileAppCustomizedEvent = {
  closeWebview: boolean;
} & MobileAppEvent;
