import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { HelpTip } from '../../core/models/help-tip.enum';
import { IntroJsService } from '../../core/services/intro-js.service';

@Directive({
  selector: '[niHelpTip]',
  standalone: true,
})
export class HelpTipDirective implements OnInit {
  // TODO @shahar - change all the helpTip to arrays (NI-104180)
  @Input('niHelpTip') tips: HelpTip | HelpTip[];

  constructor(
    private elementRef: ElementRef,
    private introJsService: IntroJsService,
  ) {}

  ngOnInit(): void {
    if (!this.tips) {
      return;
    }

    const tipArray = Array.isArray(this.tips) ? this.tips : [this.tips];
    tipArray.forEach((tip) => this.addTipToElement(tip));
  }

  private addTipToElement(tip: HelpTip): void {
    this.introJsService.addStepElementFromDirective(tip, this.elementRef.nativeElement);
  }
}
