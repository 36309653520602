import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DateFormats, transformDate } from '@next-insurance/date';
import { fromZonedTime } from 'date-fns-tz';

import { chatbotConfig } from '../../../../configs/chatbot.config';
import { chatbotEventsConfig } from '../../../../configs/chatbot-events.config';
import { ChatbotParams } from '../../../../enums/chatbot-params.enum';
import { OutgoingMessage } from '../../../../models/outgoing-message.model';
import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ZonedDateTime } from '../../models/zoned-date-time.model';
import { RichMessagesService } from '../../services/rich-messages.service';

@Component({
  selector: 'ni-zoned-date-picker-rich-message',
  templateUrl: './zoned-date-picker-rich-message.component.html',
  styleUrls: ['./zoned-date-picker-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ZonedDatePickerRichMessageComponent {
  static readonly richComponentType = RichMessageType.ZonedDatePicker;
  private readonly currDate = new Date();
  readonly timezones = [
    { name: 'Eastern Time', value: 'America/New_York' },
    { name: 'Central Time', value: 'America/Chicago' },
    { name: 'Mountain Time', value: 'America/Denver' },
    { name: 'Pacific Time', value: 'America/Los_Angeles' },
    { name: 'Alaska Time', value: 'America/Anchorage' },
    { name: 'Hawaii Aleutian Time', value: 'America/Adak' },
  ];

  maxDate = this.currDate;
  minDate = new Date(chatbotConfig.chatbotDatePickerMinDate);
  startAt = this.currDate;
  selectedDate: Date;
  selectedTimeZone: string;
  timeZonesDropDownOpened = false;

  constructor(private richMessagesService: RichMessagesService) {}

  submitDateClicked(): void {
    const zonedDate: ZonedDateTime = this.getZonedDateTimeObject();
    const outgoingMessage = this.generateOutgoingMessage(zonedDate, this.selectedDate);
    this.richMessagesService.sendMessage(outgoingMessage);
  }

  generateOutgoingMessage(zonedDate: ZonedDateTime, displayDate: Date): OutgoingMessage {
    const longDateTime = transformDate(displayDate, DateFormats.SlashesDateTimeLong);
    const timeZoneName = this.getTimeZoneNameByValue(zonedDate.dateOfLossTimeZone);
    return {
      eventName: chatbotEventsConfig.submitDate,
      displayMessage: `${longDateTime} ${timeZoneName}`,
      additionalParams: { [ChatbotParams.DateOfLossZonedTime]: zonedDate },
    };
  }

  onTimezonesDropdownShow(): void {
    this.timeZonesDropDownOpened = true;
  }

  onTimezonesDropdownHide(): void {
    this.timeZonesDropDownOpened = false;
  }

  private getZonedDateTimeObject(): ZonedDateTime {
    const utcDateTime = fromZonedTime(this.selectedDate, this.selectedTimeZone);
    return {
      dateOfLossEpochTimeMs: utcDateTime.getTime(),
      dateOfLossTimeZone: this.selectedTimeZone,
    };
  }

  private getTimeZoneNameByValue(value: string): string {
    return this.timezones.find((item: { name: string; value: string }) => item.value === value)?.name;
  }
}
