import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SaveSurveyRequest } from '../models/save-survey-request.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  saveSurvey(saveSurveyRequest: SaveSurveyRequest): Observable<string> {
    return this.httpClient.post<string>('/api/chatbot/save-survey', { saveSurveyRequest });
  }
}
