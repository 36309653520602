import { Action, createReducer, on } from '@ngrx/store';

import { Policy } from '../../policies/models/policy.model';
import { reinstateActions } from './reinstate.actions';

export interface ReinstateState {
  isLoading: boolean;
  isSubmitting: boolean;
  reinstateCharge: number;
  reinstatePolicy: Policy;
  reinstateFlowSuccess: boolean;
}

export const reinstateInitialState: ReinstateState = {
  isLoading: false,
  isSubmitting: false,
  reinstateCharge: null,
  reinstatePolicy: null,
  reinstateFlowSuccess: false,
};

const reducer = createReducer(
  reinstateInitialState,
  on(reinstateActions.reinstateFetchCharge, (state, action) => ({
    ...state,
    isLoading: true,
    reinstatePolicy: action.policy,
  })),
  on(reinstateActions.reinstateSetCharge, (state, action) => ({
    ...state,
    reinstateCharge: action.oneTimeChargeAmount,
    isLoading: false,
  })),
  on(reinstateActions.reinstateRequestSubmit, reinstateActions.reinstateUpdateCardSubmit, (state) => ({
    ...state,
    isSubmitting: true,
  })),
  on(reinstateActions.reinstateFlowSuccess, (state) => ({
    ...state,
    reinstateFlowSuccess: true,
    isSubmitting: false,
  })),
  on(reinstateActions.reinstateFlowFailed, (state) => ({
    ...state,
    reinstateFlowSuccess: false,
    isSubmitting: false,
  })),
  on(reinstateActions.setSubmitting, (state) => ({
    ...state,
    isSubmitting: false,
  })),
);

export function reinstateReducer(state: ReinstateState, action: Action): ReinstateState {
  return reducer(state, action);
}
