import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { UpdatePersonalDetailsResponse } from '../../business/models/update-personal-details.model';
import { ContactInfoTrackingService } from '../../business/services/contact-info-tracking.service';
import { businessActions } from '../../business/store/business.actions';
import { MobileAppEventId } from '../../core/models/mobile-app-event.model';
import { MobileAppService } from '../../core/services/mobile-app.service';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { loginActions } from '../../login/store/login.actions';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { navigationActions } from '../../store/navigation.actions';
import { PermittedChanges, RichChangesType, RichPolicyChangesType } from '../models/business-change.enum';
import { BusinessChangeDataService } from '../services/business-change.data.service';
import { businessChangeActions } from './business-change.actions';

@Injectable()
export class BusinessChangeEffects {
  loadPermittedChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessChangeActions.loadPermittedChanges),
      switchMap(() =>
        this.businessChangeDataService.getPermittedChanges().pipe(
          filter((res: PermittedChanges) => !!res),
          map((permittedChanges: PermittedChanges) => businessChangeActions.setPermittedChanges({ permittedChanges })),
          catchErrorAndLog(() => of(businessChangeActions.setPermittedChanges(null))),
        ),
      ),
    ),
  );

  updateContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(businessChangeActions.updateContactInfo),
      switchMap((action) =>
        this.businessChangeDataService.updateContactInfo(action.contactInfoPersonalDetails).pipe(
          switchMap((res: UpdatePersonalDetailsResponse) => {
            const changeType: RichChangesType = res && res.status;
            this.contactInfoTrackingService.trackEditResult(true, action.updatedFields);
            if (changeType === RichPolicyChangesType.Email) {
              if (this.mobileAppService.canEditContactInfo()) {
                this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.Logout, closeWebview: true });
                return [
                  toastActions.showToast({
                    toastType: ToastType.Success,
                    message: 'BUSINESS_CHANGE.INFO_UPDATED',
                  }),
                ];
              }

              return [
                toastActions.showToast({ toastType: ToastType.Success, message: 'BUSINESS_CHANGE.INFO_UPDATED' }),
                loginActions.logout(),
              ];
            }
            return [
              businessActions.loadBusiness({
                successActions: [businessActions.loadIsEmailVerified(), businessActions.loadIsPhoneVerified()],
              }),
              toastActions.showToast({ toastType: ToastType.Success, message: 'BUSINESS_CHANGE.INFO_UPDATED' }),
              navigationActions.toBusinessPage(),
            ];
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode;
            this.contactInfoTrackingService.trackEditResult(false);
            return [
              navigationActions.toBusinessPage(),
              errorReason === 'EMAIL_ADDRESS_ALREADY_IN_USE'
                ? toastActions.showToast({
                    toastType: ToastType.Error,
                    message: 'EDIT_CONTACT_INFO.ERRORS.EMAIL_ADDRESS_ALREADY_IN_USE',
                  })
                : toastActions.showGeneralErrorToast(),
            ];
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private businessChangeDataService: BusinessChangeDataService,
    private contactInfoTrackingService: ContactInfoTrackingService,
    private mobileAppService: MobileAppService,
    private mobileAppEventsService: MobileAppEventsService,
  ) {}
}
