import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { chatbotConfig } from '../../../configs/chatbot.config';

@Component({
  selector: 'ni-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() loading: boolean;
  @Input() customClass: string;
  @Output() clicked = new EventEmitter();
  @Input() dataTest: string;
  @ViewChild('niButton', { static: true }) button: ElementRef;
  private isButtonDelayed = false;

  ngOnInit(): void {
    if (this.dataTest) {
      this.button.nativeElement.dataset.test = this.dataTest;
    }
  }

  onClick(): void {
    if (!this.isButtonDelayed) {
      this.clicked.emit();
    }
    this.delayButtonClick();
  }

  private delayButtonClick(): void {
    // We prevent the option for double click
    this.isButtonDelayed = true;
    setTimeout(() => {
      this.isButtonDelayed = false;
    }, chatbotConfig.chatbotEventDebounceTime);
  }
}
