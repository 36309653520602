import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { forbiddenWords } from '../../../certificates/shared/configs/forbidden-words.config';
import { CertificatesTrackingService } from '../../../certificates/shared/services/certificates-tracking.service';
import { certificatesActions } from '../../../certificates/shared/store/certificates.actions';
import { NiValidatorsService } from '../../../core/services/ni-validators.service';
import { AppState } from '../../../store';

@Component({
  selector: 'ni-create-follower-modal',
  templateUrl: './create-follower-modal.component.html',
  styleUrls: ['./create-follower-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFollowerModalComponent implements OnInit {
  followerForm: UntypedFormGroup;
  isSubmitting: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private certificatesTrackingService: CertificatesTrackingService,
  ) {}

  ngOnInit(): void {
    this.followerForm = this.fb.group({
      name: [
        '',
        [
          NiValidatorsService.personalNamePattern,
          NiValidatorsService.maxLengthShortInput,
          NiValidatorsService.notContains(forbiddenWords),
          Validators.required,
        ],
      ],
      emailAddress: ['', [NiValidatorsService.email, Validators.required]],
    });
    this.isSubmitting = false;
    this.followerForm.reset();
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.certificatesTrackingService.trackAddFollowerModalSubmit();
    this.store.dispatch(certificatesActions.createFollower({ request: this.followerForm.value }));
  }
}
