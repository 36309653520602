import { createAction, props } from '@ngrx/store';

import { ContactInfoPersonalDetails } from '../../business/models/contact-info-personal-details.model';
import { PermittedChanges } from '../models/business-change.enum';
import { BusinessChangeData } from '../models/business-change.model';

const loadPermittedChanges = createAction('[Business change API] Get business changes permissions');

const setPermittedChanges = createAction(
  '[Business change] Store business changes permissions',
  props<{ permittedChanges: PermittedChanges }>(),
);

const setBusinessChangeData = createAction(
  '[Business change] Store business change data',
  props<{ businessChangeData: BusinessChangeData }>(),
);

const updateContactInfo = createAction(
  '[Business change API] Update contact info',
  props<{ contactInfoPersonalDetails: ContactInfoPersonalDetails; updatedFields: string[] }>(),
);

export const businessChangeActions = {
  loadPermittedChanges,
  setPermittedChanges,
  setBusinessChangeData,
  updateContactInfo,
};
