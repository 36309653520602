import { HttpErrorResponse } from '@angular/common/http';

export function isAnyHttpErrorResponse(
  error: unknown,
): error is HttpErrorResponse | { rejection: HttpErrorResponse } | { message: string } | string {
  return (
    isHttpErrorResponse(error) ||
    isHttpErrorResponseRejection(error) ||
    isHttpErrorResponseMessage(error) ||
    isHttpErrorResponseString(error)
  );
}

export function isHttpErrorResponse(error: unknown): error is HttpErrorResponse {
  return !!(error instanceof HttpErrorResponse || (error as any)?.name === 'HttpErrorResponse');
}

export function isHttpErrorResponseRejection(error: unknown): error is { rejection: HttpErrorResponse } {
  return !!((error as any)?.rejection?.name === 'HttpErrorResponse');
}

export function isHttpErrorResponseMessage(error: unknown): error is { message: string } {
  return !!(error as any)?.message?.startsWith('Http failure response for ');
}

export function isHttpErrorResponseString(error: unknown): error is string {
  return !!(typeof error === 'string' && error.startsWith('Http failure response for '));
}
