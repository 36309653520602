import { createSelector, MemoizedSelector } from '@ngrx/store';

import { Policy } from '../../policies/models/policy.model';
import { AppState } from '../../store';
import { ReinstateState } from './reinstate.reducer';

const reinstateState = (state: AppState): ReinstateState => state.reinstate;

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(reinstateState, (state) => state.isLoading);
const isSubmitting: MemoizedSelector<AppState, boolean> = createSelector(reinstateState, (state) => state.isSubmitting);
const reinstateCharge: MemoizedSelector<AppState, number> = createSelector(reinstateState, (state) => state.reinstateCharge);
const reinstatePolicy: MemoizedSelector<AppState, Policy> = createSelector(reinstateState, (state) => state.reinstatePolicy);

export const reinstateSelectors = {
  reinstateState,
  isLoading,
  isSubmitting,
  reinstateCharge,
  reinstatePolicy,
};
