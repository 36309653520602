import { Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MissingTranslationKeyHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    const lastPartOfKey: string = params.key.split('.').slice(-1)[0];
    const defaultTranslation: string = (params.interpolateParams as any)?.default;

    if (defaultTranslation === undefined) {
      logger.error(`Translation service, missing key - ${params.key}`, null);
      return lastPartOfKey;
    }
    return defaultTranslation;
  }
}
