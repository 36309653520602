import { Inject, Injectable, Injector } from '@angular/core';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { first, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppState } from '../../store';
import { navigationConfig } from '../config/navigation.config';
import { LmsSerials } from '../models/lms-serials.enum';
import { MobileAppEventId } from '../models/mobile-app-event.model';
import { PortalRoutes } from '../models/portal-routes.enum';
import { coreSelectors } from '../store/core.selectors';
import { BrowserCompatabilityService } from './browser-compatability.service';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private injector: Injector,
    private browserCompatabilityService: BrowserCompatabilityService,
  ) {}

  navigateByLMS(serial: LmsSerials, openNewTab = false, params?: Record<string, any>): void {
    const link = `${environment.trackingUrl}/links?serial=${serial}${params ? `&${new URLSearchParams(params)}` : ''}`;
    this.navigateTo(link, openNewTab);
  }

  navigateTo(url: string, openNewTab = false, closeWebview = false): void {
    this.waitForUserInteractionAndDo(() => this.openUrl(url, openNewTab, closeWebview));
  }

  reload(): void {
    this.waitForUserInteractionAndDo(() => this.window.location.reload());
  }

  getRouteActionByName(routeName: string): any {
    const routeEnum: PortalRoutes = routeName as unknown as PortalRoutes;
    return navigationConfig.mapPortalRoutToNavigationAction[routeEnum];
  }

  waitForUserInteractionAndDo(callback: () => void): void {
    this.store
      .select(coreSelectors.isSubmittingUserInteraction)
      .pipe(
        first((isSubmittingUserInteraction: boolean) => !isSubmittingUserInteraction),
        tap(() => {
          callback();
        }),
      )
      .subscribe();
  }

  private openUrl(url: string, openNewTab: boolean, closeWebview: boolean): void {
    const isSafari = this.browserCompatabilityService.isSafari();

    if (openNewTab && this.injector.get(MobileAppService).isMobileAppWebview()) {
      // TODO: ni-81573 should be removed after mobile app will support closeWebview in OpenExternalBrowser event
      this.injector.get(MobileAppEventsService).notifyMobile({
        eventId: MobileAppEventId.Back,
        closeWebview,
      });

      this.injector.get(MobileAppEventsService).notifyMobile({
        eventId: MobileAppEventId.OpenExternalBrowser,
        url,
        closeWebview: false,
      });
    } else if (openNewTab && !isSafari) {
      this.window.open(url, '_blank');
    } else {
      this.window.location.href = url;
    }
  }
}
