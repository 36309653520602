import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AffiliateData } from '../../business/models/affiliate-data.model';
import { businessSelectors } from '../../business/store/business.selectors';
import { CrossSellOrigin } from '../../core/models/cross-sell-origin.enum';
import { AppState } from '../../store';
import {
  affiliateIdParamByLob,
  businessIdParamByLob,
  campaignParamByLob,
  channelParamByLob,
  crossSellOriginParamByCrossSellOrigin,
  externalCrossSellLinks,
  ExternalCrossSellTrackingParams,
  leadSourceParamByLob,
  trackingIdParamByLob,
} from '../config/external-cross-sell-links.config';
import { CrossSellChannelTypes } from '../models/cross-sell-channel-types.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalCrossSellService {
  externalCrossSellLink: string;

  constructor(
    private cookieService: CookieService,
    private store: Store<AppState>,
  ) {}

  /**
   * Returns external partner link(Also called form assembly) for cross sell
   * a doc where you can find all the params that can be passed in the link
   * https://docs.google.com/document/d/1jzFvY_QJKE4vU2Ga06SVmkW3Y5pv75DHCaWPJhU23nE/edit#bookmark=id.lnxum395q8cg
   * @private
   */
  getLink(lob: LOB, crossSellOrigin: CrossSellOrigin): string {
    if (!externalCrossSellLinks[lob]) {
      return null;
    }

    let affiliateData: AffiliateData;
    const trackingId = this.cookieService.get(environment.tidCookieName) || undefined;
    const cookiedUser = this.cookieService.get(environment.cookiedUserCookieName) || undefined;
    const channelParam = `?${channelParamByLob[lob]}=${CrossSellChannelTypes.NextDirectCrossSell}`;
    let businessId: string;
    this.store
      .select(businessSelectors.getBusinessId)
      .pipe(first())
      .subscribe((businessIdFromStore: string) => {
        businessId = businessIdFromStore;
      });

    this.store
      .select(businessSelectors.getBusinessAffiliateData)
      .pipe(first())
      .subscribe((data: AffiliateData) => {
        affiliateData = data;
      });

    this.externalCrossSellLink = `${externalCrossSellLinks[lob]}${affiliateData ? '' : channelParam}`;

    return this.constructCrossSellLink(lob, crossSellOrigin, trackingId, cookiedUser, businessId, affiliateData);
  }

  /**
   * Returns external partner link(Also called form assembly) for cross-sell
   * @private
   */
  private constructCrossSellLink(
    lob: LOB,
    crossSellOrigin: CrossSellOrigin,
    trackingId: string,
    cookiedUser: string,
    businessId: string,
    affiliateData: AffiliateData,
  ): string {
    if (trackingId) {
      this.externalCrossSellLink = `${this.externalCrossSellLink}&${trackingIdParamByLob[lob]}=${trackingId}`;
    }

    if (businessId) {
      this.externalCrossSellLink = `${this.externalCrossSellLink}&${businessIdParamByLob[lob]}=${businessId}`;
    }

    if (cookiedUser) {
      this.externalCrossSellLink = `${this.externalCrossSellLink}&${ExternalCrossSellTrackingParams.NEXT_INSURANCE_USER}=${cookiedUser}`;
    }

    if (affiliateData) {
      const affiliateLeadSourceParam = affiliateData.leadSource ? `&${leadSourceParamByLob[lob]}=${affiliateData.leadSource}` : '';
      const affiliateIdParam = affiliateData.affiliateId ? `&${affiliateIdParamByLob[lob]}=${affiliateData.affiliateId}` : '';
      // eslint-disable-next-line no-param-reassign
      crossSellOrigin = CrossSellOrigin.CrossSellAffiliatePartner;
      this.externalCrossSellLink = `${this.externalCrossSellLink}${affiliateLeadSourceParam}${affiliateIdParam}`;
    }
    return `${this.externalCrossSellLink}&${campaignParamByLob[lob]}=${crossSellOriginParamByCrossSellOrigin[crossSellOrigin]}`;
  }
}
