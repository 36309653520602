import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NiCoreModule, SentryModule } from '@next-insurance/ng-core';
import { ChatButtonComponent, ChatComponent } from '@next-insurance/ni-chat';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxZendeskWebwidgetModule } from '@nitsanzo/ngx-zendesk-webwidget';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastModule } from 'primeng/toast';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ChatbotModule } from './chatbot/chatbot.module';
import { ZendeskWidgetConfig } from './core/config/zendesk-widget.config';
import { CoreModule } from './core/core.module';
import { MissingTranslationKeyHandler } from './core/handlers/missing-translation-key.handler';
import { CoverageCheckupModule } from './coverage-checkup/coverage-checkup.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { NiHttpLoaderFactory } from './shared/misc/ni-translate-loader';
import { effects, metaReducers, reducers } from './store';

const disableAnimations =
  // If the animate method doesn't exist on documentElement, or it's iphone 13 or less, don't show animations
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

export const appModules = [
  ToastModule,
  BrowserModule,
  BrowserAnimationsModule.withConfig({ disableAnimations }),
  HttpClientModule,
  FontAwesomeModule,
  OnboardingModule,
  CoverageCheckupModule,
  ChatbotModule,
  TranslateModule.forRoot({
    missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationKeyHandler },
    useDefaultLang: false,
    loader: {
      provide: TranslateLoader,
      useFactory: NiHttpLoaderFactory,
      deps: [HttpClient],
    },
    extend: true,
    isolate: false,
  }),
  AppRoutingModule,
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: !environment.production,
      strictActionImmutability: !environment.production,
      strictActionSerializability: !environment.production,
      strictStateSerializability: !environment.production,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    routerState: RouterState.Minimal,
  }),
  EffectsModule.forRoot(effects),
  NgxCaptchaModule,
  CoreModule,
  NiCoreModule,
  NgxZendeskWebwidgetModule.forRoot(ZendeskWidgetConfig),
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: false,
    registrationStrategy: 'registerImmediately',
  }),
  SentryModule.forRoot(),
  ChatComponent,
  ChatButtonComponent,
  LoaderComponent,
];

// StoreDevtoolsModule.instrument should run only in dev env since it
// continuously trigger CD and hurt runtime performance
if (!environment.production || location?.search.includes('enableDebug')) {
  appModules.push(
    StoreDevtoolsModule.instrument({
      maxAge: environment.production ? 25 : false, // Retains last 25 states for prod only
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  );
}
