import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactInfoPersonalDetails } from '../../business/models/contact-info-personal-details.model';
import { UpdatePersonalDetailsResponse } from '../../business/models/update-personal-details.model';
import {
  PermittedChanges,
  RichChangesType,
  RichDirectBusinessChangeType,
  RichDirectPolicyChangeType,
  RichPolicyChangesType,
  SubmitChangeResponse,
} from '../models/business-change.enum';
import { BusinessChangeResponse, BusinessChangeSimulationResult } from '../models/business-change.model';
import { SimulationRequest } from '../models/business-change-request.model';
import { BusinessChangeSimulationResponse } from '../models/business-change-simulation.model';
import { DirectChangeData } from '../models/direct-change-data.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessChangeDataService {
  readonly businessChangeApi = 'api/business-change';
  readonly policyChangeApi = 'api/policy-change';

  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getPermittedChanges(): Observable<PermittedChanges> {
    return this.httpClient.get<PermittedChanges>(`${this.businessChangeApi}/permitted-changes`);
  }

  simulateChange(simulationRequest: SimulationRequest): Observable<BusinessChangeSimulationResponse> {
    return this.isPolicyChangeType(simulationRequest.changeType)
      ? this.simulatePolicyChangeV2(simulationRequest)
      : this.simulateBusinessChangeV2(simulationRequest);
  }

  startChangeFlow(changeType: RichChangesType, policyId?: number): Observable<BusinessChangeResponse> {
    return this.isPolicyChangeType(changeType)
      ? this.startPolicyChangeFlow(changeType, policyId)
      : this.startBusinessChangeFlow(changeType);
  }

  simulateChangeAfterQuestionnaire(
    flowId: string,
    changeType: RichChangesType,
    policyId?: number,
  ): Observable<BusinessChangeSimulationResponse> {
    return this.isPolicyChangeType(changeType)
      ? this.simulatePolicyChangeAfterQuestionnaireV2(flowId, policyId)
      : this.simulateBusinessChangeAfterQuestionnaireV2(flowId);
  }

  submitChange(flowId: string, changeType: RichChangesType, policyId?: number): Observable<SubmitChangeResponse> {
    return this.isPolicyChangeType(changeType) ? this.submitPolicyChange(flowId, policyId) : this.submitBusinessChange(flowId);
  }

  /* istanbul ignore next */
  updateContactInfo(data: ContactInfoPersonalDetails): Observable<UpdatePersonalDetailsResponse> {
    return this.httpClient.post<UpdatePersonalDetailsResponse>(`${this.businessChangeApi}/update-contact`, data);
  }

  /* istanbul ignore next */
  getDirectChangeData<T extends DirectChangeData>(changeType: RichDirectPolicyChangeType, policyId: number): Observable<T> {
    return this.httpClient.get<T>(`${this.policyChangeApi}/direct-change-data`, {
      params: {
        changeType,
        policyId,
      },
    });
  }

  /* istanbul ignore next */
  getBusinessDirectChangeData<T extends DirectChangeData>(changeType: RichDirectBusinessChangeType): Observable<T> {
    return this.httpClient.get<T>(`${this.businessChangeApi}/direct-change-data`, {
      params: {
        changeType,
      },
    });
  }

  /* istanbul ignore next */
  private startBusinessChangeFlow(changeType: RichChangesType): Observable<BusinessChangeResponse> {
    return this.httpClient.post<BusinessChangeResponse>(`${this.businessChangeApi}/start-change-flow`, { changeType });
  }

  /* istanbul ignore next */
  private startPolicyChangeFlow(changeType: RichChangesType, policyId: number): Observable<BusinessChangeResponse> {
    return this.httpClient.post<BusinessChangeResponse>(`${this.policyChangeApi}/start-change-flow`, {
      policyId,
      changeType,
    });
  }

  /* istanbul ignore next */
  private submitBusinessChange(flowId: string): Observable<SubmitChangeResponse> {
    return this.httpClient.put<SubmitChangeResponse>(`${this.businessChangeApi}/submit`, { flowId });
  }

  /* istanbul ignore next */
  private submitPolicyChange(flowId: string, policyId: number): Observable<SubmitChangeResponse> {
    return this.httpClient.put<SubmitChangeResponse>(`${this.policyChangeApi}/submit`, { policyId, flowId });
  }

  private isPolicyChangeType(changeType: RichChangesType): boolean {
    return (Object.values(RichPolicyChangesType) as string[])
      .concat(Object.values(RichDirectPolicyChangeType) as string[])
      .includes(changeType as any);
  }

  /* istanbul ignore next */
  private simulateBusinessChangeAfterQuestionnaireV2(flowId: string): Observable<BusinessChangeSimulationResponse> {
    return this.httpClient.get<BusinessChangeSimulationResult>(`${this.businessChangeApi}/simulate-after-questionnaire-v2`, {
      params: new HttpParams().append('flowId', flowId),
    });
  }

  /* istanbul ignore next */
  private simulatePolicyChangeAfterQuestionnaireV2(flowId: string, policyId: number): Observable<BusinessChangeSimulationResponse> {
    return this.httpClient.get<BusinessChangeSimulationResult>(`${this.policyChangeApi}/simulate-after-questionnaire-v2`, {
      params: new HttpParams().append('policyId', policyId.toString()).append('flowId', flowId),
    });
  }

  /* istanbul ignore next */
  private simulateBusinessChangeV2(simulationRequest: SimulationRequest): Observable<BusinessChangeSimulationResponse> {
    return this.httpClient.post<BusinessChangeSimulationResult>(`${this.businessChangeApi}/simulate-v2`, simulationRequest);
  }

  /* istanbul ignore next */
  private simulatePolicyChangeV2(simulationRequest: SimulationRequest): Observable<BusinessChangeSimulationResponse> {
    return this.httpClient.post<BusinessChangeSimulationResponse>(`${this.policyChangeApi}/simulate-v2`, simulationRequest);
  }
}
