<div class="credit-card-container">
  <img
    *ngFor="let card of creditCardImages"
    [alt]="card"
    class="card-icon"
    [ngClass]="{ opacity5: isCardImageDisabled(card) }"
    src="assets/rebrand/credit-card-icons/{{ card }}.svg"
    [attr.data-test]="'card-icon-' + card"
  />
</div>
