import { KeyValue, KeyValuePipe } from '@angular/common';
import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValueKeepOrder',
  standalone: true,
})
export class KeyValueKeepOrderPipe implements PipeTransform {
  keepOrder = (): number => 0;
  reverseOrder = (): number => -1;

  constructor(public differs: KeyValueDiffers) {}

  transform(value: { [key: string]: any }, reverse?: boolean): Array<KeyValue<string, any>> {
    return new KeyValuePipe(this.differs).transform(value, reverse ? this.reverseOrder : this.keepOrder);
  }
}
