import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NIStringHelper } from '@next-insurance/utils';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';

import { ThirdPartyRequestAction, ThirdPartyRequestType } from '../../certificates/shared/models/third-party-request-type.enum';
import { InteractionType } from '../models/interaction-type.enum';
import { MobileAppCustomizedEvent, MobileAppEvent, MobileAppEventId } from '../models/mobile-app-event.model';
import { REACT_NATIVE_WEBVIEW } from '../tokens/react-native-webview.token';
import { MobileAppService } from './mobile-app.service';
import { NavigationService } from './navigation.service';
import { TrackingService } from './tracking.service';
import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class MobileAppEventsService {
  private readonly webviewLandingPagesPatterns = [
    /\/certificates\/create-certificate$/,
    /\/certificates\/followers/,
    /\/certificates\/third-party-requests\/notifications/,
    /\/certificates\/third-party-requests\/changes/,
    /\/certificates\/live-certificate/,
    /\/coverage\//,
  ];

  constructor(
    private translateService: TranslateService,
    private location: Location,
    private userAgentService: UserAgentService,
    private navigationService: NavigationService,
    private trackingService: TrackingService,
    private mobileAppService: MobileAppService,
    @Inject(REACT_NATIVE_WEBVIEW) private reactNativeWebView: any,
  ) {}

  notifyMobileAndBreakFlowIfNeeded<T extends MobileAppEvent>(event: T, forceNotify = true): Observable<T> {
    if (!this.mobileAppService.isMobileAppWebview()) {
      return of(event);
    }

    const customizedEvent = this.customizeEvent(event);
    if (forceNotify || customizedEvent.closeWebview) {
      this.notifyMobile(customizedEvent);
    }
    return customizedEvent.closeWebview ? EMPTY : of(event);
  }

  notifyMobile(event: MobileAppCustomizedEvent): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.trackingService.track({
        interactionType: InteractionType.Debug,
        placement: 'general',
        name: 'mobile-app-event',
        interactionData: {
          ...event,
        },
      });
      this.navigationService.waitForUserInteractionAndDo(() => this.postMessage(event));
    }
  }

  shouldSendPaymentRefreshEvent(): boolean {
    return this.mobileAppService.isMobileAppWebview() && this.mobileAppService.isVersionGreaterOrEqual('1.1.4');
  }

  isHideLoaderEnabled(): boolean {
    return this.mobileAppService.isVersionGreaterOrEqual('1.0.3') && this.mobileAppService.isMobileAppWebview();
  }

  sendHideLoaderEvent(): void {
    if (this.isHideLoaderEnabled()) {
      this.notifyMobile({ eventId: MobileAppEventId.HideWebViewLoader, closeWebview: false });
    }
  }

  redirectToDownloadApp(): void {
    this.notifyMobile({
      eventId: MobileAppEventId.OpenExternalBrowser,
      url: this.userAgentService.isAndroid()
        ? 'https://play.google.com/store/apps/details?id=com.nextinsurance'
        : 'https://apps.apple.com/us/app/next-insurance/id1580721755',
      closeWebview: true,
    });
  }

  private postMessage(event: MobileAppCustomizedEvent): void {
    this.reactNativeWebView.postMessage(JSON.stringify(event));
  }

  private customizeEvent(event: MobileAppEvent): MobileAppCustomizedEvent {
    let customizedEvent: MobileAppCustomizedEvent;
    switch (event.eventId) {
      case MobileAppEventId.TechnicalError: {
        customizedEvent = { ...event, closeWebview: true };
        customizedEvent.errorMessage = this.translateService.instant(event.errorMessage || 'GENERAL.ERRORS.TOAST.HEADER');
        break;
      }
      case MobileAppEventId.CompleteThirdPartyRequest: {
        customizedEvent = {
          ...event,
          closeWebview: event.requestType === ThirdPartyRequestType.Changes || event.requestAction === ThirdPartyRequestAction.Deny,
        };
        break;
      }
      case MobileAppEventId.Back: {
        const currentPath = NIStringHelper.getBefore(this.location.path(), '?');
        customizedEvent = {
          ...event,
          closeWebview: this.webviewLandingPagesPatterns.some((landingPagePattern) => currentPath.match(landingPagePattern)),
        };
        break;
      }
      default: {
        customizedEvent = { ...event, closeWebview: true };
      }
    }
    return customizedEvent;
  }
}
