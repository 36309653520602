import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { AppState } from '../../store';
import { NavigationService } from './navigation.service';
import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class MobileAppService {
  constructor(
    private cookieService: CookieService,
    private userAgentService: UserAgentService,
    private store: Store<AppState>,
    private navigationService: NavigationService,
  ) {}

  initNavigationFunction(): void {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.navigateTo = (navigationData: { page: string; queryParams?: Record<string, any> }) => {
        this.store.dispatch(this.navigationService.getRouteActionByName(navigationData.page)({ ...navigationData.queryParams }));
      };
    }
  }

  isMobileAppWebview(): boolean {
    return this.cookieService.get(environment.mobileAppWebviewCookieName) === 'true';
  }

  isDownloadAppEnabled(): boolean {
    return this.userAgentService.isAndroid() || this.userAgentService.isIos();
  }

  downloadApp(): void {
    this.navigationService.navigateTo('https://nextinsurance.page.link/wT16');
  }

  isVersionGreaterOrEqual(mobileVersion: string): boolean {
    return (
      this.cookieService
        .get(environment.mobileAppVersionCookieName)
        .localeCompare(mobileVersion, undefined, { numeric: true, sensitivity: 'base' }) >= 0
    );
  }

  canEditContactInfo(): boolean {
    return this.isMobileAppWebview() && this.isVersionGreaterOrEqual('1.0.4');
  }
}
