import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { UpdatePersonalDetailsResponse } from '../../../../../../business/models/update-personal-details.model';
import { businessSelectors } from '../../../../../../business/store/business.selectors';
import { BusinessChangeDataService } from '../../../../../../business-change/services/business-change.data.service';
import { ZendeskIssueTag } from '../../../../../../core/models/zendesk-issue-tag.enum';
import { NiValidatorsService } from '../../../../../../core/services/ni-validators.service';
import { ZendeskService } from '../../../../../../core/services/zendesk.service';
import { AppState } from '../../../../../../store';
import { OnboardingTrackingService } from '../../../../../services/onboarding-tracking.service';
import { OnboardingEditIdentityComponent } from '../onboarding-edit-identity.component';

@Component({
  selector: 'ni-onboarding-edit-phone',
  templateUrl: './onboarding-edit-phone.component.html',
  styleUrls: ['../onboarding-edit-identity.component.scss', '../../../onboarding-step/onboarding-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingEditPhoneComponent extends OnboardingEditIdentityComponent implements OnInit {
  constructor(
    protected store: Store<AppState>,
    protected changeDetectorRef: ChangeDetectorRef,
    protected businessChangeDataService: BusinessChangeDataService,
    private fb: UntypedFormBuilder,
    private onboardingTrackingService: OnboardingTrackingService,
    private zendeskService: ZendeskService,
  ) {
    super(store, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getEditIdentityForm(): UntypedFormGroup {
    return this.fb.group({
      phoneNumber: ['', [Validators.required, NiValidatorsService.phonePattern]],
    });
  }

  getIdentityValue$(): Observable<string> {
    return this.store.select(businessSelectors.getPhoneNumber);
  }

  trackEditIdentityCancelClicked(): void {
    this.onboardingTrackingService.trackEditPhoneCancelClicked();
  }

  trackEditIdentitySubmitClicked(): void {
    this.onboardingTrackingService.trackEditPhoneSubmitClicked();
  }

  trackEditIdentitySubmitResult(isSuccess: boolean, niStatusCode?: string): void {
    this.onboardingTrackingService.trackEditPhoneSubmitResult(isSuccess, niStatusCode);
  }

  updateContactInfo(): Observable<UpdatePersonalDetailsResponse> {
    return this.store.select(businessSelectors.getEmailAddress).pipe(
      take(1),
      switchMap((emailAddress) => {
        const contactInfo = {
          emailAddress,
          ...this.editIdentityForm.value,
        };
        return this.businessChangeDataService.updateContactInfo(contactInfo);
      }),
    );
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.ChatPhoneVerificationIssue]);
  }
}
