import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { OnboardingStep } from '../models/onboarding-step.enum';

@Injectable()
export class OnboardingTrackingService {
  placement = 'onboarding';
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackOnboardingOpened(remainingSteps: OnboardingStep[]): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'onboarding-opened',
      interactionData: {
        remainingSteps,
      },
      eventName: 'onboarding - VIEW onboarding modal',
    });
  }

  /* istanbul ignore next */
  trackOnboardingCompleted(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'onboarding-completed',
      eventName: 'onboarding - VIEW onboarding completed',
    });
  }

  /* istanbul ignore next */
  trackOnboardingAborted(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'onboarding-aborted',
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'landing-email-verification',
    });
  }

  /* istanbul ignore next */
  trackDownloadAppLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'landing-download-app',
    });
  }

  /* istanbul ignore next */
  trackAmazonSellerInformation(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'amazon-seller-information',
      eventName: 'onboarding - VIEW amazon seller information',
    });
  }

  /* istanbul ignore next */
  trackAmazonClickViewCertificate(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'amazon-seller-information',
      eventName: 'onboarding - CLICK amazon seller information view certificate button',
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationSendCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'email-verification-send-token-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationValidateCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'email-verification-validate-token-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationResendCodeClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'email-verification-resend-token-button',
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationResendCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'email-verification-resend-token-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackEmailVerificationEditEmailClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'email-verification-edit-email-button',
    });
  }

  /* istanbul ignore next */
  trackEditEmailSubmitClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'edit-email-submit-button',
    });
  }

  /* istanbul ignore next */
  trackEditEmailCancelClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'edit-email-cancel-button',
    });
  }

  /* istanbul ignore next */
  trackEditEmailSubmitResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'edit-email-submit-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
    });
  }

  /* istanbul ignore next */
  trackDownloadApp(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'download-app-button',
    });
  }

  /* istanbul ignore next */
  trackSkipDownloadApp(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'download-app-skip-button',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationValidateCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'phone-verification-validate-code-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
      eventName: 'onboarding - API phone verification validate code result',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationSendCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'phone-verification-send-code-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
      eventName: 'onboarding - API phone verification send code result',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationResendCodeResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'phone-verification-resend-code-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
      eventName: 'onboarding - API phone verification resend code result',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationResendCodeClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'phone-verification-resend-code-button',
      eventName: 'onboarding - CLICK phone verification resend code button',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationEditPhoneClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'phone-verification-edit-phone-button',
      eventName: 'onboarding - CLICK phone verification edit phone button',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: this.placement,
      name: 'phone-verification-landing',
      eventName: 'onboarding - VIEW phone verification landing',
    });
  }

  /* istanbul ignore next */
  trackPhoneVerificationSkippedClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'phone-verification-skip-button',
      eventName: 'onboarding - CLICK phone verification skip button',
    });
  }

  /* istanbul ignore next */
  trackEditPhoneSubmitClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'edit-phone-submit-button',
      eventName: 'onboarding - CLICK edit phone submit button',
    });
  }

  /* istanbul ignore next */
  trackEditPhoneSubmitResult(isSuccess: boolean, niStatusCode: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: this.placement,
      name: 'edit-phone-submit-result',
      interactionData: {
        isSuccess,
        errorReason: niStatusCode,
      },
      eventName: 'onboarding - API edit phone submit result',
    });
  }

  /* istanbul ignore next */
  trackEditPhoneCancelClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.placement,
      name: 'edit-phone-cancel-button',
      eventName: 'onboarding - CLICK edit phone cancel button',
    });
  }
}
