export const forbiddenWords = [
  'Insured',
  'Waiver',
  'Subrogation',
  'Non-Contributory',
  'Non Contributory',
  'Rented Property',
  'Loss Payee',
  'Rented Equipment',
  'Leased Equipment',
  'Liability',
  'Coverage',
  'Inland Marine',
  'Rights to Recovery',
  'Claims',
  'Per Project',
  'Worker’s Comp',
  'Workers Comp',
  'Workman’s Comp',
  'Workmans Comp',
  'Commercial Auto',
  'Policyholder',
  'Future Operations',
  'ongoing operations',
  'completed operations',
];
