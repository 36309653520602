<ng-container *ngIf="wcAudits$ | async as wcAudits">
  <ng-container *ngIf="wcAuditCase$ | async as wcAuditCase">
    <ni-alert-banner data-test="wc-audit-banner-container" [attr.data-banner-type]="wcAuditCase" *ngIf="selectedBannerConfig">
      <div banner-body class="banner-body">
        <div class="title" data-test="title" [innerHTML]="'WC_AUDIT.BANNER.TYPES.' + wcAuditCase + '.TITLE' | translate"></div>
        <div
          class="message"
          data-test="wc-banner-message"
          [innerHTML]="
            'WC_AUDIT.BANNER.TYPES.' + wcAuditCase + '.MESSAGE' | translate: { auditDeadline: wcAudits[0].auditDeadline | localDate }
          "
        ></div>
      </div>
      <div banner-actions class="banner-actions">
        <ni-button-rb
          [buttonType]="ButtonType.Tertiary"
          data-test="banner-action"
          (click)="selectedBannerConfig.onClick && selectedBannerConfig.onClick()"
          [disabled]="!selectedBannerConfig.onClick"
          [attr.data-type]="selectedBannerConfig.translateKey"
        >
          <div class="banner-action">
            <img *ngIf="selectedBannerConfig.icon" [src]="selectedBannerConfig.icon" data-test="banner-action-icon" alt="icon" />
            {{ 'WC_AUDIT.BANNER.ACTIONS.' + selectedBannerConfig.translateKey | translate }}
          </div>
        </ni-button-rb>
      </div>
    </ni-alert-banner>
  </ng-container>
</ng-container>
