import { Action, createReducer, on } from '@ngrx/store';

import { AuthErrorStatuses } from '../models/auth-error-status.enum';
import { VerificationCodeDeliveryMethod } from '../models/verification-code-delivery-method.enum';
import { loginActions } from './login.actions';

export interface LoginState {
  loading: boolean;
  loginInput?: string;
  verificationCodeDeliveryMethods?: VerificationCodeDeliveryMethod[];
  errorReason?: AuthErrorStatuses;
}

export const loginInitialState: LoginState = {
  loading: false,
};

const reducer = createReducer(
  loginInitialState,
  on(loginActions.authenticationSubmit, (state, action) => ({
    ...state,
    loginInput: action.loginInput,
    errorReason: null,
    loading: true,
  })),
  on(loginActions.redirectToGoogleLogin, (state) => ({
    ...state,
    errorReason: null,
    loading: true,
  })),
  on(loginActions.setLoading, (state, action) => ({
    ...state,
    errorReason: null,
    loading: action.isLoading,
  })),
  on(loginActions.verificationSubmit, loginActions.resendCode, (state) => ({
    ...state,
    errorReason: null,
    loading: true,
  })),
  on(loginActions.authenticationSuccess, (state, action) => ({
    ...state,
    verificationCodeDeliveryMethods: action.response.verificationCodeDeliveryMethods,
    errorReason: null,
    loading: false,
  })),
  on(loginActions.resendCodeSuccess, (state) => ({
    ...state,
    errorReason: null,
    loading: false,
  })),
  on(loginActions.verificationSuccess, () => ({
    ...loginInitialState,
  })),
  on(loginActions.authenticationFailed, loginActions.verificationFailed, (state, action) => ({
    ...state,
    errorReason: action.errorReason,
    loading: false,
  })),
);

export function loginReducer(state: LoginState, action: Action): LoginState {
  return reducer(state, action);
}
