import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { InteractionType } from '../models/interaction-type.enum';
import { MlTokenErrorReason } from '../models/ml-token-error-reason.enum';
import { NavigationSubTab, NavigationTab } from '../models/navigation-tab.enum';
import { PWAService } from './pwa.service';
import { TrackingService } from './tracking.service';
import { VersionDataService } from './version.data.service';

@Injectable({
  providedIn: 'root',
})
export class CoreTrackingService {
  readonly sessionDataEventSentStorageKey = 'isSessionDataEventSent';

  constructor(
    private trackingService: TrackingService,
    private pwaService: PWAService,
    private deviceDetectorService: DeviceDetectorService,
    private versionService: VersionDataService,
  ) {}

  trackSessionData(queryParams: { [key: string]: string }): void {
    if (!sessionStorage.getItem(this.sessionDataEventSentStorageKey)) {
      sessionStorage.setItem(this.sessionDataEventSentStorageKey, '1');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { os, device, browser, os_version, browser_version } = this.deviceDetectorService;
      this.versionService.fetchAppVersion().subscribe(({ version: appVersion }) => {
        this.trackingService.track({
          interactionType: InteractionType.View,
          placement: 'session-data',
          name: 'session-data',
          interactionData: {
            ...queryParams,
            isPwaMode: this.pwaService.isPwaMode(),
            appVersion,
            deviceInfo: {
              os,
              device,
              browser,
              osVersion: os_version,
              browserVersion: browser_version,
            },
          },
        });
      });
    }
  }

  /* istanbul ignore next */
  trackAuthenticateAfterPurchase(): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: 'authentication',
      name: 'after-purchase',
    });
  }

  trackAuthenticateWithMlToken(errorReason?: MlTokenErrorReason): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: 'authentication',
      name: 'magic-link-token',
      interactionData: {
        result: errorReason || 'SUCCESS',
      },
    });
  }

  /* istanbul ignore next */
  trackHelpButtonClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'header',
      name: 'help-button-click',
    });
  }

  /* istanbul ignore next */
  trackLogoutClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'header',
      name: 'logout-click',
      eventName: 'header - CLICK logout',
    });
  }

  trackReferralProgramClick(placement: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement,
        name: 'referral-program-click',
        eventName: `${placement} - CLICK referral program`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackLogoClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'header',
      name: 'logo-click',
      eventName: 'header - CLICK next logo',
    });
  }

  /* istanbul ignore next */
  trackNavigationThroughTabs(tab: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'navigation-tabs',
      name: 'tab-clicked',
      interactionData: {
        tab,
      },
    });
  }

  /* istanbul ignore next */
  trackNavigationThroughMenu(option: NavigationTab | NavigationSubTab): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'navigation-menu',
      name: 'option-clicked',
      interactionData: {
        option,
      },
    });
  }

  /* istanbul ignore next */
  trackFirstPageLoad(url: string, timing: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: url,
      name: 'first-page-load',
      interactionData: {
        timing,
      },
      excludeEventName: true,
    });
  }

  /* istanbul ignore next */
  trackNavigation(url: string, timing: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: url,
      name: 'navigation',
      interactionData: {
        timing,
      },
      excludeEventName: true,
    });
  }

  /* istanbul ignore next */
  trackHistoryNavigation(url: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Navigation,
      placement: url,
      name: 'history-navigation',
      excludeEventName: true,
    });
  }
}
