import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, ViewContainerRef } from '@angular/core';

import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatMessage } from '../../models/chat-message.model';
import { RichMessagesFactoryService } from '../../services/rich-messages-factory.service';

@Component({
  selector: 'ni-generic-rich-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMessageComponent implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef })
  containerRef: ViewContainerRef;

  @Input() type: RichMessageType;
  @Input() message: ChatMessage;

  constructor(private richMessagesFactoryService: RichMessagesFactoryService) {}

  ngAfterViewInit(): void {
    const component = this.richMessagesFactoryService.createRichMessageComponent(this.type, this.message, this.containerRef);
    component?.changeDetectorRef.detectChanges();
  }
}
