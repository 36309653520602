import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RefreshModalComponent } from '../components/refresh-modal/refresh-modal.component';
import { refreshModalConfig } from '../components/refresh-modal/refresh-modal.config';
import { InteractionType } from '../models/interaction-type.enum';
import { DynamicDialogService } from './dynamic-dialog.service';
import { TrackingService } from './tracking.service';
import { VersionDataService } from './version.data.service';

@Injectable({
  providedIn: 'root',
})
export class IdleUserService {
  time = 10800000; // 3 hours
  currentVersion: string;
  timerRef: any;
  private readonly excludedUrls = [/\/login.*/, /\/error/];

  constructor(
    private zone: NgZone,
    private dynamicDialogService: DynamicDialogService,
    private versionService: VersionDataService,
    private trackingService: TrackingService,
  ) {}

  handleRouteChange(url: string): void {
    this.excludedUrls.some((excludedUrlPattern) => url.match(excludedUrlPattern)) ? this.stop() : this.start();
  }

  private start(): void {
    if (!this.timerRef) {
      this.zone.runOutsideAngular(() => {
        this.timerRef = setInterval(this.userIdleHandler.bind(this), this.time);

        window.addEventListener('click', () => {
          clearInterval(this.timerRef);
          this.timerRef = setInterval(this.userIdleHandler.bind(this), this.time);
        });
      });
    }
  }

  private stop(): void {
    if (this.timerRef) {
      clearInterval(this.timerRef);
    }
  }

  private userIdleHandler(): void {
    this.checkIfVersionHasChanged().subscribe((versionHasChanged: boolean) => {
      if (versionHasChanged) {
        this.dynamicDialogService.open(RefreshModalComponent, refreshModalConfig);
        this.trackIdleUserModalOpen();
        clearInterval(this.timerRef);
      }
    });
  }

  private checkIfVersionHasChanged(): Observable<boolean> {
    return this.versionService.fetchAppVersion().pipe(
      map(({ version }) => {
        if (!this.currentVersion) {
          this.currentVersion = version;
          return false;
        }
        return this.currentVersion !== version;
      }),
    );
  }

  private trackIdleUserModalOpen(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'idle-service',
      name: 'session-expired-modal-has-opened',
    });
  }
}
