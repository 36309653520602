import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { Language } from '../enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  readonly defaultLang = Language.English;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
  ) {}

  initLanguage(): void {
    const selectedLanguage = this.cookieService.get(environment.selectedLangCookieName) || this.defaultLang;
    this.translateService.setDefaultLang(this.defaultLang);
    this.translateService.use(selectedLanguage);
  }

  setLanguage(lang: Language): void {
    this.cookieService.delete(environment.selectedLangCookieName, '/', environment.host);
    this.cookieService.set(environment.selectedLangCookieName, lang, { domain: this.getHostDomain() });
  }

  getLanguage(): Language {
    return this.translateService.currentLang as Language;
  }

  private getHostDomain(): string {
    return EnvConfig.isDev() ? environment.tld : `.${environment.tld}`;
  }
}
