import { createAction, props } from '@ngrx/store';

import { DocumentCenterResourceType } from '../document-center/models/document-center-resource-type.enum';

export const navigationActions = {
  toHomePage: createAction('[Navigation] To Home Page', props<{ replaceUrl?: boolean }>()),
  toAuthentication: createAction('[Navigation] To login', props<{ queryParams?: Record<string, string>; replaceUrl?: boolean }>()),
  toVerification: createAction('[Navigation] To verification'),
  toError: createAction('[Navigation] To error'),
  toBilling: createAction('[Navigation] To Billing'),
  back: createAction('[Navigation] Back clicked'),
  toCertificates: createAction('[Navigation] To Certificates Tab'),
  toCertificatesAutoTab: createAction('[Navigation] To Certificates auto tab'),
  toThirdPartyChangesRequestReviewPage: createAction(
    '[Navigation] To third party changes request review page',
    props<{ requestId: string }>(),
  ),
  toThirdPartyNotificationsRequestReviewPage: createAction(
    '[Navigation] To third party notifications request review page',
    props<{ requestId: string }>(),
  ),
  toFollowersPage: createAction('[Navigation] To Followers Page', props<{ replaceUrl?: boolean }>()),
  toLiveCertificate: createAction('[Navigation] To Live Certificate Page', props<{ liveCertificateId: string; openInNewTab?: boolean }>()),
  toLiveCertificateRequestChanges: createAction('[Navigation] To Live Certificate Request Changes'),
  toLiveCertificateRequestNotifications: createAction('[Navigation] To Live Certificate Request Notifications'),
  toLiveCertificateErrorPage: createAction('[Navigation]  To Live Certificate error page'),
  toCreateCustomCertificate: createAction('[Navigation] To Create Custom Certificate'),
  toCustomCertificateReviewPage: createAction(
    '[Navigation] To Create Custom Certificate review page',
    props<{ fromSampleCOI?: boolean }>(),
  ),
  toCustomCertificateCustomizationsPage: createAction('[Navigation] To Create Custom Certificate Customizations page'),
  toRequestChangesCustomizationsPage: createAction('[Navigation] To Create Request Changes Customizations page'),
  toRequestChangesReviewPage: createAction('[Navigation] To Custom Certificate request changes review page'),
  toClaimsChatbot: createAction('[Navigation] To claims chatbot'),
  toClaimsPage: createAction('[Navigation] To claims page'),
  toEditContactInfo: createAction('[Navigation] To contact info edit'),
  toEditRevenuePage: createAction('[Navigation] To edit revenue page'),
  toEditBusinessAddressPage: createAction('[Navigation] To edit business address page'),
  toEditMailingAddressPage: createAction('[Navigation] To edit mailing address page'),
  toWcAudit: createAction('[Navigation] To wc audit'),
  toBusinessPage: createAction('[Navigation] To business page'),
  toAddAuthorizedUserPage: createAction('[Navigation] To add authorized user page'),
  toCoveragePage: createAction('[Navigation] To coverage page'),
  toEmptyPage: createAction('[Navigation] To empty page'),
  toPolicyCoverage: createAction('[Navigation] To Policy Coverage Page', props<{ policyId: number }>()),
  toDocumentCenter: createAction('[Navigation] To document center'),
  toDocumentCenterCategoryPage: createAction(
    '[Navigation] To document center category page',
    props<{ resourceType: DocumentCenterResourceType }>(),
  ),
  toPolicyCoverageCustomization: createAction('[Navigation] To policy coverage customization page', props<{ policyId: number }>()),
  toCertificateOptionsPage: createAction('[Navigation] To certificate options page'),
  toCreateCertificatePreQuestionsPage: createAction('[Navigation] To certificate pre questions page'),
};
