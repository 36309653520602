import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { AppState } from '../../store';
import { MobileAppEventId } from '../models/mobile-app-event.model';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(
    private store: Store<AppState>,
    private mobileAppService: MobileAppService,
    private mobileAppEventsService: MobileAppEventsService,
    private navigationService: NavigationService,
  ) {}

  openOrDownloadFile(fileUrl: string, fileName: string): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({
        eventId: MobileAppEventId.OpenPdfViewer,
        url: fileUrl,
        closeWebview: false,
        fileName,
      });
    } else {
      this.navigationService.waitForUserInteractionAndDo(() => {
        const openFileInNewTabSucceed = this.openFileInNewTab(fileUrl);

        if (!openFileInNewTabSucceed) {
          const openFileInSameTabSucceed = this.openFileInSameTab(fileUrl);

          if (!openFileInSameTabSucceed) {
            this.downloadFile(fileUrl, fileName);
          }
        }
      });
    }
  }

  private openFileInNewTab(fileUrl: string): boolean {
    const result = window.open(fileUrl);
    return !!result;
  }

  private openFileInSameTab(fileUrl: string): boolean {
    const result = window.open(fileUrl, '_self');
    return !!result;
  }

  private downloadFile(fileUrl: string, fileName: string): void {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = fileUrl;
    a.target = '_blank';
    a.click();
    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Success,
        message: 'GENERAL.DOWNLOAD_FILE_MESSAGE',
      }),
    );
  }
}
