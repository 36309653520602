import { createSelector, MemoizedSelector } from '@ngrx/store';

import { Follower } from '../../../followers/models/follower.model';
import { AppState } from '../../../store';
import { selectRouteParam } from '../../../store/router.store';
import { AutoInsuranceCard } from '../models/auto-insurance-card.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolderRequirementPartialInformation } from '../models/certificate-holder-requirement-partial-information.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CreateCertificateFromHolderRequirements } from '../models/create-certificate-from-holder-requirements.model';
import { CreateCertificateRequest, CustomCertificateRequestChanges } from '../models/create-certificate-request.model';
import { CustomCertificateFlowType } from '../models/custom-certificate-flow-type.enum';
import { DesignationsLanguagesResponse } from '../models/designations-languages-response.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { CertificatesState } from './certificates.reducer';

/* istanbul ignore next */
export const certificatesState = (state: AppState): CertificatesState => state.certificates;

/* istanbul ignore next */
const isLoading: MemoizedSelector<AppState, boolean> = createSelector(certificatesState, (state) => state.isLoading);

/* istanbul ignore next */
const proofCertificate: MemoizedSelector<AppState, Certificate> = createSelector(certificatesState, (state) => state.proofCertificate);

/* istanbul ignore next */
const customCertificates: MemoizedSelector<AppState, Certificate[]> = createSelector(
  certificatesState,
  (state) => state.customCertificates,
);

/* istanbul ignore next */
const autoInsuranceCards: MemoizedSelector<AppState, AutoInsuranceCard[]> = createSelector(
  certificatesState,
  (state) => state.autoInsuranceCards,
);

/* istanbul ignore next */
const certificateAllowedOperations: MemoizedSelector<AppState, CertificateAllowedOperations> = createSelector(
  certificatesState,
  (state) => state.customCertificate.publicCertificateAllowedOperations,
);

/* istanbul ignore next */
const certificateAllowedModifiers: MemoizedSelector<AppState, CertificateAllowedModifiers> = createSelector(
  certificatesState,
  (state) => state.customCertificate.allowedModifiers,
);

/* istanbul ignore next */
const certificateHolderRequirementsPartialInformation: MemoizedSelector<AppState, CertificateHolderRequirementPartialInformation[]> =
  createSelector(certificatesState, (state) => state.customCertificate.certificateHolderRequirementsPartialInformation);

/* istanbul ignore next */
const certificateHolderRequirements: MemoizedSelector<AppState, CertificateHolderRequirements> = createSelector(
  certificatesState,
  (state) => state.customCertificate.certificateHolderRequirements,
);

/* istanbul ignore next */
const descriptionOfOperations: MemoizedSelector<AppState, string> = createSelector(
  certificatesState,
  (state) => state.customCertificate.descriptionOfOperations,
);

/* istanbul ignore next */
const designationsSpecialLanguages: MemoizedSelector<AppState, DesignationsLanguagesResponse> = createSelector(
  certificatesState,
  (state) => state.customCertificate.designationsSpecialLanguages,
);

/* istanbul ignore next */
const createCertificateRequest: MemoizedSelector<AppState, CreateCertificateRequest | CustomCertificateRequestChanges> = createSelector(
  certificatesState,
  (state) => state.customCertificate.createCertificateRequest,
);

/* istanbul ignore next */
const createCertificateFromHolderRequirements: MemoizedSelector<AppState, CreateCertificateFromHolderRequirements> = createSelector(
  certificatesState,
  (state) => state.customCertificate.createCertificateFromHolderRequirements,
);

const getLiveCertificateId: MemoizedSelector<AppState, string> = selectRouteParam('liveCertificateId');

/* istanbul ignore next */
const isWaiverChangeFlow: MemoizedSelector<AppState, boolean> = createSelector(
  certificatesState,
  (state) => state.customCertificate.isWaiverChangeFlow,
);

const isRequestChangesFlow: MemoizedSelector<AppState, boolean> = createSelector(
  certificatesState,
  (state) => state.customCertificate.flowType === CustomCertificateFlowType.RequestChanges,
);

/* istanbul ignore next */
const getThirdPartyPendingRequests: MemoizedSelector<AppState, ThirdPartyPendingRequest[]> = createSelector(
  certificatesState,
  (state) => state.thirdPartyPendingRequests,
);

/* istanbul ignore next */
const getFollowers: MemoizedSelector<AppState, Follower[]> = createSelector(certificatesState, (state) => state.followers);

const getFollowersCount: MemoizedSelector<AppState, number> = createSelector(certificatesState, (state) => state.followers?.length || 0);

/* istanbul ignore next */
const getPreQuestionsAnswer: MemoizedSelector<AppState, string> = createSelector(certificatesState, (state) => state.preQuestionsAnswer);

/* istanbul ignore next */
const isApprovingThirdPartyRequestAfterPayment: MemoizedSelector<AppState, boolean> = createSelector(
  certificatesState,
  (state) => state.isApprovingThirdPartyRequestAfterPayment,
);

export const certificatesSelectors = {
  isLoading,
  proofCertificate,
  customCertificates,
  autoInsuranceCards,
  certificateAllowedOperations,
  certificateAllowedModifiers,
  certificateHolderRequirementsPartialInformation,
  certificateHolderRequirements,
  descriptionOfOperations,
  designationsSpecialLanguages,
  createCertificateRequest,
  createCertificateFromHolderRequirements,
  getLiveCertificateId,
  isWaiverChangeFlow,
  isRequestChangesFlow,
  getThirdPartyPendingRequests,
  getFollowers,
  getFollowersCount,
  getPreQuestionsAnswer,
  isApprovingThirdPartyRequestAfterPayment,
};
