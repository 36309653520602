<div class="mobile-app-banner" data-test="mobile-app-banner" *ngIf="!hidden" [@fadeInAndOut]>
  <div class="close-container" data-test="close-banner" (click)="closeBanner()">
    <div class="close" data-test="close-icon"></div>
  </div>
  <div class="mobile-app-icon"></div>
  <div class="next-app-description-container">
    <div class="p-sm-bold">{{ 'MOBILE_APP_BANNER.NEXT_APP' | translate }}</div>
    <div class="next-insurance-label p-xs_sm">{{ 'MOBILE_APP_BANNER.NEXT_INSURANCE' | translate }}</div>
  </div>
  <div class="p-sm_md open-app" data-test="open-mobile-app-button" (click)="openOrInstallApp()">
    {{ 'MOBILE_APP_BANNER.OPEN_APP' | translate }}
  </div>
</div>
