import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginResponse } from '../models/login-response.model';
import { SendOtpResponse } from '../models/send-otp-response.model';

@Injectable({
  providedIn: 'root',
})
export class LoginDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  logout(): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/logout', {});
  }

  /* istanbul ignore next */
  sendToken(loginInput: string, recaptchaToken: string): Observable<SendOtpResponse> {
    return this.httpClient.post<SendOtpResponse>('/api/public/authentication/send-token', {
      loginInput,
      recaptchaToken,
    });
  }

  /* istanbul ignore next */
  validateToken(loginInput: string, verificationToken: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>('/api/public/authentication/validate-token', {
      loginInput,
      verificationToken,
    });
  }
}
