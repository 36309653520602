<div class="container">
  <ni-system-feedback [feedbackType]="FeedbackType.Error" class="title" data-test="title">
    {{ 'FAILED_PAYMENT_NOTICE.ACH.' + achFailureCategory + '.TITLE' | translate }}
  </ni-system-feedback>
  <div class="message p-sm" data-test="message">{{ 'FAILED_PAYMENT_NOTICE.ACH.' + achFailureCategory + '.MESSAGE' | translate }}</div>
  <div class="fix-instructions-title p-sm-bold">{{ 'FAILED_PAYMENT_NOTICE.ACH.HOW_TO_FIX' | translate }}:</div>
  <ul class="fix-instructions">
    <li
      class="fix-instruction p-sm"
      data-test="fix-instruction"
      *ngFor="let fixInstruction of fixInstructions"
      [innerHTML]="fixInstruction"
    ></li>
  </ul>
  <div class="footer p-sm" data-test="footer">{{
    'FAILED_PAYMENT_NOTICE.ACH.' + achFailureCategory + '.FOOTER' | translate: { default: '' }
  }}</div>
</div>
