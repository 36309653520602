import { createAction, props } from '@ngrx/store';

import { CombinedPaymentDetails } from '../models/combined-payment-details.model';
import { PaymentMethodTokens } from '../models/payment-method-tokens.model';

export const paymentActions = {
  loadCombinedPaymentDetails: createAction('[Payment API] Load combined payment details'),
  setCombinedPaymentDetails: createAction(
    '[Payment] Set combined payment details',
    props<{ combinedPaymentDetails: CombinedPaymentDetails }>(),
  ),
  updatePaymentMethod: createAction(
    '[Payment API] update payment method',
    props<{ tokens: PaymentMethodTokens; policyId: number; shouldCloseMobileBanner?: boolean }>(),
  ),
  updatePaymentMethodError: createAction('[Payment] update payment method error', props<{ errorMessage?: string }>()),
  updatePaymentMethodSuccess: createAction('[Payment API] update payment method success', props<{ shouldCloseMobileBanner?: boolean }>()),
};
