import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event } from '@angular/router';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { OverlayPanel } from 'primeng/overlaypanel';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, takeWhile, tap } from 'rxjs/operators';

import { businessActions } from '../../../business/store/business.actions';
import { businessSelectors } from '../../../business/store/business.selectors';
import { BusinessMoratoriums } from '../../../home/models/business-moratoriums.model';
import { Moratorium } from '../../../home/models/moratorium.model';
import { MoratoriumStatus } from '../../../home/models/moratorium-status.enum';
import { MoratoriumType } from '../../../home/models/moratorium-type.enum';
import { PaymentDetailsPerPolicy, PolicyPaymentDetails } from '../../../payment/models/payment-details-per-policy.model';
import { CreditCardPaymentMethod } from '../../../payment/models/payment-method-details.model';
import { PaymentDataService } from '../../../payment/payment.data.service';
import { PaymentService } from '../../../payment/payment.service';
import { paymentActions } from '../../../payment/store/payment.actions';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { ToastType } from '../../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { CreditCardFailedPaymentBannerType } from '../../models/credit-card-failed-payment-banner-type.enum';
import { MobileAppEventId } from '../../models/mobile-app-event.model';
import { FailedPaymentBannerTrackingService } from '../../services/failed-payment-banner-tracking.service';
import { MobileAppEventsService } from '../../services/mobile-app-events.service';
import { SESSION_STORAGE } from '../../tokens/session-storage.token';

@Component({
  selector: 'ni-credit-card-failed-payment-alert-banner',
  templateUrl: './credit-card-failed-payment-alert-banner.component.html',
  styleUrls: ['./credit-card-failed-payment-alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardFailedPaymentAlertBannerComponent implements OnInit, OnDestroy {
  @ViewChild('retryPaymentOverlay') retryPaymentOverlay: OverlayPanel;
  earliestFailedPayment: PolicyPaymentDetails;
  moratorium: Moratorium;
  creditCardPaymentMethod: CreditCardPaymentMethod;
  MoratoriumStatus = MoratoriumStatus;
  isVisible: boolean;
  bannerType: CreditCardFailedPaymentBannerType;
  readonly hideFailedPaymentBannerStorageKey = 'hideFailedPaymentBanner';
  ButtonType = ButtonType;
  private infoSub = Subscription.EMPTY;

  constructor(
    private store: Store<AppState>,
    private paymentDataService: PaymentDataService,
    private paymentService: PaymentService,
    private failedPaymentBannerTrackingService: FailedPaymentBannerTrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private mobileAppEventsService: MobileAppEventsService,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
  ) {}

  ngOnInit(): void {
    if (this.isBannerEnabledForTheSession()) {
      this.store.dispatch(businessActions.loadMoratoriums());

      this.infoSub = combineLatest([
        this.store.select(paymentSelectors.isLoading),
        this.store.select(businessSelectors.getMoratoriums),
        this.store.select(policiesSelectors.hasOperativeNextPolicy),
        this.store.select(paymentSelectors.getPaymentMethodDetails),
        this.store.select(paymentSelectors.getPaymentDetailsPerPolicy),
        this.paymentService.shouldDisplayFailedPaymentBannersInCurrURL(),
      ])
        .pipe(
          takeWhile(() => this.isBannerEnabledForTheSession()),
          filter(
            ([isLoadingPayments, moratoriums]: [
              boolean,
              BusinessMoratoriums,
              boolean,
              CreditCardPaymentMethod,
              PaymentDetailsPerPolicy,
              boolean,
            ]) => !isLoadingPayments && !!moratoriums,
          ),
        )
        .subscribe(
          ([, moratoriums, hasOperativeNextPolicy, paymentMethodDetails, paymentDetailsPerPolicy, shouldBeVisibleInCurrURL]: [
            boolean,
            BusinessMoratoriums,
            boolean,
            CreditCardPaymentMethod,
            PaymentDetailsPerPolicy,
            boolean,
          ]) => {
            this.earliestFailedPayment = this.getEarliestFailedPayment(paymentDetailsPerPolicy);
            this.creditCardPaymentMethod = paymentMethodDetails;
            this.moratorium = this.getRelevantMoratorium(moratoriums);

            if (this.earliestFailedPayment && hasOperativeNextPolicy && shouldBeVisibleInCurrURL) {
              this.bannerType = this.getBannerType();
              this.changeVisibility(true);
              this.failedPaymentBannerTrackingService.trackViewCreditCardFailedPaymentBanner(this.bannerType);
            } else {
              if (this.isVisible) {
                this.failedPaymentBannerTrackingService.trackHideCreditCardFailedPaymentBanner(this.bannerType);
              }
              this.changeVisibility(false);
            }
          },
        );
    }
  }

  ngOnDestroy(): void {
    this.infoSub.unsubscribe();
  }

  onClose(): void {
    this.failedPaymentBannerTrackingService.trackCloseCreditCardFailedPaymentBanner(this.bannerType);
    this.sessionStorage.setItem(this.hideFailedPaymentBannerStorageKey, '1');
  }

  editCard(): void {
    this.failedPaymentBannerTrackingService.trackCreditCardFailedPaymentBannerClickEditCard(this.bannerType);
    this.paymentService.openUpdatePaymentMethodModal({
      shouldCloseMobileBanner: true,
      onPaymentUpdateFinished: () => this.onPaymentUpdateFinished(),
    });
  }

  retryPayment(event: Event): void {
    this.failedPaymentBannerTrackingService.trackCreditCardFailedPaymentBannerClickRetry(this.bannerType);
    this.retryPaymentOverlay.show(event);
    this.paymentDataService
      .retryPayment()
      .pipe(
        tap(() => {
          this.store.dispatch(
            toastActions.showToast({
              toastType: ToastType.Success,
              message: 'PAYMENT.RETRY_PAYMENT.SUCCESS_MESSAGE',
            }),
          );
          if (this.mobileAppEventsService.shouldSendPaymentRefreshEvent()) {
            this.mobileAppEventsService.notifyMobile({
              eventId: MobileAppEventId.LastFailedPaymentHandledEvent,
              closeWebview: false,
            });
          }
          this.store.dispatch(paymentActions.loadCombinedPaymentDetails());
          this.changeVisibility(false);
          this.retryPaymentOverlay.hide();
        }),
        catchErrorAndLog(() => {
          this.store.dispatch(
            toastActions.showToast({
              toastType: ToastType.Error,
              message: 'PAYMENT.RETRY_PAYMENT.ERROR_MESSAGE',
            }),
          );
          this.retryPaymentOverlay.hide();
          return of(null);
        }),
      )
      .subscribe();
  }

  private isBannerEnabledForTheSession(): boolean {
    return !this.sessionStorage.getItem(this.hideFailedPaymentBannerStorageKey);
  }

  private getBannerType(): CreditCardFailedPaymentBannerType {
    if (!this.moratorium?.moratoriumStatus) {
      return CreditCardFailedPaymentBannerType.DEFAULT;
    }
    return this.moratorium.moratoriumStatus === MoratoriumStatus.Active
      ? CreditCardFailedPaymentBannerType.ACTIVE_MORATORIUM
      : CreditCardFailedPaymentBannerType.GRACE_PERIOD_MORATORIUM;
  }

  private getEarliestFailedPayment(paymentDetailsPerPolicy: PaymentDetailsPerPolicy): PolicyPaymentDetails {
    return Object.values(paymentDetailsPerPolicy).reduce(
      (earliestFailedPayment: PolicyPaymentDetails, currentPayment: PolicyPaymentDetails) => {
        if (!earliestFailedPayment) {
          return currentPayment.cardData?.firstFailedPaymentInLastSequenceOfFailures && currentPayment;
        }
        return currentPayment.cardData?.firstFailedPaymentInLastSequenceOfFailures &&
          currentPayment.cardData.firstFailedPaymentInLastSequenceOfFailures.date <
            earliestFailedPayment.cardData.firstFailedPaymentInLastSequenceOfFailures.date
          ? currentPayment
          : earliestFailedPayment;
      },
      null,
    );
  }

  private getRelevantMoratorium(moratoriums: BusinessMoratoriums): Moratorium {
    const flattenMoratoriums = Object.values(moratoriums).flat();
    return flattenMoratoriums.find((m) => m.moratoriumType === MoratoriumType.Covid19) || {};
  }

  private changeVisibility(isVisible: boolean): void {
    this.isVisible = isVisible;
    this.changeDetectorRef.markForCheck();
  }

  private onPaymentUpdateFinished(): void {
    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Success,
        message: `PAYMENT.UPDATE_PAYMENT_METHOD.SUCCESS_MESSAGE_AFTER_RETRY`,
      }),
    );
  }
}
