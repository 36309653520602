<div *ngFor="let option of options" class="checkbox-container">
  <input
    type="checkbox"
    [attr.data-test]="(option.id | dataTest) + '-option-checkbox'"
    (change)="optionSelected()"
    [(ngModel)]="option.checked"
  />
  <label>{{ option.text }}</label>
</div>
<div *ngIf="moreOptions">
  <ng-container *ngIf="!isMoreOptionsClicked; else moreOptionsExtended">
    <ni-chat-button dataTest="multiple-checkbox-more-options" customClass="secondary" (clicked)="isMoreOptionsClicked = true">
      More options...
    </ni-chat-button>
  </ng-container>
</div>
<ni-chat-button dataTest="submit" customClass="primary" [disabled]="isSubmitButtonDisabled" (clicked)="submitOptions()">
  Submit
</ni-chat-button>
<ng-template #moreOptionsExtended>
  <div *ngFor="let option of moreOptions" class="checkbox-container">
    <input
      type="checkbox"
      [attr.data-test]="(option.id | dataTest) + '-option-checkbox'"
      (change)="optionSelected()"
      [(ngModel)]="option.checked"
    />
    <label>{{ option.text }}</label>
  </div>
</ng-template>
