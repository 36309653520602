import { EventHint } from '@sentry/types';

import { isAnyChunkLoadError } from '../../helpers/is-chunk-load-error';

/**
 * Filter `ChunkLoadError` errors.
 * @param hint Sentry hint
 * @returns True for filtering the error event, otherwise false
 */
export function chunkLoadErrorFilter(hint?: EventHint): boolean {
  const error = hint?.originalException;
  return isAnyChunkLoadError(error);
}
