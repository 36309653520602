import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagsMap } from '@next-insurance/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsDataService {
  constructor(private httpClient: HttpClient) {}

  getFeatureFlags(): Observable<FeatureFlagsMap> {
    if (window.niFeatureFlags) {
      return from(window.niFeatureFlags).pipe(
        switchMap((featureFlagsMap: FeatureFlagsMap) => {
          if (featureFlagsMap) {
            return of(featureFlagsMap);
          }
          return this.getFeatureFlagsFromAPI();
        }),
      );
    }
    return this.getFeatureFlagsFromAPI();
  }

  /* istanbul ignore next */
  private getFeatureFlagsFromAPI(): Observable<FeatureFlagsMap> {
    return this.httpClient.get<FeatureFlagsMap>('/api/public/feature-flags');
  }
}
