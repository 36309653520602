import { createAction, props } from '@ngrx/store';

import { PackageDataPerPolicy } from '../../core/models/package-data.model';
import { FlexibleCoverageOptions } from '../../coverages/models/flexible-coverage-options.model';
import { CoverageExamples } from '../models/coverage-examples.model';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';

const loadPolicies = createAction('[Policies] Load policies', props<{ preventMobileBusinessChangeEvent?: boolean }>());

const setPolicies = createAction(
  '[Policies] Set Policies',
  props<{ policiesInfo: PoliciesInfoResponse; preventMobileBusinessChangeEvent?: boolean }>(),
);

const loadPackageData = createAction('[Polices] Load Policies Package Data');

const setPackageData = createAction('[Polices] Set Policies Package Data', props<{ policiesPackageData: PackageDataPerPolicy }>());

const loadCoverageExamples = createAction('[Policies API] Load coverage examples');

const setCoverageExamples = createAction(
  '[Policies] Set coverage examples',
  props<{ coverageExamples: { [lob: string]: CoverageExamples } }>(),
);

const loadFlexibleCoverageOptions = createAction('[Policies] Load flexible coverage options');

const setFlexibleCoverageOptions = createAction(
  '[Policies] set flexible coverage options',
  props<{ flexibleCoverageOptions: Record<number, FlexibleCoverageOptions> }>(),
);

const loadFlexibleCoverageOptionsFailed = createAction('[Policies] Load flexible coverage options failed');

export const policiesActions = {
  loadPolicies,
  setPolicies,
  loadPackageData,
  setPackageData,
  loadCoverageExamples,
  setCoverageExamples,
  loadFlexibleCoverageOptions,
  setFlexibleCoverageOptions,
  loadFlexibleCoverageOptionsFailed,
};
