<div [formGroup]="form" class="dynamic-field">
  <div [ngSwitch]="dynamicFormField.type" class="field">
    <input
      *ngSwitchCase="FormFieldTypes.Currency"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [options]="{ align: 'left', allowNegative: false }"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      currencyMask
      maxlength="15"
      minlength="0"
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.WholeNumber"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      maxlength="10"
      minlength="0"
      niOnlyDigits
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.PositiveInteger"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [max]="positiveIntegerConfig.max"
      [min]="positiveIntegerConfig.min"
      [pattern]="positiveIntegerConfig.pattern"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      onwheel="return false;"
      type="number"
    />
    <input
      *ngSwitchCase="FormFieldTypes.PositiveDouble"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [max]="positiveDoubleConfig.max"
      [min]="positiveDoubleConfig.min"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      onwheel="return false;"
      pattern="^\d*(\.\d{0,2})?$"
      type="number"
    />
    <input
      *ngSwitchCase="FormFieldTypes.TextBox"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      maxlength="50"
      niTrim
      nullable="true"
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.Email"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [pattern]="emailAddressPattern"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      email
      niTrim
      nullable="true"
      type="email"
    />
    <p-inputMask
      *ngSwitchCase="FormFieldTypes.Tel"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      mask="(999) 999-9999"
      ngDefaultControl
      type="tel"
    >
    </p-inputMask>
    <input
      *ngSwitchCase="FormFieldTypes.ZipCode"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [pattern]="zipCodeNumberPattern"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      [translatedCustomErrors]="zipCodeErrors"
      autocomplete="off"
      maxlength="5"
      niOnlyDigits
      niTrim
      nullable="true"
      type="text"
      zipcode
    />
    <input
      *ngSwitchCase="FormFieldTypes.State"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [pattern]="stateCodePattern"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      [translatedCustomErrors]="licenseStateErrorMessage"
      autocomplete="off"
      maxlength="2"
      niTrim
      nullable="true"
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.DateOfBirth"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      class="input-date"
      onfocusin="(this.type='date')"
      pInputText
      placeholder="Date of birth"
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.VinNumber"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      [translatedCustomErrors]="vinNumberErrors"
      autocomplete="off"
      maxlength="17"
      minlength="17"
      niTrim
      nullable="true"
      type="text"
    />
    <input
      *ngSwitchCase="FormFieldTypes.Year"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [max]="maxYear"
      [min]="minYear"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      [translatedCustomErrors]="yearErrors"
      autocomplete="off"
      niTrim
      nullable="true"
      onwheel="return false;"
      type="number"
    />
    <p-dropdown
      *ngSwitchCase="FormFieldTypes.Dropdown"
      [attr.data-test]="dynamicFormField.key + '-input'"
      [autoDisplayFirst]="false"
      [formControlName]="dynamicFormField.key"
      [options]="dynamicFormField.options"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      class="dropdown-field"
      optionLabel="text"
      optionValue="id"
    >
    </p-dropdown>
    <input
      *ngSwitchDefault
      [attr.data-test]="dynamicFormField.key + '-input'"
      [formControlName]="dynamicFormField.key"
      [id]="dynamicFormField.key"
      [placeholder]="dynamicFormField?.placeholder || dynamicFormField?.label || ''"
      autocomplete="off"
      maxlength="50"
      niTrim
      nullable="true"
      type="text"
    />
  </div>
</div>
