import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ni-credit-card-icons',
  templateUrl: './credit-card-icons.component.html',
  styleUrls: ['./credit-card-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass],
})
export class CreditCardIconsComponent {
  @Input() selectedCardType?: string;
  readonly creditCardImages = ['visa', 'mastercard', 'amex', 'discover', 'diners', 'jcb'];

  isCardImageDisabled(cardType: string): boolean {
    return this.selectedCardType !== 'unknown' && this.selectedCardType !== cardType;
  }
}
