import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommonConfigAttribute } from '../models/common-config-attribute.enum';
import { CommonConfigAttributesResponse } from '../models/common-config-attributes-response.model';
import { HolidayTheme } from '../models/holiday-themes.model';

@Injectable({
  providedIn: 'root',
})
export class CommonConfigDataService {
  constructor(private httpClient: HttpClient) {}

  getConfigAttribute(attribute: CommonConfigAttribute): Observable<string> {
    return this.getConfigAttributes([attribute]).pipe(map((attributesMap: Record<string, string>) => attributesMap[attribute]));
  }

  /* istanbul ignore next */
  getConfigAttributes(attributes: CommonConfigAttribute[]): Observable<CommonConfigAttributesResponse> {
    return this.httpClient.post<CommonConfigAttributesResponse>('/api/public/common-config/attributes', {
      attributes,
    });
  }

  /* istanbul ignore next */
  getHolidayTheme(): Observable<HolidayTheme> {
    return this.httpClient.get<HolidayTheme>('/api/public/themes/holiday');
  }

  /* istanbul ignore next */
  getVerificationCodeLength(): number {
    return 5;
  }

  /* istanbul ignore next */
  static getExcludedUrlsForPopup(): RegExp[] {
    return [
      /\/login.*/,
      /\/error/,
      /\/wc-audit.*/,
      /\/mobile-app-update/,
      /\/agents/,
      /\/public\/google-login-succeeded/,
      /\/empty-page/,
      /\/easy-login/,
      /\/public\/certificates\/live-certificate.*/,
    ];
  }
}
