import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateFormats, isAfterDate, isBefore, isToday, toDate, toLocalDate, transformDate } from '@next-insurance/date';
import { ButtonType, FeedbackType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from 'primeng/calendar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CancelPolicyRequest } from '../../../cancellation/models/cancel-policy-request.model';
import { CancellationConfig } from '../../../cancellation/models/cancellation-config.model';
import { CancellationReason } from '../../../cancellation/models/cancellation-reason.enum';
import { NonRenewPolicyRequest } from '../../../cancellation/models/non-renew-policy-request.model';
import { CancellationDataService } from '../../../cancellation/services/cancellation.data.service';
import { CancellationTrackingService } from '../../../cancellation/services/cancellation-tracking.service';
import { cancellationActions } from '../../../cancellation/store/cancellation.actions';
import { NiValidatorsService } from '../../../core/services/ni-validators.service';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../../store';
import { CancellationDateType } from '../../models/cancellation-date-type.enum';
import { CancelPolicyModalData } from './cancel-policy-modal-data.model';

type CancellationForm = {
  reason: FormControl<CancellationReason>;
  date: FormControl<Date>;
  cancellationDateType: FormControl<CancellationDateType>;
};

@Component({
  selector: 'ni-cancel-policy-modal',
  templateUrl: './cancel-policy-modal.component.html',
  styleUrls: ['./cancel-policy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelPolicyModalComponent implements OnInit {
  readonly CancellationDateType = CancellationDateType;
  readonly FeedbackType = FeedbackType;
  cancellationDateTexts: Partial<Record<CancellationDateType, string>> = {};

  @ViewChild('cancellationCalendar') cancellationCalendarElement: Calendar;
  modalData: CancelPolicyModalData;
  isLoadingCancellationConfig: boolean;
  isSubmitting: boolean;
  cancellationForm: FormGroup<CancellationForm>;
  ButtonType = ButtonType;
  minCancellationDate: Date;
  maxCancellationDate: Date;
  cancellationConfig: CancellationConfig;
  isFutureCancellationEnabled: boolean;
  cancellationReasons: CancellationReason[];
  selectedCancellationDateType: CancellationDateType;

  constructor(
    private store: Store<AppState>,
    private cancellationTrackingService: CancellationTrackingService,
    private fb: UntypedFormBuilder,
    private cancellationDataService: CancellationDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private dialogConfig: DynamicDialogConfig<CancelPolicyModalData>,
    private dialogRef: DynamicDialogRef,
  ) {}

  get dateControl(): FormControl<Date> {
    return this.cancellationForm.controls.date;
  }

  get reasonControl(): FormControl<CancellationReason> {
    return this.cancellationForm.controls.reason;
  }

  ngOnInit(): void {
    this.isLoadingCancellationConfig = true;
    this.isSubmitting = false;
    this.modalData = this.dialogConfig.data;
    this.cancellationTrackingService.trackCancelPolicyModalOpened(this.modalData.policy.lob, this.modalData.isRenewalPolicy);

    this.setCancellationFormByConfig();
  }

  onCancellationTypeChanged(value: CancellationDateType): void {
    this.selectedCancellationDateType = value;
    this.trackSelectedCancellationType();
    switch (this.selectedCancellationDateType) {
      case CancellationDateType.SelectDate:
        this.resetCancellationCalendarScrollHandler();
        break;
      default:
        this.dateControl.setValue(this.minCancellationDate);
        break;
    }
  }

  getCancellationRequest(): CancelPolicyRequest {
    return {
      reason: this.reasonControl.value,
      date: toLocalDate(this.dateControl.value),
    };
  }

  getNonRenewalRequest(): NonRenewPolicyRequest {
    return {
      reason: this.reasonControl.value,
    };
  }

  cancelCoverage(): void {
    if (this.selectedCancellationDateType === CancellationDateType.NonRenew) {
      this.handleNonRenewCoverage();
    } else {
      this.handleCancelCoverage();
    }
  }

  handleNonRenewCoverage(): void {
    const { policy } = this.modalData;

    this.isSubmitting = true;
    this.cancellationTrackingService.trackNonRenewCoverage(policy.lob);
    this.store.dispatch(
      cancellationActions.nonRenewPolicy({
        policyId: policy.policyId,
        lob: policy.lob,
        request: this.getNonRenewalRequest(),
      }),
    );
  }

  handleCancelCoverage(): void {
    const { policy, isRenewalPolicy } = this.modalData;

    this.isSubmitting = true;
    this.cancellationTrackingService.trackCancelCoverage(policy.lob, isRenewalPolicy);
    this.store.dispatch(
      cancellationActions.cancelPolicy({
        policyId: policy.policyId,
        lob: policy.lob,
        isRenewalPolicy,
        request: this.getCancellationRequest(),
      }),
    );
  }

  keepCoverage(): void {
    this.cancellationTrackingService.trackKeepCoverage(this.modalData.policy.lob, this.modalData.isRenewalPolicy);
    this.dialogRef.close();
  }

  trackSelectedDate(): void {
    this.cancellationTrackingService.trackSelectedDate(this.modalData.policy.lob, toDate(this.dateControl.value));
  }

  trackSelectedCancellationType(): void {
    this.cancellationTrackingService.trackSelectedCancellationType(this.modalData.policy.lob, this.selectedCancellationDateType);
  }

  private initCancellationDateTexts(): void {
    this.cancellationDateTexts = {};
    const canShowTodayOption = this.isDateBetween(toDate(new Date()), this.minCancellationDate, this.maxCancellationDate);
    const policyEndDate = transformDate(this.modalData.policy.policyEndDate, DateFormats.SlashesDate);
    if (canShowTodayOption) {
      this.cancellationDateTexts.TODAY = this.translate.instant('HOME.CANCEL_POLICY_MODAL.CANCELLATION_DATE_TYPES.TODAY');
    }
    this.cancellationDateTexts.SELECT_DATE = this.translate.instant('HOME.CANCEL_POLICY_MODAL.CANCELLATION_DATE_TYPES.SELECT_DATE');
    if (this.modalData.policy.isNonRenewEligible) {
      this.cancellationDateTexts.NON_RENEW = this.translate.instant('HOME.CANCEL_POLICY_MODAL.CANCELLATION_DATE_TYPES.NON_RENEW', {
        policyEndDate,
      });
    }
  }

  private isDateBetween(date: Date, startDate: Date, endDate: Date): boolean {
    return isAfterDate(date, startDate) && isBefore(date, endDate);
  }

  private resetCancellationCalendarScrollHandler(): void {
    setTimeout(() => {
      this.cancellationCalendarElement.scrollHandler = {
        element: undefined,
        listener: undefined,
        scrollableParents: undefined,
        bindScrollListener: () => {},
        unbindScrollListener: () => {},
        destroy: () => {},
      };
    }, 500);
  }

  private setCancellationFormByConfig(): void {
    this.cancellationDataService
      .getCancellationConfig(this.modalData.policy.policyId)
      .pipe(
        tap((cancellationConfig: CancellationConfig) => {
          this.cancellationConfig = cancellationConfig;
          this.isFutureCancellationEnabled = cancellationConfig.futureCancellationAllowed;
          this.cancellationReasons = cancellationConfig.reasons;
          this.initCancellationForm();
          this.initCancellationDateTexts();
          this.isLoadingCancellationConfig = false;
          this.changeDetectorRef.markForCheck();
        }),
        catchErrorAndLog(() => {
          this.store.dispatch(toastActions.showLoadingErrorToast());
          this.dialogRef.close();
          return of(null);
        }),
      )
      .subscribe();
  }

  private initCancellationForm(): void {
    this.minCancellationDate = toDate(this.cancellationConfig?.allowedCancellationDates?.earliestPossibleDateInPolicyTz);
    this.maxCancellationDate = toDate(this.cancellationConfig?.allowedCancellationDates?.latestPossibleDateInPolicyTz);
    this.selectedCancellationDateType = isToday(this.minCancellationDate) ? CancellationDateType.Today : CancellationDateType.SelectDate;
    this.cancellationForm = this.fb.group({
      reason: [null, [Validators.required]],
      date: [
        this.minCancellationDate,
        [Validators.required, NiValidatorsService.minDate(this.minCancellationDate), NiValidatorsService.maxDate(this.maxCancellationDate)],
      ],
      cancellationDateType: [this.selectedCancellationDateType, Validators.required],
    });
    if (!this.isFutureCancellationEnabled) {
      this.dateControl.disable({ onlySelf: true });
    }
    this.trackSelectedCancellationType();
    this.trackSelectedDate();
  }
}
