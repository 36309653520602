export enum PortalRoutes {
  Home = 'HOME',
  Authentication = 'AUTHENTICATION',
  Verification = 'VERIFICATION',
  Error = 'ERROR',
  Billing = 'BILLING',
  Back = 'BACK',
  Certificates = 'CERTIFICATES',
  CertificatesAutoTab = 'CERTIFICATES_AUTO_TAB',
  ThirdPartyChangesRequestReview = 'THIRD_PARTY_CHANGES_REQUEST_REVIEW',
  ThirdPartyNotificationsRequestReview = 'THIRD_PARTY_NOTIFICATIONS_REQUEST_REVIEW',
  Followers = 'CERTIFICATE_FOLLOWERS',
  LiveCertificate = 'LIVE_CERTIFICATE',
  LiveCertificateRequestChanges = 'LIVE_CERTIFICATE_REQUEST_CHANGES',
  LiveCertificateRequestNotifications = 'LIVE_CERTIFICATE_REQUEST_NOTIFICATIONS',
  LiveCertificateError = 'LIVE_CERTIFICATE_ERROR',
  CreateCustomCertificate = 'CREATE_CUSTOM_CERTIFICATE',
  CustomCertificateReview = 'CUSTOM_CERTIFICATE_REVIEW',
  CustomCertificateCustomizations = 'CUSTOM_CERTIFICATE_CUSTOMIZATIONS',
  RequestChangesReview = 'REQUEST_CHANGES_REVIEW_NEW',
  ClaimsChatbot = 'CLAIMS_CHATBOT',
  Claims = 'CLAIMS',
  EditContactInfo = 'EDIT_CONTACT_INFO',
  WcAudit = 'WC_AUDIT',
  Business = 'BUSINESS',
  AddAuthorizedUser = 'ADD_AUTHORIZED_USER',
  Coverage = 'COVERAGE',
  PolicyCoverage = 'POLICY_COVERAGE',
  EmptyPage = 'EMPTY_PAGE',
}
