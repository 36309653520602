<div class="messages-container" data-test="messages-container" #messagesContainer>
  <div
    *ngFor="let message of messages; trackBy: trackByMessageId"
    class="fade-in message-container"
    [ngClass]="{
      'message-container-user': message.sender === ChatSenderType.User,
      'message-container-bot': message.sender === ChatSenderType.Bot,
    }"
  >
    <ni-generic-rich-message [type]="message.type" [message]="message"></ni-generic-rich-message>
  </div>
</div>
