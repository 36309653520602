import { BubbleMessageComponent } from './components/bubble-rich-message/bubble-message.component';
import { ChatbotErrorHandlerComponent } from './components/chatbot-error-handler/chatbot-error-handler.component';
import { CustomHandlerRichMessageComponent } from './components/custom-handler-rich-message/custom-handler-rich-message.component';
import { DatePickerRichMessageComponent } from './components/date-picker-rich-message/date-picker-rich-message.component';
import { FileUploadRichMessageComponent } from './components/file-upload-rich-message/file-upload-rich-message.component';
import { FormRichMessageComponent } from './components/form-rich-message/form-rich-message.component';
import { LoadingRichMessageComponent } from './components/loading-rich-message/loading-rich-message.component';
import { MultipleCheckboxRichMessageComponent } from './components/multiple-checkbox-rich-message/multiple-checkbox-rich-message.component';
import { RestartSessionRichMessageComponent } from './components/restart-session-rich-message/restart-session-rich-message.component';
import { SuggestionsRichMessageComponent } from './components/suggestions-rich-message/suggestions-rich-message.component';
import { SurveyRichMessageComponent } from './components/survey-rich-message/survey-rich-message.component';
import { ZonedDatePickerRichMessageComponent } from './components/zoned-date-picker-rich-message/zoned-date-picker-rich-message.component';

export const richMessageComponents = [
  BubbleMessageComponent,
  SuggestionsRichMessageComponent,
  LoadingRichMessageComponent,
  FileUploadRichMessageComponent,
  DatePickerRichMessageComponent,
  ZonedDatePickerRichMessageComponent,
  SurveyRichMessageComponent,
  RestartSessionRichMessageComponent,
  FormRichMessageComponent,
  CustomHandlerRichMessageComponent,
  MultipleCheckboxRichMessageComponent,
  ChatbotErrorHandlerComponent,
];
