<div data-test="delete-certificate-modal">
  <div class="delete-certificate-title ni-dialog-title wide-padding">
    <img src="assets/rebrand/alert-icon.svg" />
    <div class="h6_h4-regular">{{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.TITLE' | translate }}</div>
  </div>
  <div *ngIf="displayThirtyDaysNoticeWarning" class="warning-message p-sm_md" data-test="thirty-days-cancelation-warning">
    {{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.THIRTY_DAYS_NOTICE' | translate }}
  </div>
  <div *ngIf="displayLossPayeeNoticeWarning" class="warning-message p-sm_md" data-test="loss-payee-notice-warning">
    {{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.LOSS_PAYEE_NOTICE' | translate }}
    <div class="checkbox-wrapper p-xs">
      <ni-checkbox-rb (change)="checkDesignationsApproval($event)" class="checkbox" data-test="override-designations-checkbox">
      </ni-checkbox-rb>
      <div class="checkbox-label">
        {{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.LOSS_PAYEE_NOTICE_CONFIRMATION' | translate }}
      </div>
    </div>
  </div>
  <div class="ni-dialog-content center wide-padding p-sm_md">
    {{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.MESSAGE' | translate }}
  </div>
  <div class="ni-dialog-footer wide-padding">
    <ni-button-rb
      (click)="onSubmit()"
      [buttonType]="ButtonType.Destructive"
      [disabled]="(isSubmitting || !overrideDesignationsApproved) && displayLossPayeeNoticeWarning"
      [isLoading]="isSubmitting"
      data-test="delete-certificate-submit-button"
      >{{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.SUBMIT' | translate }}</ni-button-rb
    >
    <ni-button-rb
      (click)="onCancel()"
      [buttonType]="ButtonType.Secondary"
      [disabled]="isSubmitting"
      class="delete-certificate-action"
      data-test="delete-certificate-cancel-button"
      >{{ 'CERTIFICATES.CUSTOM_CERTIFICATES.DELETE_CERTIFICATES_MODAL.CANCEL' | translate }}</ni-button-rb
    >
  </div>
</div>
