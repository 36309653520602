import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocLinkResponse } from '../../core/models/doc-link-response.model';
import { PackageDataPerPolicy } from '../../core/models/package-data.model';
import { FlexibleCoverageOptionsResponse } from '../../coverages/models/flexible-coverage-options.model';
import { CoverageExamplesPerLob } from '../models/coverage-examples.model';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';
import { Policy } from '../models/policy.model';

@Injectable({
  providedIn: 'root',
})
export class PoliciesDataService {
  constructor(private httpClient: HttpClient) {}

  private static getEnrichedPolicy(policy: Policy): Policy {
    return {
      ...policy,
      isCancellationEligible: policy.isCancellationEligible,
    };
  }

  getPoliciesInfo(): Observable<PoliciesInfoResponse> {
    return this.httpClient.get<PoliciesInfoResponse>('/api/policies').pipe(
      map((policiesInfoResponse: PoliciesInfoResponse) => ({
        ...policiesInfoResponse,
        policies: policiesInfoResponse.policies.map((policy: Policy) => PoliciesDataService.getEnrichedPolicy(policy)),
      })),
    );
  }

  getPolicyById(policyId: string): Observable<Policy> {
    return this.httpClient
      .get<Policy>('/api/policies/policy', {
        params: {
          policyId,
        },
      })
      .pipe(map((policy: Policy) => PoliciesDataService.getEnrichedPolicy(policy)));
  }

  /* istanbul ignore next */
  getPackageData(): Observable<PackageDataPerPolicy> {
    return this.httpClient.get<PackageDataPerPolicy>('/api/policies/package-data');
  }

  /* istanbul ignore next */
  getCoverageExamples(cobId: number, lobs: LOB[]): Observable<CoverageExamplesPerLob> {
    return this.httpClient.post<CoverageExamplesPerLob>('/api/policies/coverage-examples', {
      cobId,
      lobs,
    });
  }

  /* istanbul ignore next */
  getPolicyDocLink(policyId: number): Observable<DocLinkResponse> {
    return this.httpClient.get<DocLinkResponse>('/api/policies/pdf', {
      params: {
        policyId: policyId.toString(),
      },
    });
  }

  getFlexibleCoverageOptions(): Observable<FlexibleCoverageOptionsResponse> {
    return this.httpClient.get<FlexibleCoverageOptionsResponse>(`/api/policies/flexible-coverage-options`);
  }
}
