import { Injectable } from '@angular/core';

import { InteractionType } from '../core/models/interaction-type.enum';
import { TrackingService } from '../core/services/tracking.service';
import { PaymentProvider } from './enums/payment-provider.enum';
import { PaymentMethodType } from './models/payment-method-details.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackViewUpdatePaymentModal(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'update-payment-method-modal',
      name: 'modal-opened',
    });
  }

  /* istanbul ignore next */
  trackCloseUpdatePaymentModal(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'update-payment-method-modal',
      name: 'modal-closed',
    });
  }

  /* istanbul ignore next */
  trackSubmitUpdatePaymentMethod(paymentProvider: PaymentProvider, paymentMethodType: PaymentMethodType): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'update-payment-method-modal',
        name: 'submit-button',
        interactionData: {
          paymentProvider,
          paymentMethodType,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackSelectPaymentMethodType(previousPaymentMethodType: PaymentMethodType, currentPaymentMethodType: PaymentMethodType): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'update-payment-method-modal',
        name: 'select-payment-method-type',
        interactionData: {
          from: previousPaymentMethodType,
          to: currentPaymentMethodType,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackUpdatePaymentMethod(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'update-payment-method-modal',
      name: 'submit-result',
      interactionData: {
        isSuccess,
      },
    });
  }

  /* istanbul ignore next */
  trackRetryPayment(isSuccess: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'payment-card',
      name: 'retry-payment',
      interactionData: {
        isSuccess,
      },
      eventName: 'payment card - API retry payment',
    });
  }
}
