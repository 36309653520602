import { createAction, props } from '@ngrx/store';

import { ToastType } from '../models/toast-type.enum';

export const toastActions = {
  showToast: createAction(
    '[Toast] Show Toast',
    props<{
      toastType: ToastType;
      message?: string;
      title?: string;
      isAlreadyTranslated?: boolean;
      translateParams?: any;
      options?: Partial<{ timeOut: number }>;
    }>(),
  ),
  showGeneralErrorToast: createAction('[Toast] Show General Error Toast'),
  showLoadingErrorToast: createAction('[Toast] Show Loading Error Toast'),
};
