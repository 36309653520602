import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { LoginV2DataService } from '../../login/services/login-v2.data.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { QueryParams } from '../models/query-params.enum';

export const googleLoginCallbackGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const loginDataService = inject(LoginV2DataService);
  const loginTrackingService = inject(LoginTrackingService);

  loginTrackingService.trackGoogleLoginCallbackStart();
  return of(null).pipe(
    switchMap(() => {
      const { code, error: googleError } = route.queryParams;
      return loginDataService.googleSsoCallback(code, googleError).pipe(
        map(() => {
          loginTrackingService.trackGoogleLoginCallbackComplete(true);
          store.dispatch(navigationActions.toHomePage({ replaceUrl: true }));
          return false;
        }),
        catchErrorAndLog((error: HttpErrorResponse) => {
          loginTrackingService.trackGoogleLoginCallbackComplete(false, error.error?.niStatusCode);
          store.dispatch(
            navigationActions.toAuthentication({
              replaceUrl: true,
              queryParams: {
                [QueryParams.IsSSO]: 'true',
                [QueryParams.LoginError]: AuthErrorStatuses.GoogleLoginFailed,
              },
            }),
          );
          return of(false);
        }),
      );
    }),
  );
};
