import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { AuthErrorStatuses } from '../models/auth-error-status.enum';
import { VerificationCodeDeliveryMethod } from '../models/verification-code-delivery-method.enum';
import { LoginState } from './login.reducer';

const loginState = (state: AppState): LoginState => state.login;

const getErrorReason: MemoizedSelector<AppState, AuthErrorStatuses> = createSelector(loginState, (state) => state.errorReason);

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(loginState, (state) => state.loading);

const getLoginInput: MemoizedSelector<AppState, string> = createSelector(loginState, (state) => state.loginInput);

const getVerificationCodeDeliveryMethods: MemoizedSelector<AppState, VerificationCodeDeliveryMethod[]> = createSelector(
  loginState,
  (state) => state.verificationCodeDeliveryMethods,
);

export const loginSelectors = {
  isLoading,
  getErrorReason,
  getLoginInput,
  getVerificationCodeDeliveryMethods,
};
