import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { businessActions } from '../../business/store/business.actions';
import { BusinessStatus } from '../../core/models/business-status.enum';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../core/models/mobile-app-event.model';
import { PackageDataPerPolicy } from '../../core/models/package-data.model';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { SurvicateService } from '../../core/services/survicate.service';
import { coreSelectors } from '../../core/store/core.selectors';
import { FlexibleCoverageOptionsResponse } from '../../coverages/models/flexible-coverage-options.model';
import { loginActions } from '../../login/store/login.actions';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { CoverageExamples } from '../models/coverage-examples.model';
import { PoliciesInfoResponse } from '../models/policies-info-response.model';
import { Policy } from '../models/policy.model';
import { PoliciesDataService } from '../services/policies.data.service';
import { policiesActions } from './policies.actions';
import { policiesSelectors } from './policies.selectors';

@Injectable()
export class PoliciesEffects {
  loadPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesActions.loadPolicies),
      switchMap((action: { preventMobileBusinessChangeEvent?: boolean }) =>
        this.policiesDataService.getPoliciesInfo().pipe(
          switchMap((policiesInfo: PoliciesInfoResponse) => {
            if (!policiesInfo.policies.length) {
              return [loginActions.logout()];
            }
            return [
              policiesActions.setPolicies({ policiesInfo, preventMobileBusinessChangeEvent: action.preventMobileBusinessChangeEvent }),
              businessActions.loadSegmentByCob({ cobId: policiesInfo.policies[0]?.cobId }),
            ];
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  setPolicies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(policiesActions.setPolicies),
        withLatestFrom(this.store.select(policiesSelectors.isHistoricalUser), this.store.select(coreSelectors.getIsAfterPurchase)),
        tap(([action, isHistoricalUser, isAfterPurchase]: [{ preventMobileBusinessChangeEvent?: boolean }, boolean, boolean]) => {
          if (!action.preventMobileBusinessChangeEvent || isAfterPurchase) {
            // optimization for mobile - prevent reload the policies if unnecessary.
            this.mobileAppEventsService.notifyMobile({
              eventId: MobileAppEventId.BusinessStatusUpdate,
              closeWebview: false,
              businessStatus: isHistoricalUser ? BusinessStatus.Historical : BusinessStatus.Active,
            } as MobileAppCustomizedEvent);
          }
          this.survicateService.updateTraitsByPolicies();
        }),
      ),
    { dispatch: false },
  );

  loadPackageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesActions.loadPackageData),
      switchMap(() =>
        this.policiesDataService.getPackageData().pipe(
          map((policiesPackageData: PackageDataPerPolicy) => {
            return policiesActions.setPackageData({ policiesPackageData });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  loadFlexibleCoverageOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesActions.loadFlexibleCoverageOptions),
      switchMap(() => {
        return this.policiesDataService.getFlexibleCoverageOptions().pipe(
          map((optionsResponse: FlexibleCoverageOptionsResponse) =>
            policiesActions.setFlexibleCoverageOptions({
              flexibleCoverageOptions: optionsResponse.policyCoveragesOptionsAndConstraintsMap,
            }),
          ),
          catchErrorAndLog(() => {
            return [policiesActions.loadFlexibleCoverageOptionsFailed(), toastActions.showGeneralErrorToast()];
          }),
        );
      }),
    ),
  );

  loadCoverageExamples$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policiesActions.loadCoverageExamples),
      withLatestFrom(this.store.select(policiesSelectors.firstPolicy), this.store.select(policiesSelectors.allLobs)),
      switchMap(([, policy, lobs]: [Action, Policy, LOB[]]) =>
        this.policiesDataService.getCoverageExamples(policy.cobId, lobs).pipe(
          map((coverageExamples: { [lob: string]: CoverageExamples }) => {
            return policiesActions.setCoverageExamples({ coverageExamples });
          }),
          catchErrorAndLog(() => of(navigationActions.toError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private policiesDataService: PoliciesDataService,
    private survicateService: SurvicateService,
    private mobileAppEventsService: MobileAppEventsService,
  ) {}
}
