<div
  class="menu-container"
  data-test="menu-container"
  (mouseenter)="openMenuOnHover()"
  (click)="onClick($event)"
  (niClickOutside)="closeMenu()"
>
  <span class="mouse-hover" (mouseleave)="closeMenuOnHover()">
    <div *ngIf="icon" class="menu-icon-container" data-test="menu-icon" [ngClass]="{ 'is-menu-visible': isMenuVisible }">
      <img class="menu-icon" src="assets/rebrand/menu-burger.svg" alt="menu burger" />
    </div>
    <div *ngIf="isMenuVisible" class="menu-options" data-test="menu-options">
      <ng-content></ng-content>
    </div>
  </span>
</div>
