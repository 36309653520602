import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataTest',
})
export class DataTestPipe implements PipeTransform {
  transform(value: string): string {
    return value.toLowerCase().replace(/[ '`]/g, '-');
  }
}
