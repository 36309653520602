import { LOB } from '@next-insurance/core';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { CrossSellState } from './cross-sell.reducer';

const crossSellState = (state: AppState): CrossSellState => state.crossSell;

/* istanbul ignore next */
const getWCSuggestion = createSelector(crossSellState, (state: CrossSellState) => state.wcSuggestion);
/* istanbul ignore next */
const isLoading = createSelector(crossSellState, (state: CrossSellState) => state.isLoading);
const getPolicies = createSelector(crossSellState, (state: CrossSellState) =>
  state.policies.filter((policy) => Object.values(LOB).includes(policy.lob)),
);

/* istanbul ignore next */
const getIsEligibleForCoverageCheckup = createSelector(crossSellState, (state: CrossSellState) => {
  return state.eligibleForCoverageCheckup;
});

/* istanbul ignore next */
const getCoverageCheckupDetails = createSelector(crossSellState, (state: CrossSellState) => {
  return state.coverageCheckupDetails;
});

export const crossSellSelectors = {
  isLoading,
  getPolicies,
  getWCSuggestion,
  getIsEligibleForCoverageCheckup,
  getCoverageCheckupDetails,
};
