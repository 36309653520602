import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';

import { RecaptchaService } from '../../core/services/recaptcha.service';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginResponse } from '../../login/models/login-response.model';
import { LoginV2DataService } from '../../login/services/login-v2.data.service';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { BusinessCreationRequest } from '../../shared/models/business-creation-request.model';
import { BusinessCreationResponse } from '../../shared/models/business-creation-response.model';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { defaultBusinessCreationRequestWithGL } from '../../testing/mock-objects/mock-business-creation-request';
import { DeveloperLoginDataService } from '../services/developer-login.data.service';
import { developerLoginActions } from './developer-login.actions';

const RECAPTCHA_AUTHENTICATION_KEY = 'authentication';

@Injectable()
export class DeveloperLoginEffects {
  submitDeveloperLoginBusinessRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(developerLoginActions.submitDeveloperLoginBusinessRequest),
      switchMap((action: { businessCreationRequest: BusinessCreationRequest }) => {
        return this.developerLoginService.createBusiness(action.businessCreationRequest || defaultBusinessCreationRequestWithGL).pipe(
          map((businessCreationResponse: BusinessCreationResponse) => {
            return developerLoginActions.developerLoginGetOtp({
              emailAddress: businessCreationResponse.businessContactAddressData.business.emailAddress,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            const errorReason = error?.error.message;
            this.store.dispatch(developerLoginActions.developerLoginFailed({ errorReason }));
            return throwError(() => error);
          }),
        );
      }),
    );
  });

  developerLoginGetOtp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(developerLoginActions.developerLoginGetOtp),
      switchMap((action: { emailAddress: string }) => {
        return this.recaptchaService.executeRecaptcha(RECAPTCHA_AUTHENTICATION_KEY).pipe(
          switchMap((recaptchaToken: string) => {
            return this.loginDataServiceV2.sendToken(action.emailAddress, recaptchaToken);
          }),
          switchMap(() => {
            return this.developerLoginService.getOtp(action.emailAddress).pipe(
              map((verificationToken: string) => {
                return developerLoginActions.developerLoginValidateOtp({
                  verificationToken,
                  email: action.emailAddress,
                });
              }),
            );
          }),
          catchError((error: HttpErrorResponse) => {
            const errorReason = error.error?.extra?.niStatusCode || error.error?.message;
            this.store.dispatch(developerLoginActions.developerLoginFailed({ errorReason }));
            return throwError(() => error);
          }),
        );
      }),
    );
  });

  developerLoginValidateOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(developerLoginActions.developerLoginValidateOtp),
      switchMap((action: { verificationToken: string; email: string }) => {
        const validateToken$ = this.loginDataServiceV2.validateOtp(action.email, action.verificationToken);
        return validateToken$.pipe(
          map((response: LoginResponse) => {
            return developerLoginActions.developerLoginVerificationSuccess({ response });
          }),
          catchError((error: HttpErrorResponse) => {
            const errorReason = error.error?.niStatusCode || AuthErrorStatuses.InternalFailure;
            this.store.dispatch(developerLoginActions.developerLoginFailed({ errorReason }));
            return throwError(() => error);
          }),
        );
      }),
    ),
  );

  developerLoginVerificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(developerLoginActions.developerLoginVerificationSuccess),
        delay(1000),
        tap(() => {
          this.store.dispatch(navigationActions.toHomePage({}));
        }),
      ),
    { dispatch: false },
  );

  developerLoginFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(developerLoginActions.developerLoginFailed),
      map((action: { errorReason: string }) => {
        return toastActions.showToast({
          toastType: ToastType.Error,
          message: action.errorReason,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private loginDataServiceV2: LoginV2DataService,
    private recaptchaService: RecaptchaService,
    private developerLoginService: DeveloperLoginDataService,
  ) {}
}
