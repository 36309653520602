export class NotContainsValidatorService {
  static isValid(array: string[], value: string): boolean {
    if (!value) {
      return true;
    }
    const normalizeVal = NotContainsValidatorService.normalizeString(value);
    return !array.find((elem) => normalizeVal.includes(NotContainsValidatorService.normalizeString(elem)));
  }

  private static normalizeString(value: string): string {
    return value.trim().toLowerCase();
  }
}
