<div>
  <div class="form-messages-container">
    <div *ngFor="let message of formMessages" class="fade-in message-container message-container-user">
      <div class="chat-message chat-message-user" data-test="submitted-messages">
        <div>{{ message }}</div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isSubmitted">
    <form *ngIf="isAddingMode" (ngSubmit)="onSave()" [formGroup]="form" class="form-rich-message" data-test="dynamic-form">
      <h3 class="title">{{ message.data.richContent.title }}</h3>
      <div *ngFor="let dynamicFormField of message.data.richContent.fields" class="form-row">
        <ni-dynamic-form-field
          *ngIf="!dynamicFormField.isHidden"
          [dynamicFormField]="dynamicFormField"
          [form]="form"
        ></ni-dynamic-form-field>
      </div>
    </form>
    <div class="flex-row" *ngIf="isAddingMode; else viewMode">
      <ni-chat-button class="u-padding-right-small" customClass="secondary" dataTest="cancel" (click)="onCancel()"> Cancel </ni-chat-button>
      <ni-chat-button
        *ngIf="isCollection; else submitBtn"
        customClass="primary"
        [disabled]="!form.valid"
        (clicked)="onSave()"
        dataTest="save"
      >
        Save
      </ni-chat-button>
      <ng-template #submitBtn>
        <ni-chat-button customClass="primary" [disabled]="!form.valid" (clicked)="onSubmit()" dataTest="submit">
          {{ message.data.richContent.submitButtonLabel || 'Submit' }}
        </ni-chat-button>
      </ng-template>
    </div>
    <ng-template #viewMode>
      <div class="flex-row">
        <ni-chat-button class="u-padding-right-small" customClass="secondary" dataTest="add-another-btn" (click)="isAddingMode = true">
          Add Another
        </ni-chat-button>
        <ni-chat-button customClass="primary" (clicked)="onSubmit()" dataTest="submit">
          {{ message.data.richContent.submitButtonLabel || 'Submit' }}
        </ni-chat-button>
      </div>
    </ng-template>
  </ng-container>
</div>
