import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomCertificateDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  deleteCertificate(liveCertificateId: string, overrideDesignationsApproved: boolean): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/certificates/custom-certificate/delete-with-designations-override', {
      liveCertificateId,
      overrideDesignationsApproved,
    });
  }
}
