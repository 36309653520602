import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { diffInMilliseconds } from '@next-insurance/date';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { AbTestingDataService } from '../../core/services/ab-testing.data.service';
import { AbTestingService } from '../../core/services/ab-testing.service';
import { TrackingService } from '../../core/services/tracking.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AbTestsMap } from '../models/ab-test.model';
import { LoadAbTestsRequest } from '../models/load-ab-tests-request.model';
import { abTestingActions } from './ab-testing.actions';

@Injectable()
export class AbTestingEffects {
  loadSectionAbTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(abTestingActions.loadSectionAbTests),
      mergeMap((request: LoadAbTestsRequest) => {
        const startTime = new Date();
        this.trackingService.track({
          interactionType: InteractionType.Debug,
          placement: 'ab-testing',
          name: 'before-load-ab-tests',
          interactionData: {
            section: request.section,
          },
        });
        return this.abTestingDataService.getAbTestsMap(request).pipe(
          switchMap((abTestsMap: AbTestsMap) => {
            this.trackingService.track({
              interactionType: InteractionType.Debug,
              placement: 'ab-testing',
              name: 'load-ab-tests-success',
              interactionData: {
                section: request.section,
              },
            });
            const firstTest = Object.values(abTestsMap)[0];
            const actions: Action[] = [
              abTestingActions.pairAbTest({ abTest: firstTest, section: request.section }),
              abTestingActions.trackAbTest({ abTest: firstTest, section: request.section }),
            ];

            actions.push(
              firstTest
                ? abTestingActions.setSectionAbTests({ abTestsMap, section: request.section })
                : abTestingActions.resetSectionAbTests({ section: request.section }),
            );

            return actions;
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            this.trackingService.track({
              interactionType: InteractionType.Debug,
              placement: 'ab-testing',
              name: 'load-ab-tests-failure',
              interactionData: {
                section: request.section,
                duration: diffInMilliseconds(new Date(), startTime),
                error: error.message,
              },
            });
            return of(abTestingActions.onError({ section: request.section }));
          }),
        );
      }),
    ),
  );

  pairAbTest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(abTestingActions.pairAbTest),
        mergeMap((action) => this.abTestingService.pairAbTest(action.abTest, action.section)),
      ),
    { dispatch: false },
  );

  trackAbTest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(abTestingActions.trackAbTest),
        mergeMap((action) => this.abTestingService.trackAbTest(action.abTest, action.section)),
      ),
    { dispatch: false },
  );

  constructor(
    private abTestingService: AbTestingService,
    private abTestingDataService: AbTestingDataService,
    private trackingService: TrackingService,
    private actions$: Actions,
  ) {}
}
