import { Action, createReducer, on } from '@ngrx/store';

import { CustomerActivity } from '../models/customer-activity.model';
import { AuditDocument, WcAudit, WcAuditDocumentCategory } from '../models/wc-audit.model';
import { WcAuditCollectedData } from '../models/wc-audit-collected-data.model';
import { WcAuditSubmissionResponse } from '../models/wc-audit-summary.model';
import { wcAuditActions } from './wc-audit.actions';

export interface WcAuditState {
  customerActivities: CustomerActivity[];
  mentionedActivities: CustomerActivity[];
  wcAudits: WcAudit[];
  documentsByWcAuditCategory: Partial<Record<WcAuditDocumentCategory, AuditDocument[]>>;
  selectedWcAuditData: WcAuditCollectedData;
  submissionResult: WcAuditSubmissionResponse;
  isLoading: boolean;
  submissionStatus: string;
}

export const wcAuditInitialState: WcAuditState = {
  customerActivities: [],
  mentionedActivities: [],
  wcAudits: null,
  documentsByWcAuditCategory: {},
  selectedWcAuditData: {
    employees: [],
    subcontractors: [],
    ownersOfficersPartners: [],
    documents: { emailConfirmed: false },
  },
  submissionResult: null,
  isLoading: false,
  submissionStatus: '',
};

const reducer = createReducer(
  wcAuditInitialState,
  on(wcAuditActions.initWcAuditsLoading, () => ({
    ...wcAuditInitialState,
    isLoading: true,
  })),
  on(wcAuditActions.setWcAudits, (state, action) => ({
    ...state,
    wcAudits: action.wcAudits,
    isLoading: false,
  })),
  on(wcAuditActions.setIsLoading, (state, action) => ({
    ...state,
    isLoading: action.isLoading,
  })),
  on(wcAuditActions.setWcAuditCollectedData, (state, action) => ({
    ...state,
    selectedWcAuditData: {
      ...state.selectedWcAuditData,
      ...action.stepCollectedData,
    },
    isLoading: false,
  })),
  on(wcAuditActions.loadCustomerActivities, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(wcAuditActions.setCustomerActivities, (state, action) => ({
    ...state,
    customerActivities: action.customerActivities,
    isLoading: false,
  })),
  on(wcAuditActions.loadMentionedActivities, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(wcAuditActions.setMentionedActivities, (state, action) => ({
    ...state,
    mentionedActivities: action.mentionedActivities,
    isLoading: false,
  })),
  on(wcAuditActions.submitWcAuditCollectedDataFinished, (state, action) => ({
    ...state,
    isLoading: false,
    submissionStatus: action.submissionStatus || '',
  })),
  on(wcAuditActions.setWcAuditSummary, (state, action) => ({
    ...state,
    submissionResult: action.wcAuditSubmissionResponse,
  })),
  on(wcAuditActions.setWcAuditInitialData, (state, action) => ({
    ...state,
    selectedWcAuditData: {
      ...action.wcAuditInitialData,
    },
  })),
  on(wcAuditActions.setWcAuditDocuments, (state, action) => ({
    ...state,
    documentsByWcAuditCategory: action.documentsByWcAuditCategory,
  })),
);

export function wcAuditReducer(state: WcAuditState, action: Action): WcAuditState {
  return reducer(state, action);
}
