import { Injectable } from '@angular/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { AuthorizedUser } from '../models/authorized-user.model';

enum Placement {
  AddAuthorizedUserPage = 'add-authorized-user-page',
  AuthorizedUserModal = 'authorized-user-modal',
  AddAuthorizedUserTermsModal = 'add-authorized-user-terms-modal',
  DeleteAuthorizedUserTermsModal = 'delete-authorized-user-modal',
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizedUserTrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: Placement.AddAuthorizedUserPage,
      name: 'page-landing',
    });
  }

  /* istanbul ignore next */
  trackAddAuthorizedUserSubmit(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.AddAuthorizedUserPage,
      name: 'submit-button',
    });
  }

  /* istanbul ignore next */
  trackAddAuthorizedUserTermsModalClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.AddAuthorizedUserPage,
      name: 'open-terms-modal-button',
    });
  }

  /* istanbul ignore next */
  trackAddAuthorizedUserTermsModalOpened(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: Placement.AddAuthorizedUserTermsModal,
      name: 'terms-modal-view',
    });
  }

  /* istanbul ignore next */
  trackAddAuthorizedUserResult(isSuccess: boolean, authorizedUser?: AuthorizedUser): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: Placement.AddAuthorizedUserPage,
      name: 'add-authorized-user-result',
      interactionData: {
        isSuccess,
        authorizedUser,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteAuthorizedUserClicked(authorizedUserId: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.AuthorizedUserModal,
      name: 'delete-button',
      interactionData: {
        authorizedUserId,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteAuthorizedUser(isSuccess: boolean, authorizedUserId?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: Placement.AuthorizedUserModal,
      name: 'delete-authorized-user',
      interactionData: {
        isSuccess,
        authorizedUserId,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteAuthorizedUserModalOpened(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: Placement.DeleteAuthorizedUserTermsModal,
      name: 'delete-modal-view',
    });
  }

  /* istanbul ignore next */
  trackDeleteAuthorizedUserModalClickRemove(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.DeleteAuthorizedUserTermsModal,
      name: 'remove-button',
    });
  }

  /* istanbul ignore next */
  trackDeleteAuthorizedUserModalClickCancel(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: Placement.DeleteAuthorizedUserTermsModal,
      name: 'cancel-button',
    });
  }
}
