import '@next-insurance/web-vitals-collector';

import { ApplicationRef, NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appModules } from './app-modules';
import { appProviders } from './app-providers';
import { FooterComponent } from './shared/components/footer/footer.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [appModules, FooterComponent],
  providers: appProviders,
})
export class AppModule {
  constructor(private applicationRef: ApplicationRef) {
    if (environment.enableChangeDetectionLogs) {
      this.logChangeDetectionCycles();
    }
  }

  private logChangeDetectionCycles(): void {
    const originalTick = this.applicationRef.tick;

    this.applicationRef.tick = function (): void {
      const windowPerformance = window.performance;
      const before = windowPerformance.now();
      // eslint-disable-next-line prefer-rest-params
      const retValue = Reflect.apply(originalTick, this, arguments);
      const after = windowPerformance.now();
      const runTime = after - before;
      window.console.log('CHANGE DETECTION TIME', runTime);
      return retValue;
    };
  }
}
