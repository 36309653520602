import { createAction, props } from '@ngrx/store';

import { Policy } from '../../policies/models/policy.model';
import { ReinstateResponseStatus } from '../models/reinstate-response-status.enum';

export const reinstateActions = {
  reinstateFetchCharge: createAction('[Reinstate API] fetch charge amount', props<{ policy: Policy }>()),
  reinstateSetCharge: createAction('[Reinstate API] set charge amount', (oneTimeChargeAmount: number) => ({ oneTimeChargeAmount })),
  reinstateRequestSubmit: createAction('[Reinstate] Reinstate Agreement Submitted'),
  reinstateUpdateCardSubmit: createAction('[Reinstate] Reinstate After Update Credit Card Submit Payment'),
  reinstateFlowSuccess: createAction('[Reinstate] Reinstate flow has finished successfully'),
  reinstateFlowFailed: createAction('[Reinstate] Reinstate flow has failed', props<{ errorStatus?: ReinstateResponseStatus }>()),
  reinstateFlowNotActive: createAction('[Reinstate] There are no policies eligible for reinstate flow'),
  setSubmitting: createAction('[Reinstate] Set submitting flag', props<{ isSubmitting: boolean }>()),
};
