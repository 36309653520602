import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NiTrimInputDirective } from '@next-insurance/ni-material/ni-trim-input-directive';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';

import { FormErrorMessageDirective } from '../../../shared/directives/form-validation/form-error-message.directive';
import { ChatSharedModule } from '../../shared/chat-shared.module';
import { CustomHandlerRichMessageComponent } from './components/custom-handler-rich-message/custom-handler-rich-message.component';
import { DynamicFormFieldComponent } from './components/form-rich-message/components/dynamic-form-field/dynamic-form-field.component';
import { GenericMessageComponent } from './components/generic-rich-message/generic-message.component';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { richMessageComponents } from './rich-messages-list';

@NgModule({
  declarations: [
    ...richMessageComponents,
    GenericMessageComponent,
    DynamicFormFieldComponent,
    OnlyDigitsDirective,
    CustomHandlerRichMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChatSharedModule,
    CalendarModule,
    InputMaskModule,
    DropdownModule,
    CurrencyMaskModule,
    FormErrorMessageDirective,
    NiTrimInputDirective,
  ],
  exports: [richMessageComponents, GenericMessageComponent],
})
export class RichMessagesModule {}
