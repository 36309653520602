import { EnvConfig, envObject } from './env.config';

if (EnvConfig.isStagingOrProduction()) {
  require('./snippets/gtm.js');
}
require('./snippets/tracking.js')(envObject.trackingUrl);

export const environment = {
  ...envObject,
  production: true,
  enableAngularDebugTools: false,
  enableChangeDetectionLogs: false,
  enableStoreChangesLogs: false,
};
