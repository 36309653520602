import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PWAService {
  isPwaMode(): boolean {
    return matchMedia('(display-mode: standalone)').matches;
  }
}
