import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { businessChangeActions } from '../../business-change/store/business-change.actions';
import { FullstoryEvent } from '../../core/models/fullstory-event.enum';
import { DynamicDialogService } from '../../core/services/dynamic-dialog.service';
import { FullStoryService } from '../../core/services/fullstory.service';
import { CancelPolicyModalComponent } from '../../coverage-page/components/cancel-policy-modal/cancel-policy-modal.component';
import { UndoCancellationModalComponent } from '../../coverage-page/components/undo-cancellation-modal/undo-cancellation-modal.component';
import { crossSellActions } from '../../cross-sell/store/cross-sell.actions';
import { policiesActions } from '../../policies/store/policies.actions';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { LobNameService } from '../../shared/services/lob-name.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { CancellationErrorReason } from '../models/cancellation-error-reason.enum';
import { CancellationDataService } from '../services/cancellation.data.service';
import { CancellationTrackingService } from '../services/cancellation-tracking.service';
import { cancellationActions } from './cancellation.actions';

@Injectable()
export class CancellationEffects {
  cancelPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.cancelPolicy),
      switchMap((action) =>
        this.cancellationDataService.cancelPolicy(action.policyId, action.request).pipe(
          map(() => {
            return cancellationActions.cancelPolicySucceed({
              lob: action.lob,
              isRenewalPolicy: action.isRenewalPolicy,
            });
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            return of(
              cancellationActions.cancelPolicyFailed({
                errorReason: error.error?.niStatusCode,
                isRenewalPolicy: action.isRenewalPolicy,
              }),
            );
          }),
        ),
      ),
    ),
  );

  cancelPolicySucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.cancelPolicySucceed),
      mergeMap((action) => {
        this.dynamicDialogService.close(CancelPolicyModalComponent);
        this.cancellationTrackingService.trackCancelPolicyResult(true, action.isRenewalPolicy);
        this.fullStoryService.fireEvent(FullstoryEvent.CancelPolicy, { lob: action.lob });

        const translationKey = action.isRenewalPolicy ? 'RENEWAL_SUCCESS_MESSAGE' : 'SUCCESS_MESSAGE';

        return [
          toastActions.showToast({
            toastType: ToastType.Success,
            message: `HOME.CANCEL_POLICY_MODAL.${translationKey}`,
            translateParams: {
              lob: this.lobNameService.getLobName(action.lob),
            },
          }),
          policiesActions.loadPolicies({}),
          crossSellActions.loadCrossSell(),
          crossSellActions.loadWCSuggestion(),
          businessChangeActions.loadPermittedChanges(),
        ];
      }),
    ),
  );

  cancelPolicyFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.cancelPolicyFailed),
      map((action: { errorReason: CancellationErrorReason; isRenewalPolicy: boolean }) => {
        this.dynamicDialogService.close(CancelPolicyModalComponent);
        this.cancellationTrackingService.trackCancelPolicyResult(false, action.isRenewalPolicy);
        this.fullStoryService.fireEvent(FullstoryEvent.CancelPolicy, { errorReason: action.errorReason });
        return toastActions.showToast({
          toastType: ToastType.Error,
          message: `HOME.CANCEL_POLICY_MODAL.ERRORS.${action.errorReason || 'DEFAULT'}`,
        });
      }),
    ),
  );

  undoPolicyCancellation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.undoPolicyCancellation),
      switchMap(({ policy }) =>
        this.cancellationDataService.undoPolicyCancellation(policy.policyId).pipe(
          switchMap(() => {
            this.cancellationTrackingService.trackUndoCancellationSuccess(policy.lob);
            this.dynamicDialogService.close(UndoCancellationModalComponent);
            return [
              toastActions.showToast({
                toastType: ToastType.Success,
                message: 'HOME.UNDO_CANCELLATION_MODAL.SUCCESS_MESSAGE',
              }),
              policiesActions.loadPolicies({}),
              businessChangeActions.loadPermittedChanges(),
            ];
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            this.cancellationTrackingService.trackUndoCancellationFailure(policy.lob, error.error?.niStatusCode);
            this.dynamicDialogService.close(UndoCancellationModalComponent);
            return of(
              toastActions.showToast({
                toastType: ToastType.Error,
                message: 'HOME.UNDO_CANCELLATION_MODAL.ERROR_MESSAGE',
              }),
            );
          }),
        ),
      ),
    ),
  );

  nonRenewPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.nonRenewPolicy),
      switchMap((action) =>
        this.cancellationDataService.nonRenewPolicy(action.policyId, action.request).pipe(
          map(() => {
            return cancellationActions.nonRenewPolicySucceed({
              lob: action.lob,
            });
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            return of(
              cancellationActions.nonRenewPolicyFailed({
                errorReason: error.error?.niStatusCode,
              }),
            );
          }),
        ),
      ),
    ),
  );

  nonRenewPolicySucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.nonRenewPolicySucceed),
      mergeMap((action) => {
        this.dynamicDialogService.close(CancelPolicyModalComponent);
        this.cancellationTrackingService.trackNonRenewCoverageResult(true);
        this.fullStoryService.fireEvent(FullstoryEvent.NonRenewPolicy, { lob: action.lob });

        return [
          toastActions.showToast({
            toastType: ToastType.Success,
            message: `HOME.CANCEL_POLICY_MODAL.NON_RENEW.SUCCESS_MESSAGE`,
            translateParams: {
              lob: this.lobNameService.getLobName(action.lob),
            },
          }),
          policiesActions.loadPolicies({}),
          businessChangeActions.loadPermittedChanges(),
        ];
      }),
    ),
  );

  nonRenewPolicyFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancellationActions.nonRenewPolicyFailed),
      map((action: { errorReason: string }) => {
        this.dynamicDialogService.close(CancelPolicyModalComponent);
        this.cancellationTrackingService.trackNonRenewCoverageResult(false);
        this.fullStoryService.fireEvent(FullstoryEvent.NonRenewPolicy, { errorReason: action.errorReason });
        return toastActions.showToast({
          toastType: ToastType.Error,
          message: `HOME.CANCEL_POLICY_MODAL.NON_RENEW.ERROR_MESSAGE`,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private cancellationDataService: CancellationDataService,
    private dynamicDialogService: DynamicDialogService,
    private cancellationTrackingService: CancellationTrackingService,
    private fullStoryService: FullStoryService,
    private lobNameService: LobNameService,
  ) {}
}
