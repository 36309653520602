import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../../store';
import { navigationActions } from '../../../store/navigation.actions';
import { WcAudit } from '../../../wc-audit/models/wc-audit.model';
import { WcAuditCase } from '../../../wc-audit/models/wc-audit-case.enum';
import { WcAuditService } from '../../../wc-audit/services/wc-audit.service';
import { wcAuditSelectors } from '../../../wc-audit/store/wc-audit.selectors';
import { InteractionType } from '../../models/interaction-type.enum';
import { TrackingService } from '../../services/tracking.service';

interface BannerActionsConfig {
  translateKey: string;
  onClick?: any;
  icon?: string;
}

@Component({
  selector: 'ni-wc-audit-alert-banner',
  templateUrl: './wc-audit-alert-banner.component.html',
  styleUrls: ['./wc-audit-alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WcAuditAlertBannerComponent implements OnInit {
  wcAudits$: Observable<WcAudit[]>;
  wcAuditCase$: Observable<WcAuditCase>;
  selectedBannerConfig: BannerActionsConfig;
  ButtonType = ButtonType;
  auditId: number;

  readonly bannerActionsConfig: { [key in WcAuditCase]: BannerActionsConfig } = {
    [WcAuditCase.SingleNewAudit]: {
      translateKey: 'START_AUDIT',
      onClick: this.startAudit.bind(this),
    },
    [WcAuditCase.MultiAudits]: {
      translateKey: 'CALL_US',
      icon: '/assets/home/phone-icon.svg',
    },
    [WcAuditCase.Unknown]: null,
  };

  constructor(
    private store: Store<AppState>,
    private trackingService: TrackingService,
    private wcAuditService: WcAuditService,
  ) {}

  ngOnInit(): void {
    this.wcAuditService.loadWcAudits().subscribe();
    this.wcAudits$ = this.store.select(wcAuditSelectors.getWcAudits);
    this.wcAuditCase$ = combineLatest([
      this.store.select(wcAuditSelectors.getWcAuditCase),
      this.store.select(wcAuditSelectors.isLoading),
      this.store.select(wcAuditSelectors.getSelectedWcAudit),
    ]).pipe(
      map(([wcAuditCase, isloading, selectedAudit]: [WcAuditCase, boolean, WcAudit]) => {
        if (!isloading) {
          this.selectedBannerConfig = this.bannerActionsConfig[wcAuditCase];
          this.auditId = selectedAudit?.auditId;
          if (this.selectedBannerConfig) {
            this.track(InteractionType.View, 'banner-view', {
              bannerType: wcAuditCase,
            });
          }
        }
        return wcAuditCase;
      }),
    );
  }

  private startAudit(): void {
    this.track(InteractionType.Click, 'start-audit-clicked');
    this.store.dispatch(navigationActions.toWcAudit());
  }

  private track(interactionType: InteractionType, name: string, interactionData?: any): void {
    this.trackingService.track({
      interactionType,
      placement: 'wc-audit-alert-banner',
      name,
      interactionData: {
        ...interactionData,
        auditId: this.auditId,
      },
    });
  }
}
