import { NgClass, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AchFailureCategory } from '@next-insurance/core';
import { NiButtonRbModule } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import { CardType } from '../../../core/models/card-type.enum';
import { ZendeskIssueTag } from '../../../core/models/zendesk-issue-tag.enum';
import { ZendeskService } from '../../../core/services/zendesk.service';
import { PaymentMethodDetails, PaymentMethodType } from '../../../payment/models/payment-method-details.model';
import { PaymentService } from '../../../payment/payment.service';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { AppState } from '../../../store';
import { AchFailedPaymentNoticeComponent } from '../ach-failed-payment-notice/ach-failed-payment-notice.component';

@Component({
  selector: 'ni-payment-method-details',
  templateUrl: './payment-method-details.component.html',
  styleUrls: ['./payment-method-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AchFailedPaymentNoticeComponent, NgTemplateOutlet, TranslateModule, NiButtonRbModule, NgIf, NgClass, TitleCasePipe],
})
export class PaymentMethodDetailsComponent implements OnInit {
  readonly NA = 'N/A';
  @Output() updatePaymentMethodClicked = new EventEmitter<void>();
  @Output() openZendeskChatClicked = new EventEmitter<void>();
  private destroyRef = inject(DestroyRef);
  paymentMethodDetails: PaymentMethodDetails;
  achFailureCategory: AchFailureCategory;
  PaymentMethodType = PaymentMethodType;
  AchFailureCategory = AchFailureCategory;

  protected mapCardTypeToIconSvg: Record<CardType, string> = {
    [CardType.Visa]: 'visa.svg',
    [CardType.Mastercard]: 'mastercard.svg',
    [CardType.Amex]: 'amex.svg',
    [CardType.Discover]: 'discover.svg',
    [CardType.JCB]: 'jcb.svg',
    [CardType.Diners]: 'diners.svg',
  };

  constructor(
    private paymentService: PaymentService,
    private zendeskService: ZendeskService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.store
      .select(paymentSelectors.getPaymentMethodDetails)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((paymentMethodDetails: PaymentMethodDetails) => {
          this.paymentMethodDetails = paymentMethodDetails;
          this.achFailureCategory = this.paymentService.getAchFailureCategory(paymentMethodDetails);
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.AchFailedPaymentIssue]);
    this.openZendeskChatClicked.emit();
  }

  onUpdatePaymentMethodClicked(): void {
    this.updatePaymentMethodClicked.emit();
  }
}
