<ng-container *ngIf="!isLoading; else loading">
  <div class="container" [attr.data-step]="OnboardingStep.Final">
    <div class="img-container">
      <img src="/assets/rebrand/onboarding-completed.svg" alt="completed-icon" />
    </div>
    <div class="content-container">
      <div class="text-container">
        <div class="h4_h3-regular header">{{ 'ONBOARDING.FINAL.HEADER' | translate }}!</div>
        <div class="h6_h4-regular">{{ 'ONBOARDING.FINAL.SUB_HEADER' | translate }}:</div>
        <div class="p-sm_md messages">
          <li *ngFor="let message of messages" data-test="message">
            {{ message }}
          </li>
        </div>
      </div>
      <div class="action">
        <ni-button-rb (click)="onSubmit()" data-test="submit-button">
          {{ 'ONBOARDING.FINAL.SUBMIT' | translate }}
        </ni-button-rb>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading-animation-container" data-test="loading-animation">
    <img class="loading-animation" src="assets/animations/loading-rainbow.gif" alt="loading-animation" />
  </div>
</ng-template>
