import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { CustomerActivity } from '../models/customer-activity.model';
import { Subcontractor } from '../models/subcontractor.model';
import { AuditDocument, WcAudit, WcAuditBusinessOwner, WcAuditDocumentCategory } from '../models/wc-audit.model';
import { WcAuditCase } from '../models/wc-audit-case.enum';
import { WcAuditCollectedData } from '../models/wc-audit-collected-data.model';
import { WcAuditStatus } from '../models/wc-audit-status.enum';
import { WcAuditSummaryAmounts } from '../models/wc-audit-summary.model';
import { WcAuditVersion } from '../models/wc-audit-version.enum';
import { WcAuditState } from './wc-audit.reducer';

const wcAuditState = (state: AppState): WcAuditState => state.wcAudit;

const isLoading: MemoizedSelector<AppState, boolean> = createSelector(wcAuditState, (state: WcAuditState) => state.isLoading);

const getSubmissionStatus: MemoizedSelector<AppState, string> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.submissionStatus,
);

const getWcAudits: MemoizedSelector<AppState, WcAudit[]> = createSelector(wcAuditState, (state: WcAuditState) => state.wcAudits);

const getSelectedWcAuditData: MemoizedSelector<AppState, WcAuditCollectedData> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.selectedWcAuditData,
);

const getSelectedWcAuditDocuments: MemoizedSelector<AppState, Partial<Record<WcAuditDocumentCategory, AuditDocument[]>>> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.documentsByWcAuditCategory,
);

const getSelectedWcAudit: MemoizedSelector<AppState, WcAudit> = createSelector(wcAuditState, (state: WcAuditState) => {
  const openAudits = getOpenWcAudits(state.wcAudits);
  if (openAudits.length === 1 && openAudits[0].selfServiceFlowStatus === WcAuditStatus.NotStarted) {
    return openAudits[0];
  }
  return null;
});

const getCustomerActivities: MemoizedSelector<AppState, CustomerActivity[]> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.customerActivities,
);

const getMentionedActivities: MemoizedSelector<AppState, CustomerActivity[]> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.mentionedActivities,
);

const getInsuredSubcontractors: MemoizedSelector<AppState, Subcontractor[]> = createSelector(wcAuditState, (state: WcAuditState) =>
  state.selectedWcAuditData.subcontractors.filter((sub) => sub.insured),
);

const getShowDocumentsRequired: MemoizedSelector<AppState, boolean> = createSelector(wcAuditState, (state: WcAuditState) => {
  return (
    state.submissionResult.selfServiceDocumentsReviewRequirement.payrollsDocumentsReviewRequired ||
    state.submissionResult.selfServiceDocumentsReviewRequirement.insuredSubcontractorsDocumentsReviewRequired
  );
});

const getWcAuditCase: MemoizedSelector<AppState, WcAuditCase> = createSelector(
  getWcAudits,
  getSelectedWcAudit,
  (wcAudits: WcAudit[], selectedWcAudit: WcAudit) => {
    if (!wcAudits) {
      return WcAuditCase.Unknown;
    }
    const openAudits = getOpenWcAudits(wcAudits);
    if (selectedWcAudit) {
      if (selectedWcAudit.appliedLogicVersion === WcAuditVersion.SelfServiceToSimulation) {
        return WcAuditCase.SingleNewAudit;
      }
      return WcAuditCase.Unknown;
    }
    if (openAudits.length >= 1) {
      return WcAuditCase.MultiAudits;
    }
    return WcAuditCase.Unknown;
  },
);

const getAuditPolicyOwners: MemoizedSelector<AppState, WcAuditBusinessOwner[]> = createSelector(
  getSelectedWcAudit,
  (selectedAudit: WcAudit) => selectedAudit.auditPolicyOwners,
);

const getShowOwnerPercentage: MemoizedSelector<AppState, boolean> = createSelector(
  getSelectedWcAudit,
  (selectedAudit: WcAudit) => selectedAudit.auditOwnersConfiguration?.showOwnersPercentages,
);

const getWcAuditSummaryAmounts: MemoizedSelector<AppState, WcAuditSummaryAmounts> = createSelector(
  wcAuditState,
  (state: WcAuditState) => state.submissionResult.selfServiceWcAuditSummaryAmounts,
);

const getOpenWcAudits = (wcAudits: WcAudit[]): WcAudit[] => {
  if (wcAudits) {
    return wcAudits.filter((wcAudit) => [WcAuditStatus.NotStarted, WcAuditStatus.FlowInProgress].includes(wcAudit.selfServiceFlowStatus));
  }
  return [];
};

export const wcAuditSelectors = {
  isLoading,
  getWcAudits,
  getSelectedWcAuditData,
  getCustomerActivities,
  getInsuredSubcontractors,
  getMentionedActivities,
  getWcAuditCase,
  getAuditPolicyOwners,
  getSelectedWcAudit,
  getWcAuditSummaryAmounts,
  getShowDocumentsRequired,
  getShowOwnerPercentage,
  getSelectedWcAuditDocuments,
  getSubmissionStatus,
};
