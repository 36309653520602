const properties = require('../../server/properties/properties.json');

enum Environments {
  DEVELOPMENT = 'development',
  STAGING = 'stg',
  PRODUCTION = 'production',
}

const env = window.properties?.env || Environments.DEVELOPMENT;
const k8sNamespace = window.properties?.k8sNamespace || '';
const domain = window.properties?.domain || '';
const appVersion = window.properties?.appVersion || '';

console.log(`config env is: ${env}`);

export const EnvConfig = {
  getEnv(): string {
    return env;
  },

  isDev(): boolean {
    return env === Environments.DEVELOPMENT;
  },

  isStaging(): boolean {
    return env === Environments.STAGING;
  },

  isProduction(): boolean {
    return env === Environments.PRODUCTION;
  },

  isStagingOrProduction(): boolean {
    return EnvConfig.isProduction() || EnvConfig.isStaging();
  },

  getProp(key: string): string {
    const value = properties[env][key];
    return value ? value.replace('${K8S_NAMESPACE}', k8sNamespace).replace('${DOMAIN}', domain) : value;
  },

  getAppVersion(): string {
    return appVersion;
  },
};

export const envObject = {
  tld: EnvConfig.getProp('tld'),
  url: EnvConfig.getProp('url'),
  host: EnvConfig.getProp('host'),
  tidCookieName: EnvConfig.getProp('tracking_id_cookie_name'),
  selectedLangCookieName: EnvConfig.getProp('selectedLanguageCookieName'),
  cookiedUserCookieName: EnvConfig.getProp('cookied_user_cookie_name'),
  applicationUrl: EnvConfig.getProp('applicationUrl'),
  recaptchaKey: EnvConfig.getProp('recaptcha_key'),
  stripePKs: JSON.parse(EnvConfig.getProp('stripe_pk')),
  trackingUrl: EnvConfig.getProp('trackingUrl'),
  zendeskAccountUrl: EnvConfig.getProp('zendeskAccountUrl'),
  mobileAppWebviewCookieName: EnvConfig.getProp('mobileAppWebviewCookieName'),
  mobileAppVersionCookieName: EnvConfig.getProp('mobileAppVersionCookieName'),
  smartyStreetsKey: EnvConfig.getProp('smartyStreetsKey'),
  portalLoginIdentityCookieName: EnvConfig.getProp('portalLoginIdentityCookieName'),
  sentryClientDsn: EnvConfig.getProp('sentryClientDsn'),
  disableChatbotLoadingDelay: EnvConfig.getProp('disableChatbotLoadingDelaysCookieName'),
  fullStorySessionUrlCookieName: EnvConfig.getProp('fullStorySessionUrlCookieName'),
};
