import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { RichMessageType } from '../../enums/rich-message-type.enum';
import { ChatRichMessage } from '../../models/chat-rich-message.model';
import { AbstractChatbotCustomHandlerService } from '../../services/abstract-chatbot-custom-handler-service';
import { CustomHandlerRichMessageContent } from './custom-handler-rich-message-content.model';

@Component({
  selector: 'ni-custom-handler-rich-message',
  templateUrl: './custom-handler-rich-message.component.html',
  styleUrls: ['./custom-handler-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHandlerRichMessageComponent implements OnInit {
  static readonly richComponentType = RichMessageType.CustomHandler;
  @Input() message: ChatRichMessage<CustomHandlerRichMessageContent>;
  constructor(private chatbotCustomHandlerService: AbstractChatbotCustomHandlerService) {}

  ngOnInit(): void {
    this.chatbotCustomHandlerService.processMessage(this.message.data.richContent.params);
  }
}
