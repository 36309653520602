import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocLinkResponse } from '../../../core/models/doc-link-response.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CustomCertificateRequestChanges } from '../models/create-certificate-request.model';
import { DescriptionOfOperationParams } from '../models/description-of-operation-params.model';
import { DescriptionOfOperationsResponse } from '../models/description-of-operations-response.model';
import { LiveCertificateResponse } from '../models/live-certificate-response.model';
import { ThirdPartySubmitNotificationsRequest } from '../models/third-party-notifications-request.model';
import { ThirdPartyRequestResponse } from '../models/third-party-request-response.model';
import { ThirdPartyRequestType } from '../models/third-party-request-type.enum';

@Injectable({
  providedIn: 'root',
})
export class LiveCertificateDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getLiveCertificate(liveCertificateId: string): Observable<LiveCertificateResponse> {
    return this.httpClient.get<LiveCertificateResponse>('/api/public/certificates/live-certificate', {
      params: {
        liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  getLiveCertificateDocLink(liveCertificateId: string): Observable<DocLinkResponse> {
    return this.httpClient.get<DocLinkResponse>('/api/public/certificates/live-certificate/pdf', {
      params: {
        liveCertificateId,
      },
    });
  }

  /* istanbul ignore next */
  getPublicCertificateAllowedOperations(liveCertificateId: string): Observable<CertificateAllowedOperations> {
    return this.httpClient.get<CertificateAllowedOperations>(
      '/api/public/certificates/live-certificate/request-changes/allowed-operations',
      {
        params: {
          liveCertificateId,
        },
      },
    );
  }

  /* istanbul ignore next */
  getPublicDescriptionOfOperations(
    liveCertificateId: string,
    descriptionOfOperationParams: DescriptionOfOperationParams,
  ): Observable<DescriptionOfOperationsResponse> {
    const { specialLanguages, certificateHolder, jobLocationAddress } = descriptionOfOperationParams;
    return this.httpClient.post<DescriptionOfOperationsResponse>(
      '/api/public/certificates/live-certificate/request-changes/description-of-operations',
      {
        liveCertificateId,
        specialLanguages,
        certificateHolder,
        jobLocationAddress,
      },
    );
  }

  /* istanbul ignore next */
  requestChanges(
    liveCertificateId: string,
    customCertificateRequestChanges: CustomCertificateRequestChanges,
  ): Observable<ThirdPartyRequestResponse> {
    return this.httpClient.post<ThirdPartyRequestResponse>('/api/public/certificates/live-certificate/submit-third-party-request', {
      liveCertificateId,
      request: {
        type: ThirdPartyRequestType.Changes,
        ...customCertificateRequestChanges,
      },
    });
  }

  /* istanbul ignore next */
  requestNotifications(
    liveCertificateId: string,
    notificationsRequest: ThirdPartySubmitNotificationsRequest,
  ): Observable<ThirdPartyRequestResponse> {
    return this.httpClient.post<ThirdPartyRequestResponse>('/api/public/certificates/live-certificate/submit-third-party-request', {
      liveCertificateId,
      request: {
        type: ThirdPartyRequestType.Notifications,
        ...notificationsRequest,
      },
    });
  }

  /* istanbul ignore next */
  isCertificateOwnedByCustomer(liveCertificateId: string): Observable<boolean> {
    return this.httpClient.get<boolean>('/api/certificates/live-certificate/is-owned-by-customer', {
      params: {
        liveCertificateId,
      },
    });
  }
}
