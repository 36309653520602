import { Injectable } from '@angular/core';

import { CustomHandlerRichMessageContent } from '../../chatbot/components/rich-messages/components/custom-handler-rich-message/custom-handler-rich-message-content.model';
import { AbstractChatbotCustomHandlerService } from '../../chatbot/components/rich-messages/services/abstract-chatbot-custom-handler-service';
import { RichMessagesService } from '../../chatbot/components/rich-messages/services/rich-messages.service';
import { chatbotEventsConfig } from '../../chatbot/configs/chatbot-events.config';
import { ChatbotService } from '../../chatbot/services/chatbot.service';
import { ChatbotTrackingService } from '../../chatbot/services/chatbot-tracking.service';
import { ChatbotCustomMessageType } from '../models/chatbot-custom-message-type.enum';
import { HelpTipService } from './help-tip.service';
import { ZendeskService } from './zendesk.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotCustomHandlerService extends AbstractChatbotCustomHandlerService {
  constructor(
    private helpTipService: HelpTipService,
    private chatbotService: ChatbotService,
    private zendeskService: ZendeskService,
    private chatbotTrackingService: ChatbotTrackingService,
    private richMessagesService: RichMessagesService,
  ) {
    super();
  }

  processMessage(message: CustomHandlerRichMessageContent): void {
    switch (message.type) {
      case ChatbotCustomMessageType.HelpTip:
        this.helpTipService.helpTipClicked(message.params.helpTipKey, this.chatbotService.getSessionId());
        if (message.close) {
          this.chatbotService.closeChatbot();
        }

        if (message.minimize) {
          this.chatbotService.minimizeChatbot();
          this.richMessagesService.sendMessage({ eventName: chatbotEventsConfig.tipNavigationStarted });
        }
        break;
      case ChatbotCustomMessageType.NavigatedToExternalUrl:
        if (message.minimize) {
          this.chatbotService.minimizeChatbot();
          this.richMessagesService.sendMessage({ eventName: chatbotEventsConfig.ExternalUrlNavigationHandled });
        }
        break;
      case ChatbotCustomMessageType.AgentHandoff:
        this.chatbotTrackingService.trackLiveChatHandoff(this.chatbotService.getSessionId(), message.params);
        this.openZendeskChat(message, 'handoff_from_servicing_chatbot');
        break;
      case ChatbotCustomMessageType.ChatbotError:
        this.chatbotTrackingService.trackLiveChatOnChatbotError(this.chatbotService.getSessionId());
        this.openZendeskChat(message, 'chatbot_error');
        break;
    }
  }

  // prettier-ignore
  private openZendeskChat(message: CustomHandlerRichMessageContent, zendeskTag: string): void {
    setTimeout(
      () => {
        if (message.close) {
          this.chatbotService.closeChatbot();
        }

        this.zendeskService.openZendeskChat(message.params?.userMessage, [zendeskTag], this.chatbotService.getSessionId());
      },
      message.params?.delay || 0,
    );
  }
}
