<footer class="footer" data-test="footer-container">
  @if (!hideLogo) {
    <div class="next-logo" niMobileOnly data-test="next-logo">
      <img alt="next-logo" ngSrc="/assets/mobile-app-banner/mobile-app-icon.svg" height="36" width="36" />
    </div>
  }
  <div class="links p-sm_md">
    <ni-language-switch
      (onLanguageChanged)="languageChanged($event)"
      [isWhite]="true"
      data-test="footer-spanish-translation"
    ></ni-language-switch>
    <a (click)="navigateToTermsOfUse()" data-test="footer-terms-of-use-link" translate="GENERAL.TERMS_OF_USE"></a>
    <a (click)="navigateToPrivacyPolicy()" data-test="footer-privacy-policy-link" translate="GENERAL.PRIVACY_POLICY"></a>
    @if (isUserLoggedIn$ | async) {
      <ni-referral-program [placement]="'footer'" [icon]="true"> </ni-referral-program>
    }
  </div>
  <div class="copyright p-sm_md" data-test="footer-copyright-year">© {{ year }} Next Insurance Inc.</div>
</footer>
