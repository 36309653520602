import { interval, Observable } from 'rxjs';
import { filter, first, map, shareReplay } from 'rxjs/operators';

export function pollUntil<R>(poller: () => R): Observable<R> {
  return interval(1000).pipe(
    map(poller),
    filter((s) => !!s),
    first(),
    shareReplay(Number.POSITIVE_INFINITY),
  );
}
