import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IsVerifiedResponse } from '../models/is-verified-response.model';
import { VerificationScope } from '../models/verification-scope.model';

@Injectable({
  providedIn: 'root',
})
export class VerificationDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  validateOtp(otp: string, identityInput: string): Observable<void> {
    return this.httpClient.post<void>('/api/verification/validate-otp', {
      identityInput,
      verificationCode: otp,
    });
  }

  /* istanbul ignore next */
  sendOtp(identityInput: string, scope: VerificationScope): Observable<void> {
    return this.httpClient.post<void>('/api/verification/send-otp', {
      identityInput,
      scope,
    });
  }

  isVerified(identityValue: string): Observable<boolean> {
    return this.httpClient
      .get<IsVerifiedResponse>(`/api/verification/is-verified?identityValue=${encodeURIComponent(identityValue)}`)
      .pipe(map((response) => response.isVerified));
  }
}
