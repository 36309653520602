<ni-alert-banner *ngIf="isVisible" [closable]="false" data-test="ach-failed-payment-banner">
  <div banner-body class="banner-body" data-test="banner-body">
    <div class="banner-body-title p-sm_md-bold" data-test="title">
      {{ 'FAILED_PAYMENT_BANNER.ACH.' + failureCategory + '.TITLE' | translate }}
    </div>
    <div class="banner-body-message p-sm" data-test="message">
      {{ 'FAILED_PAYMENT_BANNER.ACH.' + failureCategory + '.MESSAGE' | translate }}
    </div>
  </div>
  <div banner-actions class="banner-actions">
    <ni-button-rb (click)="goToBillingPage()" [buttonType]="ButtonType.Tertiary" data-test="go-to-billing-button">
      {{
        'FAILED_PAYMENT_BANNER.ACH.' + (failureCategory === AchFailureCategory.InsufficientFunds ? 'MORE_DETAILS' : 'FIX_PAYMENT_NOW')
          | translate
      }}
    </ni-button-rb>
  </div>
</ni-alert-banner>
