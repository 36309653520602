import { Action, ActionReducer, ActionReducerMap, MetaReducer, State } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
// @ts-ignore
import { safeLocalStorage, safeSessionStorage } from 'safe-storage';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { EnvConfig } from '../../environments/env.config';
import { reinstateReducer, ReinstateState } from '../reinstate/store/reinstate.reducer';
import { paymentReducer, PaymentState } from '../payment/store/payment.reducer';
import { businessReducer, BusinessState } from '../business/store/business.reducer';
import { certificatesReducer, CertificatesState } from '../certificates/shared/store/certificates.reducer';
import { loginInitialState, loginReducer, LoginState } from '../login/store/login.reducer';
import { policiesReducer, PoliciesState } from '../policies/store/policies.reducer';
import { claimsReducer, ClaimsState } from '../claims/store/claims.reducer';
import { featureFlagsReducer, FeatureFlagsState } from '../feature-flags/store/feature-flags.reducer';
import { cancellationReducer, CancellationState } from '../cancellation/store/cancellation.reducer';
import { crossSellReducer, CrossSellState } from '../cross-sell/store/cross-sell.reducer';
import { coveragesReducer, CoveragesState } from '../coverages/store/coverages.reducer';
import { coreInitialState, coreReducer, CoreState } from '../core/store/core.reducer';
import { businessChangeReducer, BusinessChangeState } from '../business-change/store/business-change.reducer';
import { abTestingReducer, AbTestingState } from '../ab-testing/store/ab-testing.reducer';
import { coreActions } from '../core/store/core.actions';
import { wcAuditReducer, WcAuditState } from '../wc-audit/store/wc-audit.reducer';
import { cancelRewriteReducer, CancelRewriteState } from '../cancel-rewrite/store/cancel-rewrite.reducer';
import { CoreEffects } from '../core/store/core.effects';
import { BusinessEffects } from '../business/store/business.effects';
import { NavigationEffects } from './navigation.effects';
import { PaymentEffects } from '../payment/store/payment.effects';
import { FeatureFlagsEffects } from '../feature-flags/store/feature-flags.effects';
import { AbTestingEffects } from '../ab-testing/store/ab-testing.effects';
import { WcAuditEffects } from '../wc-audit/store/wc-audit.effects';
import { CancellationEffects } from '../cancellation/store/cancellation.effects';
import { PoliciesEffects } from '../policies/store/policies.effects';
import { BusinessChangeEffects } from '../business-change/store/business-change.effects';
import { ToastEffects } from '../shared/components/toast/store/toast.effects';
import { ReinstateEffects } from '../reinstate/store/reinstate.effects';
import { CancelRewriteEffects } from '../cancel-rewrite/store/cancel-rewrite.effects';
import { CertificatesEffects } from '../certificates/shared/store/certificates.effects';
import { LoginEffects } from '../login/store/login.effects';
import { ClaimsEffects } from '../claims/store/claims.effects';
import { CrossSellEffects } from '../cross-sell/store/cross-sell.effects';
import { CoveragesEffects } from '../coverages/store/coverages.effects';
import { developerLoginReducer, DeveloperLoginState } from '../developer-login/store/developer-login.reducer';
import { DeveloperLoginEffects } from '../developer-login/store/developer-login.effects';

export interface AppState {
  router?: RouterReducerState<any>;
  core?: CoreState;
  business?: BusinessState;
  certificates?: CertificatesState;
  coverages?: CoveragesState;
  claims?: ClaimsState;
  policies?: PoliciesState;
  payment?: PaymentState;
  featureFlags?: FeatureFlagsState;
  cancellation?: CancellationState;
  crossSell?: CrossSellState;
  businessChange?: BusinessChangeState;
  abTesting?: AbTestingState;
  wcAudit?: WcAuditState;
  reinstate?: ReinstateState;
  cancelRewrite?: CancelRewriteState;
  login?: LoginState;
  developerLogin?: DeveloperLoginState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  core: coreReducer,
  business: businessReducer,
  certificates: certificatesReducer,
  coverages: coveragesReducer,
  claims: claimsReducer,
  policies: policiesReducer,
  payment: paymentReducer,
  featureFlags: featureFlagsReducer,
  cancellation: cancellationReducer,
  crossSell: crossSellReducer,
  businessChange: businessChangeReducer,
  abTesting: abTestingReducer,
  wcAudit: wcAuditReducer,
  reinstate: reinstateReducer,
  cancelRewrite: cancelRewriteReducer,
  login: loginReducer,
  developerLogin: developerLoginReducer,
};

export const effects = [
  CoreEffects,
  BusinessEffects,
  NavigationEffects,
  PaymentEffects,
  FeatureFlagsEffects,
  AbTestingEffects,
  WcAuditEffects,
  CancellationEffects,
  PoliciesEffects,
  BusinessChangeEffects,
  ToastEffects,
  ReinstateEffects,
  CancelRewriteEffects,
  CertificatesEffects,
  LoginEffects,
  CoveragesEffects,
  ClaimsEffects,
  CrossSellEffects,
  DeveloperLoginEffects,
];

export function sessionStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { core: ['user', 'isAfterPurchase'] },
      { certificates: ['customCertificate', 'preQuestionsAnswer'] },
      { businessChange: ['businessChangeData'] },
    ],
    storage: safeSessionStorage,
    rehydrate: true,
    removeOnUndefined: true,
    storageKeySerializer: (key) => `com.ni.portal.${key}`,
  })(reducer);
}

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [],
    storage: safeLocalStorage,
    rehydrate: true,
    removeOnUndefined: true,
    storageKeySerializer: (key) => `com.ni.portal.${key}`,
  })(reducer);
}

export function resetStateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: AppState, action: Action) => {
    if (action.type === coreActions.clearState.type) {
      const clearedState = {
        login: { ...loginInitialState, errorReason: state.login.errorReason },
        core: {
          ...coreInitialState,
          isFooterVisible: state.core.isFooterVisible,
          holidayTheme: state.core.holidayTheme,
        },
        featureFlags: state.featureFlags,
        abTesting: state.abTesting,
      };
      return reducer(clearedState, action);
    }

    return reducer(state, action);
  };
}

export function logger<T>(reducer: ActionReducer<State<T>>): ActionReducer<State<T>> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<AppState>[] =
  environment.enableStoreChangesLogs && EnvConfig.isDev()
    ? [logger, sessionStorageReducer, localStorageReducer, resetStateReducer]
    : [sessionStorageReducer, localStorageReducer, resetStateReducer];
