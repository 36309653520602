export enum DocumentCenterResourceType {
  Policy = 'POLICY',
  Certificate = 'CERTIFICATE',
  CommercialAutoProofCard = 'COMMERCIAL_AUTO_PROOF_CARD',
  Receipt = 'RECEIPT',
  LossRunReport = 'LOSS_RUN_REPORT',
  Endorsement = 'ENDORSEMENT',
  Audit = 'AUDIT',
  Form = 'FORM',
}

export const documentCenterResourceTypeToPath: Map<DocumentCenterResourceType, string> = new Map([
  [DocumentCenterResourceType.Policy, 'policies'],
  [DocumentCenterResourceType.Certificate, 'certificates'],
  [DocumentCenterResourceType.CommercialAutoProofCard, 'commercial-auto-proof-cards'],
  [DocumentCenterResourceType.Receipt, 'receipts'],
  [DocumentCenterResourceType.LossRunReport, 'loss-run-reports'],
  [DocumentCenterResourceType.Endorsement, 'endorsements'],
  [DocumentCenterResourceType.Audit, 'audit'],
  [DocumentCenterResourceType.Form, 'forms'],
]);
