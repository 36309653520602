import { RetryRequestData } from '@next-insurance/ng-core';

import { InteractionType } from '../../../models/interaction-type.enum';
import { UserInteractionParams } from '../../../models/user-interaction-params.model';
import { TrackingService } from '../../../services/tracking.service';

export function retryCallbackFactory(interactionService: TrackingService): (retryRequestData: RetryRequestData) => void {
  return (retryRequestData: RetryRequestData): void => {
    const data: UserInteractionParams = {
      name: 'api retry',
      placement: 'RetryInterceptor',
      interactionData: {
        ...retryRequestData,
      },
      interactionType: InteractionType.Debug,
    };

    interactionService.track(data);
  };
}
