import { Pipe, PipeTransform } from '@angular/core';
import { LOB } from '@next-insurance/core';

import { LobCoverageDetails } from '../models/coverage-checkup-details.model';

enum CoverageRiskEnum {
  NoRisk = 'NO_RISK',
  ExposedRisk = 'EXPOSED_RISK',
  CoveredRisk = 'COVERED_RISK',
}

@Pipe({
  name: 'orderLobsCoverageDetails',
})
export class OrderLobsCoverageDetailsPipe implements PipeTransform {
  readonly mapStatusToOrder: Record<CoverageRiskEnum, number> = {
    [CoverageRiskEnum.CoveredRisk]: 0,
    [CoverageRiskEnum.NoRisk]: 1,
    [CoverageRiskEnum.ExposedRisk]: 2,
  };

  readonly mapLobToOrder: { [key in Exclude<LOB, LOB.AUTO>]: number } = {
    [LOB.BP]: 0,
    [LOB.GL]: 1,
    [LOB.PL]: 2,
    [LOB.WC]: 3,
    [LOB.CP]: 4,
    [LOB.CA]: 5,
    [LOB.IM]: 6,
  };

  transform(lobCoverageDetails: LobCoverageDetails[]): LobCoverageDetails[] {
    return lobCoverageDetails.slice().sort((first: LobCoverageDetails, second: LobCoverageDetails) => {
      const firstCoverageStatus = this.getCoverageRiskStatus(first);
      const secondCoverageStatus = this.getCoverageRiskStatus(second);

      return (
        this.mapStatusToOrder[firstCoverageStatus] - this.mapStatusToOrder[secondCoverageStatus] ||
        this.mapLobToOrder[first.lob as Exclude<LOB, LOB.AUTO>] - this.mapLobToOrder[second.lob as Exclude<LOB, LOB.AUTO>]
      );
    });
  }

  private getCoverageRiskStatus(lobCoverageDetails: LobCoverageDetails): CoverageRiskEnum {
    if (lobCoverageDetails.alreadyCovered) {
      return CoverageRiskEnum.CoveredRisk;
    }

    if (lobCoverageDetails.required) {
      return CoverageRiskEnum.ExposedRisk;
    }

    return CoverageRiskEnum.NoRisk;
  }
}
