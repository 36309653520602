import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomTitlesStrategy extends TitleStrategy {
  constructor(
    private title: Title,
    private translateService: TranslateService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    const translatedTitle = this.translateService.instant(`PAGE_TITLE.${title || 'CUSTOMER_PORTAL'}`);
    this.title.setTitle(`Next Insurance - ${translatedTitle}`);
  }
}
