import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { ChangeResultStatus } from '../../business-change/models/business-change.enum';
import { CoverageService } from '../../coverage-page/services/coverage.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { CoveragesDataService } from '../coverages.data.service';
import { PackagesResponse } from '../models/packages.model';
import { coveragesActions } from './coverages.actions';

@Injectable()
export class CoveragesEffects {
  loadPackagesForPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coveragesActions.loadPackagesForPolicy),
      switchMap((action) =>
        this.coveragesDataService.getPackagesForPolicy(action.policyId).pipe(
          map((response: PackagesResponse) => {
            const packagesDetails = response.status === ChangeResultStatus.Success ? response.packagesDetails : null;
            return coveragesActions.setPackagesForPolicy({ packagesDetails });
          }),
          catchErrorAndLog(() => {
            return [coveragesActions.loadPackagesForPolicyFailed()];
          }),
        ),
      ),
    ),
  );

  loadPackagesIfPermitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coveragesActions.loadPackagesIfPermitted),
      switchMap((action) => {
        return this.coverageService.isPermittedForPackageChange(action.policyId).pipe(
          map((isPermitted: boolean) => {
            if (isPermitted) {
              return coveragesActions.loadPackagesForPolicy({ policyId: action.policyId });
            }
            return coveragesActions.loadPackagesIfPermittedFinished();
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private coveragesDataService: CoveragesDataService,
    private coverageService: CoverageService,
  ) {}
}
