<div class="content-container">
  <div class="h4_h3-regular" data-test="header">{{ 'VERIFICATION.PHONE.EDIT.HEADER' | translate }}</div>
  <form [formGroup]="editIdentityForm">
    <div class="ni-input-label-rb">{{ 'VERIFICATION.PHONE.EDIT.PHONE_NUMBER' | translate }}</div>
    <p-inputMask
      [placeholder]="identityValue$ | async"
      data-test="identity-input"
      formControlName="phoneNumber"
      id="phone"
      mask="(999) 999-9999"
      ngDefaultControl
      unmask="true"
    >
    </p-inputMask>
  </form>
  <div class="actions short-text">
    <ni-button-rb (click)="submit()" [disabled]="editIdentityForm.invalid" [isLoading]="isSubmitting" data-test="submit-button">
      {{ 'GENERAL.SUBMIT' | translate }}
    </ni-button-rb>
    <ni-button-rb (click)="cancel()" [buttonType]="ButtonType.Secondary" [disabled]="isSubmitting" data-test="cancel-button">
      {{ 'GENERAL.CANCEL' | translate }}
    </ni-button-rb>
  </div>

  <div class="footer p-xs_sm">
    <span> {{ 'VERIFICATION.HAVING_TROUBLE' | translate }} </span>
    <div (click)="openZendeskChat()" class="chat-with-us-link">{{ 'VERIFICATION.CHAT_WITH_US' | translate }}</div>
  </div>
</div>
