import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { Policy } from '../../policies/models/policy.model';
import { PolicyStatus } from '../../policies/models/policy-status.enum';
import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';
import { PermittedChanges, RichChangesType } from '../models/business-change.enum';
import { businessChangeSelectors } from '../store/business-change.selectors';

@Injectable({
  providedIn: 'root',
})
export class PermittedChangesService {
  private permittedChanges: PermittedChanges;

  constructor(private store: Store<AppState>) {
    this.store.select(businessChangeSelectors.getPermittedChanges).subscribe((permittedChanges: PermittedChanges) => {
      this.permittedChanges = permittedChanges;
    });
  }

  isPermitted(changeType: RichChangesType, lob?: LOB): boolean {
    if (lob) {
      return this.isPermittedPerPolicy(changeType, lob);
    }
    return this.isPermittedPerBusiness(changeType);
  }

  waitForPermittedChanges(): Observable<boolean> {
    return this.store.pipe(
      select(businessChangeSelectors.isLoadingPermittedChanges),
      filter((isLoadingPermittedChanges: boolean) => !isLoadingPermittedChanges),
      first(),
    );
  }

  getPolicyForRichChange(changeType: RichChangesType): Observable<Policy> {
    return combineLatest([
      this.store.select(policiesSelectors.getPolicies),
      this.store.select(businessChangeSelectors.getPermittedChanges),
    ]).pipe(
      map(([policies, permittedChanges]: [Policy[], PermittedChanges]) => {
        return policies.find(
          (policy: Policy) =>
            permittedChanges[policy.lob]?.includes(changeType) &&
            [PolicyStatus.Active, PolicyStatus.Bound, PolicyStatus.Paid].includes(policy.policyStatus),
        );
      }),
    );
  }

  private isPermittedPerBusiness(changeType: RichChangesType): boolean {
    return Object.values(this.permittedChanges).flat().includes(changeType);
  }

  private isPermittedPerPolicy(changeType: RichChangesType, lob: LOB): boolean {
    return this.permittedChanges[lob] && this.permittedChanges[lob].includes(changeType);
  }
}
