<div class="modal" data-test="help-modal">
  <div class="ni-dialog-content" data-test="modal-content">
    <div *ngFor="let section of sections" class="option-section p-md-bold">
      <span class="option-title">
        {{ section.title | translate }}
      </span>
      <ng-container *ngFor="let option of section.options">
        <ng-container *ngIf="optionsConfig[option | as: HelpTip] as currentOption">
          <a
            (click)="onOptionClicked(option, $event)"
            *ngIf="currentOption.visible"
            [attr.help-tip]="option"
            [translate]="currentOption.displayName || helpTipsConfig[option | as: HelpTip] + '.TITLE'"
            class="option p-sm_md"
            data-test="help-option-button"
          >
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
