import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Observable } from 'rxjs';

import { ChatbotSessionType } from '../models/chatbot-session-type.enum';
import { ClaimDetails } from '../models/claim-details.model';
import { ClaimFraudLanguage } from '../models/claim-fraud-language.model';
import { ClaimPolicy } from '../models/claim-policy.model';
import { ClaimResourceRequest } from '../models/claim-resource-request.model';
import { ClaimResourcesResponse } from '../models/claim-resource-response.model';
import { ClaimResourceTypesResponse } from '../models/claim-resource-types-response.model';
import { UploadClaimResourceRequest } from '../models/claim-upload-resource-request.model';
import { LossRunsReportResponse } from '../models/loss-runs-report-response.model';

@Injectable({
  providedIn: 'root',
})
export class ClaimsDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getEncryptedBusinessId(): Observable<string> {
    return this.httpClient.get<string>('/api/chatbot/encrypted-business-id');
  }

  /* istanbul ignore next */
  getClaims(): Observable<ClaimDetails[]> {
    return this.httpClient.get<ClaimDetails[]>('/api/claims/insured-claims-details');
  }

  /* istanbul ignore next */
  getClaimPolicies(): Observable<ClaimPolicy[]> {
    return this.httpClient.get<ClaimPolicy[]>('/api/claims/claim-policies');
  }

  /* istanbul ignore next */
  getFraudLanguage(state: string, lob: LOB): Observable<ClaimFraudLanguage> {
    return this.httpClient.get<ClaimFraudLanguage>('/api/claims/fraud-language', {
      params: { state, lob },
    });
  }

  uploadChatbotClaimResources(
    sessionType: ChatbotSessionType,
    claimResourcesRequest: ClaimResourceRequest,
    businessId?: string,
  ): Observable<string> {
    return this.httpClient.post<string>(
      '/api/chatbot/upload-claim-resources',
      { claimResourcesRequest },
      {
        params: { sessionType },
        headers: businessId ? { 'Business-Id': businessId } : null,
      },
    );
  }

  /* istanbul ignore next */
  getLossRunsReport(): Observable<LossRunsReportResponse> {
    return this.httpClient.get<LossRunsReportResponse>('/api/claims/loss-runs-report/pdf');
  }

  /* istanbul ignore next */
  getClaimResources(claimId: string): Observable<ClaimResourcesResponse> {
    return this.httpClient.get<ClaimResourcesResponse>('/api/claims/resources', {
      params: { claimId },
    });
  }

  /* istanbul ignore next */
  getClaimResourceTypes(lob: LOB): Observable<ClaimResourceTypesResponse> {
    return this.httpClient.get<ClaimResourceTypesResponse>('/api/claims/resource-types-by-lob', {
      params: { lob },
    });
  }

  /* istanbul ignore next */
  uploadClaimResource(claimId: string, uploadClaimResourceRequest: UploadClaimResourceRequest): Observable<string> {
    return this.httpClient.post<string>(
      '/api/claims/upload-resource',
      { uploadClaimResourceRequest },
      {
        params: { claimId },
      },
    );
  }
}
