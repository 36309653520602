<ng-container *ngIf="{ loading: coverageCheckupService.isSubmittingAnswers$ | async } as result">
  <div [ngClass]="{ loading: result.loading }">
    <ni-loader *ngIf="result.loading; else lobsStepContainer" [isLoading]="true"></ni-loader>
    <ng-template #lobsStepContainer>
      <div class="external-lobs-step-container ni-dialog-content p-sm_md">
        <div class="lobs-container">
          <div class="h4_h3-regular" data-test="lobs-step-title">{{ 'COVERAGE_CHECKUP.EXTERNAL_LOBS.TITLE' | translate }}</div>
          <div class="sub-title" data-test="lobs-step-subtitle">
            {{ 'COVERAGE_CHECKUP.EXTERNAL_LOBS.SUB_TITLE' | translate }}
          </div>
          <div
            *ngFor="let lob of externalLobsAnswers | keyValueKeepOrder; let first = first"
            class="checkbox-container"
            data-test="lobs-step-checkbox"
            [ngClass]="{ first: first }"
          >
            <ni-checkbox-rb
              [label]="$any(lob.key) | lobName"
              [checked]="lob.value"
              (click)="$event.stopPropagation()"
              (change)="onLobChecked($any($event.target).checked, $any(lob.key))"
              [attr.data-test]="lob.key"
            >
            </ni-checkbox-rb>
          </div>
          <div class="checkbox-container last">
            <ni-checkbox-rb
              [label]="'COVERAGE_CHECKUP.EXTERNAL_LOBS.NONE' | translate"
              [checked]="isNoneSelected"
              (click)="$event.stopPropagation()"
              (change)="onNoneChecked($any($event.target).checked)"
              data-test="NONE"
            >
            </ni-checkbox-rb>
          </div>
        </div>
      </div>
      <div class="actions-container ni-dialog-footer">
        <ni-button-rb
          [buttonType]="ButtonType.Default"
          [disabled]="!isSomeOptionChecked"
          data-test="coverage-checkup-next-button"
          (click)="nextStep()"
        >
          {{ 'GENERAL.NEXT' | translate }}
        </ni-button-rb>
        <ni-button-rb [buttonType]="ButtonType.Text" data-test="coverage-checkup-back-button" (click)="moveToPreviousStep.emit()">
          {{ 'GENERAL.BACK' | translate }}
        </ni-button-rb>
      </div>
    </ng-template>
  </div>
</ng-container>
