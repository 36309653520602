/**
 * Try finding original error.
 *
 * See here for more details:
 * https://github.com/angular/angular/blob/main/packages/core/src/error_handler.ts
 * https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
 *
 * @param error Error
 * @returns Original error
 */
export function findOriginalError(error: any): any | null {
  if (!error) {
    return null;
  }

  let originalError = null;
  let nextErr = error.ngOriginalError;
  while (nextErr) {
    originalError = nextErr;
    nextErr = originalError.ngOriginalError;
  }

  return originalError;
}
