<div class="container" data-test="send-certificate-modal">
  <div class="ni-dialog-content">
    <div>
      <div class="p-sm_md" data-test="sub-title">
        {{
          'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.' + (hasPolicyWithAgent ? 'SUB_TITLE_AGENT' : 'SUB_TITLE_CUSTOMER')
            | translate
        }}
      </div>
      <form [formGroup]="sendCertificateForm" class="p-sm_md">
        <div class="form-control">
          <label class="ni-input-label-rb" for="recipientName">
            {{ 'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.RECIPIENT_NAME' | translate }}
          </label>
          <input class="ni-input-rb" data-test="recipient-name" formControlName="recipientName" id="recipientName" niTrim type="text" />
        </div>
        <div class="form-control">
          <label class="ni-input-label-rb" for="recipientEmail">
            {{ 'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.RECIPIENT_EMAIL' | translate }}
          </label>
          <input class="ni-input-rb" data-test="recipient-email" formControlName="recipientEmail" id="recipientEmail" niTrim type="text" />
        </div>
        <div class="form-control">
          <label class="ni-input-label-rb" for="message">
            {{ 'CERTIFICATES.LIVE_CERTIFICATE.SHARE.SEND_CERTIFICATE.MESSAGE' | translate }}
          </label>
          <input class="ni-input-rb" data-test="message" formControlName="message" id="message" niTrim type="textarea" />
        </div>
      </form>
    </div>
  </div>
  <div class="ni-dialog-footer with-border flex-start">
    <ni-button-rb (click)="onSubmit()" [disabled]="sendCertificateForm.invalid" [isLoading]="isSubmitting" data-test="submit-button">
      {{ 'GENERAL.SUBMIT' | translate }}
    </ni-button-rb>
    <ni-button-rb (click)="onCancel()" [buttonType]="ButtonType.Secondary">
      {{ 'GENERAL.CANCEL' | translate }}
    </ni-button-rb>
  </div>
</div>
