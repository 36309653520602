import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

import { chatbotConfig } from '../../configs/chatbot.config';
import { OutgoingMessage } from '../../models/outgoing-message.model';

@Component({
  selector: 'ni-chat-text-input',
  templateUrl: './chat-text-input.component.html',
  styleUrls: ['./chat-text-input.component.scss'],
  animations: [
    trigger('textInputAnimation', [
      transition(':enter', [style({ transform: 'translateY(100%)' }), animate(chatbotConfig.animations.textInputAnimationTiming)]),
      transition(':leave', [animate(chatbotConfig.animations.textInputAnimationTiming, style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTextInputComponent {
  @Input() exposed: boolean;
  @Output() sendMessageClicked = new EventEmitter();
  @ViewChild('userMessageInput') userMessageInput: ElementRef;
  readonly DF_MESSAGE_MAX_LENGTH = 250;
  faArrowCircleUp = faArrowCircleUp;
  invalidLength = false;
  characterCounterText = `0 / ${this.DF_MESSAGE_MAX_LENGTH}`;
  showCharCounter = false;

  @HostListener('input')
  inputChanged(): void {
    const { textContent } = this.userMessageInput.nativeElement;
    this.invalidLength = textContent.length > this.DF_MESSAGE_MAX_LENGTH;
    this.updateCharacterCounterText(textContent.length);

    if (this.invalidLength) {
      this.showCharCounter = true;
    }
  }

  sendMessage(event: MouseEvent): void {
    if (this.isMessageInvalid()) {
      return;
    }
    this.showCharCounter = false;
    const userMessage = this.userMessageInput.nativeElement.innerText.trim();

    const outgoingMessage: OutgoingMessage = {
      actualMessage: userMessage,
      displayMessage: userMessage,
      additionalParams: {
        userFreeTextInput: userMessage,
      },
    };

    this.sendMessageClicked.emit(outgoingMessage);
    this.simulateInputEvent();
    event?.preventDefault();
  }

  isMessageInvalid(): boolean {
    return this.invalidLength || !this.userMessageInput?.nativeElement?.textContent;
  }

  private updateCharacterCounterText(currentLength: number): void {
    this.characterCounterText = `${currentLength} / ${this.DF_MESSAGE_MAX_LENGTH}`;
  }

  private simulateInputEvent(): void {
    this.userMessageInput.nativeElement.innerText = '';
    const inputEvent = new InputEvent('input', { bubbles: true, cancelable: true });
    this.userMessageInput.nativeElement.dispatchEvent(inputEvent);
  }
}
