import { Action, createReducer, on } from '@ngrx/store';

import { CoverageCheckupDetails } from '../../coverage-checkup/models/coverage-checkup-details.model';
import { CrossSellPolicy } from '../models/cross-sell-policy.model';
import { CrossSellSuggestion } from '../models/cross-sell-suggestion.model';
import { crossSellActions } from './cross-sell.actions';

export interface CrossSellState {
  policies: CrossSellPolicy[];
  isLoading: boolean;
  wcSuggestion: CrossSellSuggestion;
  eligibleForCoverageCheckup: boolean;
  coverageCheckupDetails?: CoverageCheckupDetails;
}

export const crossSellInitialState: CrossSellState = {
  policies: [],
  isLoading: false,
  wcSuggestion: null,
  eligibleForCoverageCheckup: null,
};

const reducer = createReducer(
  crossSellInitialState,
  on(crossSellActions.loadCrossSell, (state) => ({
    ...state,
    isLoading: true,
    policies: [],
    coverageCheckupDetails: null,
    eligibleForCoverageCheckup: null,
  })),
  on(crossSellActions.setCrossSell, (state, action) => {
    return {
      ...state,
      policies: action.crossSellResponse.crossSellPolicies,
      eligibleForCoverageCheckup: action.crossSellResponse.eligibleForCoverageCheckup,
      coverageCheckupDetails: action.crossSellResponse.eligibleForCoverageCheckup ? action.crossSellResponse.coverageCheckupDetails : null,
      isLoading: false,
    };
  }),
  on(crossSellActions.setNotifyStatus, (state, { lob }) => ({
    ...state,
    policies: state.policies.map((policy) =>
      policy.lob === lob ? { ...policy, notifyBusinessOnLob: !policy.notifyBusinessOnLob } : policy,
    ),
  })),
  on(crossSellActions.setWCSuggestion, (state, { wcSuggestion }) => ({
    ...state,
    wcSuggestion,
  })),
);

export function crossSellReducer(state: CrossSellState, action: Action): CrossSellState {
  return reducer(state, action);
}
