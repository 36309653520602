export enum QueryParams {
  TurnOn = 'turnOn',
  TurnOff = 'turnOff',
  SetUserGroupId = 'setUserGroupId',
  TurnOnAbTests = 'turnOnAbTests',
  TurnOnAbTestVariants = 'turnOnAbTestVariants',
  TurnOffAbTests = 'turnOffAbTests',
  ReturnUrl = 'return_url',
  Operation = 'operation',
  PolicyId = 'policy_id',
  Source = 'source',
  Channel = 'channel',
  UserType = 'user_type',
  MlToken = 'ml_tok',
  MlTokenStatus = 'ml_token_status',
  RequestId = 'request_id',
  LiveCertificateId = 'live_certificate_id',
  ThirdPartyId = 'third_party_id',
  AgentId = 'agentId',
  ProjectId = 'projectId',
  LoginError = 'login_error',
  ShouldKeepTrackingId = 'keep_tracking_id',
  IsSSO = 'is_sso',
  FromSampleCOI = 'from_sample_coi',
  OnboardingVisible = 'onboarding_visible',
  Lob = 'lob',
  ChatBusinessHours = 'chat_business_hours',
  HelpTipKey = 'help_tip_key',
}
