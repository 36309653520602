import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';

import { BillingTrackingService } from '../../../billing/services/billing-tracking.service';
import { PaymentService } from '../../../payment/payment.service';
import { PaymentMethodDetailsComponent } from '../payment-method-details/payment-method-details.component';
import { BillingNotificationModalData } from './billing-notification-modal-data.model';

@Component({
  selector: 'ni-billing-notification-modal',
  templateUrl: './billing-notification-modal.component.html',
  styleUrls: ['./billing-notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PaymentMethodDetailsComponent],
})
export class BillingNotificationModalComponent implements OnInit {
  constructor(
    private paymentService: PaymentService,
    private dynamicDialogConfig: DynamicDialogConfig<BillingNotificationModalData>,
    private dynamicDialogRef: DynamicDialogRef,
    private billingTrackingService: BillingTrackingService,
  ) {}

  ngOnInit(): void {
    this.billingTrackingService.trackViewBillingNotificationModal();
    this.listenToCloseEvent();
  }

  openUpdatePaymentModal(): void {
    this.billingTrackingService.trackBillingNotificationModalEditPaymentMethodButtonClicked();
    this.dynamicDialogRef.close(true);
    this.paymentService.openUpdatePaymentMethodModal({
      isSubmittingAction$: this.dynamicDialogConfig.data.isSubmittingAction$,
      onPaymentUpdateFinished: () => this.dynamicDialogConfig.data.onPaymentUpdateFinished(),
    });
  }

  onOpenZendeskChatClicked(): void {
    this.billingTrackingService.trackBillingNotificationModalOpenZendeskChatClicked();
  }

  private listenToCloseEvent(): void {
    this.dynamicDialogRef.onClose.pipe(first()).subscribe((isActionTaken: boolean) => {
      if (!isActionTaken) {
        this.billingTrackingService.trackCloseBillingNotificationModal();
      }
    });
  }
}
