import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbTest, AbTestsMap } from '../../ab-testing/models/ab-test.model';
import { AbTestSection } from '../../ab-testing/models/ab-test-section.enum';
import { LoadAbTestsRequest } from '../../ab-testing/models/load-ab-tests-request.model';

@Injectable({
  providedIn: 'root',
})
export class AbTestingDataService {
  constructor(private httpClient: HttpClient) {}

  getAbTestsMap(request: LoadAbTestsRequest): Observable<AbTestsMap> {
    return this.httpClient.post<AbTest[]>('/api/public/ab-testing/ab-tests', request).pipe(
      map((abTests: AbTest[]) => {
        return Object.fromEntries(
          abTests.map((abTest) => [
            abTest.ab_test_name,
            {
              ...abTest,
              section: request.section,
            },
          ]),
        );
      }),
    );
  }

  /* istanbul ignore next */
  pairAbTest(section: AbTestSection): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/public/ab-testing/pair-ab-test-group', {
      section,
    });
  }

  /* istanbul ignore next */
  trackAbTest(abTestVariantId: number, section: AbTestSection): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/public/ab-testing/track-ab-test', {
      abTestVariantId,
      section,
    });
  }
}
