import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ChatSenderType } from '../rich-messages/enums/chat-sender-type.enum';
import { ChatMessage } from '../rich-messages/models/chat-message.model';

@Component({
  selector: 'ni-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesComponent implements OnInit, AfterViewChecked {
  ChatSenderType = ChatSenderType;
  @Input() messages: ChatMessage[];
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnInit(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch {}
  }

  // @ts-ignore
  trackByMessageId(index: number, item: ChatMessage): string {
    return item?.id;
  }
}
