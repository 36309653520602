import { createAction, props } from '@ngrx/store';

import { LoginResponse } from '../../login/models/login-response.model';
import { BusinessCreationRequest } from '../../shared/models/business-creation-request.model';

export const developerLoginActions = {
  submitDeveloperLoginBusinessRequest: createAction(
    '[Login] Developer login business request submit',
    props<{ businessCreationRequest: BusinessCreationRequest }>(),
  ),
  developerLoginGetOtp: createAction('[Login] Developer login business authentication', props<{ emailAddress: string }>()),
  developerLoginValidateOtp: createAction(
    '[Login] Developer login verification submit',
    props<{ verificationToken: string; email: string }>(),
  ),
  developerLoginVerificationSuccess: createAction('[Login] Developer login verification Success', props<{ response: LoginResponse }>()),
  developerLoginFailed: createAction('[Login] Developer login business authentication failed', props<{ errorReason: string }>()),
  setLoading: createAction('[Login] Set loading', props<{ isLoading: boolean }>()),
};
