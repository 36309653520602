export const chatbotConfig = {
  userInteractionFadeoutDelay: 200,
  chatbotLoadingTypingDelay: 300,
  betweenMessagesDelay: 1000,
  errorFallbackMessage:
    'I’m sorry, but there was an error. To get help, you can chat with a live support agent by clicking below, Monday-Friday from 8 am-5 pm CT.',
  events: {
    initConversation: 'InitConversation',
  },
  sessionTime: 1000 * 60 * 28,
  initChatbotRequestTimeout: 5000,
  sendMessageOrEventRequestTimeout: 10000,
  sessionTimeoutWarningMsg:
    'Just wanted to check in. If you don’t type anything in the next 15 minutes,' +
    ' then the information you entered won’t be saved and the chat will end',
  sessionTimeoutMsg: 'Your chat has ended. If you wish to try again, please restart',
  endSessionPageName: 'END_SESSION',
  animations: {
    textInputAnimationTiming: '0.3s',
  },
  chatbotEventDebounceTime: 300,
  chatbotDatePickerMinDate: '01-01-1900',
};
