import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'input:not([type=submit])[niFocus], textarea[niFocus], div[contentEditable=true][niFocus]',
})
export class NiFocusDirective implements AfterContentInit {
  constructor(private ele: ElementRef) {}

  ngAfterContentInit(): void {
    this.ele.nativeElement.focus();
  }
}
