import { createAction, props } from '@ngrx/store';

import { AuthErrorStatuses } from '../models/auth-error-status.enum';
import { LoginResponse } from '../models/login-response.model';
import { SendOtpResponse } from '../models/send-otp-response.model';
import { VerificationCodeDeliveryMethod } from '../models/verification-code-delivery-method.enum';

export const loginActions = {
  logout: createAction('[Login] Logout'),
  authenticationSubmit: createAction('[Login] Authentication Submit', props<{ loginInput: string }>()),
  authenticationSuccess: createAction('[Login] Authentication Success', props<{ response: SendOtpResponse }>()),
  authenticationFailed: createAction('[Login] Authentication failed', props<{ errorReason: AuthErrorStatuses; isSSO?: boolean }>()),
  verificationSubmit: createAction('[Login] Verification submit', props<{ verificationToken: string }>()),
  verificationSuccess: createAction('[Login] Verification Success', props<{ response: LoginResponse }>()),
  verificationFailed: createAction('[Login] Verification Failed', props<{ errorReason: AuthErrorStatuses }>()),
  resendCode: createAction('[Login] Resend code', props<{ loginInput: string }>()),
  resendCodeSuccess: createAction('[Login] Resend code success', props<{ identityType: VerificationCodeDeliveryMethod }>()),
  redirectToGoogleLogin: createAction('[Login] Redirect to google login'),
  setLoading: createAction('[Login] Set loading', props<{ isLoading: boolean }>()),
};
