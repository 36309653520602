<div
  [ngClass]="{ loading: isLoadingCancellationConfig }"
  [niFreezeWhile]="isSubmitting"
  class="cancel-policy-modal"
  data-test="cancel-policy-modal"
>
  <div>
    <ni-loader *ngIf="isLoadingCancellationConfig" [isLoading]="true"></ni-loader>
    <div *ngIf="!isLoadingCancellationConfig" class="cancel-policy-content ni-dialog-content">
      <div class="message-wrapper">
        <div class="p-sm_md">
          {{ 'HOME.CANCEL_POLICY_MODAL.MESSAGE' | translate }}
        </div>
        <div *ngIf="modalData.isRenewalPolicy" class="renewal-policy-sub-message p-sm" data-test="renewal-policy-sub-message">
          {{
            'HOME.CANCEL_POLICY_MODAL.RENEWAL_POLICY_MESSAGE'
              | translate
                : {
                    policyType: modalData.policy.lob | lobName,
                  }
          }}
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="form"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="!isLoadingCancellationConfig" [ngTemplateOutlet]="actions"></ng-container>
</div>

<ng-template #form>
  <form [formGroup]="cancellationForm">
    <div class="form-label p-sm_md">{{ 'HOME.CANCEL_POLICY_MODAL.CANCELLATION_DATE' | translate }}:</div>
    <div
      [ngClass]="{
        'select-date': selectedCancellationDateType === CancellationDateType.SelectDate,
        'non-renew': selectedCancellationDateType === CancellationDateType.NonRenew,
      }"
      class="date-picker-container"
      niSpinner
      spinnerSize="40px"
    >
      <div class="dropdown-picker-wrapper">
        <p-dropdown
          (onChange)="onCancellationTypeChanged($event.value)"
          [options]="cancellationDateTexts | keyValueKeepOrder"
          optionLabel="value"
          optionValue="key"
          data-test="cancellation-date-type-dropdown"
          formControlName="cancellationDateType"
        >
        </p-dropdown>
      </div>
      <div *ngIf="selectedCancellationDateType === CancellationDateType.SelectDate" class="date-picker-wrapper">
        <p-calendar
          #cancellationCalendar
          (onSelect)="trackSelectedDate()"
          [maxDate]="maxCancellationDate"
          [minDate]="minCancellationDate"
          [readonlyInput]="true"
          [showIcon]="true"
          class="ni-date-picker"
          data-test="cancellation-date-picker"
          formControlName="date"
        >
        </p-calendar>
      </div>
    </div>

    <ni-system-feedback
      *ngIf="selectedCancellationDateType === CancellationDateType.NonRenew"
      [feedbackType]="FeedbackType.Default"
      class="system-feedback non-renewal-information-box"
      data-test="non-renewal-information-box"
    >
      {{ 'HOME.CANCEL_POLICY_MODAL.NON_RENEWAL_INFORMATION' | translate }}
    </ni-system-feedback>

    <div class="form-label p-sm_md">{{ 'HOME.CANCEL_POLICY_MODAL.SELECT_REASON' | translate }}</div>
    <ni-radio-button-list-rb>
      <ni-radio-button-rb
        *ngFor="let reason of cancellationReasons | randomizeCancellationReasonsOrder; let i = index"
        [label]="'HOME.CANCEL_POLICY_MODAL.REASONS.' + reason | translate"
        [tabindex]="i"
        [value]="reason"
        data-test="cancel-policy-reason"
        formControlName="reason"
        name="cancel-reason"
      >
      </ni-radio-button-rb>
    </ni-radio-button-list-rb>
  </form>
</ng-template>

<ng-template #actions>
  <div class="ni-dialog-footer sticky flex-start with-border">
    <ni-button-rb (click)="keepCoverage()" [buttonType]="ButtonType.Tertiary" [disabled]="isSubmitting" data-test="keep-coverage-button">
      {{ 'HOME.CANCEL_POLICY_MODAL.ACTIONS.KEEP_COVERAGE' | translate }}
    </ni-button-rb>
    <ni-button-rb
      (click)="cancelCoverage()"
      [buttonType]="ButtonType.Destructive"
      [disabled]="!cancellationForm.valid"
      [isLoading]="isSubmitting"
      data-test="cancel-coverage-submit-button"
    >
      {{
        (selectedCancellationDateType === CancellationDateType.NonRenew
          ? 'HOME.CANCEL_POLICY_MODAL.ACTIONS.NON_RENEW_COVERAGE'
          : 'HOME.CANCEL_POLICY_MODAL.ACTIONS.CANCEL_COVERAGE'
        ) | translate
      }}
    </ni-button-rb>
  </div>
</ng-template>
