import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OutgoingMessage } from '../../../models/outgoing-message.model';

@Injectable()
export class RichMessagesService {
  private richMessagesSubject$: BehaviorSubject<OutgoingMessage> = new BehaviorSubject(null);
  private _richMessages$: Observable<OutgoingMessage> = this.richMessagesSubject$.asObservable();

  get richMessages$(): Observable<OutgoingMessage> {
    return this._richMessages$;
  }

  sendMessage(outgoingMessage: OutgoingMessage | string): void {
    let outgoingMessageForSend: OutgoingMessage = {};
    if (typeof outgoingMessage === 'string') {
      outgoingMessageForSend = {
        actualMessage: outgoingMessage,
        displayMessage: outgoingMessage,
      };
    } else if (typeof outgoingMessage === 'object') {
      outgoingMessageForSend = outgoingMessage;
    }

    this.richMessagesSubject$.next(outgoingMessageForSend);
  }

  cleanSubject(): void {
    this.richMessagesSubject$.next(null);
  }
}
