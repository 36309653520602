<div [attr.data-step]="OnboardingStep.MobileApp" class="container">
  <img [src]="stepSettings[currentStepStatus].icon" alt="step-icon" class="step-img" data-test="step-icon" />
  <div class="content">
    <div class="header h4_h3-regular" data-test="header">
      {{ stepSettings[currentStepStatus].header | translate }}
    </div>
    <p data-test="description" class="h6_h4-regular">{{ 'ONBOARDING.DOWNLOAD_APP.INITIAL.CONTENT' | translate }}</p>
    <div niDesktopOnly data-test="qr-code">
      <img src="/assets/onboarding/mobile-app-qr-code.png" alt="qr-code" class="qr-code" />
    </div>
    <div *ngIf="!isStepCompleted" class="actions qr-code-actions" data-test="actions">
      <ni-button-rb niMobileOnly (click)="downloadApp()" data-test="download-app-button">
        {{ 'ONBOARDING.DOWNLOAD_APP.DOWNLOAD_BUTTON' | translate }}
      </ni-button-rb>
      <ni-button-rb niMobileOnly (click)="onSkip()" [buttonType]="ButtonType.Text" class="skip-button" data-test="skip-step-button">
        {{ 'ONBOARDING.SKIP' | translate }}
      </ni-button-rb>
      <ni-button-rb niDesktopOnly (click)="onSkip()" class="skip-button" data-test="skip-qr-step-button">
        {{ 'GENERAL.NEXT' | translate }}
      </ni-button-rb>
    </div>
  </div>
</div>
