import { inject, Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { policiesSelectors } from '../../policies/store/policies.selectors';
import { AppState } from '../../store';

@Injectable({
  providedIn: 'root',
})
export class LobNameService {
  private translate = inject(TranslateService);
  private store = inject(Store<AppState>);

  getLobName(lob: LOB, displayType?: 'initials' | 'short', cob?: number): string {
    const translationObj = this.translate.instant('GENERAL');
    const cobId = cob || this.getCobId();
    const translationKey = lob;
    const translationTextByCob = translationObj.LOB_WITH_COB?.[translationKey]?.[cobId];

    if (translationTextByCob) {
      return translationTextByCob;
    }

    if (displayType === 'initials') {
      return translationObj.LOB_INITIALS[translationKey];
    }

    if (displayType === 'short') {
      return translationObj.LOB_SHORT[translationKey];
    }

    return translationObj.LOB[translationKey];
  }

  private getCobId(): number {
    let cobId: number;
    this.store
      .select(policiesSelectors.getCOBId)
      .pipe(first())
      .subscribe((cob) => {
        cobId = cob;
      });
    return cobId;
  }
}
