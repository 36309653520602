<div class="business-answers-step-container ni-dialog-content p-sm_md">
  <div class="h4_h3-regular" data-test="business-answers-title">{{ 'COVERAGE_CHECKUP.BUSINESS_ANSWERS.TITLE' | translate }}</div>
  <div
    *ngFor="let answer of businessPropertiesAnswers | keyValueKeepOrder; let first = first; let last = last"
    class="checkbox-container"
    data-test="answer-checkbox"
    [ngClass]="{ first: first, last: last }"
  >
    <ni-checkbox-rb
      [label]="'COVERAGE_CHECKUP.BUSINESS_ANSWERS.ANSWERS.' + answer.key | translate"
      [checked]="answer.value"
      (click)="$event.stopPropagation()"
      (change)="onAnswerChecked($any($event.target).checked, $any(answer.key))"
      [attr.data-test]="answer.key"
    >
    </ni-checkbox-rb>
  </div>
</div>
<div class="actions-container ni-dialog-footer">
  <ni-button-rb
    [buttonType]="ButtonType.Default"
    data-test="coverage-checkup-next-button"
    (click)="nextStep()"
    [disabled]="!isSomeOptionChecked"
  >
    {{ 'GENERAL.NEXT' | translate }}
  </ni-button-rb>
  <ni-button-rb [buttonType]="ButtonType.Text" data-test="coverage-checkup-back-button" (click)="moveToPreviousStep.emit()">
    {{ 'GENERAL.BACK' | translate }}
  </ni-button-rb>
</div>
