import { Action, createReducer, on } from '@ngrx/store';

import { cancelRewriteActions } from './cancel-rewrite.actions';

export interface CancelRewriteState {
  isLoading: boolean;
}

export const cancelRewriteInitialState: CancelRewriteState = {
  isLoading: false,
};

const reducer = createReducer(
  cancelRewriteInitialState,
  on(cancelRewriteActions.completeCancelRewrite, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(cancelRewriteActions.cancelRewriteCompleted, cancelRewriteActions.cancelRewriteFailed, (state) => ({
    ...state,
    isLoading: false,
  })),
);

export function cancelRewriteReducer(state: CancelRewriteState, action: Action): CancelRewriteState {
  return reducer(state, action);
}
