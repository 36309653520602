import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';

import { HelpTip } from '../../../../core/models/help-tip.enum';
import { HelpTipDirective } from '../../../directives/help-tip.directive';

@Component({
  selector: 'ni-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HelpTipDirective, NgClass, NgIf, FontAwesomeModule],
})
export class MenuOptionComponent {
  @Input() label: string;
  @Input() icon?: string;
  @Input() isLoading?: boolean;
  @Input() helpTip?: HelpTip;
  @Output() clicked = new EventEmitter<MouseEvent>();
  faCircleNotch = faCircleNotch;
  HelpTip = HelpTip;
}
