interface FormErrorMessageConfig {
  translateKey: string;
  defaultErrors: Record<string, string>;
}

export const formErrorMessageConfig: FormErrorMessageConfig = {
  translateKey: 'GENERAL.ERRORS.FORM',
  defaultErrors: {
    required: 'REQUIRED',
    email: 'EMAIL',
    minlength: 'MIN_LENGTH',
    maxlength: 'MAX_LENGTH',
    default: 'DEFAULT',
  },
};
