export const chatbotEventsConfig = {
  initConversation: 'InitConversation',
  success: 'SUCCESS',
  submitForm: 'form.submit',
  cancelForm: 'form.cancel',
  multipleSelectionSubmit: 'multiple-selection.submit',
  submitDate: 'date.submit',
  tipNavigationStarted: 'TipNavigationStarted',
  ExternalUrlNavigationHandled: 'ExternalUrlNavigationHandled',
};
