export enum CancelRewriteCompleteStatus {
  Success = 'SUCCESS',
  TimeExpired = 'TIME_TO_APPROVE_EXPIRED',
  PaymentFailed = 'PAYMENT_FAILED',
  PolicyHasUpdatedRequest = 'POLICY_HAS_UPDATED_REQUEST',
  InvalidInput = 'INVALID_INPUT',
  FailedToCreatePolicies = 'FAILED_TO_CREATE_POLICIES',
  FailedToBindPolicy = 'FAILED_TO_BIND_POLICY',
  GeneralError = 'GENERAL_ERROR',
  FailedToCreatePoliciesOfferExpired = 'FAILED_TO_CREATE_POLICIES_OFFER_EXPIRED',
}

export enum CancelRewriteCompleteStatusV2 {
  Success = 'SUCCESS',
  TimeExpired = 'CANCEL_REWRITE_TIME_TO_APPROVE_EXPIRED',
  PaymentFailed = 'CANCEL_REWRITE_PAYMENT_FAILED',
  PolicyHasUpdatedRequest = 'CANCEL_REWRITE_POLICY_HAS_UPDATED_REQUEST',
  InvalidInput = 'CANCEL_REWRITE_INVALID_INPUT',
  FailedToCreatePolicies = 'CANCEL_REWRITE_FAILED_TO_CREATE_POLICIES',
  FailedToBindPolicy = 'CANCEL_REWRITE_FAILED_TO_BIND_POLICY',
  GeneralError = 'GENERAL_ERROR',
  FailedToCreatePoliciesOfferExpired = 'CANCEL_REWRITE_FAILED_TO_CREATE_POLICIES_OFFER_EXPIRED',
}
