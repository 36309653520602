<ng-container *ngIf="paymentMethodType$ | async as paymentMethodType">
  <ni-credit-card-failed-payment-alert-banner
    *ngIf="paymentMethodType === PaymentMethodType.CreditCard"
    data-test="credit-card-failed-payment-banner"
  ></ni-credit-card-failed-payment-alert-banner>
  <ni-ach-failed-payment-banner
    *ngIf="paymentMethodType === PaymentMethodType.BankAccount"
    data-test="ach-failed-payment-banner"
  ></ni-ach-failed-payment-banner>
</ng-container>
