export enum ThirdPartyRequestErrorReason {
  ApplyChangePaymentFailed = 'THIRD_PARTY_REQUEST_CERTIFICATE_APPLY_CHANGE_PAYMENT_FAILED',
  StatusIsNotPending = 'THIRD_PARTY_REQUEST_STATUS_IS_NOT_PENDING',
  WrongBusinessId = 'THIRD_PARTY_WRONG_BUSINESS_ID',
}

export enum ThirdPartyNotificationsRequestErrorReason {
  ThirdPartyAlreadyFollowsThisBusiness = 'THIRD_PARTY_ALREADY_FOLLOWS_THIS_BUSINESS',
  ThirdPartyHasPendingFollowRequest = 'THIRD_PARTY_ALREADY_HAS_PENDING_FOLLOW_REQUEST',
}
