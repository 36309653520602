import { Injectable } from '@angular/core';

import { GtmCategory, GtmEvent } from '../models/gtm-events.enum';
import { UserInteractionParams } from '../models/user-interaction-params.model';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private dataLayer: any[];

  constructor() {
    this.dataLayer = (window as any).dataLayer || [];
  }

  sendGTMInteractionEvent(event: GtmEvent, eventCategory: GtmCategory, eventAction = '', eventLabel = ''): void {
    this.dataLayer.push({
      event,
      eventCategory,
      eventAction,
      eventLabel,
    });
  }

  sendUserInteractionAsGTMEvent(userInteractionParams: UserInteractionParams): void {
    const { data, userType, isMobileWebview, businessId } = userInteractionParams.interactionData;
    this.dataLayer.push({
      event: GtmEvent.Interaction,
      eventName: userInteractionParams.eventName,
      data: JSON.stringify(data),
      userType,
      isMobileWebview,
      businessId,
    });
  }
}
