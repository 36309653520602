type ChunkLoadError = { name: 'ChunkLoadError' };

export function isAnyChunkLoadError(error: unknown): error is ChunkLoadError | { rejection: ChunkLoadError } {
  return isChunkLoadError(error) || isChunkLoadErrorRejection(error);
}

export function isChunkLoadError(error: unknown): error is ChunkLoadError {
  return (error as any)?.name === 'ChunkLoadError';
}

export function isChunkLoadErrorRejection(error: unknown): error is { rejection: ChunkLoadError } {
  return (error as any)?.rejection?.name === 'ChunkLoadError';
}
