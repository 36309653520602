import { createAction, props } from '@ngrx/store';

import { CustomerActivity } from '../models/customer-activity.model';
import { AuditDocument, WcAudit, WcAuditDocumentCategory } from '../models/wc-audit.model';
import { WcAuditCollectedData } from '../models/wc-audit-collected-data.model';
import { WcAuditSubmissionResponse } from '../models/wc-audit-summary.model';

const initWcAuditsLoading = createAction('[WcAudit API] Load wc audits');

const setWcAudits = createAction('[WcAudit] Set wc audits', props<{ wcAudits: WcAudit[] }>());

const setIsLoading = createAction('[WcAudit API] Set is loading', props<{ isLoading: boolean }>());

const setWcAuditCollectedData = createAction(
  '[WcAudit] Set wc audit collectedData',
  props<{ stepCollectedData: Partial<WcAuditCollectedData> }>(),
);

const submitWcAuditCollectedDataV2 = createAction('[WcAudit API] Submit wc audit collected data V2');

const submitWcAuditCollectedDataFinished = createAction(
  '[WcAudit] Submit wc audit collected data finished',
  props<{ submissionStatus: string }>(),
);

const setWcAuditSummary = createAction('[WcAudit] Set wc audit summary', props<{ wcAuditSubmissionResponse: WcAuditSubmissionResponse }>());

const loadCustomerActivities = createAction('[WcAudit API] Load customer activities', props<{ wcAuditId: number }>());

const setCustomerActivities = createAction('[WcAudit] Set customer activities', props<{ customerActivities: CustomerActivity[] }>());

const loadMentionedActivities = createAction('[WcAudit API] Load mentioned activities', props<{ wcAuditId: number }>());

const setMentionedActivities = createAction('[WcAudit] Set mentioned activities', props<{ mentionedActivities: CustomerActivity[] }>());

const setWcAuditInitialData = createAction('[WcAudit] Set wc audit initial data', props<{ wcAuditInitialData: WcAuditCollectedData }>());

const setWcAuditDocuments = createAction(
  '[WcAudit] Set wc audit documents',
  props<{ documentsByWcAuditCategory: Partial<Record<WcAuditDocumentCategory, AuditDocument[]>> }>(),
);

export const wcAuditActions = {
  initWcAuditsLoading,
  setWcAudits,
  setIsLoading,
  submitWcAuditCollectedDataV2,
  submitWcAuditCollectedDataFinished,
  setWcAuditSummary,
  setWcAuditCollectedData,
  loadCustomerActivities,
  setCustomerActivities,
  loadMentionedActivities,
  setMentionedActivities,
  setWcAuditInitialData,
  setWcAuditDocuments,
};
