import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: 'i[pTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public tooltip: Tooltip,
  ) {}

  ngOnInit(): void {
    this.tooltip.setOption({ tooltipEvent: 'focus' });
    this.setIconStyle();
  }

  private setIconStyle(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', '20px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'vertical-align', 'middle');
    this.renderer.addClass(this.elementRef.nativeElement, 'ni-icon-tooltip');
    this.renderer.addClass(this.elementRef.nativeElement, 'icon');
    this.renderer.addClass(this.elementRef.nativeElement, 'info-icon');
  }

  @HostListener('mouseover') onMouseOver(): void {
    this.tooltip.activate();
  }

  @HostListener('mouseout') onMouseLeave(): void {
    this.tooltip.deactivate();
  }

  @HostListener('click') onClick(): void {
    this.tooltip.activate();
  }

  @HostListener('document:click', ['$event.target']) onClickOutside(targetElement: HTMLElement): void {
    if (!this.elementRef.nativeElement?.contains(targetElement)) {
      this.tooltip.deactivate();
    }
  }
}
