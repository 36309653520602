import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { HolidayThemeElement } from '../../core/models/holiday-theme-element.enum';
import { HolidayTheme } from '../../core/models/holiday-themes.model';
import { coreSelectors } from '../../core/store/core.selectors';
import { AppState } from '../../store';

@Directive({
  selector: '[niHolidayTheme]',
  standalone: true,
})
export class HolidayDecorDirective implements AfterViewInit {
  @Input() niHolidayTheme: HolidayThemeElement;
  mapTypeToFunc = {
    [HolidayThemeElement.Header]: this.setHeaderBackground.bind(this),
    [HolidayThemeElement.Logo]: this.setLogo.bind(this),
  };

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {}

  ngAfterViewInit(): void {
    const holidayTheme = this.getHolidayTheme();
    if (holidayTheme) {
      this.mapTypeToFunc[this.niHolidayTheme](holidayTheme);
      this.cdr.markForCheck();
    }
  }

  private setHeaderBackground(holidayTheme: HolidayTheme): void {
    setTimeout(() => {
      this.elementRef.nativeElement.style.backgroundImage = `url('${holidayTheme.background}')`;
      this.elementRef.nativeElement.style.backgroundRepeat = 'no-repeat';
      this.elementRef.nativeElement.style.backgroundSize = 'cover';
      this.elementRef.nativeElement.style.backgroundPositionY = 'bottom';
      if (holidayTheme.headerBackgroundColor) {
        this.elementRef.nativeElement.style.backgroundColor = holidayTheme.headerBackgroundColor;
      }
    }, 0);
  }

  private setLogo(holidayTheme: HolidayTheme): void {
    setTimeout(() => {
      this.elementRef.nativeElement.style.backgroundImage = `url('${holidayTheme.logo}')`;
    }, 0);
  }

  private getHolidayTheme(): HolidayTheme {
    let theme: HolidayTheme;
    this.store
      .select(coreSelectors.getHolidayTheme)
      .pipe(first())
      .subscribe((holidayTheme: HolidayTheme) => {
        theme = holidayTheme;
      });
    return theme;
  }
}
