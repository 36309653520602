export enum InteractionType {
  View = 'view',
  Api = 'api',
  Debug = 'debug',
  Change = 'change',
  Click = 'click',
  Error = 'error',
  AbTest = 'ab_test',
  Navigation = 'navigation',
  ServerRequest = 'app_server_request',
  Performance = 'performance',
  Event = 'event',
}
