import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niCurrency',
  standalone: true,
})
export class NiCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, format = '1.0-2', fallbackValue?: string): string {
    if (!value && fallbackValue) {
      return fallbackValue;
    }
    return this.currencyPipe.transform(value, 'USD', 'symbol', format);
  }
}
