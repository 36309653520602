export enum CancelRewriteGetPaymentDetailsStatus {
  CancelRewriteAlreadyCompleted = 'CANCEL_REWRITE_ALREADY_COMPLETED',
  InvalidInput = 'INVALID_INPUT',
  SessionExpired = 'SESSION_EXPIRED',
  PaymentFailed = 'PAYMENT_FAILED',
  QuoteGotDecline = 'QUOTE_GOT_DECLINE',
  FailedToGetFcra = 'FAILED_TO_GET_FCRA',
  GeneralError = 'GENERAL_ERROR',
  ApplyInProgress = 'APPLY_IN_PROGRESS',
}

export enum CancelRewriteGetPaymentDetailsStatusV2 {
  CancelRewriteAlreadyCompleted = 'CANCEL_REWRITE_CANCEL_REWRITE_ALREADY_COMPLETED',
  InvalidInput = 'CANCEL_REWRITE_INVALID_INPUT',
  SessionExpired = 'CANCEL_REWRITE_SESSION_EXPIRED',
  PaymentFailed = 'CANCEL_REWRITE_PAYMENT_FAILED',
  QuoteGotDecline = 'CANCEL_REWRITE_QUOTE_GOT_DECLINE',
  FailedToGetFcra = 'CANCEL_REWRITE_FAILED_TO_GET_FCRA',
  GeneralError = 'GENERAL_ERROR',
  ApplyInProgress = 'CANCEL_REWRITE_APPLY_IN_PROGRESS',
}
