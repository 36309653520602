<div class="confirmation-container" data-test="exit-confirmation-container">
  <div class="header">
    <label>Confirmation</label>
  </div>
  <div class="content">
    <div class="message">Are you sure you want to exit? Your chat progress won’t be saved.</div>
    <div class="buttons-container">
      <ni-chat-button customClass="secondary" (clicked)="onCancel()" data-test="cancel-exit-button"> Cancel </ni-chat-button>
      <ni-chat-button customClass="primary" (clicked)="onConfirm()" data-test="confirm-exit-button"> Exit </ni-chat-button>
    </div>
  </div>
</div>
<div class="mask"></div>
