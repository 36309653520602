import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  isAndroid(): boolean {
    return window.navigator.userAgent.toLowerCase().includes('android');
  }

  isIos(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
}
