import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class BrowserCompatabilityService {
  browserName: string;
  browserVersion: string;

  constructor() {
    const userAgent = UAParser(window.navigator.userAgent);
    this.browserName = userAgent.browser.name;
    this.browserVersion = userAgent.browser.version;
  }

  isSafari(): boolean {
    return this.browserName.toLowerCase().includes('safari');
  }

  isAspectRatioSupported(): boolean {
    if (!this.isBrowserAndVersionDetected()) return false;

    const isSafari = this.isSafari();
    const browserVersionSplit = this.browserVersion.split('.');
    const browserVersionNumber = +browserVersionSplit[0];

    return !isSafari || (isSafari && browserVersionNumber >= 15);
  }

  private isBrowserAndVersionDetected(): boolean {
    return !!this.browserName && !!this.browserVersion;
  }
}
