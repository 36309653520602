import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { businessActions } from '../../business/store/business.actions';
import { businessSelectors } from '../../business/store/business.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { coreActions } from '../store/core.actions';

let store: Store<AppState>;
let httpClient: HttpClient;

const setBusinessId = (businessId: string): boolean => {
  store.dispatch(businessActions.setBusinessId({ businessId }));
  store.dispatch(coreActions.setIsUserLoggedIn({ isUserLoggedIn: !!businessId }));
  return true;
};

const getBusinessId = (ignoreAuthError = false): Observable<boolean> => {
  return httpClient.get('/api/business/id').pipe(
    map((businessId: string) => setBusinessId(businessId)),
    catchError((err) => {
      if (ignoreAuthError && [401, 403, 441].includes(err.status)) {
        setBusinessId('');
        return of(true);
      }

      return throwError(() => err);
    }),
    catchErrorAndLog(() => of(false)),
  );
};

export const businessIdGuard: CanActivateFn = (): Observable<boolean> => {
  store = inject(Store<AppState>);
  httpClient = inject(HttpClient);

  return store.pipe(
    select(businessSelectors.getBusinessId),
    first(),
    switchMap((businessId: string) => {
      if (!businessId) {
        return getBusinessId();
      }
      return of(true);
    }),
  );
};

export const publicCertificateBusinessIdGuard: CanActivateFn = (): Observable<boolean> => {
  store = inject(Store<AppState>);
  httpClient = inject(HttpClient);

  // Authentication errors or mixed sessions don't affect the public live certificate route,
  // as users can access it even if they're not logged in.
  return getBusinessId(true);
};
