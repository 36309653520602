import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { TrackingService } from '../../core/services/tracking.service';
import { AppState } from '../../store';
import { WcAudit, WcAuditDocumentCategory } from '../models/wc-audit.model';
import { WcAuditStepId } from '../models/wc-audit-step-id.enum';
import { wcAuditSelectors } from '../store/wc-audit.selectors';

@Injectable({
  providedIn: 'root',
})
export class WcAuditTrackingService {
  constructor(
    private trackingService: TrackingService,
    private store: Store<AppState>,
  ) {}

  /* istanbul ignore next */
  trackWcStartPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-start-page',
      name: 'start-page-view',
      eventName: 'wc audit - VIEW start page',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcStartAuditClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-start-page',
      name: 'start-audit-button-click',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcStartHelpLinkClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-start-page',
      name: 'help-link-click',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcEmployeesPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'employees-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcSubcontractorsPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'subcontractors-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcOwnersPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'owners-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcEditableOwnersPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'editable-owners-page-view',
      eventName: 'wc audit owners - VIEW editable owners step',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcAuditCompletionPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'audit-completion-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcAuditDocumentsStepLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'audit-documents-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackDocumentsUploadByCategoryView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'audit-documents-upload-by-category-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteOwnerButtonClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'delete-owner-button',
      eventName: 'wc audit owners - CLICK delete owner button',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackAddOwnerButtonClick(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'add-owner-button',
      eventName: 'wc audit owners - CLICK add owner button',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackOwnerCoverageCheckboxClick(insured: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'owner-coverage-checkbox',
      interactionData: {
        insured,
        auditId: this.auditId,
      },
      eventName: `wc audit owners - CLICK owner coverage checkbox`,
    });
  }

  /* istanbul ignore next */
  trackDocumentAddedForUpload(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'document-added-for-upload',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackDeleteDocumentClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'delete-document-button',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackGetDocumentUploadUrlResult(succeed: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'wc-audit-wizard',
      name: 'get-document-upload-url',
      interactionData: {
        succeed,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackUploadFileToS3Result(succeed: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'wc-audit-wizard',
      name: 'upload-audit-file-to-s3',
      interactionData: {
        succeed,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcReviewPageLanding(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-wizard',
      name: 'review-page-view',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcReviewPageEditButtonClick(stepId: WcAuditStepId): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'review-page-edit-button-click',
      interactionData: {
        stepId,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcAuditCompletedSubmitResult(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'wc-audit-completed',
      name: 'completed-result',
      interactionData: {
        isSuccess,
        errorReason,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackWcAuditSaveDraftData(isSuccess: boolean, errorReason?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'wc-audit-wizard',
      name: 'save-draft',
      interactionData: {
        isSuccess,
        errorReason,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackPayrollAlternativeDocumentsDialogView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-payroll-alternative-documents-dialog',
      name: 'view-dialog',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackPayrollAlternativeDocumentsDialogClose(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-payroll-alternative-documents-dialog',
      name: 'close-dialog',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackSaveForLaterDialogView(): void {
    this.trackingService.track({
      interactionType: InteractionType.View,
      placement: 'wc-audit-save-for-later-dialog',
      name: 'view-dialog',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackSaveForLaterIUnderstandBtnClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-save-for-later-dialog',
      name: 'click-i-understand-close-button',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackSaveForLaterContinueUploadBtnClicked(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-save-for-later-dialog',
      name: 'click-continue-upload-button',
      interactionData: {
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackAuditDocumentUploadCategoryClick(category: WcAuditDocumentCategory): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'wc-audit-wizard',
      name: 'document-upload-category-click',
      interactionData: {
        category,
        auditId: this.auditId,
      },
    });
  }

  /* istanbul ignore next */
  trackAuditStepChange(currentStepId: WcAuditStepId, dir: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Change,
      placement: 'wc-audit-wizard',
      name: 'change-step-change',
      interactionData: {
        currentStepId,
        direction: dir > 0 ? 'NEXT' : 'PREV',
        auditId: this.auditId,
      },
      eventName: 'wc audit - CHANGE change step',
    });
  }

  private get auditId(): number {
    let auditId: number;
    this.store.pipe(select(wcAuditSelectors.getSelectedWcAudit), first()).subscribe((wcAudit: WcAudit) => {
      auditId = wcAudit.auditId;
    });
    return auditId;
  }
}
