export enum PartnerType {
  Common = 0,
  Hiscox = 1,
  Markel = 2,
  MunichRe = 3,
  Atlas = 4,
  Btis = 5,
  Next = 7,
  NextCarrier = 8,
  NationalSpecialty = 9,
}
