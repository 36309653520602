import { Action, createReducer, on } from '@ngrx/store';

import { Follower } from '../../../followers/models/follower.model';
import { AutoInsuranceCard } from '../models/auto-insurance-card.model';
import { Certificate } from '../models/certificate.model';
import { CertificateAllowedModifiers } from '../models/certificate-allowed-modifiers.model';
import { CertificateAllowedOperations } from '../models/certificate-allowed-operations.model';
import { CertificateHolderRequirementPartialInformation } from '../models/certificate-holder-requirement-partial-information.model';
import { CertificateHolderRequirements } from '../models/certificate-holder-requirements.model';
import { CreateCertificateFromHolderRequirements } from '../models/create-certificate-from-holder-requirements.model';
import { CreateCertificateRequest, CustomCertificateRequestChanges } from '../models/create-certificate-request.model';
import { CustomCertificateFlowType } from '../models/custom-certificate-flow-type.enum';
import { DesignationsLanguagesResponse } from '../models/designations-languages-response.model';
import { ThirdPartyPendingRequest } from '../models/third-party-pending-request.model';
import { certificatesActions } from './certificates.actions';

export interface CertificatesState {
  isLoading: boolean;
  proofCertificate: Certificate;
  customCertificates: Certificate[];
  autoInsuranceCards: AutoInsuranceCard[];
  thirdPartyPendingRequests: ThirdPartyPendingRequest[];
  followers: Follower[];
  preQuestionsAnswer: string;
  customCertificate: {
    flowType?: CustomCertificateFlowType;
    publicCertificateAllowedOperations?: CertificateAllowedOperations;
    allowedModifiers?: CertificateAllowedModifiers;
    descriptionOfOperations?: string;
    createCertificateRequest?: CreateCertificateRequest | CustomCertificateRequestChanges;
    createCertificateFromHolderRequirements?: CreateCertificateFromHolderRequirements;
    isWaiverChangeFlow?: boolean;
    designationsSpecialLanguages?: DesignationsLanguagesResponse;
    certificateHolderRequirementsPartialInformation?: CertificateHolderRequirementPartialInformation[];
    certificateHolderRequirements?: CertificateHolderRequirements;
  };
  isApprovingThirdPartyRequestAfterPayment: boolean;
}

export const certificatesInitialState: CertificatesState = {
  isLoading: false,
  proofCertificate: null,
  customCertificates: null,
  autoInsuranceCards: null,
  thirdPartyPendingRequests: null,
  followers: null,
  preQuestionsAnswer: null,
  customCertificate: {},
  isApprovingThirdPartyRequestAfterPayment: false,
};

const reducer = createReducer(
  certificatesInitialState,
  on(certificatesActions.loadProofCertificate, (state) => ({
    ...state,
    proofCertificate: null,
    isLoading: true,
  })),
  on(certificatesActions.setProofCertificate, (state, action) => ({
    ...state,
    proofCertificate: action.proofCertificate,
    isLoading: false,
  })),
  on(certificatesActions.loadCustomCertificates, (state) => ({
    ...state,
    customCertificates: null,
  })),
  on(certificatesActions.setCustomCertificates, (state, action) => ({
    ...state,
    customCertificates: action.customCertificates,
  })),
  on(certificatesActions.loadAutoInsuranceCards, (state) => ({
    ...state,
    autoInsuranceCards: null,
  })),
  on(certificatesActions.setAutoInsuranceCards, (state, action) => ({
    ...state,
    autoInsuranceCards: action.autoInsuranceCards,
  })),
  on(certificatesActions.loadPublicCertificateAllowedOperations, (state) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      publicCertificateAllowedOperations: null,
    },
  })),
  on(certificatesActions.setPublicCertificateAllowedOperations, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      publicCertificateAllowedOperations: action.allowedOperations,
      isWaiverChangeFlow: false,
    },
  })),
  on(certificatesActions.loadCertificateAllowedModifiers, (state) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      allowedModifiers: null,
    },
  })),
  on(certificatesActions.setCertificateAllowedModifiers, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      allowedModifiers: action.allowedModifiers,
      isWaiverChangeFlow: false,
    },
  })),
  on(certificatesActions.loadCertificateHolderRequirementsPartialInformation, (state) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirementsPartialInformation: null,
      createCertificateFromHolderRequirements: null,
    },
  })),
  on(certificatesActions.setCertificateHolderRequirementsPartialInformation, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirementsPartialInformation: action.certificateHolderRequirementsPartialInformation,
    },
  })),
  on(certificatesActions.loadCertificateHolderRequirements, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirements: null,
      createCertificateRequest: null,
      createCertificateFromHolderRequirements: { certificateHolderRequirementId: action.certificateHolderRequirementId },
    },
  })),
  on(certificatesActions.loadCertificateHolderRequirementsSuccess, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      certificateHolderRequirements: action.certificateHolderRequirements,
      createCertificateRequest: action.request,
    },
  })),
  on(certificatesActions.submitCustomCertificateForm, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      createCertificateRequest: {
        ...state.customCertificate.createCertificateRequest,
        ...action.request,
      },
    },
  })),
  on(certificatesActions.submitWaiverChange, (state) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      isWaiverChangeFlow: true,
    },
  })),
  on(certificatesActions.cleanCustomCertificateState, (state) => ({
    ...state,
    customCertificate: certificatesInitialState.customCertificate,
  })),
  on(certificatesActions.setCustomCertificateFlowType, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      flowType: action.flowType,
    },
  })),
  on(certificatesActions.loadThirdPartyPendingRequests, (state) => ({
    ...state,
    thirdPartyPendingRequests: null,
  })),
  on(certificatesActions.setThirdPartyPendingRequests, (state, action) => ({
    ...state,
    thirdPartyPendingRequests: action.thirdPartyPendingRequests,
  })),
  on(certificatesActions.loadFollowers, (state) => ({
    ...state,
    followers: null,
  })),
  on(certificatesActions.setFollowers, (state, action) => ({
    ...state,
    followers: action.followers,
  })),
  on(certificatesActions.createFollowerSuccess, (state, action) => ({
    ...state,
    followers: [...state.followers, action.follower],
  })),
  on(certificatesActions.removeFollowerSuccess, (state, action) => ({
    ...state,
    followers: state.followers.filter((follower) => follower.thirdPartyId !== action.follower.thirdPartyId),
  })),
  on(certificatesActions.setCertificateRequest, (state, action) => ({
    ...state,
    customCertificate: {
      ...state.customCertificate,
      createCertificateRequest: action.request,
    },
  })),
  on(certificatesActions.setPreQuestionsAnswer, (state, action) => ({
    ...state,
    preQuestionsAnswer: action.answer,
  })),
  on(certificatesActions.approveThirdPartyRequestAfterPayment, (state) => ({
    ...state,
    isApprovingThirdPartyRequestAfterPayment: true,
  })),
  on(certificatesActions.submitThirdPartyRequestSuccess, certificatesActions.submitThirdPartyRequestFailure, (state) => ({
    ...state,
    isApprovingThirdPartyRequestAfterPayment: false,
  })),
);

export function certificatesReducer(state: CertificatesState, action: Action): CertificatesState {
  return reducer(state, action);
}
