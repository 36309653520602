export enum HelpTip {
  ManageCertificates = 'MANAGE_CERTIFICATES',
  AddCertificateHolder = 'ADD_CERTIFICATE_HOLDER',
  CertificateDetails = 'CERTIFICATE_DETAILS',
  ShareCertificate = 'SHARE_CERTIFICATE',
  DownloadCertificate = 'DOWNLOAD_CERTIFICATE',
  UpComingPayments = 'UPCOMING_PAYMENTS',
  UpdateBusiness = 'UPDATE_BUSINESS',
  DownloadReceipt = 'DOWNLOAD_RECEIPT',
  FileClaim = 'FILE_CLAIM',
  CancelPolicy = 'CANCEL_POLICY',
  ReinstatePolicy = 'REINSTATE_POLICY',
  RestartPolicy = 'RESTART_POLICY',
  DownloadPolicyDocs = 'DOWNLOAD_POLICY_DOCS',
  UpdatePaymentCard = 'UPDATE_PAYMENT_CARD',
  SeeWhatIsCovered = 'SEE_WHAT_IS_COVERED',
  AddCoverageFollower = 'ADD_COVERAGE_FOLLOWER',
  AddAuthorizedAdmin = 'ADD_AUTHORIZED_ADMIN',
  UpdateContactInfo = 'UPDATE_CONTACT_INFO',
  ContactClaimAdvocate = 'CONTACT_CLAIM_ADVOCATE',
  CheckClaimStatus = 'CHECK_CLAIM_STATUS',
  CustomizeDescriptionOfOperations = 'CUSTOMIZE_DESCRIPTION_OF_OPERATIONS',
  UpdateBusinessAddress = 'UPDATE_BUSINESS_ADDRESS',
  UpdateBusinessDetails = 'UPDATE_BUSINESS_DETAILS',
  AddOrRemoveVehicles = 'ADD_OR_REMOVE_VEHICLES',
  AddOrRemoveDrivers = 'ADD_OR_REMOVE_DRIVERS',
  ChangeCoverageLimits = 'CHANGE_COVERAGE_LIMITS',
  DownloadLossRunReport = 'DOWNLOAD_LOSS_RUN_REPORT',
  AddPolicy = 'ADD_POLICY',
  CrossSellNotification = 'CROSS_SELL_NOTIFICATION',
  AssignLossPayee = 'ASSIGN_LOSS_PAYEE',
  CertificatesGeneral = 'CERTIFICATES_GENERAL',
  CoverageGeneral = 'COVERAGE_GENERAL',
  AccountGeneral = 'ACCOUNT_GENERAL',
  ClaimsGeneral = 'CLAIMS_GENERAL',
}

export const helpTipsConfig: Record<HelpTip, string> = {
  [HelpTip.ManageCertificates]: 'HELP.CERTIFICATES.MANAGE_CERTIFICATES',
  [HelpTip.AddCertificateHolder]: 'HELP.CERTIFICATES.ADD_THIRD_PARTY',
  [HelpTip.CertificateDetails]: 'HELP.CERTIFICATES.CERTIFICATE_DETAILS',
  [HelpTip.ShareCertificate]: 'HELP.CERTIFICATES.SHARE_CERTIFICATE',
  [HelpTip.DownloadCertificate]: 'HELP.CERTIFICATES.DOWNLOAD_CERTIFICATE',
  [HelpTip.UpComingPayments]: 'HELP.ACCOUNT_MANAGEMENT.UPCOMING_PAYMENTS',
  [HelpTip.UpdateBusiness]: 'HELP.ACCOUNT_MANAGEMENT.UPDATE_BUSINESS',
  [HelpTip.DownloadReceipt]: 'HELP.ACCOUNT_MANAGEMENT.DOWNLOAD_RECEIPT',
  [HelpTip.FileClaim]: 'HELP.POLICY_ACTIONS.FILE_CLAIM',
  [HelpTip.CancelPolicy]: 'HELP.POLICY_ACTIONS.CANCEL_POLICY',
  [HelpTip.ReinstatePolicy]: 'HELP.POLICY_ACTIONS.REINSTATE_POLICY',
  [HelpTip.RestartPolicy]: 'HELP.POLICY_ACTIONS.RESTART_POLICY',
  [HelpTip.DownloadPolicyDocs]: 'HELP.POLICY_ACTIONS.DOWNLOAD_POLICY_DOCS',
  [HelpTip.UpdatePaymentCard]: 'HELP.BILLING.UPDATE_PAYMENT_METHOD',
  [HelpTip.SeeWhatIsCovered]: 'HELP.POLICY_ACTIONS.SEE_WHAT_IS_COVERED',
  [HelpTip.CustomizeDescriptionOfOperations]: 'HELP.CERTIFICATES.CUSTOMIZE_DESCRIPTION_OF_OPERATIONS',
  [HelpTip.AddCoverageFollower]: 'HELP.CERTIFICATES.ADD_COVERAGE_FOLLOWER',
  [HelpTip.AssignLossPayee]: 'HELP.CERTIFICATES.ASSIGN_LOSS_PAYEE',
  [HelpTip.AddAuthorizedAdmin]: 'HELP.ACCOUNT_MANAGEMENT.ADD_AUTHORIZED_ADMIN',
  [HelpTip.UpdateContactInfo]: 'HELP.ACCOUNT_MANAGEMENT.UPDATE_CONTACT_INFO',
  [HelpTip.ContactClaimAdvocate]: 'HELP.CLAIMS.CONTACT_CLAIM_ADVOCATE',
  [HelpTip.CheckClaimStatus]: 'HELP.CLAIMS.CHECK_CLAIM_STATUS',

  [HelpTip.UpdateBusinessAddress]: 'HELP.ACCOUNT_MANAGEMENT.UPDATE_BUSINESS_ADDRESS',
  [HelpTip.UpdateBusinessDetails]: 'HELP.ACCOUNT_MANAGEMENT.UPDATE_BUSINESS_DETAILS',
  [HelpTip.AddOrRemoveVehicles]: 'HELP.ACCOUNT_MANAGEMENT.ADD_OR_REMOVE_VEHICLES',
  [HelpTip.AddOrRemoveDrivers]: 'HELP.ACCOUNT_MANAGEMENT.ADD_OR_REMOVE_DRIVERS',
  [HelpTip.ChangeCoverageLimits]: 'HELP.POLICY_ACTIONS.CHANGE_LIMITS',
  [HelpTip.DownloadLossRunReport]: 'HELP.CLAIMS.DOWNLOAD_LOSS_RUN_REPORT',
  [HelpTip.AddPolicy]: 'HELP.POLICY_ACTIONS.ADD_POLICY',
  [HelpTip.CrossSellNotification]: 'HELP.POLICY_ACTIONS.CROSS_SELL_NOTIFICATION',

  [HelpTip.CertificatesGeneral]: 'HELP.CERTIFICATES.CERTIFICATES_GENERAL',
  [HelpTip.CoverageGeneral]: 'HELP.COVERAGE.COVERAGE_GENERAL',
  [HelpTip.AccountGeneral]: 'HELP.ACCOUNT_MANAGEMENT.ACCOUNT_GENERAL',
  [HelpTip.ClaimsGeneral]: 'HELP.CLAIMS.CLAIMS_GENERAL',
};
