import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { pollUntil } from '../helpers/custom-operators';
import { UserInteractionParams } from '../models/user-interaction-params.model';
import { FullStoryService } from './fullstory.service';
import { SurvicateService } from './survicate.service';
import { UserInteractionsDataService } from './user-interactions.data.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private cookieService: CookieService,
    private fullStoryService: FullStoryService,
    private survicateService: SurvicateService,
    private userInteractionsService: UserInteractionsDataService,
  ) {
    this.waitForTrackingJS().subscribe(() => {
      const trackingId = this.getTrackingId();
      this.fullStoryService.init(trackingId);
      this.survicateService.init(trackingId);
    });
  }

  track(data: UserInteractionParams, sendToGTM?: boolean): void {
    this.userInteractionsService.sendInteraction(data, sendToGTM);
  }

  private waitForTrackingJS(): Observable<boolean> {
    return pollUntil(() => {
      return !!window._TRACKING;
    });
  }

  getTrackingId(): string {
    return this.cookieService.get(environment.tidCookieName);
  }
}
