import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NiButtonRbModule } from '@next-insurance/ni-material';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { InformativeModalData } from './informative-modal-data.model';

@Component({
  selector: 'ni-informative-modal',
  templateUrl: './informative-modal.component.html',
  styleUrls: ['./informative-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiButtonRbModule, TranslateModule],
})
export class InformativeModalComponent implements OnInit {
  private dynamicDialogRef = inject(DynamicDialogRef);
  private dynamicDialogConfig = inject(DynamicDialogConfig<InformativeModalData>);
  modalData: InformativeModalData;

  ngOnInit(): void {
    this.modalData = this.dynamicDialogConfig.data;
  }

  onSubmit(): void {
    this.dynamicDialogRef.close(true);
  }
}
