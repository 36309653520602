import { ChangeDetectionStrategy, Component } from '@angular/core';

import { InteractionType } from '../../../../../core/models/interaction-type.enum';
import { TrackingService } from '../../../../../core/services/tracking.service';
import { ChatSessionService } from '../../../../services/chat-session.service';
import { ChatbotService } from '../../../../services/chatbot.service';
import { RichMessageType } from '../../enums/rich-message-type.enum';

@Component({
  selector: 'ni-restart-session-rich-message',
  templateUrl: './restart-session-rich-message.component.html',
  styleUrls: ['./restart-session-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestartSessionRichMessageComponent {
  static readonly richComponentType = RichMessageType.RestartSession;

  constructor(
    private chatSessionService: ChatSessionService,
    private chatbotService: ChatbotService,
    private trackingService: TrackingService,
  ) {}

  restartSession(): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'chatbot',
      name: 'restart-session',
      interactionData: {
        sessionId: this.chatbotService.getSessionId(),
      },
    });
    this.chatSessionService.startNewSession(this.chatbotService.getAgentType());
  }
}
