import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { InteractionType } from '../../models/interaction-type.enum';
import { NavigationService } from '../../services/navigation.service';
import { TrackingService } from '../../services/tracking.service';
import { MobileAppBannerConfig } from './mobile-app-banner.config';

@Component({
  selector: 'ni-mobile-app-banner',
  templateUrl: './mobile-app-banner.component.html',
  styleUrls: ['./mobile-app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate(MobileAppBannerConfig.bannerFadeInFadeOutAnimationInMilliseconds),
      ]),
      transition(':leave', [
        animate(MobileAppBannerConfig.bannerFadeInFadeOutAnimationInMilliseconds, style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class MobileAppBannerComponent implements OnInit {
  hidden = true;
  trackingPlacement = 'mobile-app-banner';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private trackingService: TrackingService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    timer(MobileAppBannerConfig.displayDelayInMilliseconds)
      .pipe(
        tap(() => {
          this.hidden = false;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe();
  }

  closeBanner(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.trackingPlacement,
      name: 'click-exit-button',
      eventName: 'mobile app banner - CLICK exist',
    });
    this.hidden = true;
  }

  openOrInstallApp(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: this.trackingPlacement,
      name: 'click-open-button',
      eventName: 'mobile app banner - CLICK open',
    });
    this.navigationService.navigateTo(MobileAppBannerConfig.mobileAppDeepLink, true);
  }
}
