import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ni-exit-confirmation',
  templateUrl: './exit-confirmation.component.html',
  styleUrls: ['./exit-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExitConfirmationComponent {
  @Output() confirmed = new EventEmitter();
  @Output() canceled = new EventEmitter();

  onConfirm(): void {
    this.confirmed.emit();
  }

  onCancel(): void {
    this.canceled.emit();
  }
}
