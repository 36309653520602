import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { ButtonType } from '@next-insurance/ni-material';
import { UsStateCode } from '@next-insurance/utils';
import { Store } from '@ngrx/store';
import { map, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { Business } from '../../../../business/models/business.model';
import { businessSelectors } from '../../../../business/store/business.selectors';
import { CrossSellOfferThrough } from '../../../../core/enums/cross-sell-offer-through.enum';
import { LmsSerials } from '../../../../core/models/lms-serials.enum';
import { NavigationService } from '../../../../core/services/navigation.service';
import { AppState } from '../../../../store';
import { CrossSellPolicy } from '../../../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../../../models/cross-sell-policy-status.enum';
import { CrossSellService } from '../../../services/cross-sell.service';
import { CrossSellTrackingService } from '../../../services/cross-sell-tracking.service';
import { crossSellActions } from '../../../store/cross-sell.actions';

@Component({
  selector: 'ni-cross-sell-policy',
  templateUrl: './cross-sell-policy.component.html',
  styleUrls: ['./cross-sell-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossSellPolicyComponent implements OnInit, OnDestroy {
  readonly crossSellOfferThrough = CrossSellOfferThrough;
  @Input() policy: CrossSellPolicy;
  CrossSellPolicyStatus = CrossSellPolicyStatus;
  ButtonType = ButtonType;
  isAvailableThroughNext: boolean;
  shouldOfferedThrough: CrossSellOfferThrough;
  getBusinessSubscription: Subscription;
  isRequiredToBePurchasedFromState: boolean;
  shouldSuggestLOB: boolean;

  readonly mapLobToSerial: Record<Exclude<LOB, LOB.AUTO>, LmsSerials> = {
    [LOB.WC]: LmsSerials.PublicSiteWCPage,
    [LOB.CA]: LmsSerials.PublicSiteCAPage,
    [LOB.GL]: LmsSerials.PublicSiteGLPage,
    [LOB.IM]: LmsSerials.PublicSiteIMPage,
    [LOB.CP]: LmsSerials.PublicSiteCPPage,
    [LOB.PL]: LmsSerials.PublicSitePLPage,
    [LOB.BP]: LmsSerials.PublicSiteBPPage,
  };

  constructor(
    private store: Store<AppState>,
    private crossSellTrackingService: CrossSellTrackingService,
    private navigationService: NavigationService,
    private crossSellService: CrossSellService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  startCrossSell(): void {
    const { lob, status } = this.policy;
    this.crossSellTrackingService.trackClickAddNow(lob, status);
    this.crossSellService.startCrossSell(this.shouldOfferedThrough, lob);
  }

  clickNotify(): void {
    this.store.dispatch(
      crossSellActions.modifyNotifyStatus({
        lob: this.policy.lob,
        status: this.policy.notifyBusinessOnLob,
      }),
    );
  }

  clickLearnMore(): void {
    this.crossSellTrackingService.trackClickLearnMore(this.policy.lob);
    this.navigationService.navigateByLMS(this.mapLobToSerial[this.policy.lob as Exclude<LOB, LOB.AUTO>], true);
  }

  ngOnInit(): void {
    this.getBusinessSubscription = this.store
      .select(businessSelectors.getBusiness)
      .pipe(
        map((business: Business) => business.state),
        map((stateCode: UsStateCode) => {
          const isRequiredToBePurchasedFromState = this.crossSellService.isPolicyRequiredToBePurchasedFromState(this.policy, stateCode);
          this.shouldSuggestLOB = this.getShouldSuggestLOB(stateCode) && !isRequiredToBePurchasedFromState;
          return isRequiredToBePurchasedFromState;
        }),
        withLatestFrom(this.store.select(businessSelectors.isBusinessLinkedToExternalAgent)),
      )
      .subscribe(([isRequiredToBePurchasedFromState, isBusinessLinkedToExternalAgent]: [boolean, boolean]) => {
        this.isRequiredToBePurchasedFromState = isRequiredToBePurchasedFromState;
        this.isAvailableThroughNext =
          !this.isRequiredToBePurchasedFromState &&
          this.policy.status !== CrossSellPolicyStatus.CurrentlyNotAvailable &&
          this.policy.status !== CrossSellPolicyStatus.ExternalLobAvailable;
        this.setCrossSellShouldOfferedThrough(isBusinessLinkedToExternalAgent);
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    if (this.getBusinessSubscription) {
      this.getBusinessSubscription.unsubscribe();
    }
  }

  // NI-83231 we should not suggest CP, IM and PL cross sell for businesses from FL
  private getShouldSuggestLOB(stateCode: UsStateCode): boolean {
    const forbiddenLOBs: LOB[] = [LOB.CP];
    return !(forbiddenLOBs.includes(this.policy.lob) && stateCode === 'FL');
  }

  private setCrossSellShouldOfferedThrough(isBusinessLinkedToExternalAgent: boolean): void {
    if (this.isAvailableThroughNext) {
      this.shouldOfferedThrough = CrossSellOfferThrough.Next;
    }

    if (this.shouldOfferedThroughPartner(isBusinessLinkedToExternalAgent)) {
      this.shouldOfferedThrough = CrossSellOfferThrough.Partner;
    }

    if (this.shouldOfferedThroughSmartAgent()) {
      this.shouldOfferedThrough = CrossSellOfferThrough.SmartAgent;
    }
  }

  private shouldOfferedThroughPartner(isBusinessLinkedToExternalAgent: boolean): boolean {
    return (
      !isBusinessLinkedToExternalAgent &&
      !this.isRequiredToBePurchasedFromState &&
      this.policy.status === CrossSellPolicyStatus.CurrentlyNotAvailable &&
      [LOB.WC, LOB.CA, LOB.IM, LOB.PL, LOB.CP].includes(this.policy.lob)
    );
  }

  private shouldOfferedThroughSmartAgent(): boolean {
    return this.policy.status === CrossSellPolicyStatus.ExternalLobAvailable && this.policy.lob === LOB.WC;
  }
}
