import { Injectable } from '@angular/core';

import { InteractionType } from '../models/interaction-type.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class PWATrackingService {
  constructor(private trackingService: TrackingService) {}

  /* istanbul ignore next */
  trackClickingOnPhoneIcon(): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'pwa-flow',
      name: 'home-screen-icon',
    });
  }
}
