import logger from '@next-insurance/logger';
import { ObservableInput, ObservedValueOf, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function catchErrorAndLog<T, O extends ObservableInput<any>>(
  callback: (err: any) => O,
): OperatorFunction<T, T | ObservedValueOf<O>> {
  return catchError((err: any) => {
    logger.error(`${err.message} (catchErrorAndLog)`, err, { catchErrorAndLog: true });
    return callback(err);
  });
}
