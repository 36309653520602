import { StripeAchNachaCode } from '@next-insurance/core';

export type PaymentMethodDetails = CreditCardPaymentMethod | ACHPaymentMethod;

export interface CreditCardPaymentMethod {
  type: PaymentMethodType.CreditCard;
  creditCardDetails: CreditCardDetails;
}

export interface ACHPaymentMethod {
  type: PaymentMethodType.BankAccount;
  achDetails: ACHDetails;
}

export enum PaymentMethodType {
  CreditCard = 'CREDIT_CARD',
  BankAccount = 'BANK_ACCOUNT',
}

export interface CreditCardDetails {
  cardType: string;
  cardEndingDigits: string;
  funding: CreditCardFunding;
}

export enum CreditCardFunding {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
}

export interface ACHDetails {
  bankAccount: {
    bankName: string;
    accountType: BankAccountType;
    accountEndingDigits: string;
  };
  blockStatusReason?: {
    nachaCode: StripeAchNachaCode;
    name: string;
  };
  isBlockedInternally?: boolean;
  isBlockedByPaymentProvider?: boolean;
}

export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}
