import { createAction, props } from '@ngrx/store';

import { CancelRewriteCompleteStatus, CancelRewriteCompleteStatusV2 } from '../models/cancel-rewrite-complete-status.enum';
import {
  CancelRewriteGetPaymentDetailsStatus,
  CancelRewriteGetPaymentDetailsStatusV2,
} from '../models/cancel-rewrite-payment-details-status.enum';

const completeCancelRewrite = createAction(
  '[Cancel Rewrite api] Complete cancel rewrite',
  props<{ policyId: string; quoteRequestId: string }>(),
);
const cancelRewriteCompleted = createAction('[Cancel Rewrite] Cancel rewrite flow completed successfully');
const cancelRewriteFailed = createAction(
  '[Cancel Rewrite] Cancel rewrite flow has failed',
  props<{
    status:
      | CancelRewriteCompleteStatus
      | CancelRewriteGetPaymentDetailsStatus
      | CancelRewriteGetPaymentDetailsStatusV2
      | CancelRewriteCompleteStatusV2;
  }>(),
);

export const cancelRewriteActions = {
  completeCancelRewrite,
  cancelRewriteCompleted,
  cancelRewriteFailed,
};
