import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaymentMethodType } from '../../../payment/models/payment-method-details.model';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { AppState } from '../../../store';

@Component({
  selector: 'ni-failed-payment-banner',
  templateUrl: './failed-payment-banner.component.html',
  styleUrls: ['./failed-payment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FailedPaymentBannerComponent implements OnInit {
  paymentMethodType$: Observable<PaymentMethodType>;
  protected readonly PaymentMethodType = PaymentMethodType;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.paymentMethodType$ = this.store.select(paymentSelectors.getPaymentMethodDetails).pipe(
      filter((paymentMethodDetails) => !!paymentMethodDetails),
      map((paymentMethodDetails) => {
        return paymentMethodDetails.type;
      }),
    );
  }
}
