import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';

import { NiValidatorsService } from '../../../../core/services/ni-validators.service';
import { AppState } from '../../../../store';
import { forbiddenWords } from '../../configs/forbidden-words.config';
import { LiveCertificateTrackingService } from '../../services/live-certificate-tracking.service';
import { certificatesActions } from '../../store/certificates.actions';

@Component({
  selector: 'ni-send-certificate-modal',
  templateUrl: './send-certificate-modal.component.html',
  styleUrls: ['./send-certificate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendCertificateModalComponent implements OnInit {
  @Input() hasPolicyWithAgent: boolean;
  sendCertificateForm: UntypedFormGroup;
  isMobile: boolean;
  isSubmitting: boolean;
  liveCertificateId: string;
  ButtonType = ButtonType;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private liveCertificateTrackingService: LiveCertificateTrackingService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<{ liveCertificateId: string }>,
  ) {}

  ngOnInit(): void {
    this.liveCertificateId = this.dynamicDialogConfig.data.liveCertificateId;
    this.isMobile = this.deviceDetectorService.isMobile();
    this.sendCertificateForm = this.fb.group({
      recipientName: [
        '',
        [
          NiValidatorsService.entityNamePattern,
          NiValidatorsService.maxLengthShortInput,
          NiValidatorsService.notContains(forbiddenWords),
          Validators.required,
        ],
      ],
      recipientEmail: ['', [NiValidatorsService.email, Validators.required]],
      message: ['', [NiValidatorsService.maxLengthLongInput]],
    });
    this.listenToCloseEvent();
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.liveCertificateTrackingService.trackSendCertificateSubmit();
    this.store.dispatch(
      certificatesActions.sendCertificate({
        request: this.sendCertificateForm.value,
        liveCertificateId: this.liveCertificateId,
      }),
    );
  }

  onCancel(): void {
    this.dynamicDialogRef.close();
  }

  private listenToCloseEvent(): void {
    this.dynamicDialogRef.onClose.pipe(first()).subscribe(() => {
      this.liveCertificateTrackingService.trackSendCertificateClose();
    });
  }
}
