import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs/operators';

import { CancellationTrackingService } from '../../../cancellation/services/cancellation-tracking.service';
import { cancellationActions } from '../../../cancellation/store/cancellation.actions';
import { Policy } from '../../../policies/models/policy.model';
import { AppState } from '../../../store';
import { UndoCancellationModalData } from './undo-cancellation-modal-data.model';

@Component({
  selector: 'ni-undo-cancellation-modal',
  templateUrl: './undo-cancellation-modal.component.html',
  styleUrls: ['./undo-cancellation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoCancellationModalComponent implements OnInit {
  modalData: { policy: Policy };
  ButtonType = ButtonType;
  undoCancellationInProgress = false;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<UndoCancellationModalData>,
    private store: Store<AppState>,
    private cancellationTrackingService: CancellationTrackingService,
  ) {}

  ngOnInit(): void {
    this.modalData = this.dynamicDialogConfig.data;
    this.cancellationTrackingService.trackUndoCancellationModalOpened(this.modalData.policy.lob);
    this.listenToCloseEvent();
  }

  undoCancellation(): void {
    this.undoCancellationInProgress = true;
    this.cancellationTrackingService.trackUndoCancellationModalSubmit(this.modalData.policy.lob);
    this.store.dispatch(cancellationActions.undoPolicyCancellation({ policy: this.modalData.policy }));
  }

  private listenToCloseEvent(): void {
    this.dynamicDialogRef.onClose.pipe(first()).subscribe(() => {
      this.cancellationTrackingService.trackUndoCancellationModalClosed(this.modalData.policy.lob);
    });
  }
}
