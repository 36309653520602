import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { first, tap } from 'rxjs/operators';

import { BusinessStatus } from '../../../core/models/business-status.enum';
import { MobileAppCustomizedEvent, MobileAppEventId } from '../../../core/models/mobile-app-event.model';
import { MobileAppEventsService } from '../../../core/services/mobile-app-events.service';
import { CrossSellTrackingService } from '../../../cross-sell/services/cross-sell-tracking.service';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { toastActions } from '../../../shared/components/toast/store/toast.actions';
import { AppState } from '../../../store';
import { ExternalLobAnswer } from '../../models/external-lob-answer.model';
import { CoverageCheckupService } from '../../services/coverage-checkup.service';
import { CoverageCheckupStepComponent } from '../coverage-checkup-step/coverage-checkup-step.component';

type ExternalLob = Exclude<LOB, LOB.GL | LOB.PL | LOB.BP | LOB.AUTO>;

@Component({
  selector: 'ni-coverage-checkup-lobs-step',
  templateUrl: './coverage-checkup-lobs-step.component.html',
  styleUrls: ['./coverage-checkup-lobs-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageCheckupLobsStepComponent extends CoverageCheckupStepComponent implements OnInit {
  protected readonly ButtonType = ButtonType;
  isNoneSelected: boolean;
  isSomeOptionChecked: boolean;
  externalLobsAnswers: Record<ExternalLob, boolean> = {
    [LOB.WC]: false,
    [LOB.CA]: false,
    [LOB.CP]: false,
    [LOB.IM]: false,
  };

  constructor(
    protected coverageCheckupService: CoverageCheckupService,
    private crossSellTrackingService: CrossSellTrackingService,
    private mobileAppEventsService: MobileAppEventsService,
    private dynamicDialogRef: DynamicDialogRef,
    private store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.autoFillSavedAnswers();
    this.crossSellTrackingService.trackExternalLobsStepViewed();
  }

  private autoFillSavedAnswers(): void {
    this.coverageCheckupService.getLastExternalLobsSavedAnswers().subscribe((externalLobsAnswers: ExternalLobAnswer[]) => {
      if (externalLobsAnswers.every((externalLobAnswer: ExternalLobAnswer) => !externalLobAnswer.alreadyInsuredDeclaration)) {
        this.checkNone(true);
      } else {
        externalLobsAnswers.forEach((externalLobAnswer: ExternalLobAnswer) => {
          if (externalLobAnswer.alreadyInsuredDeclaration) {
            this.checkLob(true, externalLobAnswer.lob as ExternalLob);
          }
        });
      }
    });
  }

  onLobChecked(isChecked: boolean, lob: ExternalLob): void {
    this.checkLob(isChecked, lob);
    this.crossSellTrackingService.trackExternalLobsAnswerChecked(lob, isChecked);
  }

  private checkLob(isChecked: boolean, lob: ExternalLob): void {
    this.isNoneSelected = false;
    this.externalLobsAnswers = { ...this.externalLobsAnswers, [lob]: isChecked };
    this.isSomeOptionChecked = Object.values(this.externalLobsAnswers).includes(true);
  }

  onNoneChecked(isChecked: boolean): void {
    this.checkNone(isChecked);
    this.crossSellTrackingService.trackExternalLobsNoneChecked(isChecked);
  }

  private checkNone(isChecked: boolean): void {
    this.isNoneSelected = isChecked;
    this.isSomeOptionChecked = isChecked;
    if (isChecked) {
      const externalLobsCopy = { ...this.externalLobsAnswers };
      Object.keys(this.externalLobsAnswers).forEach((key: ExternalLob) => {
        externalLobsCopy[key] = false;
      });
      this.externalLobsAnswers = externalLobsCopy;
    }
  }

  nextStep(): void {
    this.crossSellTrackingService.trackExternalLobsStepCompleted(this.externalLobsAnswers as any);
    this.coverageCheckupService.saveExternalLobsAnswers(
      Object.keys(this.externalLobsAnswers).map((lob: ExternalLob) => ({
        lob,
        alreadyInsuredDeclaration: this.externalLobsAnswers[lob],
      })),
    );

    this.coverageCheckupService.updateAnswers().subscribe((succeed: boolean) => {
      if (succeed) {
        this.moveToNextStep.emit();
        this.notifyMobileAboutCoverageCheckupChanges();
      } else {
        this.showErrorAndCloseModal();
      }
    });
  }

  private showErrorAndCloseModal(): void {
    this.crossSellTrackingService.trackErrorUpdatingCoverageCheckup();
    this.store.dispatch(toastActions.showGeneralErrorToast());
    this.dynamicDialogRef.close();
  }

  private notifyMobileAboutCoverageCheckupChanges(): void {
    this.store
      .select(policiesSelectors.isHistoricalUser)
      .pipe(
        first(),
        tap((isHistoricalUser: boolean) => {
          this.mobileAppEventsService.notifyMobile({
            eventId: MobileAppEventId.BusinessStatusUpdate,
            closeWebview: false,
            businessStatus: isHistoricalUser ? BusinessStatus.Historical : BusinessStatus.Active,
          } as MobileAppCustomizedEvent);
        }),
      )
      .subscribe();
  }
}
