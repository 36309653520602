import { Pipe, PipeTransform } from '@angular/core';

export const defaultPhoneMask = '(000) 000 - 0000';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: any, phoneMask: string = defaultPhoneMask): string {
    if (!phoneNumber) {
      return '';
    }
    const phoneNumberString = phoneNumber.toString().trim().replace(/\D+/g, '');
    let maskedPhoneNumber = '';
    let currentInputPosition = 0;
    for (let i = 0; i < phoneMask.length && currentInputPosition < phoneNumberString.length; i++) {
      const maskChar = phoneMask.charAt(i);
      if (maskChar === '0') {
        maskedPhoneNumber += phoneNumberString.charAt(currentInputPosition);
        currentInputPosition++;
      } else {
        maskedPhoneNumber += maskChar;
      }
    }
    return maskedPhoneNumber;
  }
}
