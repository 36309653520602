import { Injectable } from '@angular/core';
import { FeatureFlagsMap } from '@next-insurance/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { FeatureFlagsDataService } from '../../core/services/feature-flags.data.service';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { featureFlagsActions } from './feature-flags.actions';

@Injectable()
export class FeatureFlagsEffects {
  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.loadFeatureFlags),
      switchMap((action) => {
        return this.featureFlagsDataService.getFeatureFlags().pipe(
          mergeMap((featureFlagsMap: FeatureFlagsMap) => {
            const turnOnActions = (action.turnOnFeatureFlags || []).map((name) => featureFlagsActions.turnOnFeatureFlag({ name }));
            const turnOffActions = (action.turnOffFeatureFlags || []).map((name) => featureFlagsActions.turnOffFeatureFlag({ name }));
            return [featureFlagsActions.setFeatureFlags({ featureFlagsMap }), ...turnOnActions, ...turnOffActions];
          }),
          catchErrorAndLog(() => of(featureFlagsActions.onError())),
        );
      }),
    ),
  );

  constructor(
    private featureFlagsDataService: FeatureFlagsDataService,
    private actions$: Actions,
  ) {}
}
