import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[niFreezeWhile]',
  standalone: true,
})
export class FreezeDialogDirective implements OnChanges, OnDestroy, OnInit {
  @Input('niFreezeWhile') shouldFreeze: boolean;
  FREEZE_CLASS_NAME = 'freeze';
  private freezeSub = Subscription.EMPTY;
  private mouseDownSub = Subscription.EMPTY;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.overrideMouseDownEventWhileFreeze();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldFreeze) {
      changes.shouldFreeze.currentValue ? this.addFreezeClass() : this.removeFreezeClass();
    }
  }

  ngOnDestroy(): void {
    this.removeFreezeClass();
    this.freezeSub.unsubscribe();
    this.mouseDownSub.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.shouldFreeze) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  private addFreezeClass(): void {
    this.renderer.addClass(document.body, this.FREEZE_CLASS_NAME);
  }

  private removeFreezeClass(): void {
    this.renderer.removeClass(document.body, this.FREEZE_CLASS_NAME);
  }

  private overrideMouseDownEventWhileFreeze(): void {
    const dialogMaskElement = this.elementRef.nativeElement.closest('.p-dialog-mask');
    if (dialogMaskElement) {
      this.mouseDownSub = fromEvent(dialogMaskElement, 'mousedown').subscribe((event: MouseEvent) => {
        if (this.shouldFreeze) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      });
    }
  }
}
