import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { diffInMilliseconds, isDateInWorkingHours } from '@next-insurance/date';
import { NIError } from '@next-insurance/errors';
import logger from '@next-insurance/logger';
import {
  ZendeskHolidays,
  ZendeskOperationHours,
  ZendeskSchedule,
} from '@next-insurance/nest-modules/src/lib/zendesk/models/zendesk-operation-hours.model';
import { Store } from '@ngrx/store';
import { NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { isWithinInterval } from 'date-fns';
import { BehaviorSubject, combineLatest, interval, Observable } from 'rxjs';
import { filter, first, takeWhile, tap } from 'rxjs/operators';

import { ContactInfoPersonalDetails } from '../../business/models/contact-info-personal-details.model';
import { businessSelectors } from '../../business/store/business.selectors';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { AppState } from '../../store';
import { zendeskConfig } from '../config/zendesk.config';
import { pollUntil } from '../helpers/custom-operators';
import { InteractionType } from '../models/interaction-type.enum';
import { UserType } from '../models/user-type.enum';
import { ZendeskAction } from '../models/zendesk-action.enum';
import { coreSelectors } from '../store/core.selectors';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  readonly mapChatEvents = {
    open: 'portal-chat-clicked',
    'chat:start': 'portal-chat-requested',
    'chat:end': 'portal-chat-end',
  };

  private scheduleData: ZendeskSchedule;
  private unreadMessagesSubject$ = new BehaviorSubject<boolean>(false);
  unreadMessages$ = this.unreadMessagesSubject$.asObservable();

  constructor(
    private ngxZendeskWebWidgetService: NgxZendeskWebwidgetService,
    private store: Store<AppState>,
    private trackingService: TrackingService,
    private router: Router,
    private httpClient: HttpClient,
  ) {}

  initZendeskWidget(): void {
    const loadingStartTime = new Date();

    this.ngxZendeskWebWidgetService.initZendesk().catch((error) => {
      const loadingTime = diffInMilliseconds(new Date(), loadingStartTime);
      this.trackZendeskLoadingError(loadingTime);
      if (error.message !== 'timeout') {
        // For more info about timeouts issue - NI-67103
        throw error;
      }
    });

    this.waitForZendeskInit().subscribe(() => {
      const loadingTime = diffInMilliseconds(new Date(), loadingStartTime);
      this.trackZendeskLoadingSuccess(loadingTime);
      this.registerChatEvents();
      this.updateWidgetVisibility();
      this.utilizeUserDetails();
      this.addTags();
      this.setUnreadMessages();
      this.positionWidget();
      this.registerVisibilityChangesEvents();
    });
  }

  private waitForZendeskInit(): Observable<boolean> {
    return pollUntil(() => {
      return !!this.ngxZendeskWebWidgetService.zE;
    });
  }

  updateWidgetVisibility(forceHiding = false): void {
    if (!this.isChatOpened() || forceHiding) {
      this.hideZendeskChat();
    }
  }

  private setUnreadMessages(): void {
    this.ngxZendeskWebWidgetService.zE('webWidget:on', 'chat:unreadMessages', (unreadMassages: number) => {
      this.unreadMessagesSubject$.next(unreadMassages > 0 && !this.isChatOpened());
    });
  }

  openZendeskChat(message: string, messageTag: string[], chatbotSessionId: string = undefined, openingDelayMs = 0): void {
    if (!this.ngxZendeskWebWidgetService.zE) {
      this.store.dispatch(
        toastActions.showToast({
          toastType: ToastType.Error,
          message: 'CHAT.ZENDESK.HANDOFF_ERROR',
        }),
      );
      throw new NIError('ngxZendeskWebWidgetService.zE is undefined', null, {
        message,
        messageTag,
        chatbotSessionId,
      });
    }

    if (messageTag.length) {
      for (const tag of messageTag) {
        this.ngxZendeskWebWidgetService.zE('webWidget', 'chat:addTags', tag);
      }
    }
    if (chatbotSessionId) {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'chat:addTags', `session_id:${chatbotSessionId}`);
    }
    if (message) {
      this.ngxZendeskWebWidgetService.zE('webWidget', 'chat:send', message);
    }

    setTimeout(() => {
      this.ngxZendeskWebWidgetService.zE.activate();
    }, openingDelayMs);

    this.validateWidgetOpenedSuccessfully({ message, chatbotSessionId });
  }

  continueChat(): void {
    this.ngxZendeskWebWidgetService.zE.activate();
  }

  isChatting(): boolean {
    if (!this.ngxZendeskWebWidgetService.zE) {
      return false;
    }

    return this.ngxZendeskWebWidgetService.zE('webWidget:get', 'chat:isChatting');
  }

  validateWidgetOpenedSuccessfully(interactionData?: any): void {
    interval(1000)
      .pipe(
        takeWhile((second: number) => !this.isChatOpened() && second < zendeskConfig.secondsUntilHandoffError),
        tap((second: number) => {
          if (second === zendeskConfig.secondsUntilHandoffError - 1) {
            this.handleHandoffError(interactionData);
          }
        }),
      )
      .subscribe();
  }

  private hideZendeskChat(): void {
    this.ngxZendeskWebWidgetService.zE('webWidget', ZendeskAction.Hide);
  }

  private handleHandoffError(interactionData: any): void {
    this.store.dispatch(
      toastActions.showToast({
        toastType: ToastType.Error,
        message: 'CHAT.ZENDESK.HANDOFF_ERROR',
      }),
    );

    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'zendesk-service',
      name: 'chat-handoff-failure',
      interactionData: {
        display: this.ngxZendeskWebWidgetService.zE('webWidget:get', 'display'),
        isInitialized: this.ngxZendeskWebWidgetService.isInitialized,
        ...interactionData,
      },
    });
  }

  private addTags(): void {
    this.store
      .select(coreSelectors.getUserType)
      .pipe(first((userType) => !!userType))
      .subscribe((userType: UserType) => this.ngxZendeskWebWidgetService.zE('webWidget', 'chat:addTags', userType));
  }

  private utilizeUserDetails(): void {
    this.store
      .select(businessSelectors.getPersonalDetails)
      .pipe(filter((details) => !!details?.userFirstName))
      .subscribe((details: ContactInfoPersonalDetails) =>
        this.ngxZendeskWebWidgetService.zE('webWidget', 'identify', {
          name: `${details.userFirstName} ${details.userLastName}`,
          email: details.emailAddress,
        }),
      );

    this.store
      .select(coreSelectors.isUserLoggedIn)
      .pipe(filter((userLoggedIn) => !userLoggedIn))
      .subscribe(() => this.ngxZendeskWebWidgetService.zE('webWidget', ZendeskAction.Logout));
  }

  private registerChatEvents(): void {
    Object.entries(this.mapChatEvents).forEach(([chatEvent, trackingEvent]) => {
      this.ngxZendeskWebWidgetService.zE('webWidget:on', chatEvent, () => {
        this.trackChatEvent(trackingEvent);
      });
    });
  }

  private trackChatEvent(name: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'portal-chat',
      name,
    });
  }

  private positionWidget(): void {
    this.ngxZendeskWebWidgetService.zE('webWidget', 'updateSettings', {
      webWidget: {
        position: { horizontal: 'right', vertical: 'bottom' },
      },
    });
  }

  private registerVisibilityChangesEvents(): void {
    combineLatest([
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
      this.store.select(coreSelectors.isNavigationTabsVisible),
    ]).subscribe(([, isNavigationTabsVisible]: [Event | RouterEvent, boolean]) => {
      if (isNavigationTabsVisible) {
        this.updateWidgetVisibility();
      } else {
        this.updateWidgetVisibility(true);
      }
    });
  }

  private isChatOpened(): boolean {
    return ['chat', 'contactForm'].includes(this.ngxZendeskWebWidgetService.zE('webWidget:get', 'display'));
  }

  private trackZendeskLoadingError(loadingTime: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'portal-zd',
      name: 'zd-loading-error',
      interactionData: { loadingTime },
    });
  }

  private trackZendeskLoadingSuccess(loadingTime: number): void {
    this.trackingService.track({
      interactionType: InteractionType.Debug,
      placement: 'portal-zd',
      name: 'zd-loading-success',
      interactionData: { loadingTime },
    });
  }

  isBusinessHours(): boolean {
    if (!this.scheduleData) {
      // in case that operation hours is not initialized - fallback to hard coded values
      return isDateInWorkingHours({
        timezone: 'America/Los_Angeles', // -2 hours from CST
        startTime: 6,
        endTime: 15,
        dayOfWeekMask: '12345', // monday - friday
      });
    }

    const now = new Date();
    const isHoliday = this.scheduleData.holidays.some((holiday: ZendeskHolidays) =>
      isWithinInterval(now, { start: holiday.startTime, end: holiday.endTime }),
    );

    let isOperationHours = this.scheduleData.operationHours.some((operationHoursInterval: ZendeskOperationHours) =>
      isWithinInterval(now, { start: operationHoursInterval.startTime, end: operationHoursInterval.endTime }),
    );

    return !isHoliday && isOperationHours;
  }

  loadSchedule(): void {
    this.httpClient.get('/api/zendesk/schedule').subscribe((schedule: ZendeskSchedule) => {
      if (!schedule) {
        logger.error('loadSchedule(): zendesk schedule returned undefined');
      }
      this.scheduleData = schedule;
    });
  }
}
