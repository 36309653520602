import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';

export const verificationConfig = {
  codeErrors: {
    [AuthErrorStatuses.WrongIdentityOrOtp]: 'VERIFICATION.ERRORS.WRONG_CODE',
    [AuthErrorStatuses.InvalidOtpVerificationCode]: 'VERIFICATION.ERRORS.WRONG_CODE',
    [AuthErrorStatuses.InvalidOtp]: 'VERIFICATION.ERRORS.WRONG_CODE',
    [AuthErrorStatuses.TokenExpired]: 'VERIFICATION.ERRORS.TOKEN_EXPIRED',
    [AuthErrorStatuses.PhoneTooManyAttempts]: 'VERIFICATION.ERRORS.TOO_MANY_ATTEMPTS_SMS',
    [AuthErrorStatuses.EmailTooManyAttempts]: 'VERIFICATION.ERRORS.TOO_MANY_ATTEMPTS_EMAIL',
    [AuthErrorStatuses.InvalidPhoneNumber]: 'VERIFICATION.ERRORS.INVALID_PHONE_NUMBER',
    [AuthErrorStatuses.VerificationCodeExpired]: 'VERIFICATION.ERRORS.VERIFICATION_CODE_EXPIRED',
    [AuthErrorStatuses.UnsubscribedSmsRecipient]: 'VERIFICATION.ERRORS.UNSUBSCRIBED_SMS_RECIPIENT',
    [AuthErrorStatuses.UserBlocked]: 'VERIFICATION.ERRORS.USER_BLOCKED',
    default: 'VERIFICATION.ERRORS.DEFAULT',
  },
};
