import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChatSessionType } from '../enums/chat-session-type.enum';
import { LOB } from '../enums/lob.enum';
import { FileUploadRequest } from '../models/file-upload-request.model';
import { ResourceTypesResponse } from '../models/resource-types-response.model';

@Injectable({
  providedIn: 'root',
})
export class FilesUploadDataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  getResourceTypes(lob: LOB): Observable<ResourceTypesResponse> {
    return this.httpClient.get<ResourceTypesResponse>('/api/claims/resource-types-by-lob', {
      params: { lob },
    });
  }

  /* istanbul ignore next */
  uploadChatbotClaimResources(sessionType: ChatSessionType, fileUploadRequest: FileUploadRequest): Observable<string> {
    return this.httpClient.post<string>(
      '/api/chatbot/upload-claim-resources',
      { claimResourcesRequest: fileUploadRequest },
      {
        params: { sessionType },
      },
    );
  }
}
