import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { CoverageRiskLevel } from '../../enums/coverage-risk-level.enum';

@Component({
  selector: 'ni-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeComponent implements OnInit {
  riskLevel: CoverageRiskLevel;
  @Input() riskLevelInput: CoverageRiskLevel;

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.animate();
    setTimeout(() => {
      this.animate(this.riskLevelInput);
      this.cdr.detectChanges();
    }, 1000);
  }

  animate(mode?: CoverageRiskLevel): void {
    this.riskLevel = mode;
    this.cdr.detectChanges();
  }
}
