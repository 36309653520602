import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Policy } from '../../../policies/models/policy.model';
import { ReinstateTrackingService } from '../../../reinstate/services/reinstate-tracking.service';
import { reinstateActions } from '../../../reinstate/store/reinstate.actions';
import { reinstateSelectors } from '../../../reinstate/store/reinstate.selectors';
import { AppState } from '../../../store';
import { ReinstateAgreementModalData } from './reinstate-agreement-modal-data.model';

@Component({
  selector: 'ni-reinstate-agreement-modal',
  templateUrl: './reinstate-agreement-modal.component.html',
  styleUrls: ['./reinstate-agreement-modal.component.scss'],
})
export class ReinstateAgreementModalComponent implements OnInit, OnDestroy {
  modalData: { policy: Policy };
  charge: number;
  chargeSubscription: Subscription;
  reinstateForm: UntypedFormGroup;
  isLoading: boolean;
  isSubmitting: boolean;
  private isLoadingSub = Subscription.EMPTY;
  private isSubmittingSub = Subscription.EMPTY;

  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private reinstateTrackingService: ReinstateTrackingService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<ReinstateAgreementModalData>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.modalData = this.dynamicDialogConfig.data;
    this.chargeSubscription = this.store.pipe(select(reinstateSelectors.reinstateCharge)).subscribe((charge) => {
      this.charge = charge;
    });
    this.store.dispatch(reinstateActions.reinstateFetchCharge({ policy: this.modalData.policy }));
    this.reinstateTrackingService.trackOpenReinstateModal(this.modalData.policy.lob);
    this.listenToCloseEvent();

    this.isLoadingSub = this.store.select(reinstateSelectors.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.cdr.markForCheck();
    });

    this.isSubmittingSub = this.store.select(reinstateSelectors.isSubmitting).subscribe((isSubmitting: boolean) => {
      this.isSubmitting = isSubmitting;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.isLoadingSub.unsubscribe();
    this.isSubmittingSub.unsubscribe();
  }

  private initForm(): void {
    this.reinstateForm = this.fb.group({
      agreementCheckbox: [false, [Validators.requiredTrue]],
    });
  }

  submit(): void {
    this.reinstateTrackingService.trackSubmitReinstate();
    this.store.dispatch(reinstateActions.reinstateRequestSubmit());
  }

  private listenToCloseEvent(): void {
    this.dynamicDialogRef.onClose.pipe(first()).subscribe(() => {
      this.reinstateTrackingService.trackCloseReinstateModal();
    });
  }
}
