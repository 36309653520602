import { Pipe, PipeTransform } from '@angular/core';

import { MobileAppService } from '../../core/services/mobile-app.service';

@Pipe({
  name: 'hidePhoneNumber',
  standalone: true,
})
export class HidePhoneNumberPipe implements PipeTransform {
  constructor(private mobileAppService: MobileAppService) {}

  transform(value: string): string {
    if (!this.mobileAppService.isMobileAppWebview()) {
      return `${value}.WITHOUT_PHONE_NUMBER`;
    }

    return `${value}.WITH_PHONE_NUMBER`;
  }
}
