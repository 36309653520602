import { InteractionType } from './interaction-type.enum';

export interface UserInteractionParams {
  interactionType: InteractionType;
  name: string;
  interactionData?: any;
  placement?: string;
  eventName?: string;
  interactionTimestamp?: number;
  abTestVariant?: string;
  excludeEventName?: boolean;
}

export const eventNamePattern = /^[\d A-Za-z-]{5,150}$/;
