import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormFieldRichMessageContent } from '../models/form-field-rich-message-content.model';

@Injectable()
export class DynamicFormService {
  toFormGroup(formFields: FormFieldRichMessageContent[]): UntypedFormGroup {
    const group: Record<string, UntypedFormControl> = {};

    formFields.forEach((formField) => {
      const field = formField;
      if (field.required && typeof field.required === 'string') {
        // Supporting strings like "false" or "true"
        field.required = (field.required as string).toLowerCase() === 'true';
      }
      group[field.key] = field.required
        ? new UntypedFormControl(field.value || undefined, Validators.required)
        : new UntypedFormControl(field.value || undefined);
      // hidden control must be disabled so its value won't be retrieved in form.value
      if (field.isHidden) {
        group[field.key].disable();
      }
    });
    return new UntypedFormGroup(group);
  }
}
