import { ChangeDetectionStrategy, Component } from '@angular/core';

import { RichMessageType } from '../../enums/rich-message-type.enum';

@Component({
  selector: 'ni-loading-rich-message',
  templateUrl: './loading-rich-message.component.html',
  styleUrls: ['./loading-rich-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingRichMessageComponent {
  static readonly richComponentType = RichMessageType.Loading;
}
